/*  review css*/
.revimg-radius {
    border-radius: 8px 8px 0px 0px;
}
.rev-bg{
    background-color: #fff;
    padding: 20px;
    border-radius: 0px 0px 8px 8px;
}
.review-txt{
    font-size: 15px;
    font-family: 'Poppins' !important;
    color: rgb(7, 7, 7);
    font-weight: 300;
    margin: 10px 0px 10px 0px;
}
.client-name{
    font-size: 18px;
    font-family: 'Poppins' !important;
    font-weight: 600;
    margin: 0px !important;
}
.client-pos{
    font-size: 14px;
    font-family: 'Poppins' !important;
    font-weight: 400;
    margin: 0px;
}
.review-height{
    height: 245px !important;
}
/*  review css*/
.template-page-review{
    background-color: #161616;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-x: hidden;
}

.xsf-sep {
    width: 100%;
    height: 150px;
}

/* ======================================================================================================================================================
HR
====================================================================================================================================================== */

.hr{
    clear: both;
    display:block;
    width:100%;
    height:25px;
    line-height:25px;
    position: relative;
    margin:30px 0;
    float:left;
    }
    
    .hr-inner{
    width:100%;
    position: absolute;
    height:1px;
    left:0;
    top:50%;
    width:100%;
    margin-top:-1px;
    border-top-width:1px;
    border-top-style:solid;
    }
    
    #top .hr-invisible, .hr-invisible .hr-inner{margin:0; border:none; }
    
    .hr-invisible {float:none;}
    
    .hr-full, .hr-big{margin:70px 0;}
    .hr-full .hr-inner{width:300%; left:-100%;}
    .hr-full.hr-shadow .hr-inner{ box-shadow:0 1px 2px 0px rgba(0, 0, 0, 0.1);}
    .sidebar_right .hr-full .hr-inner{left:auto; right:-50px;}
    .sidebar_left .hr-full .hr-inner{left:-50px;}
    
    
    
    .hr-short{height:20px; line-height:20px; margin:30px 0; float:none;}
    .hr-short .hr-inner{ width:32%; left:50%; margin-left:-16%;  }
    .hr-short.hr-left .hr-inner{  left:0%; margin-left:0%;}
    .hr-short.hr-right .hr-inner{ left:auto; right:0; margin-left:0%; }
    .hr-short .hr-inner-style{border-radius: 20px; height:9px; width:9px; border-width:2px; border-style:solid; display:block; position: absolute; left:50%; margin-left:-5px; margin-top:-5px; }
    .hr-short.hr-left .hr-inner-style{  left:5px; }
    .hr-short.hr-right .hr-inner-style{  left:auto; right:0; }
    
    body .container_wrap .hr.avia-builder-el-last, body .container_wrap .hr.avia-builder-el-first {
    margin:30px 0;
    }
    
    
    /*custom*/
    .hr-custom{margin:0; display: block; min-height:21px; height:auto; overflow: hidden; white-space: nowrap; width:100%; float: left;}
    .hr-custom .hr-inner{display: inline-block; position: relative; vertical-align: middle;}
    .hr-custom.hr-center{text-align: center;}
    .hr-custom.hr-right {text-align: right;}
    .hr-custom.hr-left  {text-align: left;}
    
    .hr-custom .hr-inner.inner-border-av-border-none{border:none;}
    .hr-custom .hr-inner.inner-border-av-border-fat{border-top-width:2px;}
    .av-seperator-icon { display: inline-block; vertical-align: middle; font-size: 15px; }
    .hr-custom.hr-icon-yes .hr-inner{margin-left:10px; margin-right:10px;}
















.row:before, .row:after {display: none !important;}
/*header Styling */
body {
    font-family: 'Gotham' !important;
}
html{
	   font-family: 'GothamLight' !important;
	
}
.avia-menu-text,
p {
    font-family: 'GothamLight' !important;
}
.avia-menu-text{
    color:#000 !important;
    font-weight: bolder;
    font-size: 1vw;
}
span.logo {
    padding: 20px;
}
.html_header_transparency #top .avia-builder-el-0 .container, .html_header_transparency #top .avia-builder-el-0 .slideshow_caption {
    padding-top: 0px !important;
}

div#header_main {
    border: none !important;
}
.avia-menu-fx {
    height: 5px;
    margin-bottom: 83px;
    background: #e47558 !important;
    display: block !important;
}
#top .av_header_transparency .avia-menu-fx {
    bottom: 0;
    width: 100%;
    left: 0;
}
.header_color .sign-btn a {
    text-decoration: none !important;
}

.header_bg {
    opacity: 1;
}
.main_menu {
    left: 17vw;
}
.search-bar {
    top: 30%;
    bottom: 0;
    left: 56vw;
    position: absolute;
    z-index: 999;
    color: #ffffff;
    width: 24%;
}
#search-box {
    background: transparent !important;
    color: #000 !important;
    border: none !important;
    box-shadow: none !important;
}
.search-query {
    font-family: 'GothamLight' !important;
    font-size: 13px !important;
}
/*.responsive .container {
    max-width: 1920px !important;
}*/
.magnifying-glass {
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0px;
    margin: auto auto;
    left: 100%;
    width: 20px;
    height: 48px;
    transition: opacity 400ms cubic-bezier(0.694, 0, 0.335, 1), transform 400ms cubic-bezier(0.694, 0, 0.335, 1), visibility 300ms cubic-bezier(0.694, 0, 0.335, 1) 0ms;
}
.sign-btn {
    right: 0;
    /* color: #000 !important; */
    font-size: 20px;
    position: absolute;
    font-family: 'GothamLight' !important;
    font-weight: bolder !important;
    text-transform: uppercase !important;
    z-index:99999;
    text-decoration: none !important;
}
#search-bar button.btn.btn-search {
    vertical-align: top !important;
    border-bottom: 1px solid;
    border-radius: 0;
    bottom: 0px;
    outline: none !important;
    box-shadow: none !important;
    z-index: 1;
    /*margin-top: -55px !important;*/
}
#top #search-bar input[type="text"] {
    margin-bottom: 0px !important;
    border-bottom: 1px solid #000 !important;
    color:#000 !important;
    font-weight: bolder;
    font-size: 0.8vw !important;

}

.av-main-nav > li > a {
    font-weight: normal;
    font-size: 1vw;
}
button#search-submit {
    color: #000;
    background: transparent;
    border: none;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    right: 0px;
    margin: auto auto;
    left: 100%;
    width: 20px;
    height: 36px;
    transition: opacity 400ms cubic-bezier(0.694, 0, 0.335, 1), transform 400ms cubic-bezier(0.694, 0, 0.335, 1), visibility 300ms cubic-bezier(0.694, 0, 0.335, 1) 0ms;
}
button.btn.btn-search {
    background: transparent;
    color: #000;
}

/*Home Page Css */
.slider-heading {
    color: #222222 !important;
    line-height: 1.3;
    text-align: center;
    font-size: 2.3vw;
    font-family: 'GothamBook' !important;
}
.slider-text {
    color: #575758 !important;
    line-height: 1.068;
    text-align: center;
    font-size: 1.6vw;
    margin-bottom: 50px;
    font-family: 'GothamLight' !important;
}
.slider-btn {
    border: 1px solid #474747 !important;
    padding: 8px 50px;
    border-radius: 30px/30px;
    color: #474747 !important;
    text-decoration: none !important;
    font-size: 1.7vw;
    font-family: 'GothamBook' !important;
}
.content-heading {
    color: #606060;
    text-transform: capitalize !important;
    font-size: 1.7vw;
    font-family: 'GothamBook' !important;
}
#first_section {
    padding: 0 0px 0 80px;
}
#first_section div .av_three_fifth {
    margin-left: 6%;
    width: 52.6% !important;
}
.text-content {
    color: #606060;
    font-size: 1vw;
    line-height: 1.3;
    font-family: 'GothamLight' !important;
}
.start-now {
    color: #80c3c2 !important;
    text-decoration: none !important;
    font-size: 1.3vw;
    font-family: 'GothamBook' !important;
}
h3.slide-entry-title.entry-title {
    display: none;
}
/*Dot Css on home page Custom/Content Slider*/
#top .avia-smallarrow-slider .avia-slideshow-dots a {
   border-style: solid;
    border-width: 1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-bottom: -1vw !important;
    /* margin-left: 0; */
}
.avia-slideshow-dots{
    z-index :99999 !important;
}

#top .avia-content-slider-element-container .avia-slideshow-dots{

        /* left: 0; */
    text-align: left !important;
    margin-left: 55px !important;
    width: 100% !important;
}
#top .avia-smallarrow-slider  .avia-slideshow-dots{

        position: absolute !important;
    width: 100% !important;
    left: 0 !important;
    bottom: 0 !important;
    margin-top: 0px !important;
}
#top .avia-smallarrow-slider-heading> div {
    display: block !important;
}
.avia-slideshow-dots{
    height: auto !important;
}

.avia-content-slider .slide-entry-excerpt{
    padding: 0px !important;
}
a.goto-slide {
    border: 1px solid #82cccb;
}
a.goto-slide.active,
a.goto-slide:hover {
    background: #82cccb !important;
    border: 1px solid #82cccb;
}
/*Dot Css on home page Custom/Content Slider*/
#second_section {
    padding-right: 0px;
}
.right {
    width: 45%;
    float: right;
    padding-top: 200px;
}
.left {
    width: 45%;
    float: left;
}
.content-heading1 {
    color: #606060;
    text-transform: capitalize !important;
    font-size: 3.0vw;
    text-align: center;
    font-weight: 400;
    margin-bottom: 50px !important;
    font-family: 'GothamBook' !important;
}
.get-started-btn {
    border: 1px solid #e7866d !important;
    background: #e7866d;
    padding: 8px 70px;
    border-radius: 21px /20px;
    color: #fff !important;
    text-decoration: none !important;
    font-size: 1.7vw;
    font-family: 'GothamBook' !important;
}
h3.widgettitle {
    font-size: 23px;
    font-family: 'GothamBook' !important;
}
.footer-com-desc{
margin-top: 1.6vw !important;
font-size:16px !important;
line-height: 2;

}


#footer .flex_column:nth-child(1) {
    width: 15% !important;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(2) {
    width: 15% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(3) {
    width: 15% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(4) {
    width: 15% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(5) {
    width: 30% !important;
    margin-left: 2% !important;
}
#footer {
    padding: 50px 40px 40px 50px;
    font-size: 19px;
    border-top: 2px solid #000000;
    background: #ffffff !important;
}
#footer .textwidget {
    line-height: 1.8;
}
.footer-links {
    font-family: 'GothamLight' !important;
    text-decoration: none;
    font-size: 16px !important;
}
.footer-links:hover,.footer-links:focus {
    color: #e47558 !important;
    text-decoration: none;
}
.footer-column {
    margin-top: 60px;
}
.slider-btn1 {
    font-size: 31px;
    cursor: pointer;
    background: 0 0;
    width: initial;
    border: 1px solid #000 !important;
    transition: all .5s ease-in-out;
    display: inline-block;
    margin: 0;
    border: 1px solid #474747 !important;
    padding: 18px 50px;
    border-radius: 30px/30px;
    color: #474747 !important;
    text-decoration: none !important;
    font-size: 1.6vw;
    font-family: 'GothamBook' !important;
}
.slider-btn1:hover {
    text-decoration: none !important;
    background: #e47558 !important;
    border-color: #e47558 !important;
    color: #fff !important;
}
div#header_main:hover {
    background: #ffffff !important;
    transition: all .8s ease-in-out !important;
    webkit-animation: .8s ease-in-out !important;
    animation: .8s linear ease-in-out !important;
}

/* About Us Page CSS*/
.slideshow_caption {
    padding: 0 30px !important;
}
html,body {
    overflow-x: hidden;
}
/*.avia-builder-el-0.avia-fullscreen-slider .avia-slideshow {
    height: 100% !important;
}*/
.about-sub-heading {
    line-height: 1.2 !important;
}

.page-id-259 .avia-slideshow-dots {
    display: none;
}
.inner-page-heading {
    font-size: 3.4vw !important;
    color: #080808;
    font-family: 'GothamLight' !important;
    text-transform: capitalize;
    font-weight: normal;
}
.page-id-259 #fullscreen_slider_0 .slideshow_align_caption {
    display: table-cell;
}
.about-text-content {
    color: #606060;
    font-size: 1.1vw;
    line-height: 1.5;
    font-family: 'GothamLight' !important;
}
.about-content-heading {
    color: #080808 !important;
    text-transform: capitalize !important;
    font-size: 2.5vw;
    font-family: 'GothamBook' !important;
}
.about-heading {
    font-size: 2.5vw;
    font-family: 'GothamMedium' !important;
    font-weight: normal;
    text-transform: capitalize;
}
.about-sub-heading {
    font-size: 2.1vw;
    font-family: 'GothamBook' !important;
}
.about-sub-text {
    font-size: 1.2vw;
    line-height: 1.4;
    font-family: 'GothamLight' !important;
    font-weight: lighter;
}
.web-separator {
    width: 50px;
    height: 50px;
}
.web-small-separator {
    width: 10px;
    height: 10px;
}
.page-id-259 .slideshow_caption {
    z-index: 100;
    width: 65%;
    position: absolute;
    bottom: 0;
    top: auto;
    height: 100%;
    display: block;
    text-decoration: none;
    padding: 50px;
}
/*.page-id-259 .slideshow_align_caption {
    display: block;
}*/
.about-get-started {
    cursor: pointer;
    background: 0 0;
    width: initial;
    border: 1px solid #e47558 !important;
    transition: all .5s ease-in-out;
    display: inline-block;
    margin: 0;
    padding: 18px 50px;
    border-radius: 30px/30px;
    color: #e47558 !important;
    text-decoration: none !important;
    font-size: 1.5vw;
    font-family: 'GothamBook' !important;
}
.about-get-started:hover {
    text-decoration: none !important;
    background: #e47558 !important;
    border-color: #e47558 !important;
    color: #fff !important;
}
.need-more-info,
.need-more-info:hover {
    font-family: 'GothamBook' !important;
    text-decoration: none;
    font-size: 25px;
}
/* Contact Us Page CSS*/

.contact-page-banner-heading {
    font-size: 3.5vw;
    color: #FFF;
    text-shadow: 6px 6px 16px #080808;
    font-family: 'GothamLight' !important;
    text-transform: capitalize;
    font-weight: normal;
    line-height: 1.0;
}
h1.cls-contact-us-heading {
    color: #fff;
    font-family: 'GothamMedium';
    font-weight: normal;
    font-size: 2.0vw;
    text-transform: capitalize !important;
}
.cls-contact-us-text {
    color: #fff;
    font-size: 1.4vw;
    font-family: 'GothamLight' !important;
    font-weight: normal;
    line-height: 1.2;
}
h1.contact-content-heading {
    color: #080808 !important;
    font-family: 'GothamLight';
    font-weight: bolder;
    text-transform: none !important;
    font-size: 1.8vw;
}

#top #contactus .av-flex-placeholder {
    display: table-cell;
    width: 3px;
}
#contactus .container {
    padding: 0px 50px!important;
}

.cls-contact-btn:hover {
    background: #fff;
    color: #545454 !important;
    text-decoration: none !important;
}
.cls-contact-btn {
   border: 2px solid #dce9e2;
    color: #fff !important;
    text-decoration: none !important;
    padding: 7px 70px;
    border-radius: 15px;
    font-size: 1.5vw;
    font-family: 'GothamLight';
    text-decoration: none;
    cursor: pointer;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
}
#top #contactus .flex_column_table {
    display: table;
    table-layout: fixed;
    width: 100%;
    /*height: 450px;*/
    height: 4vw !important;
}

/* web-page-package Page Css*/

.page-id-758 .main_color.container_wrap_first.container_wrap.fullsize {
    display: none;
}

.cls-web-page-package-heading {
    text-align: center;
    font-size: 1.7vw;
line-height:1.0;
}
h3.cls-web-page-package-text {
    text-align: center;
    font-weight: 500;
    font-family: 'GothamMedium';
    font-size: 2.0vw;
    color: #000 !important;
}

.web-page-package-column {
    float: left;
    width: 460px;
  background:#fff;
    border: 0px solid #CC6666;
    border-radius: 32px/28px;
    display: inline-block;
    height: 600px;
}
.web-page-package-column-right {
    float: right;
    width: 460px;
    border: 0px solid #80c3c2;
    border-radius: 32px/28px;
    display: inline-block;
height:600px;
background:#fff;
}
.web-page-package-heading-right {
    font-size: 1.7vw;
    text-align: center;
    color: #fff;
    font-family: 'GothamLight' !important;
}
.web-page-package-header-right {
    border-radius: 25px 25px 0px 0px;
    text-align: center;
    background: #80c3c2;
    padding: 20px 0;
}
.web-page-package-heading {
    font-size: 1.7vw;
    text-align: center;
    color: #fff;
    font-family: 'GothamLight' !important;
}
.web-page-package-header {
    border-radius: 25px 25px 0px 0px;
    text-align: center;
    background: #e47558;
    padding: 20px 0;
}
.web-page-package-body {
    padding: 20px;
background:#fff;
}
.seprator-big {
    width: 30px;
    height: 30px;
}
.seprator-small {
    width: 20px;
    height: 20px;
}
.web-page-package-body-row {
    text-align: center;
    font-size: 15px;
    line-height: 1.3;
    color: #838484;
    font-family: 'GothamLight' !important;
}
.web-page-package-price {
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    color: #e47558;
    font-family: 'Gotham' !important;
}
.web-page-package-price-right {
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    color: #80c3c2;
    font-family: 'Gotham' !important;
}
.web-page-package-price-subtext {
    font-size: 18px;
    text-align: center;
    color: #e47558;
    font-family: 'GothamLight' !important;
}
.web-page-package-price-subtext-right {
    font-size: 18px;
    text-align: center;
    color: #80c3c2;
    font-family: 'GothamLight' !important;
}
.web-page-package-description {
    text-align: left;
}
.web-page-package-description-list {
    font-size: 18px;
    line-height: 1.4;
    color: #838484;
    font-family: 'GothamLight' !important;
}
.web-page-package-footer {
    text-align: center;
}
.web-page-package-footer-text {
    color: #4c4b4b;
    font-weight: bold;
    line-height: 0.876;
    text-align: center;
    font-size: 20px;
    font-family: 'GothamBold' !important;
}
.web-page-packages-fee-heading {
    font-size: 2.4vw;
    color: #fff;
    font-family: 'GothamMedium' !important;
}
.web-page-packages-fee-text {
    font-size: 1.4vw;
    line-height: 1.5;
    color: #fff;
    font-family: 'GothamLight' !important;
}
.web-page-packages-fee-sub-text {
    font-family: 'GothamLight' !important;
    color: #fff;
    font-size: 20px;
}
.web-page-packages-no-extra-fee {
    font-family: 'GothamMedium' !important;
    color: #fff;
    font-size: 1.9vw;
line-height:1.0;
}

.standard-web-pack-page-btn {
  font-family: 'GothamLight' !important;
    color: #ffffff !important;
    font-size: 1.7vw;
    background: #fff;
    padding: 10px 40px;
    border-radius: 20px;
    text-decoration: none;
    cursor: pointer;
    background: 0 0;
    width: initial;
    border: 1px solid #fff !important;
    transition: all .5s ease-in-out;
}
.standard-web-pack-page-btn:hover {
    text-decoration: none;
background:#fff;
color:#e47558 !important;

}
.ultimate-pack-page-btn {
    font-family: 'GothamLight' !important;
    color: #fff !important;
    font-size: 1.7vw;
    background: #fff;
    padding: 10px 40px;
    border-radius: 20px;
text-decoration:none;
 cursor: pointer;
    background: 0 0;
    width: initial;
    border: 1px solid #fff !important;
    transition: all .5s ease-in-out;
}
.ultimate-pack-page-btn:hover {
    text-decoration: none;
color:#80c3c2 !important;
background:#fff;
}

.div-pack-btn{

    text-align: left;
    width: 100%;
    margin-top: 80px;
}
span.web-page-packages-fee-subheading {
    font-size: 1.2vw;
    font-weight: lighter;
    font-family: 'GothamLight';
}

.web-page-pack-column {
    float: left;
    width: 50%;
    padding: 10px;
  float: none;
    display: table-cell;
vertical-align: middle !important;
}

/* Clear floats after the columns */
.pack-row-container:after {
    content: "";
    display: table;
    clear: both;
}
.pack-row-container{
display: table;
    table-layout: fixed;
    width: 100%;

}
    

/* Process Page Css */

h1.process-step-heading {
    text-align: center;
    color: #e47558;
    font-family: 'GothamBook';
    font-size: 2.2vw !important;
text-transform: capitalize !important;
}
.step_details {
    font-size: 1.4vw !important;
    font-family: 'GothamLight';
   line-height:1.1;
}
.process-get-started, .process-get-started:hover {
    color: #80c3c2 !important;
    text-decoration: none !important;
}
.process-step-done {
    font-size: 3.2vw !important;
    font-family: 'GothamLight';
    font-weight: normal;
    text-transform: none !important;
    color: #2a2a2a !important;
}
a.cls-done-get-started {
    border: 2px solid #e47558;
    padding: 14px 50px;
    font-size: 24px;
    border-radius: 14px;
    font-family: 'GothamMedium';
    color: #e47558;
    text-decoration: none !important;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
}
a.cls-done-get-started:hover{
color:#fff;
 background: #e47558;
}

/*Review Page*/
.review-column {
    height: auto !important;
}
.review-clients {
    padding: 0px !important
}
.reviews-heading {
    font-size: 38px!important;
    color: #222;
    font-family: GothamMedium !important;
    line-height: 56px;
    font-weight: 700;
    text-align: center;
}
.reviews-subtext {
    text-align: center;
    font-size: 1.8vw;
    font-family: 'GothamLight' !important;
    color: #222 !important;
}
.review-column {
    float: left;
    width: 33.33%;
    padding: 10px;
    height: 100%; 
}

/* Clear floats after the columns */
.container-row:after {
    content: "";
    display: table;
    clear: both;
}
.vertical-seperator {
  float: right;
    background: #e47558;
    height: 400px;
    width: 2px;
    margin-top: -375px;
}
.review-content {
    padding: 30px 20px;
}
.review-clients {
    font-size: 18px;
    color: #222 !important;
    padding: 20 0 0 20px;
    line-height: 1.2;
}
.review-company-logo{
text-align:center;
}

/* .vertical-divider{
    clear: both;
    position: relative;
} */

/* .vertical-divider:after {
    clear: both;
    content: " ";
    display: block;
    height: 0;
    visibility: hidden;
} */

/* .vertical-divider .review-column:not(:first-child):after, .vertical-divider .review-columns:not(:first-child):after{
    background: #002536;
    bottom: 0;
    content: " ";
    margin-left: -10px;
    position: absolute;
    top: 0;
    width: 0.2ch;
} */
.client-details {
    color: #3f3f3f !important;
    font-family: 'GothamBook' !important;
    font-weight: bolder;
    font-size: 18px;
    margin: 20px 0 0 20px !important;
}
.client-details > span {
    color: #222222 !important;
    font-family: 'GothamLight' !important;
font-size:16px;
}
a.reviews-btn{
    color: #e47558 !important;
    border: 1px solid #e47558;
    padding: 7px 60px;
    text-decoration: none;
    background: #ffffff;
    border-radius: 26px/26px;
    font-size: 25px;
    font-family: 'GothamLight' !important;
    transition: all .5s ease-in-out;
    cursor: pointer;
}

.reviews-btn:hover {
    background: #e47558;
    color: #fff !important;
}

/* Design Page */

.design-heading
{
text-align: center;
    font-family: 'GothamBook';
    color: #222222 !important;
    text-transform: capitalize !important;
    font-size: 2.8vw !important;
}
p.design-subtext
{
text-align: center;
    font-size: 1.6vw !important;
    font-family: 'GothamLight' !important;
    color: #222 !important;
    line-height: 1.0;
    margin: 0;
}
.design-separator{
width:40px;
height:40px;
}
.design-image{
box-shadow: 3px 3px 50px #b1b1b1;
}
h2.design-theme-name {
    font-family: 'GothamBook';
    font-size: 1.5vw !important;
    color: #080808;
}
p.design-theme-description {
    font-family: 'GothamLight';
    font-size: 1.0vw !important;
    color: #080808;
    line-height: 1.4;
    width: 95%;
    height: 7vw;
}
p.design-sub-theme-description b{
     font-family: 'GothamLight';
    font-size: 1.0vw !important;
    color: #080808;
    line-height: 1.4;
    width: 95%;
}

/* Design Page Addition*/
h2.web-theme-name {
    color: #e47558;
    font-family: 'GothamLight';
    font-size: 30px;
    margin-bottom: 20px;
}
a.web-theme-btn.next {
    padding: 12px 48px;
    text-align: center;
    color: white;
    z-index: 1;
    text-decoration: none;
    background: #e4775b;
    border-radius: 25px/26px;
    font-family: 'GothamLight';
    margin: 0 2px;
}
a.webpage-view-btn {
    color: #080808;
    text-decoration: none;
    font-family: 'GothamLight';
    font-size: 23px;
    /* border-bottom: 2px solid #000; */
    transition: background-color 0.45s, border-bottom 0.45s;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.webpage-view-btn:hover:after {
    transform: scaleX(0.5);
}
.webpage-view-btn:after {
    display: block;
    content: ' ';
    border-bottom: solid 2px #080808;
    transform: scaleX(1);
    transition: transform 250ms ease-in-out;
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
}
.web-page-design{
padding-left: 1.8vw;
}

 div#webpage-design-theme {
    background: #ffffff;
}
 .page-id-912 div#av_section_1{
    background: #ffffff; 
}
.page-id-912 div#av_section_3{
      background: #ffffff; 
}

.page-id-912 .main_color.container_wrap_first.container_wrap.fullsize{
display: none;
}
/* Design preview form webpage*/
.webpage-theme-preview {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 1;
    background: #ffffff;
    padding: 0px 4%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: opacity .3s ;
    z-index: 999;
 }

.webpage-theme-preview header {
    width: 100%;
    height: 130px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}




.webpage-theme-preview .webpage-exit {
    position: absolute;
    top: 5px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
}

.webpage-theme-preview  .webpage-theme-preview-content {
    width: 100%;
    height: calc(100vh - 110px - 40px);
     background: #fff;
}
iframe#webpage_theme_preview_iframe {
    box-shadow: 2px 2px 40px #cecbcb;
}
#webpage_theme_preview_iframe{
    outline: none !important;
}

a.webpage-choose-design {
    background: #64bef8;
    color: #fff !important;
    padding: 15px 28px;
    font-family: 'GothamLight';
    font-size: 18px;
    border-radius: 40px;
    text-decoration: none;
    cursor: pointer;
}







/*Feature Page*/
#feature_section .container{
padding: 0 100px;
}
.feature-page-heading {
    font-size: 3.0vw  !important;
    line-height: 1.1;
    color: #080808;
    font-family: 'GothamLight' !important;
    text-transform: capitalize;
    font-weight: normal;
}
h2.features-heading {
    font-family: 'GothamBook';
    color: #e47558;
    text-transform: capitalize !important;
    font-size: 30px;
    font-weight: lighter;
}
p.features-text {
    font-family: 'GothamLight' !important;
    font-size: 1.0vw;
    line-height: 1.2;
}
p.features-end-text {
    font-family: 'GothamBook';
    font-weight: bold;
    color: #323232;
    font-size: 30px;
    text-align: center;
    line-height: 1.2;
    padding: 0 50px;
}
.features-column{
    z-index: 1;
    float: left;
    position: relative;
    min-height: 1px;
}
.features-half{
    margin-left: 6%;
    width: 47%;
}
.features-first{
margin-left:0px ;
    clear: left;
}
.features-display{
    float: none;
    display: table-cell;
}

.feature-seprator {
    width: 40px;
    height: 40px;
}

/*Porfolio Static Page */

.porfolio-column{
    z-index: 1;
    float: left;
    position: relative;
    min-height: 1px;
}
.porfolio-half{
    margin-left: 6%;
    width: 47%;
}
.porfolio-first{
margin-left:0px ;
    clear: left;
}
.porfolio-column-display{
float:none;
display:table-cell;
}
.porfolio-align{
vertical-align:middle;
}
.vertical-placeholder{
    display: table-cell;
    width: 6%;
}
.portfolio-theme-description {
    font-size: 1.5vw !important;
    line-height: 1.1;
    margin: 0;
    width: 450px;
    color: #222;
    font-family: 'GothamLight' !important;
}
.portfolio-btn{
border: 1px solid #474747 !important;
    padding: 8px 50px;
    color: #474747 !important;
    text-decoration: none !important;
    font-size: 1.4vw !important;
    font-family: 'GothamBook' !important;
    text-decoration:none;
   cursor: pointer;
    background: 0 0;
    width: initial;
    border: 1px solid #000 !important;
    border-radius: 21px /20px;
    transition: all .5s ease-in-out;
}
.portfolio-btn:hover{
text-decoration: none !important;
    background: #e47558 !important;
    border-color: #e47558 !important;
    color: #fff !important;
}

.portfolio-heading {
    text-align: center;
    font-family: 'GothamBook' !important;
    color: #222222;
    font-size: 2.2vw;
    text-transform: capitalize !important;
}
/* Portfolio Static Page End */

/* Privacy Policy Page*/

h1.privacy-policy-heading {
    text-align: center;
    color: #222;
    text-transform: capitalize !important;
    font-family: 'GothamBook';
    font-weight: 800;
    font-size: 55px;
}
h1.privacy-sub-headings {
    font-size: 25px;
    color: #222;
    font-family: 'GothamBook';
    font-weight: bolder;
    line-height: 1.0;
}
p.privacy-sub-text {
    font-size: 20px;
    line-height: 1.5;
    font-family: 'GothamLight';
    color: #080808;
}
ul.privacy-list {
    font-size: 20px;
    font-family: 'GothamLight';
    color: #080808;
}
.privacy-container {
    padding: 0 80px;
}

/* Terms And Condition Page*/

.terms-heading{
    text-align: center;
    color: #222 !important;
    text-transform: capitalize !important;
    font-family: 'GothamBook';
    font-weight: 800;
    font-size: 55px;
}
h1.terms-sub-headings {
    font-size: 25px;
    color: #222;
    font-family: 'GothamBook';
    font-weight: bolder;
    line-height: 1.0;
}
ul.terms-list {
    list-style-type: decimal;
    font-size: 20px;
    line-height: 1.5;
    font-family: 'GothamLight';
    color: #080808 !important;
margin-left:1em;
}
.terms-container{
padding: 0 80px;
}




/* Mobile Responsivness */
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .template-page-review{
        padding: 20px !important;
    }
.avia-menu-text{
    font-size: 16px !important;
}

/* slider */
  .slider-heading{
    font-size: 22px !important;
  }
  .slider-text {
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.slider-btn1 {
    padding: 11px 30px;
    font-size: 18px;
}
.avia-caption-content {
    margin-top: -100px;
}


/* section1  */

#first_section div .av_three_fifth{
    width: 100% !important;
}

#main-puzzle{
    height: 100vw !important;
}

#first_section{
    padding: 0 0px 0 58px;
}
 
.hr.hr-invisible.avia-builder-el-2.el_before_av_two_fifth.avia-builder-el-first , .hr.hr-invisible.avia-builder-el-5.el_after_av_codeblock.el_before_av_codeblock {
    height: 40px !important;
}
.content-heading
{
  font-size: 18px !important;
}
.text-content{
  font-size: 15px !important;
}
.start-now
{
  font-size: 18px !important;
}
.content-heading1{
  font-size: 25px !important;
}
.get-started-btn{
      padding: 8px 30px;
      font-size: 23px !important;
}

/*Section 1 end*/

/*Section 2 start*/
.responsive #top .avia-slideshow-controls a {
    display: inline-block !important;
}
/*Section 2 end*/

#footer .flex_column:nth-child(1) {
    width: 100% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(2) {
    width: 100% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(3) {
    width: 100% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(4) {
    width: 100% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(5) {
    width: 100% !important;
    margin-left: 2% !important;
}
#footer {
    padding: 10px;
}





    /* Footer */
    #footer {
    padding: 20px 20px !important;
    }
    .footer-column{
    margin-top: 25px;
    }
    #footer .widget{
        margin: 0 !important;
    }
    .search-bar {
        display: none;
    }
    .sign-btn {
        display: none;
    }

    /* web-page-packages Page */

    h3.cls-web-page-package-text{
        font-size: 26px !important;
    }

    .column {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 20px;
    }

/* Features Page */

.feature-page-heading{

    font-size: 30px !important;
}
    
.responsive #top .features_column_table_cell {
   display: block;
}
p.features-text {

font-size: 16px !important;
}
.features-column{
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
}
.features-half{
    width: 100%;
}
.hr.hr-invisible.avia-builder-el-3.el_after_av_codeblock.el_before_av_codeblock {
    height: 10px;
}
p.features-end-text{
    padding: 0;
    font-size: 20px;
    text-align: left;
}
/* Review Page */
    .review-column{
        width: 100%;
    }
     .review-content {
    
    padding: 0 !important;
    }
    .column {
    width: 100% !important;
    padding: 0 !important;
    }
  /* .vertical-divider:after {
    display: none;
    } */
    .reviews-heading{
        font-size: 45px !important;
    } 
    .reviews-subtext{
         font-size: 20px !important;
    }
    .review-clients{
        padding: 0;
    }
    /* Design Page */

    h2.web-theme-name{
        font-size: 18px !important;
        margin-bottom: 0 !important;
    }
    a.web-theme-btn.next{
        font-size: 13px !important;
        padding: 7px 32px !important;
    }
    a.webpage-view-btn{
        font-size: 15px !important; 
    }

    .design-heading{

        font-size:30px !important;
        margin-top: 50px !important;
    }
    .design-subtext{
        font-size:20px !important;
    }
    p.design-subtext{
        font-size: 16px !important; 
    }
    p.design-theme-description{
        font-size: 16px !important;
        height: 100% !important;
    }
    p.design-sub-theme-description b{
        font-size: 16px !important;
    }
    .web-page-design {
    padding-left: 5px;
    }
    h2.design-theme-name{
        font-size: 22px !important;
    }
    .webpage-theme-preview  .webpage-theme-preview-content{
   width: 100%;
   height: 100%;
   -webkit-overflow-scrolling: touch !important;
     overflow-y: scroll !important;
    }

    /*Process Page*/
    .inner-page-heading {
    font-size: 30px !important;
    line-height: 1.0;
    }
    h1.process-step-heading{
        font-size: 30px !important;
    }
    .step_details {
    font-size: 16px !important;
    }
    .process-step-done{
        font-size: 30px !important;
    }
    /* Portfolio Page*/
    .portfolio-heading {
        font-size: 22px !important;
        padding: 25px 0 !important;
    }
    .responsive #top .porfolio_column_table_cell {
       display: block;
    }
    .porfolio-column{
        margin: 0;
        margin-bottom: 20px;
        width: 100%;
    }
    .portfolio-theme-description {
    font-size: 23px !important;
    line-height: 1.1;
    width: 100%;
    }
    .cls-web-page-package-heading{
        font-size: 25px !important;
    }
    .web-page-package-heading, .web-page-package-heading-right {
    font-size: 18px;
    }
    /*Package page*/
    .web-page-pack-column{
        display: table !important;
        width: 100% !important;
        margin-top: 25px;
    }
    .web-page-package-column,.web-page-package-column-right{
        width: 100% !important;
    }
    .web-page-package-heading,.web-page-package-heading-right {
    font-size: 20px !important;
    }
    .web-page-package-description-list {
    font-size: 16px !important; 
    }
    .web-page-packages-fee-heading {
    font-size: 35px !important;
    }
    span.web-page-packages-fee-subheading{
        font-size: 16px !important;
     }
    .web-page-packages-fee-text {
    font-size: 22px !important;
    }
    .web-page-packages-no-extra-fee {
    font-size: 16px !important;
     }
     .standard-web-pack-page-btn, .ultimate-pack-page-btn{
        padding: 10px 20px;
        font-size: 25px !important;
     }
      #ultimate_package .container {
    height: 1200px !important;
     }

.web-page-package-column-right{

    height: 650px !important;
}
    .div-pack-btn{

    width: 100%;
}
/* About Us*/
.about-content-heading{
    font-size: 36px !important;
}
.about-text-content{

    font-size: 16px !important;
}
.about-heading {
    font-size: 30px !important;
    line-height: 1.0;
    }
    .about-sub-heading {
    font-size: 30px !important;
    line-height: 1.0;
}
.about-sub-text {
    font-size: 16px !important;
}
.about-get-started{

        padding: 10px 35px !important;
        font-size: 20px !important;
}
  /*Terms of services*/

.terms-container{
    padding: 0;
}
.terms-heading{
    font-size: 30px;
}
h1.terms-sub-headings{
    font-size: 22px;
}
ul.terms-list{
    font-size: 16px;
}
/*Privacy Policy Page*/
p.privacy-sub-text{
    font-size: 16px;
}
h1.privacy-policy-heading{
        font-size: 22px;
}
.privacy-container{
    padding: 0;
}
/* Contact Us Page*/
.responsive #top .flex_column_table {
    display: block !important;
}

h1.cls-contact-us-heading{
    font-size: 23px !important;
}
.cls-contact-btn{
    font-size: 23px !important;
}

#contactus .container {
    padding: 0px 0px!important;
}
#top #contactus .flex_column_table{
    height: 100%;
}
.cls-contact-us-text{
    font-size: 18px !important;
}
.contact-page-banner-heading {
    font-size: 30px !important;
}
h1.contact-content-heading{
    font-size: 26px !important;
}

.portfolio-btn{
    font-size: 20px !important;
}
.client-details{
    margin: 0 !important;
}
.review-clients{
    padding: 0 !important;
}
}

/*Ipad pro*/
@media only screen and (min-width: 1024px) and (max-width: 1260px) {

#footer {
    padding: 0;
}
#footer .flex_column:nth-child(1) {
    width: 135px !important;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(2) {
    width: 135px !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(3) {
    width: 135px !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(4) {
    width: 166px !important;
    margin-left: 20px !important;
}
#footer .flex_column:nth-child(5) {
    width: 240px !important;
    margin-left: 2% !important;
}

}

/*Ipad pro end*/


   
/* Tablet Responsivness */
@media only screen and (min-width: 768px) and (max-width: 996px) {

/*#search-bar button.btn.btn-search{
    bottom: 1px !important;
}*/
#top #search-bar input[type="text"]{
   font-size: 16px !important; 
}
/*Section 1*/
#first_section div .av_three_fifth{
    width: 100% !important;
}

.avia-menu-text{

    font-size:16px !important;
}

#main-puzzle{
    height: 80vw !important;
}
#first_section div .av_three_fifth {
    width: 100%;
    margin-left: 0 !important;
}

    .search-bar {
        left: 40%;
        width: 44%;
    }
    .sign-btn {
        display: none;
    }
    .web-page-package-column,.web-page-package-column-right{
        width: 96% !important;
    }

    .slider-heading{
        font-size: 25px !important;
    }
    .slider-text{
        font-size: 20px !important;
    }
    .slider-btn1{
        font-size: 23px !important;
    }
    
    #first_section div .av_two_fifth{
        width: 100%;
    }
    #second_section div .av_one_half{
        width: 100% !important;
        margin-left: 0 !important; 
    }
    #top #second_section .flex_column_table,#top #second_section .flex_column_table_cell,#top #first_section .flex_column_table,#top #first_section .flex_column_table_cell  {
    display: block;
    }

    .hr.hr-invisible.avia-builder-el-5.el_after_av_codeblock.el_before_av_codeblock {
    height: 100px !important;
}


.content-heading{
    font-size: 26px !important;
}
.text-content{
    font-size: 18px !important;
}
.start-now{
    font-size: 22px !important;
}
.content-heading1{
    font-size: 30px !important;
}

.get-started-btn{
    font-size: 25px !important;
}
/*
#top .avia-smallarrow-slider .avia-slideshow-dots a{
        margin-bottom: -33% !important;
    left: -91.4% !important;
}
*/

    /* Features Page*/

    #feature_section .container{
        padding: 0 50px;
    }
    h2.features-heading{
        font-size: 18px;
    }
    p.features-text{
        font-size: 16px !important;
    }
    .feature-page-heading{
        font-size: 35px !important;
    }
    p.features-end-text{
        font-size: 22px;
        padding: 0;
    }
    .page-id-517 .hr.hr-invisible.avia-builder-el-3.el_after_av_codeblock.el_before_av_codeblock{
        height: 20px !important;
    }


    /* Review Page */
  .reviews-heading{
    font-size: 30px !important;
  }
    .review-content{
        padding: 0 !important;
    }
     .review-column{
        width: 100%;
    }
     .review-content {
    
    padding: 0 !important;
    }
    .column {
    width: 100% !important;
    padding: 0 !important;
    }
  /* .vertical-divider:after {
    display: none;
    } */
    .design-heading{

        font-size:35px !important;
    }
    .design-subtext{
        font-size:20px !important;
    }
    .web-page-design {
    padding-left: 5px;
    }
    h2.design-theme-name{
        font-size: 22px !important;
    }
    .reviews-subtext{
        font-size: 20px !important;
        line-height: 1.0;
    }
    .review-clients{
        padding: 0;
    }

    /* Design Page*/
    p.design-subtext{
        font-size: 16px !important;
    }
    p.design-theme-description
    {
        font-size: 16px !important;
        height: 100% !important;
    }
    p.design-sub-theme-description b
    {
        font-size: 16px !important;
    }
    #top #webpage-design-theme .flex_column_table,#top #webpage-design-theme .flex_column_table_cell  {
    display: block;
    width: 100%;
    }
    
    /* Process Page*/
    .inner-page-heading {
    font-size: 35px !important;
    line-height: 1.0;
    }
    .process-step-done{
        font-size: 48px !important;
    }
    h1.process-step-heading{
        font-size: 30px !important;
    }
    .step_details{
        font-size: 16px !important;
    }
    /*Portfolio page*/
    .portfolio-heading{
        font-size: 22px !important;
    }
    .portfolio-theme-description {
    font-size: 16px !important;
    line-height: 1.1;
    width: 100%;
    }
    .portfolio-theme-description{
        font-size: 20px;
    }
    .portfolio-btn{
        font-size: 20px !important;
    }
    /* Package Page*/
    h3.cls-web-page-package-text,.cls-web-page-package-heading {
        font-size: 26px !important;
    }
    .web-page-package-heading,.web-page-package-heading-right {
    font-size: 20px !important;
    }
    .web-page-package-description-list {
    font-size: 16px !important;
    }
    .web-page-packages-fee-heading {
    font-size: 35px !important;
    }
    .web-page-packages-fee-text {
    font-size: 22px !important;
    }
    .web-page-packages-no-extra-fee {
    font-size: 16px !important;
     }
     .standard-web-pack-page-btn, .ultimate-pack-page-btn{
        padding: 10px 20px;
        font-size: 22px !important;
     }
     span.web-page-packages-fee-subheading{
        font-size: 16px !important;
     }
     /*About Page*/
     .about-heading {
    font-size: 30px !important;
    line-height: 1.0;
    }
    .about-sub-heading {
    font-size: 30px !important;
    line-height: 1.0;
}
.about-get-started{

        padding: 10px 35px !important;
        font-size: 20px !important;
}
.about-text-content{
    font-size: 16px !important;
}
.about-sub-text {
    font-size: 16px !important;
}
.about-content-heading{
    font-size: 36px !important;
}
/*Contact Page*/
.contact-page-banner-heading {
    font-size: 30px !important;
}
h1.cls-contact-us-heading{
    font-size: 23px !important;
}
.cls-contact-us-text{
    font-size: 18px !important;
}
.cls-contact-btn{
    font-size: 23px !important;
}
h1.contact-content-heading
{
    font-size: 26px !important;
}

/*Terms Page*/
 .terms-container{
    padding: 0;
}
.terms-heading{
    font-size: 30px;
}
h1.terms-sub-headings{
    font-size: 22px;
}
ul.terms-list{
    font-size: 16px;
}   
p.privacy-sub-text{
    font-size: 16px;
}
/*Privacy Policy Page*/
p.privacy-sub-text{
    font-size: 16px;
}
h1.privacy-policy-heading{
        font-size: 30px;
}
.privacy-container{
    padding: 0;
}
h1.privacy-sub-headings{
    font-size: 22px;
}

/*Footer Styling*/
#footer .container {
    padding: 0 10px !important;
}
#footer .flex_column:nth-child(1) {
    width: 125px !important;
    padding-right: 5px !important;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(2) {
   width: 135px !important;
    padding-right: 5px;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(3) {
           width: 135px !important;
    padding-right: 5px;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(4) {
            width: 135px !important;
    padding-right: 5px !important;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(5) {
        width: 200px !important;
    margin-left: 0!important;
}
h3.widgettitle {
    font-size: 17px !important;
}
#footer{
    padding: 0 !important;
}
a.footer-links{
    font-size: 14px !important;
}
.footer-com-desc{
margin-top: 22px !important;
font-size:13px !important;

}
img.footer-image {
    width: 80%;
}
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  


.main_menu {
    left: 20vw !important;
}
.sign-btn {
    right: -38px !important;
}

#footer {
    padding: 0 0 0 50px !important;
}
#footer .flex_column:nth-child(1) {
    width: 150px !important;
    padding-right: 5px !important;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(2) {
    width: 150px !important;
    padding-right: 5px;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(3) {
    width: 150px !important;
    padding-right: 5px;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(4) {
    width: 150px !important;
    padding-right: 5px;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(5) {
    width: 230px !important;
    padding-right: 5px;
    margin-left: 0!important;
}
h3.widgettitle {
    font-size: 20px !important;
}


  
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
   /* .search-bar {
        left: 63%;
        width: 24%;
    }
    input#search-box {
        font-size: 10px !important;
    }
    .main_menu {
        left: 22%;
    }*/
    .main_menu {
    left: 20vw !important;
    }
    .search-bar {
        left: 54vw !important;
    }



    /*first section spacer*/
    .hr.hr-invisible.avia-builder-el-5.el_after_av_codeblock.el_before_av_codeblock{
        height: 100px !important;
    }
    /*first section spacer*/

}
@media screen and (min-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .right {
        padding-top: 70px !important;
    }
    /*
    #top .avia-smallarrow-slider .avia-slideshow-dots a {
        margin-bottom: -45% !important;
        /*left: -44% !important;
    }
    */
    .hr.hr-invisible.avia-builder-el-14.el_before_av_codeblock.avia-builder-el-first
    {

            margin-top: -250px !important;
    }
    /*first section spacer*/
    .hr.hr-invisible.avia-builder-el-5.el_after_av_codeblock.el_before_av_codeblock{
        height: 100px !important;
    }
    /*first section spacer*/
    

}

@media only screen and (min-width: 997px) and (max-width: 1024px) {
.hr.hr-invisible.avia-builder-el-5.el_after_av_codeblock.el_before_av_codeblock{

    height: 100px !important;
}

}

/*TZ NEW STYLE FOR BLOG, CATEGORY, TAG PAGE STARTS*/

/* img{
      image-rendering: auto !important;
} */
span.logo{
    padding: 0px !important;
    margin: 0px 0;
}
li#menu-item-1229 {
    display: none;
}
.responsive .container.template-blog {
    max-width: 1430px !important;
    padding: 0 !important;
}
.stretch_full.container_wrap.alternate_color.light_bg_color.title_container {
    display: none;
}
.tag .post-title.tag-page-post-type-title {
    font-family: 'GothamMedium' !important;
    color: #222222 !important;
    text-transform: capitalize !important;
    font-size: 42px;
    font-weight: bold;
text-align:center;
margin-top: 95px;
}
.tag .avia-content-slider .slide-entry-excerpt {
    padding: 20px 0 !important;
}
.tag .slide-entry-excerpt.entry-content {
    padding: 0 !important;
    font-family: 'Bariol';
    font-size: 18px;
}
.slide-meta {
    display: none;
}
.tag .slide-entry-title.entry-title {
    display: block !important;
    font-family: 'GothamLight';
    font-size: 20px;
    color: #000;
}
.tag .slide-content {
    width: 94%;
    border: 1px solid #ccc !important;
    border-radius: 10px !important;
    padding: 23px 22px 23px 22px;
    margin: 0 auto!important;
    position: relative;
    top: -40px;
    min-height: 200px;
    background: #fff;
    box-shadow: 1px 1px 13px 0px rgba(67, 93, 133, 0.16);
}
.avia_transform a:hover .image-overlay {
    opacity: 0 !important;
}
.tag .container_wrap.container_wrap_first.main_color.fullsize {
    background: #fcfcfc !important;
}
.tag .av_one_third {
    margin-left: 2% !important;
    width: 30.333333% !important;
}
.tag .slide-entry-wrap {
    margin-top: 50px !important;
}
#top .fullsize .template-blog .tag-page-post-type-title {
    font-size: 54px !important;
}
.category-blog {
    max-width: 1430px;
    margin: 0 auto;
}
.category .entry {
    margin-left: 2% !important;
    width: 30.333333%;
    /*display: inline-block;*/
        display: inline-table;
}
.category .slide-content {
    width: 96%;
    border: 1px solid #ccc !important;
    border-radius: 10px !important;
    padding: 23px 22px 23px 22px;
    margin: 0 auto!important;
    position: relative;
    top: -40px;
    min-height: 200px;
    background: #fff;
    box-shadow: 1px 1px 13px 0px rgba(67, 93, 133, 0.16);
}
.category  .slide-content h3 {
    display: block !important;
    font-family: 'GothamLight';
    font-size: 20px;
    color: #000;
}
section.category-section {
    padding: 100px 0;
}
h1.archive-title {
    text-align: center;
    font-family: 'GothamMedium' !important;
    color: #222222 !important;
    text-transform: capitalize !important;
    font-size: 42px;
    font-weight: normal;
    margin-bottom: 50px;
}
.slide-entry-excerpt{
    padding: 0 !important;
    font-family: 'Bariol' !important;
    font-size: 18px !important;
    color: #222 !important;
}
a.sw_cattitle {
    color: #606060;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
.category .entry{
    width: 100% !important;
    display: block;
    /*padding: 0 30px;*/
}
.tag .slide-entry-wrap {
    padding: 0 30px;
}
.tag .av_one_third {
    width: 100% !important;
    display: block;
}
h1.archive-title,#top .fullsize .template-blog .tag-page-post-type-title {
    font-size: 30px !important;
    margin-bottom: 20px !important;
}
section.category-section {
    padding: 10px 0;
}
.archive .category-term-description {
    margin-bottom: 0;
}
p.excerpt_text strong {
    color: #000 !important;
    font-family: 'GothamLight';
    font-size: 15px;
}
p.excerpt_text {
    font-size: 15px;
}
h1.archive-title, #top .fullsize .template-blog .tag-page-post-type-title {
    margin: 0 !important;
    padding: 0 !important;
}
.tag .content{
    padding-top: 0 !important;
}
h3.post-title.tag-page-post-type-title {
    font-size: 30px !important;
    margin-top: 150px !important;
}
.slide-entry-excerpt{
    font-size: 14px !important;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
.tag .post-title.tag-page-post-type-title{
font-size: 26px!important;
    line-height: 1.3!important;
}
p.excerpt_text strong {
    font-size: 11px !important;
}
.slide-content h3{
    font-size: 14px !important;
    line-height: 1.4em;
}
.excerpt_text + p {
    font-size: 14px !important;
    font-family: 'Bariol' !important;
}
.slide-content {
    padding: 8px !important;
    min-height: 240px !important;
}
h3.slide-entry-title.entry-title, #swb_section .avia-content-slider .slide-entry-excerpt {
    font-size: 14px !important;
}
.tag .slide-entry-excerpt.entry-content {
    padding: 0 !important;
    font-family: 'Bariol';
    font-size: 14px;
}
h3.slide-entry-title.entry-title {
    font-size: 14px !important;
    line-height: 1.4em;
}
h3.slide-entry-title.entry-title {
    font-size: 14px !important;
    line-height: 1.4em;
    padding-bottom: 0px !important;
}
.tag h3.slide-entry-title.entry-title {
    font-size: 14px !important;
    line-height: 1.4em;
    padding-bottom: 0px !important;
}
.archive-title.xs-cat-title {
    font-weight: bold !important;
    font-size: 26px;
}
.slide-entry-excerpt{
    font-size: 16px !important;
}
section.category-section {
    padding: 10px 0 !important;
}
}
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
section.category-section {
    padding: 100px 0 !important;
}
}

/*TZ NEW STYLE FOR BLOG, CATEGORY, TAG PAGE END*/

/*TZ NEW STYLE FOR PAGES START*/

div .logo{
    position: relative;
}
#swb_section .avia-content-slider .slide-entry-excerpt {
    padding: 0 !important;
    font-family: 'Bariol' !important;
    font-size: 18px !important;
    color: #222 !important;
}
h3.slide-entry-title.entry-title {
    display: block !important;
    font-family: 'GothamBook' !important;
    font-size: 18px !important;
    color: #000 !important;
    padding-bottom: 15px !important;
}
.sw_slider_heading {
    color: #292929!important;
    font-family: GothamMedium !important;
    font-size: 42px !important;
    text-transform: capitalize!important;
    font-weight: bolder!important;
    text-shadow: none!important;
    line-height: 1.3 !important;
    margin: 0 0 20px 0 !important;
    text-transform: inherit !important;
}
.reviews_heading {
    font-size: 38px!important;
    color: #222 !important;
    font-family: 'Gotham-Medium' !important;
    line-height: 1.2 !important;
    font-weight: 700 !important;
    text-align: center !important;
}
.sw_subheading_heading {
    font-family: GothamMedium!important;
    font-weight: 700!important;
    font-size: 36px !important;
    color: #292929 !important;
    width: 100% !important;
}
.sw_fea_subheading_heading{
    font-family: GothamMedium!important;
    font-weight: 700!important;
    font-size: 25px !important;
    color: #292929 !important;
    width: 100% !important;
}
.sw_slider_subtext {
    color: #000 !important;
    font-family: Bariol !important;
    font-size: 23px !important;
    line-height: 1.5 !important;
    text-shadow: none!important;
    margin: 0 0 20px 0 !important;
}
.sw_heading_sub_text {
    font-size: 23px !important;
    font-family: Bariol !important;
    text-align: center !important;
    color: #4d545b !important;
    line-height: 1.3 !important;
    margin: 0 auto !important;
}
.sw_content_text {
    font-size: 23px !important;
    line-height: 1.3 !important;
    font-family: Bariol !important;
    color: #222 !important;
    margin: 20px 0 !important;
}
.sw_fill_btn {
    background: #002536!important;
    color: #fff !important;
    border: 1px solid #002536!important;
    padding: 10px 30px !important;
    text-decoration: none!important;
    border-radius: 5px !important;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px !important;
    min-width: 240px!important;
    display: block !important;
    text-align: center !important;
    background: 0 0 !important;
    width: initial !important;
    transition: all .5s ease-in-out !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    margin: 0 15px 0 0 !important;
    text-shadow: none !important;
}
.sw_fill_btn:hover {
    background: transparent !important;
    text-decoration: none!important;
    color: #002536!important;
    border-color: #002536!important;
}
.sw_unfill_btn {
    color: #002536!important;
    border: 1px solid #002536!important;
    padding: 10px 30px !important;
    text-decoration: none!important;
    border-radius: 5px !important;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px !important;
    min-width: 240px!important;
    display: block !important;
    text-align: center !important;
    background: 0 0 !important;
    width: initial !important;
    transition: all .5s ease-in-out !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    text-shadow: none !important;
}
.sw_unfill_btn:hover {
    background: #002536!important;
    text-decoration: none!important;
    border-color: #002536!important;
    color: #fff!important;
}
.sw_unfill_conbtn {
    color: #ffffff!important;
    border: 1px solid #ffffff!important;
    padding: 10px 30px !important;
    text-decoration: none!important;
    border-radius: 5px !important;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px !important;
    min-width: 240px!important;
    display: block !important;
    text-align: center !important;
    background: 0 0 !important;
    width: initial !important;
    transition: all .5s ease-in-out !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    text-shadow: none !important;
}
.sw_unfill_conbtn:hover {
    background: #002536!important;
    text-decoration: none!important;
    border-color: #002536!important;
    color: #fff!important;
}
.section_sp_ft {
    width: 100%;
    height: 80px;
}
.white{
    color:#fff !important;
}
.txt-left{
text-align: left !important;
}
.border-clr{
    border: 1px solid #fff !important;
}
.cat-sep{
    width: 100%;
    height: 50px;
}
.archive-title.xs-cat-title{
    font-weight: bold !important;
}
@media only screen and (max-width: 1600px) and (min-width: 1200px){
.sw_slider_heading {
    font-size: 30px !important;
}
.reviews_heading {
    font-size: 28px!important;
}
.sw_subheading_heading {
    font-size: 24px!important;
}
.sw_fea_subheading_heading{
    font-size: 21px!important;
}
.sw_slider_subtext {
    font-size: 20px !important;
}
.sw_content_text,.sw_heading_sub_text{
    font-size: 20px !important;
}
}
@media only screen and (max-width: 1024px) and (min-width: 768px){
.sw_slider_heading {
    font-size: 26px!important;
    line-height: 1.3!important;
    margin: 0 0 20px 0 !important;
}
.reviews_heading {
    font-size: 24px!important;
}
.sw_subheading_heading {
    font-size: 20px !important;
}
.sw_fea_subheading_heading{
    font-size: 21px!important;
}
.sw_slider_subtext {
    font-size: 18px !important;
}
.sw_content_text,.sw_heading_sub_text{
    font-size: 18px!important;
}
.section_sp_ft {
    width: 100%;
    height: 30px;
}
.hmbg-overlay .closebtn {
    position: absolute;
    top: 32px;
    right: 40px;
}
.cat-sep{
    width: 100%;
    height: 150px;
}
}
@media only screen and (max-width: 767px) and (min-width: 280px){
.sw_slider_heading {
    font-size: 24px!important;
    margin: 6px 0;
}
.reviews_heading {
    font-size: 22px!important;
    line-height: 35px!important;
}
.sw_subheading_heading {
    font-size: 22px!important;
    margin: 0 !important;
}
.sw_fea_subheading_heading{
    font-size: 21px!important;
}
.sw_slider_subtext {
    font-size: 18px!important;
    line-height: 1.2!important;
}
.sw_content_text,.sw_heading_sub_text{
    font-size: 18px !important;
}
a.sw_fill_btn,.sw_unfill_btn,.sw_unfill_conbtn {
    margin: 10px 0!important;
    display: block !important;
    font-size: 16px !important;
}
.section_sp_ft {
    width: 100%;
    height: 30px;
}
.archive-title.xs-cat-title{
    margin-bottom: 50px !important;
}
.cat-sep{
    width: 100%;
    height: 120px;
}
/*.hmbg-overlay .closebtn {
    position: absolute;
    top: 32px;
    right: 40px;
}*/
}
/*TZ NEW STYLE FOR PAGES END*/