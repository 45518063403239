/* RMI - Category Sidebar - S */
.design-bgclr{
    background-color: #f7f7f7 !important;
}

.design-selection-box {
    display: flex;
}
.filter-category-design{
    border-bottom: 1px solid #bfbfbf2e;
    padding: 10px 0px 15px 0px;
}
.filter-category-design h3{
    font-size: 20px;
    font-family: 'poppins';
    color: #404040;
}
.category-sidebar {
    max-height: 600px;
    overflow-y: scroll;
}

.category-sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.category-sidebar::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.category-sidebar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.3);
    background-color: #dddddd;
}

.category-heading {
    padding: 40px 0px;
    padding-bottom: 5px;
}

.category-heading h3 {
    font-size: 18px;
    font-family: 'Gotham-Book';
    color: #404040;
}
/* RMI - Category Sidebar - E */

.design-category-list {
    padding: 0;
}

.design-category-list .cl-item-web {
    padding: 18px 0px;
    list-style: none;
   
}

.cl-item-web a {
    color: #494949;
    font-family: 'Gotham-Book';
    font-size: 16px;
    text-decoration: none !important;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    -webkit-transition-property: padding-left text-indent;
    transition-property: padding-left text-indent;
    /* font-smoothing: antialiased; */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.cl-item-web:hover .cl-item-name {
    padding-left: 5px;    
}


/* .cl-item:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0px 6px;
    content: "\f054";
    font-family: FontAwesome;
    font-size: 16px;
    color: #21bdbb;
} */

.design-items, .design-items > div {
    display: block;
    float: left;
 }

.design-items {
    padding: 5px 30px;
    width: 100%;
    min-height: 1200px;
}

.design-items.minheight {
    min-height: 1200px;
}

.item-details {
    /* padding: 0 30px !important; */
}

.item-details img {
    width: 100%;
}

.design-box {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    border: 1px solid #e0e0e0;
}
.design-box img {
    width: 100%;
    height: 250px !important;
}
.design-box .design-inner {
    background: #ffffff;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    padding: 0;
    transition: opacity .5s;
}
.design-box .design-inner p {
    color: #fff;
    line-height: 150px;
    font-family: 'arial';
    text-align: center;
}
.design-box:hover .design-inner {
    /* opacity: .96;
    transition: opacity .5s; */
}

.design-inner .inner-content {
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
}

.noScroll {
    overflow-y: hidden;
}

.stickySidebar {
    position: fixed;
    width: 290px;
    max-height: 100%;
    height: calc(100vh - 50px);
    bottom: 0px;
    top: auto;
}

.search-by-tag-web-design {
    padding-bottom: 10px;
    border-bottom: 3px solid #21bdbb;
    width: 250px;
}

.search-by-tag-web-design .tag-input {
    outline: 0;
    border: 0;
    box-shadow: none !important;
    width: 210px;
}
.search-by-tag-web-design .tag-input:hover {
    outline: 0;
    border: 0;
    box-shadow: none !important;
}

/* RMI - Screen loader spinner */
.preview-progressbar .screen-overlay {
    display: block;
    z-index: 100;
    background-color: #fff;
    opacity: 0.97;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: 0 0;
    height: auto;
    position: absolute;
}
.preview-progressbar .loader-message {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    height: 100px;
    bottom: 0;
    top: 0;
    margin: auto;
    font-family: 'Gotham-Book';
    font-size: 18px;
}

.preview-progressbar .screen-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;    
    margin: auto;
}

@-webkit-keyframes rotation {
from {-webkit-transform: rotate(0deg);}
to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
from {-moz-transform: rotate(0deg);}
to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
from {-o-transform: rotate(0deg);}
to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
from {transform: rotate(0deg);}
to {transform: rotate(359deg);}
}

/* Design Preview Box - S */
.design-preview {
    background-color: #fff;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 10px;
    z-index: 250;
    transition: all 0.25s ease-in;
    display: none;
}
.design-preview.show {
    opacity: 1;
    top: 0;
    position: fixed;
    display: block;
    z-index: 999;
}

.item-preview-open {
    background: transparent;
    color: #212529;
    font-family: 'Gotham-Medium';
    font-size: 16px !important;
    text-transform: uppercase;
}

.btn-sw-theme {
    background: transparent;
    color: #212529;
    font-family: 'Gotham-Medium';
    font-size: 16px !important;
    text-transform: uppercase;
}

.apple-os .btn-sw-theme, .apple-os .item-preview-open {
    line-height: 30px;    
}

.btn-sw-theme:hover, .item-preview-open:hover {
    background: transparent;
    color: #21bdbb !important;
}

.btn-sw-theme:focus, .item-preview-open:focus {
    box-shadow: none;
}

.design-box .or-seperator {
    border-top: 2px solid #21bdbb;
    position: relative;
    margin: 26px 0 0px;
    text-align: center;
}

.design-box .or-seperator .text-seperator {
    background-color: #fff;
    padding: 0 .5em;
    position: relative;
    color: #000000;
    top: -.9em;
    font-style: italic;
    font-size: 18px;
}

.design-box:hover {
    border: 1px solid #e0e0e0;
}

.box-actions {
    border: 1px solid #e0e0e0;
    background: rgba(241, 241, 241, 0.5);
}

.box-actions a i {
    color: #21bdbb;
}

/* Design Preview Box - E */

/* Design Preview Modal Box - S */
#previewDesignModalBox .modal-content {
    min-height: 300px;
    /* padding: 22px 4px; */
}
#previewDesignModalBox #iframe-view {
    /* max-width: 100%;
    min-height: 600px;
    width: 100%;
    margin: 0 auto !important; */

    max-width: 100%;
    min-height: 600px;
    width: 100%;
    margin: 0 auto !important;
    max-height: 60vh;    
}
#previewDesignModalBox .btn-select-preview {
    color: #ffffff;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 20px;
    border: 2px solid #21bdbb;
    text-transform: uppercase;
    width: 240px;
    padding: 4px 6px;
}
#previewDesignModalBox .btn-select-preview:hover {
    background: #21bdbb;
    border: 2px solid #21bdbb;
    color: #ffffff;
}
#previewDesignModalBox .btn-select-preview i {
    margin-left: 0px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
#previewDesignModalBox .btn-select-preview:hover i { 
    margin-left: 10px;
}
#previewDesignModalBox .btn-select-preview:focus, #previewDesignModalBox .btn-select-preview:active {
    box-shadow: none;
}

#previewDesignModalBox .btn-close-preview {
    border: 0;
    border-bottom: 2px solid #524940;
    width: 180px;
    padding: 4px 6px;
    background: transparent;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    margin-right: 28px !important;
    text-transform: uppercase;
    border-radius: 0 !important;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}

#previewDesignModalBox .btn-close-preview:focus {
    box-shadow: none!important;
}
#previewDesignModalBox .btn-close-preview i { 
    color: #21bdbb;
    margin-right: 0px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
#previewDesignModalBox .btn-close-preview:hover i { 
    margin-right: 10px;
}
#previewDesignModalBox #previewDesignModalBoxLongTitle {
    line-height: 1.8;
    font-family: 'Gotham-Medium';
}
#previewDesignModalBox #iframe-view {
    /* -webkit-overflow-scrolling: touch;
    overflow: auto; */
}

/* Design Preview Modal Box - E */

/* RMI - Preview Top Bar - S */
.preview-topbar {
    height: 50px;
    color: rgba(255,255,255,0.6);
    background-color: #18202e;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0px 1px 2px 0px black;
    z-index: 15;
}

.preview-topbar .brand-logo {
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.preview-topbar .brand-logo img {
    height: 30px;
    display: inline-block;
    line-height: 48px;
}

.preview-topbar .brand-logo span {
    line-height: 49px;
    overflow: hidden;
    display: inline-block;
    padding-left: 4px;
    font-family: 'Gotham-Book';
    color: #d0d0d0;
}

.preview-topbar .btn-select-design {
    background: #21bdbb;
    color: #ffffff;
    padding: 6px 50px;
    font-family: 'Gotham-Medium';
    text-transform: uppercase;
}

.apple-os .preview-topbar .btn-select-design {
    line-height: 32px;
    height: 35px;
}

.preview-topbar .btn-select-design:hover {
    background: #0e9e9d;
    color: #ffffff;
}

.preview-topbar .btn-select-design:focus {
    box-shadow: none;
}

.preview-topbar .close-preview {
    background: transparent;
    color: #e4e4e4;
    padding: 5px 18px;
    font-family: 'Bariol-Bold';
    text-transform: uppercase;
    word-spacing: 4px;
    outline:0 !important
}

.apple-os .preview-topbar .close-preview {
    line-height: 32px;
    height: 35px;
}

.preview-topbar .close-preview:hover {
    background: transparent;
    color: #ffffff;
    outline:0 !important
}
/* RMI - Preview Top Bar - E */


/* RMI - Mobile Menu Side Nav - S */

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 760;
    top: 0;
    left: 0;
    background-color:#fff;
    border-right: 1px solid #e5e5e5;
    overflow: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a {
    display: block;
    transition: 0.3s;
    white-space: nowrap;
}

.sidenav a:hover {
/* color: #f1f1f1; */
}

.sidenav .closebtn {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 36px;
    font-family: 'Bariol-Bold';
    color: #ef6742;
    margin-left: 50px;
}

.sidenav .closebtn:hover {
    text-decoration: none !important;
}

.mbl-industry-menu {
    font-size: 20px;
    font-family: 'Gotham-Book';
    font-weight: bolder;
    text-align: center;
    display: none;
    margin-bottom: 18px;
}

.mbl-industry-menu:hover {
    cursor: pointer !important;
}

.mbl-industry-text {
    text-align: center;
    padding: 0px 16px;
    font-size: 18px;
    display: none;
}
/* RMI - Mobile Menu Side Nav - E */


/* RMI - Preview Iframe Box - S */
.preview-iframe {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50px;
    height: calc(100vh - 50px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
 }

.preview-iframe iframe {
    border: 0;
    width: 100%;
    height: 100%;
    display: block;
}
/* RMI - Preview Iframe Box - E */

.heading-section {
    font-family: 'Gotham-Medium';
    font-size: 24px;
    text-transform: uppercase;
    color: #161616;
}

#selected_design {
    display: none;
    border: 1px solid #d6d6d6;
}

#selected_industry {
    display: none;
    font-family: 'Gotham-Book';
    margin: 0 auto;
    padding-bottom: 10px;
    padding-top: 22px;
}

.selected-industry-heading {
    padding: 0px 20px;
    font-size: 20px;
    font-family: 'Gotham-Medium';
    color: #404040;
    /* border-bottom: 2px solid #21bdbb; */
    padding-bottom: 6px;
}

.selected-industry-heading .noresult-subtext {
    font-size: 18px;
    font-family: 'Gotham-Light';
    color: #404040;
    margin-top: 6px;
}

#selected_design_sticky_bar {
    background: #ffffff;
    border: 1px solid #222222;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    text-align: center;
    margin: 0 auto;
    padding: 10px;
    display: none;
}
#selected_design_sticky_bar .design-thumbnail {
    margin-right: 20px;
}
#selected_design_sticky_bar .design-thumbnail img {
    width: 80px;
}
#selected_design_sticky_bar .design-info {
    font-family: 'Gotham-Book';
    font-size: 22px;
    vertical-align: middle;
}
#selected_design_sticky_bar .step-continue {
    margin-left: 15px;
}

#selected_design_sticky_bar .mbl-show {
    display:none;
}

.step-continue {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    border: 2px solid #21bdbb;
    width: 180px; 
    padding: 4px 6px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.step-continue:hover {
    background: #0e9e9d;border: 2px solid #0e9e9d;
    color: #f7f7f7 !important;
}
.step-continue:focus {
    box-shadow: none;
}

.category-sidebar::-webkit-scrollbar {
    width: 1em;
}
 
.category-sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    
}
 
.category-sidebar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.main-sidebar {
    width: 313px;
    padding: 10px;
    box-sizing: border-box;
}
.main-design-container {
    float: right;
    width: calc(100% - 313px);
    padding: 10px;
}

.op-ie-valign{
    display: flex; 
    justify-content: center; 
    align-items: center;
}

/* RMI - Media Queries - S */
@media screen and (max-width: 1199px) and (min-width: 991px) {
    .btn-sw-theme, .item-preview-open {
        padding: 8px 4px;
    }
    .btn-sw-theme, .item-preview-open {
        font-size: 14px !important;
    }
}
@media screen and (max-width: 992px) {
    /* .main-sidebar {display:none;} */
    .main-design-container {width: 100%;}
    .mbl-industry-menu {display: block!important;
        overflow: hidden;}
    .selected-industry-heading {text-align: center;}
    #previewDesignModalBox {
        padding: 0!important;
    }
    #previewDesignModalBox .modal-dialog {
        max-width: 95%;
    }
}  
@media screen and (max-width: 989px) {
    .responsive #top #wrap_all .av_mobile_menu_tablet .main_menu {
        top: 40 !important;
        left: auto !important;
        right: 0 !important;
        display: block !important;
    }
}
@media screen and (max-width: 767px) {
    #header_main .inner-container {height: auto;}
    .selected-design-heading {
        padding: 40px 0!important;
        border-top: 1px solid #b2b2b2;
    }
    body {
        overflow: auto!important
    }

    .design-preview.show {
        overflow: auto!important;
        /* position: absolute; */
    }

    /* Fixing scroll */
    .designs-container > div {
    margin: 20px auto 0!important;
    }
    
    .designs-container > div > div {
    margin: 0!important;
    padding: 0;
    }
    
    .designs-container > div > .item-details {
    padding: 0!important;
    }

    .mbl-industry-text {display: block;}

    #selected_design_sticky_bar {
        text-align: left;
        min-height: 90px;
    }
    #selected_design_sticky_bar .design-info .design-info-text, #selected_design_sticky_bar .design-thumbnail {
        display: none!important;
    }    
    #selected_design_sticky_bar .step-continue {
        margin-left: 12px;
        margin-top: 12px;
    }
    .general-footer-actions {
        padding-bottom: 130px !important;
    }
    #selected_design_sticky_bar .mbl-show {
        display: inline;
    }
    #selected_design_sticky_bar .step-continue {
        font-size: 20px;
        width: 350px;   
    }
    #previewDesignModalBox .btn-close-preview, #previewDesignModalBox .btn-select-preview {
        width: 45%;
        display: inline-block;
    }
    #previewDesignModalBox .btn-preview-actions {
        display: block !important;
        width: 100%;


        width: 100%;
        height: 100%;
        max-width: 94%;
        max-height: 500px;
        position: absolute;
    }
    #previewDesignModalBox .modal-header {
        height: 70px;
    }
    #previewDesignModalBox .modal-body {
        overflow-y:auto !important; 
        -webkit-overflow-scrolling:touch !important;
        position: relative;
        width: 100%;
        max-height: 600px;
        height: 450px;
        padding: 0px;
    }    

    #previewDesignModalBox #iframe-view {
        min-height: 400px;        
        border: none;
    }
    #previewDesignModalBox #previewDesignModalBoxLongTitle {
        display: none;
    }
}
@media screen and (max-width: 560px) {
    #selected_design_sticky_bar .step-continue {
        font-size: 18px;
        width: 285px;
    }
}
@media screen and (max-width: 480px) {
    .screen-loader {left: 45%;}
    .preview-topbar .brand-logo span {display:none;}

    #selected_design_sticky_bar .mbl-show {
        display: none;
    }
    #selected_design_sticky_bar .step-continue {
        font-size: 20px;    
        width: 240px;
    }


    #selected_design_sticky_bar .design-info .design-info-text {
        display: block!important;
        font-size: 16px;
        margin-left: 12px;
    }    
    #selected_design_sticky_bar {
        min-height: 120px;
    }    
    #selected_design_sticky_bar .step-continue {
        margin-top: 16px;
    }

    #previewDesignModalBox .btn-close-preview, #previewDesignModalBox .btn-select-preview {
        width: 45%;
        display: inline-block;
        font-size: 18px;
        margin: 0 !important;
    }
    #previewDesignModalBox .btn-close-preview {
        margin-right: 10px !important;
    }
}
@media screen and (max-width: 430px) {
    #selected_design_sticky_bar .step-continue {
        font-size: 20px;
        width: 170px;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}
@media screen and (max-width: 420px) {
    #previewDesignModalBox .btn-close-preview i, #previewDesignModalBox .btn-select-preview i {
        display: none;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}
@media screen and (max-width: 400px) {
    .btn-sw-theme, .item-preview-open {
        font-size: 14px !important;
    }
    .preview-progressbar .screen-loader {
        top: 80px;
    }
    .preview-progressbar .loader-message {
        padding: 0 20px;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}
@media screen and (max-width: 360px) {
    #previewDesignModalBox .btn-close-preview, #previewDesignModalBox .btn-select-preview {
        font-size: 15px;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}
@media screen and (max-width: 360px) {
    .btn-sw-theme, .item-preview-open {
        padding: 4px;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}
@media screen and (max-width: 350px) {
    .btn-sw-theme, .item-preview-open {
        padding: 8px 4px;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}
@media screen and (max-width: 330px) {
    .box-actions a i {
        display: none;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}

/* RMI - Height Media Queries */
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}     
}
@media screen and (max-height: 330px) {
    .preview-topbar .brand-logo {
        display: none;
    }
}

.modal-content{
    height: 600px !important;
}
.spinner-border{
    margin-left: 50%;
    margin-top: 12%;
}
.selected{
    color: #fbc504 !important;
}