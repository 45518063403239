/* css start */
.blog-category{
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 400;
    color: #fff;
}

.blog-heading{
    font-family: 'Poppins';
    font-size: 25px;
    font-weight: 600;
    color: #fff;
}
.blog-heading:hover{
    color: #FBC504;
    text-decoration: none;
}
.blog-details{
    font-size: 14px;
    font-family: 'Poppins' !important;
    color: #fff;
    font-weight: 300;
}
.img-heigh{
    height: 100%;
}
.l-partner{
    overflow: hidden;
}
.l-partner:hover img {
    transform: scale(1.09);
    transition: .3s;
    filter: opacity(0.2);
}
.l-partner img {
    transition: .3s;
}
.l-partner:hover .btn_display{
    display: block;
}
.btn_display{
    position: absolute;
    display: none;
}
.pos1{
    top: 27%;
}
.pos2{
    top: 72%;
    right: 32%;
}
.pos3{
    top: 45%;
    right: 32%;
}
.logistics-btn{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 15px 30px;
    border-radius: 50px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    text-decoration: none !important;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
}
.process-flow{
    background-color: #131313;
}
.process-bg{
    padding: 30px;
    border-radius: 8px;
    height: 350px;
}
.clrbg-1{
background-color: #7D69FF;
}
.clrbg-2{
background-color: #70C78E;
}
.clrbg-3{
background-color: #BC5ADF;
}
.clrbg-4{
background-color: #DB5555;
}
.process-head{
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins' !important;
    font-weight: 600;
    margin: 20px 10px 20px 10px !important;
}
.process-description{
    font-size: 14px;
    font-family: 'Poppins' !important;
    color: #fff;
    font-weight: 300;
    margin: 10px 0px 10px 0px;
}
.col-rev-padd{
    padding: 0px 80px 0px 80px;
}
.reviewbox-details{
    display: flex;
    align-items: center;
}
.clientreview-heading{
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins' !important;
    font-weight: 600;
    margin: 2px 0px 2px 0px !important;
}
.cl-details{
    padding-left: 20px;
}
.reviewbox-bg{
    padding: 25px;
    background-color: #707070;
    border-radius: 8px;
    /* height: 280px; */
}

/* css start */


.template-page {
    /* overflow-x: hidden; */
    font-family: "Poppins";
}
.slider-container
{
    width: 1920px;
    padding: 0 50px;
}
.sw_ind{
    font-size: 23px;
    line-height: 33px;
    color: #292929;
    font-family: Bariol !important;
    text-align: center;
}
ul.sw_ind_list li {
    font-size: 23px;
    display: inline-block;
    margin: 0 50px 0 0;
    font-family: 'GothamBook' !important;
    font-weight: bold;
}
.inner_container {
    width: 950px!important;
    max-width: 950px !important;
    margin: 0 auto;
}
img.in_blg_icon {
    width: 100%;
}
p.sw_ind_name {
    margin: 0;
    text-align: center;
    font-family: 'Bariol' !important;
    font-weight: bolder;
    color: #ffffff !important;
    font-size: 19px;
}
p.sw_blg_text {
    color: #ffffff;
    font-style: italic;
    font-family: bariol !important;
    font-size: 19px;
    font-weight: bold;
    line-height: 2.0;
}
p.sw_ind_dgn {
    text-align: center;
    color: #9fa3a7;
    font-size: 14px;
    font-family: 'Bariol' !important;
}
div#sw_ind_blg {
    padding: 30px 0;
    margin: 30px 0;
    background: #1881dd;
}
p.sw_ind_list1 {
    padding: 3px 18px;
    text-align: center;
    font-size: 19px;
    font-family: 'GothamBook' !important;
    font-weight: bold;
    position: relative;
    max-width: max-content;
    margin: 0 auto;
}
p.sw_ind_list1:after {
    content: attr(data-content);
    position: absolute;
    color: #000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #ff899d;
    z-index: -1;
}

p.sw_ind_list1::selection {
     background: #d4fffb;
}
p.sw_ind_list2 {
    padding: 3px 10px;
    text-align: center;
    font-size: 19px;
    font-family: 'GothamBook' !important;
    font-weight: bold;
    position: relative;
    max-width: max-content;
    margin: 0 auto;
}
p.sw_ind_list2:after {
    content: attr(data-content);
    position: absolute;
    color: #000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #79c0ff;
    z-index: -1;
}

p.sw_ind_list2::selection {
     background: #d4ffd9;
}
p.sw_ind_list3 {
    padding: 3px 18px;
    text-align: center;
    font-size: 19px;
    font-family: 'GothamBook' !important;
    font-weight: bold;
    position: relative;
    max-width: max-content;
    margin: 0 auto;
}
p.sw_ind_list3:after {
    content: attr(data-content);
    position: absolute;
    color: #000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #f7d843;
    z-index: -1;
}

p.sw_ind_list3::selection {
     background: #fcffd4;
}
p.sw_ind_list4 {
    padding: 3px 18px;
    text-align: center;
    font-size: 19px;
    font-family: 'GothamBook' !important;
    font-weight: bold;
    position: relative;
    max-width: max-content;
    margin: 0 auto;
}
p.sw_ind_list4:after {
    content: attr(data-content);
    position: absolute;
    color: #000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #ddd9fc;
    z-index: -1;
}
p.sw_ind_list4::selection {
     background: #64f767;
}

img {
    max-width: 100%;
}
img.lg-hide{
    width: 100%;
}
.sw_rdw_cp{
   padding: 0 74px 0 150px;
}
.fl_image{
float: right;
}
#sw_rdw{
padding-bottom: 100px;
padding-top: 30px;
}
.sla-mid {
    vertical-align: bottom;
}
.sp-30{
    width: 30px;
    height: 30px;
}

section#sw_rdw_one_caption {
    background: #e7c001;
}
.sw-tour-slider{
  position: relative;
  text-align: center;
  color: white;
}
p.icon_sub_text {
    font-size: 18px;
    font-family: 'Bariol' !important;
    text-align: center;
}
ul.redesign_list li {
    font-size: 23px;
    font-family: Bariol !important;
    color: #4d545b;
    line-height: 1.8;
    list-style: disc;
}
ul.redesign_list {
    padding-left: 18px;
}
section#sw_tour_one_image .col-sm {
    padding: 0 !important;
    margin-top: -1px !important;
}
.container-fluid.sw-tour-slider {
    padding: 0 !important;
}
p.sw_slider_sup {
    font-size: 20px;
    color: #000;
    font-family: Bariol!important;
    text-transform: uppercase;
    text-shadow: none!important;
}
.logistics_heading {
    color: #ffffff!important;
    font-family: 'Gotham-Medium' !important;
    font-size: 42px;
    text-transform: capitalize!important;
    font-weight: bolder!important;
    text-shadow: none!important;
    line-height: 1.3;
    margin: 0 0 20px 0 !important;
    text-transform: inherit !important;
    text-align: left !important;
}
p.sw_slider_subtext {
    color: #ffffff;
    font-family: Bariol !important;
    font-size: 23px;
    line-height: 1.5;
    text-shadow: none!important;
    margin: 0 0 20px 0 !important;
    text-align: left !important;
}
.sw_blg_seprator {
    width: 100%;
    height: 40px;
}
.row.sw_section_one {
    padding: 50px 40px;
    border-radius: 9px;
    -webkit-box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
    -moz-box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
    box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
}
.section_seprator {
    width: 80px;
    height: 80px;
}
.sw_online_heading {
    font-size: 38px!important;
    color: #222;
    font-family: GothamMedium !important;
    line-height: 1.5;
    font-weight: 700;
    text-align: center;
}
.sw_div_center{
text-align: center !important;
}
.white{
    color: #ffffff !important;
}
.sw_sep_30 {
    width: 100%;
    height: 30px;
}
.sw_sep_15 {
    width: 100%;
    height: 15px;
}
.sw_s4_sup {
    color: #80c3c2!important;
    font-size: 21px;
    font-family: GothamMedium!important;
}
.sw_s4_heading {
    font-family: GothamMedium!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
}
.sw_s4_heading_center{
   font-family: GothamMedium!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
    text-align: center;
}
.sw_s4_text {
    font-size: 23px;
    line-height: 32px;
    font-family: Bariol !important;
    color: #4d545b;
    margin: 20px 0;
}
.sw_industry_button {
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol !important;
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 10px;
    margin-bottom: 10px;
}
.sw_industry_button:hover {
    background: #ffffff !important;
    text-decoration: none!important;
    border-color: #ffffff !important;
    color: #e7c000!important;
}
.sw_industry_button.gs_bottom {
    color: #1881dd !important;
    border: 1px solid #1881dd !important;
}
.sw_industry_button.gs_bottom:hover {
    background: #1881dd !important;
    text-decoration: none!important;
    border-color: #1881dd !important;
    color: #ffffff!important;
}
h2.sw_con_heading {
    color: #fff;
    text-align: center!important;
    font-size: 42px;
    font-family: GothamBook;
    font-weight: bolder;
    margin: 0 auto;
    line-height: 46px;
}
h3.sw_con_su_heading {
    font-size: 22px;
    color: #fff;
    font-family: GothamBook;
    text-align: center!important;
    margin: 0 auto;
    line-height: 29px;
}
.sw_btn_div {
    text-align: center;
    margin: 0 auto;
}
.po_icon {
    text-align: center;
}
h3.sw_suheading {
    text-align: center;
    font-size: 22px;
    font-family: GothamMedium;
    line-height: 30px;
}
.col-center {
    margin: 0 auto;
    float: none!important;
}
#custom-footer {
    display: block!important;
}
.horizontal_seprator {
    border: 0.5px solid #a1a1a1;
}
p.sw_ind_dgn {
    display: none;
}
@media only screen and (max-width:1600px) and (min-width:1200px) {
.sw_rdw_cp {
        padding: 0 30px 0 88px;
}
.logistics_heading {
    font-size: 30px!important;
}
p.sw_slider_subtext {
    font-size: 20px!important;
}
.sw_online_heading {
    font-size: 28px!important;
}
.sw_ind,.sw_s4_text {
    font-size: 20px;
}
.sw_s4_heading {
    font-size: 24px;
}
}
@media only screen and (max-width:1366px) and (min-width:1024px) {
.row.sw_section_one {
    padding: 50px 11px;
}
}
@media only screen and (max-width:1199px) and (min-width:1025px) {
.sw_rdw_cp {
    padding: 0 30px 0 30px;
}
}
@media only screen and (max-width:1024px) and (min-width:768px) {
.sw_rdw_cp {
    width: 100%;
    padding: 0 20px 0 72px;
    /*margin-top: 40px;*/
}
    .inner_container {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 auto;
}
p.sw_ind_list1,p.sw_ind_list2,p.sw_ind_list3,p.sw_ind_list4{
    font-size: 16px !important;
}
ul.sw_ind_list li {
    display: block;
    margin: 0 50px 15px 0;
}
section#sw_home {
    margin-top: 0!important;
}
.logistics_heading {
    font-size: 26px!important;
    line-height: 1.3!important;
}
p.sw_slider_subtext {
    font-size: 18px!important;
    line-height: 1.2!important;
   /* margin: 20px 0 !important;*/
}
.sw_s4_text{
    font-size: 18px !important;
}
ul.redesign_list li {
    font-size: 18px;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px;
}
.sw_s4_heading,.sw_s4_heading_center {
    font-size: 20px;
}
.sw_online_heading {
    font-size: 24px!important;
}
.sw_online_heading br{
display: none !important;
}
h3.sw_suheading {
    font-size: 18px !important;
    line-height: 25px !important;
}
.po_icon img {
    width: 100px !important;
}
.sw_industry_button,.sw_industry_button_color {
    padding: 10px !important;
    font-size: 16px;
    /*width: 230px;*/
}
.sw_ind {
    font-size: 18px !important;
}
}
@media only screen and (max-width:1023px) and (min-width:768px) {
.section_seprator {
    width: 80px;
    height: 40px;
}
.logistics_heading {
    font-size: 26px!important;
    line-height: 1.3!important;
    /*margin-bottom: 10px !important;*/
}
p.sw_slider_subtext br {
    display: none;
}
.tb-heading{
padding: 0 !important;
}
.md-hide{
    display:none;
}
}

@media only screen and (max-width:767px) and (min-width:280px) {
.slide_caption {
    margin-left: 0 !important;
}
    .sw_ind {
    font-size: 18px !important;
}
    .sw_rdw_cp {
    padding: 0 30px;
}
ul.sw_ind_list li {
    font-size: 18px;
    display: block;
    margin: 16px 0px 0 0;
}
ul.sw_ind_list {
    padding: 0;
}
.sw_rdw_cp_dsn{
display: none !important;
}
    .inner_container {
    width: 100% !important;
}
img {
    max-width: 100%;
}
img.in_blg_icon {
    width: 100px;
    padding-bottom: 20px;
}
.flex-switch{
    display: flex !important;
    flex-direction: column-reverse;
}
.sw_rdw_cp {
    width: 100%;
}
.sl-xs{
    padding-bottom: 50px !important;
}
.xs-hide{
display: none !important;
}
a.sw-brand-logo img {
    width: 155px!important;
    padding: 10px 0 12px 24px!important;
}
.header-custom {
    padding: 4px!important;
}
section#sw_home {
    margin-top: 0!important;
}
.sw_online_heading {
    font-size: 22px!important;
    line-height: 1.3;
}
.sw_online_heading br{
  display: none !important;
}
.sw_wk_rating_icon img {
    width: 90px!important;
    height: 81px!important;
}
.sw_s4_heading,.sw_s4_heading_center {
    font-size: 20px!important;
}
.sw_s4_text {
    font-size: 18px!important;
    margin: 0!important;
}
.sw_posu_heading, h2.sw_con_heading {
    font-size: 28px;
}
.sw_industry_button {
    display: block!important;
    margin: 10px 0!important;
}
.logistics_heading {
    font-size: 24px!important;
    /*margin: 6px 0 !important;*/
}
.logistics_heading br,.sw_slider_subtext br{
    display: none !important;
}
p.sw_slider_subtext {
    font-size: 18px!important;
    line-height: 1.2!important;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px!important;
}
.sw_ol_sub_text {
    width: 100%}
.sw_wk_heading {
    font-size: 26px!important;
    line-height: 34px!important;
}
.section_seprator {
    width: 80px!important;
    height: 30px!important;
}
.sw_industry_button,.sw_industry_button_color {
    display: block !important;
    margin: 10px 0 !important;
    font-size: 16px;
}
h3.sw_suheading {
    font-size: 18px !important;
}
a.sw_tour_sac,a.sw_tour_succeed{
    margin: 0 !important;
    display: block !important;
}
ul.redesign_list li {
    font-size: 19px !important;
}
.mb-col-sep{
width: 100%;
height: 40px;
}
.sw_blg_text{
padding: 10px !important;
}
}
@media only screen and (max-width: 1366px) and (min-width: 280px){
}



  