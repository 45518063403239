.domain-status-bar i {font-size: 22px;}
.domain_status {max-width: 100%;}
.domain_status i {font-size: 18px;}
.domain_new, .domain_email_new {margin-right: -32px;box-shadow: 0 13px 24px 1px rgba(195, 195, 195, 0.32);min-height: 50px;border: 1px solid #2b2e54;font-family: 'Bariol-Regular';font-size: 22px;}
.domain_new:focus, .domain_email_new:focus {outline:0;box-shadow: 0 13px 24px 1px rgba(195, 195, 195, 0.32); border: 1px solid #2b2e54;}
.domain_own {box-shadow: 0 13px 24px 1px rgba(195, 195, 195, 0.32);min-height: 50px;border: 1px solid #2b2e54;font-family: 'Bariol-Regular';font-size: 22px;}
.domain_own:focus {outline:0;box-shadow: 0 13px 24px 1px rgba(195, 195, 195, 0.32); border: 1px solid #2b2e54;}
.opt_domain .domain_own {max-width: 880px;margin: 0 auto;}
.domain-field {width: 100%;display: flex;}


.lead {
  font-family: "Poppins" !important;
}

.col-aligment{
  align-self: center;
}
.card-header{
  font-family: 'Poppins' !important;
  font-size: 20px;
  color: black;
  font-weight: 700;
}

.domain-card-body label{
  font-family: 'Poppins' !important;
  font-size: 16px;
  color: black;
  font-weight: 700;
}

.domain-card-body p{
  font-family: 'Poppins' !important;
  font-size: 16px;
  color: black;
  font-weight: 500;
}

.btn-search.btn_find_new_domain {
  width: 100%;
  color: #f7f7f7;
  background: #161616;
  font-family: 'Bariol-Bold';
  font-size: 22px;
  text-transform: uppercase;
  border: 2px solid #161616;
  max-width: 180px;
}
.btn-search {
transition: all 0.3s ease-in-out !important;
-webkit-transition: all 0.3s ease-in-out !important;
}
.btn-search i{
opacity: 0;
visibility: hidden;
margin-left: -18px;
font-size: 20px;
transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
-webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.btn-search:hover i {  
margin-right: 10px;
opacity: 1;
visibility: visible;  
}
.btn-next-step{
width: 100%;
color: #f7f7f7;
background: #1980dd;
font-family: 'Bariol-Bold';
font-size: 22px;
text-transform: uppercase;
border: 2px solid #1980dd;
max-width: 180px;
}
.btn-next-step:hover{
color: #f7f7f7;
background: #1980dd;
border: 2px solid #1980dd;
box-shadow: none;
}
.btn-next-step:focus {
box-shadow: none!important;
color: #f7f7f7;
background: #1980dd;
border: 2px solid #1980dd;
}

.btn-next-step i{
opacity: 0;
visibility: hidden;
margin-right: -22px;
transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
-webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.btn-next-step:hover i {
margin-left: 12px;
opacity: 1;
visibility: visible;  
}
.btn-search.btn-search-again {
background: transparent;
color: #161616;
}
.btn-search.btn-search-again:focus {
box-shadow: none!important;
}
#next_step_bar {display: none; margin-bottom: 140px;}
#error_step_bar {display: none; margin-bottom: 140px;}
.btn-go-next {
color: #ffffff;
background: #1980dd;
font-family: 'Bariol-Bold';
font-size: 22px;
border: 2px solid #1980dd;
text-transform: uppercase;
width: 180px;
padding: 4px 6px;
}
.btn-go-next:hover {
background: #1980dd;border: 2px solid #1980dd;color:#ffffff;
}
.btn-go-next:focus {
box-shadow: none!important;
}
.err-colour{
  color: #ff0b0b;
    font-size: 14px;
    font-weight: bold;
    margin-top: -24px;
}
#search{
  display: none;
}
.domain-title{
  font-family: 'poppins';
    font-size: 18px;
    font-weight: 400;
    color:#131313;
}
.domain-request-head{
  font-size: 22px;
    color: #131313;
    font-family: 'poppins';
    margin: 5px;
}
.domain-head{
  margin-top: 20px;
    padding: 15px;
    background-color: #fbc504;
    border-radius: 10px;
}
.domain-info-padd{
  padding: 20px 0px 20px 0px !important;
}
@media only screen and (max-width:767px) and (min-width:280px){
  .domain-card-body p{
    font-size: 12px;
    color: black;
    font-weight: 800;
  }
  .domain-card-body label{
    font-size: 12px;
    font-weight: 500;
  }
  .domain-head{
    margin-top: 15px;
    padding: 2px;
  }
  .domain-request-head{
    font-size: 18px;
  }
  .domain-title{
    font-size: 14px;
  }
  .domainname-option{
    margin-bottom: 5px !important;
  }
  .mg-bottom{
    margin-bottom: 40px;
  }
  .btn-next-step{
    font-size: 18px;
  }
  .domain_own{
    font-size: 17px;
  }
  .form-size{
    height: 50px !important;
  }
}
@media only screen and (max-width:1024px) and (min-width:768px){
  .domain-title{
    font-size: 18px;
  }
}
@media only screen and (max-width:1600px) and (min-width:1200px){
  .domain-title{
    font-size: 18px;
  }
}