@font-face {
    font-family: Bariol;
    src:
     url('../../../assets/process/bariol_regular-webfont.eot');
    
    src:
     url('../../../assets/process/bariol_regular-webfont.eot?#iefix') format("embedded-opentype"),
     url('../../../assets/process/bariol_regular-webfont.svg') format('svg'),
     url('../../../assets/process/bariol_regular-webfont.ttf') format('truetype'),
     url('../../../assets/process/bariol_regular-webfont.woff') format('woff'),
     url('../../../assets/process/Bariol_Regular.otf') format('otf'),
     url('../../../assets/process/bariol_regular-webfont.woff2') format('woff2');
     font-weight: 400;
     font-style: normal;


}


@font-face {
    font-family: GothamBook;
    src: url('../../../assets/process/Gotham-Book.eot');
    src: url('../../../assets/process/Gotham-Book.eot?#iefix') format("embedded-opentype"), 
    url('../../../assets/process/Gotham-Book.woff') format("woff"),
     url('../../../assets/process/Gotham-Book.ttf') format('truetype'), 
     url('../../../assets/process/Gotham-Book.svg#Gotham-Book') format("svg"),
      url('../../../assets/process/Gotham-Book.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: BariolBold;
    src: url('../../../assets/process/bariol_bold-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

section#sw_home_three{
display: none !important;
}
.new_spin{
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.head-left{
    text-align: left;
}
img#deskstop_slider {
    display: block;
}
img#mobile_slider {
    display: none;
}
p.sw_slider_sup {
    font-size: 20px;
    color: #000;
    font-family: "Poppins"!important;
    text-transform: uppercase;
    text-shadow: none!important;
}
.middle_heading {
    color: #ffffff!important;
    font-family: "Product Sans";
    font-size: 100px;

    font-weight: bold!important;
    line-height: 1.3;
    margin: 0 0 40px 0 !important;
    text-transform: inherit !important;
}
p.middle_subtext {
    color: #ffffff;
    font-family: 'Poppins' !important;
    font-size: 18px;
    line-height: 1.5;
    margin: 0 0 40px 0 !important;
    font-weight: 300 !important;
}




input#sw_slider_email {
    padding: 9px;
    border-radius: 5px;
    box-shadow: none!important;
    border: none!important;
    -webkit-box-shadow: 0 9px 5px 0 rgba(0, 0, 0, .18);
    -moz-box-shadow: 0 9px 5px 0 rgba(0, 0, 0, .18);
    box-shadow: 0 9px 5px 0 rgba(0, 0, 0, .18)!important;
    outline: 0;
    width: 60%;
    font-family: Bariol!important;
    color: #000!important;
}
.sw_slider_submit {
    background: #1880dd;
    border: none;
    padding: 11px 20px;
    border-radius: 6px;
    width: 130px;
    -webkit-box-shadow: 0 17px 5px 0 rgba(0, 0, 0, .18);
    -moz-box-shadow: 0 17px 5px 0 rgba(0, 0, 0, .18);
    box-shadow: 0 9px 13px 0 rgba(0, 0, 0, .16)!important;
    outline: 0;
    font-family: "Poppins";
    margin: 0 0 0 10px;
    text-transform: uppercase;
    font-weight: bold !important; 
}
#myCarousel img {
    width: 100%!important;
}
/*.container {
    width: 1270px!important;
    max-width: 1249px !important;
}*/

.fa-check{

    color: green;
}
section#sw_home .col-sm {
    padding: 0;
}
.sw_features_div {
    padding: 16px 0 40px 98px;
}
.vertical_seprator {
    background: #e5e5e5;
    bottom: 0;
    content: " ";
    margin-left: -15px;
    margin-bottom: 20px;
    position: absolute;
    top: 0;
    width: .3ch;
}
h3.sw_features_heading {
    margin: 0;
    font-size: 16px;
    font-family: "Poppins";
    color: #292929!important;
    font-weight: 700;
}
ul.sw_features_list {
    margin-left: 0!important;
    padding-left: 0;
    font-size: 14px!important;
    font-family: "Poppins" !important;
    color: #292929;
}
.sw_features_seprator {
    width: 100%;
    height: 30px;
}
.row.sw_section_one {
    padding: 50px 40px;
    border-radius: 9px;
    -webkit-box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
    -moz-box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
    box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
}
#myCarousel >.item-middle {
    position: relative;
    display: block!important;
}
section#main_banner{
    background-color: #131313;
    /* //padding-top: 65px; h*/
}
.mainbanner_padd{
    padding: 20px 20px 80px 60px;
    align-self: center;
}
.mainbannerimg_padd{
    /* padding: 80px;h */
    text-align: right;
}
section#sw_home .container {
    background: #fff!important;
}
section#sw_home {
    margin-top: -120px;
    position: relative;
    left: 0;
    right: 0;
}
ul.sw_features_list li {
    list-style-type: none;
    line-height: 2.2;
}
#sw_home i {
    color: #62abaa!important;
    font-size: 14px!important;
}
.section_seprator {
    width: 80px;
    height: 80px;
}
.sw_online_heading {
    font-size: 38px!important;
    color: #222;
    font-family: "Poppins" !important;
    line-height: 56px;
    font-weight: 700;
    text-align: center;
}
.sw_ol_sub_text {
    font-size: 23px;
    font-family: "Poppins" !important;
    text-align: center;
    color: #4d545b;
    line-height: 33px;
    width: 80%;
    margin: 0 auto;
}
.sw_fm_heading {
    font-size: 21px;
    line-height: 26px;
    color: #010101;
    text-align: center;
    font-family: "Poppins"!important;
    font-weight: bolder!important;
    margin: 0!important;
}
.sw_fm {
    margin: 0 auto;
    text-align: center;
}
input#email_address, input#name, input#phone_no {
    padding: 10px;
    border-radius: 5px;
    box-shadow: none!important;
    border: none!important;
    -webkit-box-shadow: 0 9px 5px 0 rgba(0, 0, 0, .18);
    -moz-box-shadow: 0 9px 5px 0 rgba(0, 0, 0, .18);
    box-shadow: 0 9px 5px 0 rgba(0, 0, 0, .18)!important;
    outline: 0;
    color: #000!important;
    border: 2px solid #000!important;
    width: 220px;
    background: #e2f2fb!important;
    font-family: "Poppins" !important;
    font-size: 16px;
    margin: 0 15px;
}

input#sw_fm_submit {
    background: #1880dd;
    border: none;
    padding: 12px 20px;
    border-radius: 6px;
    width: 130px;
    -webkit-box-shadow: 0 17px 5px 0 rgba(0, 0, 0, .18);
    -moz-box-shadow: 0 17px 5px 0 rgba(0, 0, 0, .18);
    box-shadow: 0 9px 13px 0 rgba(0, 0, 0, .16)!important;
    outline: 0;
    color: #fff!important;
}
.sw_sep_30 {
    width: 100%;
    height: 30px;
}
.sw_sep_15 {
    width: 100%;
    height: 15px;
}
.sw_sep_20 {
    width: 100%;
    height: 20px;
}
::placeholder {
    font-size: 16px;
    font-family: Bariol;
    color: #222;
}
.sw_s4_sup {
    color: #FBC504!important;
    font-size: 20px;
    font-family: "Poppins"!important;
}
.ds_s4_sup{
    color: #fff!important;
    font-size: 18px;
    font-family: "Poppins"!important;
    font-weight:300;
}
.sw_s4_heading {
    font-family: "Poppins"!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #fff;
    width: 100%}
.sw_s4_text {
    font-size: 18px;
    font-weight: 300 !important;
    line-height: 32px;
    font-family: "poppins" !important ;
    color: #fff !important;
    margin: 30px 0;
}
.sw_portfo_sz{
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 20px;
    font-family: "poppins" !important ;
    color: #fff !important;
    margin: 0px !important;
}
.txt_mid{
    align-self: center;
    text-align: right;
}
.view_padd{
    padding-right: 40px;
    padding-right: 40px;
}
.sw_home_button {
    color: #ffffff!important;
    border: 1px solid #ffffff!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    text-shadow:none !important;
}
.sw_home_button:hover {
    background: #ffffff!important;
    text-decoration: none!important;
    border-color: #ffffff!important;
    color: #1880dd !important;
}
.sw_home_button_bk {
    color: #1880dd!important;
    border: 1px solid #1880dd!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    text-shadow:none !important;
}
.sw_home_button_bk:hover {
    background: #1880dd!important;
    text-decoration: none!important;
    border-color: #1880dd!important;
    color: #ffffff !important;
}
.sw_work_button {
    color: #ffffff!important;
    border: 1px solid #ffffff!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
}
.sw_work_button:hover {
    background: #ffffff!important;
    text-decoration: none!important;
    border-color: #ffffff!important;
    color: #1880dd!important;
}
.mainclr{
    color: #FBC504;
}
.mainclr:hover{
    text-decoration: none !important;
    color: #FBC504 !important;
}
section#ourprocess_bg{
    background-color: #141414;
    border: none
}
.sw_home_succeed{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 15px 30px;
    text-decoration: none!important;
    border-radius: 50px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    /* margin: 0 5px 0 5px !important; */
    text-shadow: none !important;
}
.sw_home_succeed:hover {
    background: transparent !important;
    text-decoration: none!important;
    color: #ffffff!important;
    border-color: #ffffff!important;
}
.sw_portfolio_succeed:hover{
    background: transparent !important;
    text-decoration: none!important;
    color: #ffffff!important;
    border-color: #ffffff!important;
}
.sw_home_succeed_bk{
    background: #1880dd!important;
    color: #ffffff !important;
    border: 1px solid #1880dd!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    margin: 0 15px 0 0 !important;
    text-shadow: none !important;
}
.sw_home_succeed_bk:hover {
    background: transparent !important;
    text-decoration: none!important;
    color: #1880dd!important;
    border-color: #1880dd!important;
}
section#sw_home_seven {
    background-image: url(../../../assets/img/make-decision.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
h2.sw_con_heading {
    text-align: center!important;
    margin: 0 auto;
    line-height: 46px;
    font-family: "Poppins"!important;
    font-weight: 700!important;
    font-size: 38px;
    color: #ffffff;
    width: 100%;
}
h3.sw_con_su_heading {
    font-size: 22px;
    color: #ffffff;
    font-family: "Poppins";
    text-align: center!important;
    margin: 0 auto;
    line-height: 29px;
}
p.sw_con_su_text {
    font-family: "Poppins" !important;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    margin: 0 auto;
    line-height: 37px;
    width: 100%}
.sw_btn_div {
    text-align: center;
    margin: 0 auto;
}
a.sw_home_sac {
    color: #fff!important;
    border: 1px solid #fff!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    margin: 0 15px 0 0;
}
.sw_home_sac:hover {
    background: #fff!important;
    text-decoration: none!important;
    border-color: #fff!important;
    color: #1880dd!important;
}
.row {
    --bs-gutter-x: 0rem !important;
  }
.process_set{
    display: "inline";
}
.process_pad{
    margin-top: 40px;
}
.sw_posu_text {
    font-size: 18px !important;
    font-weight: 300 !important;
    line-height: 33px;
    color: #ffffff !important;
    font-family: "Poppins" !important;
    text-align: center;
    width: 100%
}
.sw_posu_heading {
    font-family: "Poppins"!important;
    font-weight: 400!important;
    font-size: 35px;
    color: #ffffff;
    width: 100%;
}
.hd_centr{
    text-align: center;
}
.hd_left{
    text-align: left;
}
section#sw_home_threea img {
    width: auto!important;
}
.po_icon {
    margin: 30px auto;
    text-align: center;
    height: 101px;
    width: 101px;
    padding: 30px;
    
    border-radius: 50%;
}
h3.sw_suheading {
    text-align: center;
    font-size: 16px;
    font-family: "Poppins";
    line-height: 30px;
}
.process_suheading{
    text-align: center;
    font-size: 20px;
    font-family: "Poppins";
    line-height: 30px;
    color: #fff;
}
p.sw_sutext {
    text-align: center;
    line-height: 25px;
    margin: 0 auto;
    padding-left: 0;
    font-size: 14px!important;
    font-family: "Poppins"!important;
    color: #292929;
    width: 70%;
}
.process_sutext{
    text-align: center;
    line-height: 23px;
    margin: 0 auto;
    padding-left: 0;
    font-size: 14px!important;
    font-family: "Poppins"!important;
    color: #fff;
    width: 90%;
}
.col-center {
    margin: 0 auto;
    float: none!important;
}

 .item-middle {
    color: #999;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    min-height: 290px;
}
 .item-middle .img-box {
    width: auto;
    height: auto;
    margin: 0;
    padding: 5px;
    display: table-cell;
}
 .testimonial {
    padding: 20px 10px;
}
.overview b {
    text-transform: uppercase;
    color: #7aa641;
}



div#myCarousel1 {
    background: #fff;
    padding: 0 0px;
    border-radius: 5px!important;
}

#testimonial_background a.carousel-control {
    display: none;
}

p.overview {
    display: table-cell;
    vertical-align: middle;
}
.sw_owner_name {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 28px;
    color: #000!important;
}
p.overview {
    font-family: "Poppins";
    font-size: 13px;
    color: #000!important;
}
p.testimonial {
    line-height: 2;
    color: #000!important;
    text-align: left;
    margin-left: 0!important;
    padding-left: 0;
    font-size: 18px!important;
    font-family: Bariol!important;
}
.testimonial_pvl {
    font-style: italic;
    color: #777 !important;
    font-family: "Poppins";
    text-align: left;
    text-decoration: underline;
    font-size: 15px;
}
#custom-footer {
    display: block!important;
}
.multipletab {
    width: 100%;
    margin: 0 auto;
    background: #131313;
    overflow: hidden;
    position: relative;
}
.tab-buttons {
    display: inline-block;
    width: 100%;
    border-bottom: 2px solid #666;
}
.tab-buttons>span {
    display: block;
    float: left;
    cursor: pointer;
    color: #fff;
    background: #7d69ff;
    padding: 0 15px 0 0;
    line-height: 50px!important;
    font-size: 16px;
    font-family: "Poppins";
}
.tab-buttons span {
    /* border-bottom: 3px solid #ffffff; */
    margin: 0 30px;
}
.tab-buttons>span.active {
    background: #1880dd;
    color: #fff;
    font-family: "Poppins";
    font-size: 20px;
    line-height: 50px!important;
    border-bottom: 3px solid #fff;
}
.tab-content {
    position: relative;
}
.tab-content>div {
    position: absolute;
    color: #666;
    font-family: 'pt sans', sans-serif;
}
.tab-nav {
    opacity: 1;
    transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
}
.multipletab:hover .tab-nav {
    opacity: 1;
}
.tab-nav>span {
    position: absolute;
    top: 0;
    cursor: pointer;
    display: block;
    height: 35px;
    width: 35px;
}
.tab-nav>span.next {
    right: 0;
    background-position: 5px;
    color: #fff;
    font-size: 34px;
    padding-top: 3px;
}
.tab-nav>span.prev {
    right: 50px;
    background-position: 5px;
    color: #fff;
    font-size: 34px;
    padding-top: 3px;
}
section#sw_home_work {
    background: #7D69FF;
    padding: 80px 40px 80px 40px;
}
.col_padd{
    padding-left: 40px;
    padding-right: 40px;
}
.cont-mid{
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-self: center;
}
.sw_wk_heading {
    font-size: 38px;
    line-height: 40px;
    font-family: "Poppins";
    color: #fff;
}
.sw_wk_text {
    font-size: 23px;
    line-height: 29px;
    font-family: "Poppins" !important;
    color: #fff;
}
.tab-content {
    background: #1880dd;
}
.tab-buttons {
    background: #7d69ff;
    border: none;
}
.tab-content {
    height: 550px!important;
}
.sw_wk_stars {
    display: inline-block;
}
.sw_wk_stars i {
    font-size: 24px;
    color: #FBC504;
}
.sw_portfolio_succeed{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 8px 15px;
    text-decoration: none!important;
    border-radius: 50px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 14px;
    min-width: 150px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    /* margin: 0 5px 0 5px !important; */
    text-shadow: none !important;
}
.horizontal_seprator {
    border: 1px solid #a1a1a1;
  
    margin-left: 60px;
    margin-right: 60px;
    text-align: center;
}
#sw_home_threea .col-sm:nth-child(2):before {
    content: "";
    position: absolute;
    left: -45px;
    top: 105px;
    display: inline-block;
    width: 80px;
    margin: 0 auto;
    text-align: center;
    height: 3px;
    border-bottom: 1px solid #272230;
}
#sw_home_threea .col-sm:nth-child(3):before {
    content: "";
    position: absolute;
    left: -45px;
    top: 105px;
    display: inline-block;
    width: 80px;
    margin: 0 auto;
    text-align: center;
    height: 3px;
    border-bottom: 1px solid #272230;
}
#sw_home_threea .col-sm:nth-child(4):before {
    content: "";
    position: absolute;
    left: -30px;
    top: 105px;
    display: inline-block;
    width: 80px;
    margin: 0 auto;
    text-align: center;
    height: 3px;
    border-bottom: 1px solid #272230;
}
#sw_home_threea .col-sm:nth-child(5):before {
    content: "";
    position: absolute;
    left: -48px;
    top: 105px;
    display: inline-block;
    width: 80px;
    margin: 0 auto;
    text-align: center;
    height: 3px;
    border-bottom: 1px solid #272230;
}
.slide-viewer {
    position: relative;
    overflow: hidden;
    height: 300px;
    background: #fff;
    padding: 20px;
    text-align: left;
}
.slide-group {
    width: 100%}
.slide {
    width: 100%;
    /*height: 100%;*/
    display: none;
}
.slide:first-child {
    display: block;
}
.slide-buttons {
    text-align: center;
    margin: 10px 0;
}
.slide-btn {
    width: 20px;
    height: 20px;
    background-color: #666;
}
ol.carousel-indicators li.active {
    background-color: #000 !important;
}
.bs-example {
    background: #fff;
    padding: 0 20px;
}
.slide-btn {
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 2px solid #000!important;
    margin: 0 2px;
    outline: 0!important;
}
.slide-buttons {
    text-align: center;
    margin: 10px 0;
    position: absolute;
    top: -38px;
    left: 0;
}
.img-box {
    text-align: left;
    display: table-cell;
}
.sw_wk_rating_icon {
    margin: 20px 0;
}
.sw_lg {
    display: block!important;
}
.sw_md {
    display: none!important;
}
.sw_xs {
    display: none!important;
}
.sw_xsp {
    display: none!important;
}
.sw_xsp1 {
    display: none!important;
}
 
.sw_rimg{
width: 100%;
float: right;
}
.sw_limg{
width: 100%;
}
.img_siz{
    height: auto;
    width: 600px;
}
.section_box
    {
        padding: 50px 40px;
        border-radius: 9px;
        -webkit-box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
        -moz-box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
        box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
    }

.myimg{
    width: auto!important;
}
.myimg1{
    width: auto!important;      
}


.align-middle-top{
vertical-align: top !important;
}
div#content1, div#content2, div#content3, div#content4.div#content5 {
    width: 100% !important;
}

@media only screen and (max-width:1600px) and (min-width:1200px) {
    section#sw_home_work {
        background: #7D69FF;
        padding: 80px 40px 80px 40px;
    }
    #business>.a {
        grid-column: 1;
      }
      
      #business>.b {
        grid-column: 2;
      }
    .cont-mid{
        padding: 40px;
        padding-top: 0px !important;
    }
    .process_pad{
        margin-top: 40px;
    }
    .mainbannerimg_padd{
        display: block;
    }
    .mainbanner_padd {
        padding: 20px 20px 80px 60px !important;
}
    .middle_heading {
        font-size: 40px;
    }
    p.middle_subtext {
        font-size: 18px;
    }
    .sw_online_heading,.sw_wk_heading,.sw_posu_heading,h2.sw_con_heading {
        font-size: 32px !important;
    }
    .sw_ol_sub_text,.sw_wk_text,.sw_posu_text,p.sw_con_su_text,.sw_s4_text {
        font-size: 20px;
    }
    .sw_s4_heading{
        font-size: 24px!important;
    }
    .tab-nav>span.next,.tab-nav>span.prev {
        font-size: 25px;
    }

}
@media only screen and (max-width:1366px) and (min-width:1024px) {
 .row.sw_section_one {
    padding: 50px 11px;
}
 .tab-buttons span {
     margin: 0 15px;
 }
 .sw_posu_heading, h2.sw_con_heading {
     font-size: 32px;
 }
 .testimonial {
     padding: 0 0 10px!important;
 }
 
 }

@media only screen and (max-width:1199px) and (min-width:768px) {
 
    .slide-viewer {
    height: 100%!important;
}
 .testimonial {
    padding: 0 0 10px!important;
}
#sw_home_threea .col-sm:nth-child(2):before {
    content: "";
    position: absolute;
    left: -28px!important;
    width: 35px!important;
}
#sw_home_threea .col-sm:nth-child(3):before {
    content: "";
    position: absolute;
    left: -16px!important;
    width: 40px!important;
}
#sw_home_threea .col-sm:nth-child(4):before {
    content: "";
    position: absolute;
    left: -24px!important;
    width: 40px!important;
}
#sw_home_threea .col-sm:nth-child(5):before {
    content: "";
    position: absolute;
    left: -33px!important;
    width: 40px!important;
}
section#sw_home {
    margin-top: 0!important;
}
.middle_heading {
    font-size: 26px!important;
    line-height: 1.3!important;
    margin: 0 0 20px 0 !important;
}
p.middle_subtext {
    font-size: 18px;
    line-height: 1.2!important;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px 0 10px;
}
.tab-buttons span {
    margin: 0 5px;
}
input#email_address, input#name, input#phone_no {
    width: 160px!important;
    font-size: 14px!important;
    margin: 0 4px!important;
}
.sw_s4_heading {
    font-size: 20px;
}
.tab-content .col-sm {
    padding: 0 80px!important;
}
.vertical_seprator {
    display: none!important;
}
.sw_online_heading,.sw_posu_heading {
    font-size: 20px !important;
    padding-top: 40px;
    

}
.sw_con_heading{
    font-size: 24px!important;
}
.sw_wk_heading{
    font-size: 20px!important;
}
.sw_ol_sub_text,.sw_wk_text,.sw_posu_text,.sw_s4_text,.sw_s4_sup,p.sw_con_su_text {
    font-size: 18px !important;
}
#sw_home_four img, #sw_home_five img{
width: 100% !important;
}
.icon1, .icon2, .icon3 {
    background-position: top left;
    padding: 80px 0;
}
.sw_features_div {
    padding: 16px 0 0 8px;
}
#testimonial_background {
    padding: 12px !important;
}
.slide-viewer {
    padding: 5px 10px !important;
}
/* .section_seprator {
    width: 80px;
    height: 40px;
} */
.sw_sl_btn a.sw_home_succeed {
    margin: 0 10px 10px 0 !important;
}
.sw_sl_btn a.sw_home_succeed_bk {
    margin: 0 10px 10px 0 !important;
}
}
@media only screen and (max-width:1023px) and (min-width:768px) {

.mainbanner_padd {
        padding: 20px 20px 80px 60px !important;
}



.tab-nav>span.prev{
    display: none;
}
div#content1, div#content2, div#content3, div#content4.div#content5 {
    width: 100% !important;
}
#sw_home_threea .col-sm:nth-child(4):before {
    content: "";
    position: absolute;
    left: -24px!important;
    width: 40px!important;
    display: none;
}
}
@media only screen and (max-width:767px) and (min-width:280px) {
    section#sw_home_work {
        background: #7D69FF;
        padding: 40px 20px 40px 20px;
    }
    #business.reverse>.a {
        grid-column: 2;
      }
      #business.reverse>.b {
        grid-column: 1;
      }
    .cont-mid{
        padding: 5px;
    }
    .process_pad{
        margin-top: 20px;
    }
    /* i have Chnage here */
    /* .mainbannerimg_padd{
        display: none;
    } */
    .sw_wk_rating_icon{
        margin: 10px 0 !important;
    }
   
    .bs-example {
        padding: 0 0px !important;
    }
   
    div#content1, div#content2, div#content3, div#content4.div#content5 {
        width: 100% !important;
    }

.sw_slider_submit {
    width: 98px !important;
}

a.sw-brand-logo img {
    width: 155px!important;
    padding: 10px 0 12px 24px!important;
}
.header-custom {
    padding: 4px!important;
}
section#sw_home {
    margin-top: 0!important;
}
img {
    width: 100%!important;
}
input#email_address, input#name, input#phone_no {
    margin: 12px 15px!important;
}
ul.sw_features_list li {
    line-height: 1.6!important;
}
.sw_ol_sub_text {
    font-size: 18px;
}
.sw_online_heading {
    font-size: 22px!important;
    line-height: 35px!important;
}
.tab-buttons span {
    margin: 0 30px;
    float: none!important;
}
.tab-content {
    height: 705px!important;
}
.tab-content .col-sm {
    padding: 0 0 0 40px !important;
}
.sw_wk_rating_icon img {
    width: 90px!important;
    height: 81px!important;
}
.sw_s4_heading {
    font-size: 20px!important;
    margin: 0 !important;
}
.sw_s4_text,.sw_posu_text,.sw_wk_text,.sw_con_su_text {
    font-size: 18px!important;
}
.sw_con_su_heading{
font-size: 16px!important;
}
#sw_home_threea .col-sm:nth-child(2):before, #sw_home_threea .col-sm:nth-child(3):before, #sw_home_threea .col-sm:nth-child(4):before, #sw_home_threea .col-sm:nth-child(5):before {
    border-bottom: none!important;
}
#testimonial_background {
    margin-top: 100px!important;
    padding: 40px 15px!important;
    /*left: 15px;*/
}
.sw_posu_heading, h2.sw_con_heading {
    font-size: 22px!important;
    line-height: 35px!important;
    /* padding: 20px !important; */
}
.sw_home_button {
    display: block!important;
    /*margin: 10px 0!important;*/
    font-size: 16px !important;
}
.sw_work_button {
    display: block!important;
    margin: 20px 0!important;
    font-size: 16px !important;
    line-height: 2.0 !important;
}
.sw_btn_div {
    text-align: center;
    margin: 0 auto;
    width: 92% !important;
}
img#deskstop_slider {
    display: none;
}
img#mobile_slider {
    display: block;
}
.middle_heading {
    font-size: 30px!important;
    margin: 6px 0;
}
input#sw_slider_email {
    width: 55%!important;
}
p.middle_subtext {
    font-size: 16px!important;
    line-height: 1.2!important;
    margin-bottom: 10px !important;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px!important;
}
::placeholder {
    font-size: 12px!important;
}
.sw_ol_sub_text {
    width: 100%}
.sw_wk_heading {
    font-size: 22px!important;
    line-height: 34px!important;
}
.section_seprator {
    width: 80px!important;
    height: 30px!important;
}

.tab-nav>span.prev {
    right: 30px!important;
    font-size: 17px!important;
    padding-top: 17px!important;
    display:none !important;
}
.tab-nav>span.next {
    font-size: 17px!important;
    padding-top: 17px!important;
}
.slide-viewer {
    height: auto !important;
}
.sw_xs {
    display: block!important;
}
.sw_md {
    display: none!important;
}
.sw_lg {
    display: none!important;
}
.sw_xsp {
    display: none!important;
}
.sw_xsp1 {
    display: none!important;
}
a.sw_home_sac,a.sw_home_succeed,a.sw_home_succeed_bk,a.sw_home_button_bk{
    margin: 10px 0!important;
    display: block !important;
    font-size: 16px !important;
}
.sw_s4_sup {
    font-size: 16px;
}
.mb-col-sep{
    width: 40px;
    height: 40px;
}
.flex-switch{
    display: flex !important;
    flex-direction: column-reverse;
}
span#content1,span#content2,span#content3,span#content4,span#content5 {
    display: none;
}
.tab-buttons .active{
    display: block !important;
}
}
@media only screen and (max-width: 375px) {
    #myCarousel >.item-middle {
        padding-top: 150px;
        background: #1881dd;
    }
}
@media (min-width:320px) and (max-width:480px) and (orientation:landscape) {
    .sw_lg {
    display: none!important;
}
.sw_md {
    display: none!important;
}
.sw_xs {
    display: none!important;
}
.sw_xsp {
    padding-top: 50px!important;
    display: block!important;
}
.sw_xsp1 {
    display: none!important;
}
}
@media (max-width:767px) and (min-width:481px) and (orientation:landscape) {
    .sw_xsp1 {
    padding-top: 210px!important;
    display: block!important;
    background: #1881dd;
}
.sw_md {
    display: none!important;
}
.sw_lg {
    display: none!important;
}
.sw_xs {
    display: none!important;
}
.sw_xsp {
    display: none!important;
}
}
@media (max-width:1023px) and (min-width:768px) and (orientation:landscape) {


}
@media only screen and (max-width:1023px) and (min-width:768px) {
    .sw_md {
    display: block!important;
}
.sw_lg {
    display: none!important;
}
.sw_xs {
    display: none!important;
}
.sw_xsp {
    display: none!important;
}
.sw_xsp1:fo {
    display: none!important;
}
}
@media (min-width:480px) and (max-width:767px) and (orientation:landscape) {
.tab-content {
    height: 860px!important;
}
}

@media (min-width:280px) and (max-width:767px) and (orientation:landscape) {


}


#readmore :hover {
    background-color: #010101;
    transition: width 1s, border-radius 0.7s;
    width: 200px; /* Increases the width to create a rectangle effect */
    border-radius: 0; /* Removes border-radius to make it a rectangle */
  }
  .input[type="date"].form-control {
    display: none !important;
  }