@font-face {
    font-family: Bariol;
    src: url('../../assets/process/bariol_regular-webfont.eot');
    src: url('../../assets/process/bariol_regular-webfontd41d.eot?#iefix') format("embedded-opentype"),
     url('../../assets/process/bariol_regular-webfont.svg') format('svg'),
      url('../../assets/process/bariol_regular-webfont.ttf') format('truetype'),
       url('../../assets/process/bariol_regular-webfont.woff') format('woff'),
        url('../../assets/process/Bariol_Regular.otf') format('otf'),
         url('../../assets/process/bariol_regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Book';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Book'), 
    url('../../assets/process/Gotham-Book.woff') format('woff'),
    url('../../assets/process/Gotham-Book.eot'),
    url('../../assets/process/Gotham-Bookd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/Gotham-Book.ttf') format('truetype'),
    url('../../assets/process/Gotham-Book.svg#Gotham-Book') format('svg'),
    url('../../assets/process/Gotham-Book.otf') format('opentype');   
}
@font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url('../../assets/process/GothamMedium.woff') format('woff'),
    url('../../assets/process/GothamMedium.woff2') format('woff2'),
    url('../../assets/process/GothamMedium.eot'),
    url('../../assets/process/GothamMediumd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/GothamMedium.ttf') format('truetype'),
    url('../../assets/process/GothamMedium.svg#Gotham-Book') format('svg'),
    url('../../assets/process/GothamMedium.otf') format('opentype'); 
}
@font-face {
    font-family: 'Bariol-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Bold'), 
    url('../../assets/process/Bariol-Bold.woff') format('woff'),
    url('../../assets/process/Bariol-Bold.eot'),
    url('../../assets/process/Bariol-Boldd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/Bariol-Bold.ttf') format('truetype'),
    url('../../assets/process/Bariol-Bold.svg#Bariol-Regular') format('svg');
}








  .template-page{
    overflow-x: hidden;
}
.main-header.header-custom.header-transparency {
    background: #fff !important;
}
  .section_seprator {
    width: 100%;
    height: 80px;
}
.sw_heading {
    font-size: 38px!important;
    color: #222;
    font-family: 'Gotham-Medium' !important;
    line-height: 56px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.sw_tcs_heading {
    font-family: 'Gotham-Medium'!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
}
ul.sw_tcs_list li {
    color: #4d545b;
    font-family: 'Bariol' !important;
    font-size: 23px;
    padding: 10px 0px;
}
ul.sw_tcs_list {
    list-style: disc;
}
.horizontal_seprator {
    border: 1px solid #a1a1a1;
    width: 100%;
}
p.sw_tcs_text {
    font-size: 23px;
    line-height: 32px;
    font-family: Bariol !important;
    color: #4d545b;
    margin: 10px 0;
}
@media only screen and (max-width: 1600px) and (min-width: 1200px){
    .sw_tcs_heading {
        font-size: 24px !important;
    }
    .sw_heading {
        font-size: 28px!important;
    }
    ul.sw_tcs_list li, p.sw_tcs_text {
        font-size: 20px !important;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 768px){
.sw_tcs_heading {
    font-size: 20px !important;
}
.sw_heading {
    font-size: 24px!important;
}
ul.sw_tcs_list li, p.sw_tcs_text {
    font-size: 18px !important;
}
.section_seprator {
    width: 80px;
    height: 40px;
}
ul.sw_tcs_list {
    padding: 0;
}
}
@media only screen and (max-width: 767px) and (min-width: 280px){
    .sw_tcs_heading {
        font-size: 20px !important;
    }
    .sw_heading {
        font-size: 22px!important;
    }
    ul.sw_tcs_list li, p.sw_tcs_text {
        font-size: 18px !important;
    }
    .section_seprator {
        width: 80px;
        height: 30px;
    }
    ul.sw_tcs_list {
        padding: 0;
    }
    }
@media only screen and (max-width: 1366px) and (min-width: 280px){
}