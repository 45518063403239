/* .paddtopbottom{
    padding-top: 40px !important;
    padding-bottom: 40px !important;
} */

.padding {
    padding: 3rem !important
}
.info-box{
    background-color: #fff;
   
    padding: 20px;
    height: 434px;


}

.row>* {
    padding-left: 0;
}
.dashboard-list a{
    color: #808080 ;
   font-weight: 400;
    font-family: 'poppins' !important;
    font-size: 20px !important;
    text-decoration: none !important;
    transition-duration: .2s;
}
.black{
    color: #000000 !important;
    font-weight: bold;
}
.btnhover{
    margin: 0 !important ;

}
.btnhover:hover{
    background-color: #fbc504;
   
}

.design-category-list .dashboard-list{
   }
.user-profile-head{
    font-size: 25px;
    font-family: 'poppins';
    font-weight: 400;
    padding: 6px 0px 4px 15px;
    /* margin: 0px; */
    border-left: 4px solid #fbc504;
}
.icon-padd{
    padding: 15px 0px 15px 0px;
}
.user-dash-head{
    font-size: 43px;
    font-family: 'poppins';
    font-weight: 500;
    margin-bottom: 20px;
}
.card-background{
    background-color: #fbc504;
    padding: 10px;
}
.cont-centr{
    align-self: center;
}
.card-head{
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 400;
    margin-bottom: 0px;
    color: black !important;
}
.user-card-full {
    overflow: hidden
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

.m-r-0 {
    margin-right: 0px
}

.m-l-0 {
    margin-left: 0px
}

.user-card-full .user-profile {
    border-radius: 5px 0 0 5px
}

.bg-c-lite-green {
    background: linear-gradient(to right, #000000, #40352f);
}

.user-profile {
    padding: 20px 0
}

.card-block {
    padding: 1.25rem
}

.m-b-25 {
    margin-bottom: 25px
}

.img-radius {
    border-radius: 5px
}

h6 {
    font-size: 14px
}

.card .card-block p {
    line-height: 25px
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px
    }
}

.card-block {
    padding: 1.25rem
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.m-b-20 {
    margin-bottom: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.card .card-block p {
    line-height: 25px
}

.m-b-10 {
    margin-bottom: 10px
}

.text-muted {
    color: #919aa3 !important
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.f-w-600 {
    font-weight: 600
}

.m-b-20 {
    margin-bottom: 20px
}

.m-t-40 {
    margin-top: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.m-b-10 {
    margin-bottom: 10px
}

.m-t-40 {
    margin-top: 20px
}

.user-card-full .social-link li {
    display: inline-block
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}


/* orderCard CSS */





.order-card {
    background-color: #fff;
    width: 300px;
    border: none;
    border-radius: 16px
}

.info {
    line-height: 19px
}

.name {
    color: #4c40e0;
    font-size: 18px;
    font-weight: 600
}

.order {
    font-size: 14px;
    font-weight: 400;
    color: #b7b5b5
}

.detail {
    line-height: 19px
}

.summery {
    color: #d2cfcf;
    font-weight: 400;
    font-size: 13px
}


.text {
    line-height: 15px
}

.new {
    color: #000;
    font-size: 14px;
    font-weight: 600
}

.money {
    font-size: 14px;
    font-weight: 500
}

.address {
    color: #d2cfcf;
    font-weight: 500;
    font-size: 14px
}

.last {
    font-size: 10px;
    font-weight: 500
}

.address-line {
    color: #4C40E0;
    font-size: 11px;
    font-weight: 700
}

.log-div{
    margin: 25px auto;
}
.padding-cards{
    padding: 0px 5px 0px 5px !important;
}
@media only screen and (max-width: 500px) {
     .media-div{
       display: block !important;
    }
}



@media (min-width: 768px) and (max-width: 1324px)  {
    /* CSS */
    
  }
  
  @media (min-width: 481px) and (max-width: 768px) {
 


    /* CSS */
    .step-description {
    display: none !important;
    }
  
  
  }
  

  @media (min-width: 481px) and (max-width: 1200px) {
    .main{

        padding-top: 0;;
   
    }

    
    /* CSS */
    .step-description {
    display: none !important;
    }
  
  
  }
  @media screen and  (min-width:1025px) {
    .bottombar{
        display: none;
    }
    }
  @media (min-width: 481px) and (max-width: 1024px) {
    .main{

        padding-top: 0 ;
        margin: 0 10px !important;
    }
    .main{
      padding: 0 !important;
    }
    .iconss{
   
   font-size: 20px !important;
    }
    .cl-item-web span{
        display: block !important;
       
        }
    .main{

        padding-top: 0;;
   
    }
    .listr{
        padding-left: 0 !important;
        text-align: center !important;
    }
    ul {
        padding-left: 0 !important;
}
    

    .sidebar{
        display: none !important;
    }
    .cl-item-name{
        
    }
    .tabletnavicons{
        display: block !important;
        
    }
    
    .bottombar{
        
        
    }
    
    /* CSS */
    .step-description {
    display: none !important;
    }
  
  
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
  
    .category_card{
        display: flex;
        justify-content: center;
    }
    .main{
    padding: 0 !important;
    }
    

   
   

    .Search{
        display: none;
    }
    .left{
        display: none;
    }
    .main{

        padding-top: 0;
   
    }
    .mainwrapper{
        padding: 0 !important;
    }

    .main{

        padding-top: 0;;
   
    }
    .sidebar{
        display: none !important;
    }
    .cl-item-web{
        padding: 0 !important;
    }
    .cl-item-web span{
    display: block !important;
    font-size: 14px !important;

    }
    .cl-item-web span{
    }
    .cl-item-web{
        margin: 0 !important;
       
    padding: 0 !important;
    }
    .bottomul{
        padding: 0 !important;
        padding-bottom: 0 !important;
    }
    .listr{
        padding-left: 0 !important;
        text-align: center !important;
    }
    ul {
        padding-left: 0 !important;
}
.iconss{
   
    font-size: 20px !important;
     }


  }
  

