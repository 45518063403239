/*Created By: TZ */
/*Created On: 2020-03-02*/
/*File Description: CSS */
/*TZ CSS FOR login Starts*/
/* @font-face {
    font-family: 'Gotham-Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Light'), 
    url('../../assets/process/GothamLight.woff') format('woff'),
    url('../../assets/process/GothamLight.eot') format('woff'),
    url('../../assets/process/GothamLightd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/GothamLight.ttf') format('truetype'),
    url('../../assets/process/GothamLight.svg#Gotham-Light') format('woff'),
    url('../../assests/process/GothamLight.woff') format('svg'),
    url('../../assests/process/GothamLight.otf') format('opentype');
} */

@font-face {
    font-family: 'Gotham-Book';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Book'), 
    url('../../assets/process/Gotham-Book.woff') format('woff'),
    url('../../assets/process/Gotham-Book.eot'),
    url('../../assets/process/Gotham-Bookd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/Gotham-Book.ttf') format('truetype'),
    url('../../assets/process/Gotham-Book.svg#Gotham-Book') format('svg'),
    url('../../assets/process/Gotham-Book.otf') format('opentype');   
}

@font-face {
    font-family: 'Gotham-Black';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Black'), url('../../assets/process/Gotham-Black.woff') format('woff');
}
.new{

    margin-top:"13px" ;  
    position :'absolute'; 
    right:'60' !important;

}
@font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url('../../assets/process/GothamMedium.woff') format('woff'),
    url('../../assets/process/GothamMedium.woff2') format('woff2'),
    url('../../assets/process/GothamMedium.eot'),
    url('../../assets/process/GothamMediumd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/GothamMedium.ttf') format('truetype'),
    url('../../assets/process/GothamMedium.svg#Gotham-Book') format('svg'),
    url('../../assets/process/GothamMedium.otf') format('opentype'); 
}

@font-face {
    font-family: 'Bariol-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Regular'), 
    url('../../assets/process/Bariol-Regular.woff') format('woff'),
    url('../../assets/process/Bariol-Regular.eot'),
    url('../../assets/process/Bariol-Regulard41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/Bariol-Regular.ttf') format('truetype'),
    url('../../assets/process/Bariol-Regular.svg#Bariol-Regular') format('svg'),
    url('../../assets/process/Bariol-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Bariol-Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Thin'), url('../../assets/process/Bariol-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Bariol-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Bold'), 
    url('../../assets/process/Bariol-Bold.woff') format('woff'),
    url('../../assets/process/Bariol-Bold.eot'),
    url('../../assets/process/Bariol-Boldd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/Bariol-Bold.ttf') format('truetype'),
    url('../../assets/process/Bariol-Bold.svg#Bariol-Regular') format('svg');
}

/* css new */
.form-label-group{
    padding-bottom: 25px;
    height: 85px !important;
}
.placeholder-clr::placeholder {
    color: #bcbcbc !important;
    font-size: 16px;
}
.create-forget-account{
    color: #123C66;
    font-weight: 500;
    font-family: 'poppins';
    font-size: 13px;
}
.section_seprator_20{
    height: 20px;
    width: 100%;
}
.gotquerytext{
    font-family: 'poppins' !important;
    font-size: 17.39px;
    font-weight: 300;
    color: #202020;
}
.mailtext{
    color: #fbc504;
    background-color: #fff !important;
    border: none;
    font-size: 18px;
    font-weight: 500;
    font-family: 'poppins';
}
/* css end */


header {
    background: #ffffff;
    display: none !important;
    visibility: hidden !important;
}

body {
    background: #f7f7f7;
    margin: 0;
}
.login100-more {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* width: calc(100% - 730px); */
    width: 50%;
    position: relative;
    z-index: 1;
    background-image: url("../../assets/img/loginbg.png");
}
.login100-more::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 520px;
    background: linear-gradient(bottom,#e8519e,#c77ff2);
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: calc(100% - 520px);
    z-index: 1;
}
.wrap-login100 {
    /* width: 50% !important; */
    min-height: 100vh;
    background: #fff;
    border-radius: 2px;
    position: relative;
}
.container-login100 {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-wrap: wrap;
}
.card{
    border:none !important;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
}

.card-signin .card-title {
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
    padding: 0;
    width: 458px;
    margin: 0 auto;
}

.form-control{
  /*  background: #f1f1f1 !important;*/
    border: 1px solid #707070;
    border-radius: 0.55rem !important;
    margin-bottom: 25px !important;
    height: 60px !important;
    font-family: 'poppins' !important;
}
.form-control:focus{
    color: #495057;
    background-color: #fff;
    border-color: #fbc504 !important;
    outline: 0;
    box-shadow: none !important;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
      border-color: transparent !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgb(84, 84, 84,0.15) !important;
}
.sw-logo{
    margin: 0 auto;
    text-align: center;
}
.sw-logo img {
    width: 220px;
}
.form-col {
  min-height: 100% !important;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh !important; /* These two lines are counted as one :-)       */
  
  align-items: center !important;
  width: 100%;
    margin: 0 auto;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
}
.myflex-5{
  flex: 1;
}
.btn-login{
    color: #fff;
    background-color: #1980dd !important;
    border-color: #1980dd !important;
}
a.forgot-password-link,.query-link,.create-account-link {
    color: #222222 !important;
    font-weight: 800;
    font-family: 'Gotham-Book';
    line-height: 2;

}
.query-text {
    margin-bottom: 0 !important;
    font-family: 'Gotham-Book';
}

p.sw-login {
    font-family: 'poppins' !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    margin-bottom: 40px !important;
}
.error-login {
    color: #C54D51;
    font-family: 'Gotham-Book';
    font-size: 14px;
    padding: 15px;
    margin-bottom: 30px;
}
#btn_login {
    margin-bottom: 10px;
}
.op-lg-sep {
    width: 100%;
    height: 60px !important;
}
.dsl-inline{
    width: 50%;
    float: left;
}
.dsr-inline{
  display: inline-block;
  float: right;
}
div#error_login {
    border: 1px solid #C54D51 !important;
}
#fa-icon{
    padding-right: 10px;
    padding-top: 5px;
    display: flex;
    font-size: 24px;
        color: #58adab;
}
#fa-text{
      display: flex;
      color: #000000 !important;
      text-shadow: none;
      margin: 0 !important;
}
input::placeholder {
  font-family: 'poppins';
}
button#btn_login {
    margin: 10px 0px 10px 0px;
    background-color: #fbc504 !important;
    border-radius: 50px;
    color: #000000;
    border: none;
    font-family: 'poppins';
    font-weight: 400;
    padding: 10px !important;
}
input::placeholder {
  font-family: 'poppins';
}
.error-field{
    border: 1px solid #ff0000 !important;
}

/*Slider Text Starts*/
.thumbnail {
    display: block !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
    border: none !important;
}
.item {
    background: #fff;
    text-align: center;
    height: 150px;
}
/* /*i.fa {
    margin-top: 50px;
}*/
/*Slider Text End*/
@media only screen and (max-width:767px) and (min-width:280px) {
.form-col,.login100-more,.wrap-login100{
  width: 100% !important;
}
.wrap-login100 {
    min-width: 100%;
}
.form-col{
    display: block !important;
    width: 100% !important; 
}
a.forgot-password-link, .query-link, .create-account-link {
    font-size: 13px !important;
}
button#btn_signup{
  font-size: 16px;
}
.op-lg-sep {
    width: 100%;
    height: 10px !important;
}
button#btn_login {
    font-size: 16px;
    padding: 13px !important;
}
p.sw-login {
    margin-bottom: 25px !important;
}
.card-signin .card-title {
  margin-bottom: 0.5rem !important;
}
}


@media only screen and (max-width:360px) and (min-width:280px) {
.card-signin .card-body {
    margin: 0 auto;
    width: 100%;
    padding: 10px 20px 20px 20px; 
}
@media only screen and (max-width:320px) and (min-width:480px) {
    .sect{
text-align: center !important;
margin:  0 auto !important;
    }
    }

.my-5 {
    margin-top: 0 !important;
}
}
@media only screen and (max-width:767px) and (min-width:361px) {
.card-signin .card-body {
    margin: 0 auto;
    width: 100%;
    padding: 20px; 
}
}



@media only screen and (max-width:996px) and (min-width:768px) {
.form-col,.login100-more,.wrap-login100{
  width: 100% !important;
}
.new{
    margin-top:"13px" ;  
    position :'absolute'; 
    right:'20' !important;

}
.wrap-login100 {
    min-width: 100%;
}
.form-col{
    display: block !important;
    width: 100% !important; 
}
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #BCBCBC;
  }
}
@media only screen and (max-width:480px) and (min-width:320px) {
    .new{
        margin-top:"13px" !important ;  
        position :'absolute' !important; 
        right:'20' !important;
    
    }
}
@media only screen and (max-width:1024px) and (min-width:481px) {
    .new{
        margin-top:"13px" !important ;  
        position :'absolute' !important; 
        right:'20' !important;
    
    }
}