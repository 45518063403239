body {
  margin: 0;
  font-family: 'Product Sans !important' ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y:hidden;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* // header css */
@import url("./common/header/header-styles.css");

@import url("./components/404/index-page.css");



@import url("./components/blog/blog.css");


@import url("./components/private/form-pages/modules/UserProfile/orderProgress/checkprogress.css");


@import url("./components/cancelsubscription/cancelsubscription-style.css");

@import url("./components/changepassord/Forget-style.css");

@import url("./components/dashboard/home/header/header.component.css");

@import url("./components/Dashbord/dashbord-style.css");

@import url("./components/Dashbord/header-styles.css");

@import url("./components/features/features-styles.css");

@import url("./components/Forgotpassword/Forget-style.css");


@import url("./components/home/footer/footer-styles.css");

@import url("./components/home/header/header-styles.css");

@import url("./components/home/middle/middle-styles.css");

@import url("./components/login/login-styles.css");

@import url("./components/logistics/logistics-styles.css");


@import url("./components/portfolio/portfolio-styles.css");


@import url("./components/pricing/pricing-styles.css");

@import url("./components/privacy-policy/privacy-styles.css");

@import url("./components/private/hosting-information/info-material-styles.css");

@import url("./components/private/information-material/info-material-styles.css");

@import url("./components/private/managed-hosting-billing/index.css");

@import url("./components/private/managed-hosting/managed-hosting.css");

@import url("./components/private/service/service.css");

@import url("./components/private/subscription-plan-billing/subscription-styles.css");

@import url("./components/private/web-design-package/website-design-styles.css");

@import url("./components/private/web-domain-email/web-domain-styles.css");

@import url("./components/private/website-desgin-inspiration/website-design-styles.css");

@import url("./components/process/process-styles.css");

@import url("./components/profile/profile-styles.css");

@import url("./components/reviews/reviews-styles.css");

@import url("./components/schedule-a-call/schedule-call-styles.css");

@import url("./components/signup/signup-styles.css");

@import url("./components/terms-and-conditions/tems-styles.css");

@import url("./components/UserDashboard/userdashboard-style.css");


@import url("./components/website-redesign/website-redesign-styles.css");

.tawk-custom-color{
  bottom: 61px !important;
}


































