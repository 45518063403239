.pricesign{
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    position: relative;
    font-family: 'poppins';
}
.service-pg-button:hover{
    background: transparent !important;
    text-decoration: none!important;
    color: #ffffff!important;
    border-color: #ffffff!important;
}

.category_card{
     margin-top: 10px;
       
}

.service-pg-button{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 10px 80px;
    text-decoration: none!important;
    border-radius: 50px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    min-width: 175px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    /* margin: 0 5px 0 5px !important; */
    text-shadow: none !important;
}

@media only screen and (max-width:480px) and (min-width:320px){
   
.cat{
    margin-top: 10px !important
    ;   
} 


}
.cat{
    cursor: pointer;
}