/* RMI - General Classes */

.btn_place_order
 {
    box-sizing: content-box;
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    border: 2px solid #21bdbb;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.infoform-padd{
    padding: 10px 80px 10px 80px;
}
.heading-section {
    font-family: 'Gotham-Medium';
    font-size: 24px;
    text-transform: uppercase;
    color: #161616;
}
.section-title {
    font-family: 'Bariol-Bold';
    font-size: 22px;
}
.section-subtext {
    font-family: 'Bariol-Regular';
    font-size: 18px;
}

.textarea-style{
    border: 1px solid #222222;
    color: #222222;
}
.textarea-style:focus {
    box-shadow: 0 4px 4px 0px rgba(128, 128, 128, 0.42);
}
.payment-form {
    border: 1px solid #222222;
    padding: 8px 14px !important;
    height: calc(1.9em + .75rem + 6px);
    color: #222222;
}
.section-subtext.b-bold {
    font-family: 'Bariol-Bold';
}
.subscription-features-section .feature-title {
    font-family: 'Bariol-Bold';
    font-size: 22px;
}
.subscription-features-section .feature-list {
    list-style: none;
    padding: 0;
}
.subscription-features-section .feature-list li {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    line-height: 1.7;
}

.subscription-features-section .feature-list li:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0 0;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 16px;
    color: #04cc09;
}

.subscription-features-section .feature-list li.cross:before {
    content: "\f00d";
    color: #c20d0d;
    font-size: 18px;
}

.cart-section, .cart-section td {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    color: #555555;
}
.cart-section .thick-line {
    color: #161616;
}

.terms-section {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    color: #555555;
}

hr {border-top: 1px solid rgba(0,0,0,.3);}

/* RMI - Form control styling */
.payment-section {
    padding-right: 50px;
}
.payment-form .form-control {
    border: 1px solid #222222;
    padding: 8px 14px;
    height: calc(1.9em + .75rem + 2px);
    color: #222222;
}
.payment-form:focus {
    box-shadow: 0 4px 4px 0px rgba(128, 128, 128, 0.42);
}
.payment-form .form-control.error-txt:focus {
    box-shadow: none;
}
.payment-section .img-cards-accepted {
    margin-top: -10px;
}
.btn_place_order {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    border: 2px solid #21bdbb;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.btn_place_order:hover {
    background: #0e9e9d;
    border: 2px solid #0e9e9d;
}
 .btn_place_order:focus {
    box-shadow: none;
}

.btn_place_order i{
opacity: 0;
visibility: hidden;
margin-left: -25px;
font-size: 20px;
transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
-webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.btn_place_order:hover i {  
margin-right: 10px;
opacity: 1;
visibility: visible;  
}
 .btn_place_order i.fa-spin {
    margin-right: 10px;
    opacity: 1;
    visibility: visible;
}

.first-opt {
    color: #a20000 !important;
}

.error-txt {
    border: 2px solid #ef4836!important;
}

.error-checkout {
    color: red;
    font-size: 14px;
    text-align: center;
    font-family: 'Gotham-Book';
}

#error_check_terms {
    margin-top: 12px;
    color: red;
    font-size: 14px;
    font-family: 'Gotham-Book';
}

.rs-enabled {
    display: none;
}

#hideAll-1 {
    display: none;
}

#seeAll-1, #hideAll-1 {
    width: 100%;
    text-transform: none;
    font-size: 20px;
}
#seeAll-1:hover {
    cursor: pointer;
}

.general-footer-actions .step-previous {
    margin-right: 0px !important;
}

.op-sb-terms{
    width: 100% !important;
}

.cls-termspolicy:before {
    min-width: 1.8em !important;
}

/* Media Queries - S */
@media screen and (max-width: 1200px) {
    .package-col-1, .package-col-2 {
        margin: 0!important;
    }
}

@media screen and (max-width: 767px) {
    .feature-list {
        display: none;
    }
    #seeAll-1 {
        display: block;
    }
    .rs-disabled {
        display: none;
    }
    .rs-enabled {
        display: block;
    }
    .supported-card-col {
        display: none;
    }
    .payment-section {
        padding-right: 0px;
    }
    .payment-section .mb-3 {
        margin-bottom: 0px!important;
    }
    .payment-section .secure-payment {
        width: 100%;
    }

    .general-footer-actions .step-previous {
        margin: 0 auto !important;
        text-align: center;
        float: none !important;
    }
    
    .cart-section {
        border-top: 1px solid #dee2e6;
    }
}

@media screen and (max-width: 480px) {
    .infoform-padd{
        padding: 10px 10px 10px 10px;
    }
    .info-form-padd{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    }
}