@font-face {
    font-family: Bariol;
    src: url('../../assets/process/bariol_regular-webfont.eot');
    src: url('../../assets/process/bariol_regular-webfontd41d.eot?#iefix') format("embedded-opentype"),
     url('../../assets/process/bariol_regular-webfont.svg') format('svg'),
      url('../../assets/process/bariol_regular-webfont.ttf') format('truetype'),
       url('../../assets/process/bariol_regular-webfont.woff') format('woff'),
        url('../../assets/process/Bariol_Regular.otf') format('otf'),
         url('../../assets/process/bariol_regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: GothamBook;
    src: url('../../assets/process/Gotham-Book.eot');
    src: url('../../assets/process/Gotham-Bookd41d.eot?#iefix') format("embedded-opentype"),
     url('../../assets/process/Gotham-Book.woff') format("woff"), 
     url('../../assets/process/Gotham-Book.ttf') format('truetype'),
      url('../../assets/process/Gotham-Book.svg#Gotham-Book') format("svg"), 
      url('../../assets/process/Gotham-Book.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: BariolBold;
    src: url('../../assets/process/bariol_bold-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url('../../assets/process/GothamMedium.woff') format('woff'),
    url('../../assets/process/GothamMedium.woff2') format('woff2'),
    url('../../assets/process/GothamMedium.eot'),
    url('../../assets/process/GothamMediumd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/GothamMedium.ttf') format('truetype'),
    url('../../assets/process/GothamMedium.svg#Gotham-Book') format('svg'),
    url('../../assets/process/GothamMedium.otf') format('opentype'); 
}
/*  css start */
.designprocess-bg {
    padding: 30px;
    border-radius: 8px;
    height: 290px;
}
.websiteredesign-bg{
    padding: 20px;
}
.featureslist{
    padding: 0px 20px 10px 20px;
}
.featurespoints{
    font-size: 15px;
    font-family: 'Poppins';
    color: #fff;
    font-weight: 300;
}
/*  css start */


.template-page{
    overflow-x: hidden;
}
section#sw_rdw_one_caption {
    background: #00E90B;
}
.sw-tour-slider{
  position: relative;
  text-align: center;
  color: white;
}
p.icon_sub_text {
    font-size: 18px;
    font-family: 'Bariol';
    text-align: center;
}
ul.redesign_list li {
    font-size: 23px;
    font-family: Bariol;
    color: #4d545b;
    line-height: 1.8;
    list-style: disc;
}
ul.redesign_list {
    padding-left: 18px;
}
section#sw_tour_one_image .col-sm {
    padding: 0 !important;
    margin-top: -1px !important;
}
.container-fluid.sw-tour-slider {
    padding: 0 !important;
}
img.sw_lg{
width: 100% !important;
}
p.sw_slider_sup {
    font-size: 20px;
    color: #000;
    font-family: Bariol!important;
    text-transform: uppercase;
    text-shadow: none!important;
}
.sw_slider_heading_redesign {
    color: #ffffff!important;
    font-family: 'Gotham-Medium' !important;
    font-size: 42px;
    text-transform: capitalize!important;
    font-weight: bolder!important;
    text-shadow: none!important;
    line-height: 1.3;
    margin: 0 0 20px 0 !important;
    text-transform: inherit !important;
    text-align: center !important;
}
p.sw_slider_subtext_redesign {
    color: #ffffff;
    font-family: Bariol;
    font-size: 23px;
    line-height: 1.5;
    text-shadow: none!important;
    margin: 0 0 30px 0 !important;
    text-align: center !important;
}
section#sw_home .col-sm {
    padding: 0;
}
.sw_features_div {
    padding: 16px 0 0 98px;
}
.vertical_seprator {
    background: #e5e5e5;
    bottom: 0;
    content: " ";
    margin-left: -15px;
    position: absolute;
    top: 0;
    width: .3ch;
}
h3.sw_features_heading {
    margin: 0;
    font-size: 16px;
    font-family: GothamBook;
    color: #292929!important;
    font-weight: 700;
}
ul.sw_features_list {
    margin-left: 0!important;
    padding-left: 0;
    font-size: 14px!important;
    font-family: GothamBook!important;
    color: #292929;
}
.sw_features_seprator {
    width: 100%;
    height: 30px;
}
.row.sw_section_one {
    padding: 50px 40px;
    border-radius: 9px;
    -webkit-box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
    -moz-box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
    box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
}
.section_seprator {
    width: 80px;
    height: 80px;
}
.sw_online_heading {
    font-size: 38px!important;
    color: #222;
    font-family: 'Gotham-Medium' !important;
    line-height: 56px;
    font-weight: 700;
    text-align: center;
}
.sw_ol_sub_text {
    font-size: 23px;
    font-family: Bariol;
    text-align: center;
    color: #4d545b;
    line-height: 33px;
    width: 80%;
    margin: 0 auto;
}
.sw_fm_heading {
    font-size: 21px;
    line-height: 26px;
    color: #010101;
    text-align: center;
    font-family: GothamBook!important;
    font-weight: bolder!important;
    margin: 0!important;
}
.sw_fm {
    margin: 0 auto;
    text-align: center;
}
section#sw_rdw_threee{
background: #00E90B;
background:#00E90B;
}
.sw_div_center{
text-align: center !important;
}
.white{
    color: #ffffff !important;
}
.sw_sep_30 {
    width: 100%;
    height: 30px;
}
.sw_sep_15 {
    width: 100%;
    height: 15px;
}
.sw_s4_sup {
    color: #80c3c2!important;
    font-size: 21px;
    font-family: 'Gotham-Medium'!important;
}
.sw_s4_heading {
    font-family: 'Gotham-Medium'!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
}
.sw_s4_heading_center{
   font-family: 'Gotham-Medium'!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
    text-align: center;
}
.sw_s4_text {
    font-size: 23px;
    line-height: 32px;
    font-family: Bariol;
    color: #4d545b;
    margin: 20px 0;
}
.sw_redesign_button{
    color: #ffffff!important;
    border: 1px solid #ffffff!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 10px;
}
.sw_redesign_button:hover {
    background: #ffffff!important;
    text-decoration: none!important;
    border-color: #ffffff!important;
    color: #d9b717!important;
}
.sw_redesign_button_sc {
    color: #00E90B!important;
    border: 1px solid #00E90B!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 10px;
}
.sw_redesign_button_sc:hover {
    background: #00E90B !important;
    text-decoration: none!important;
    border-color: #00E90B!important;
    color: #fff!important;
}

.sw_redesign_button_color{
      color: white !important;
    border: 1px solid #ffffff !important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 20px !important; 
}
.sw_redesign_button_color:hover {
    background: #ffffff!important;
    text-decoration: none!important;
    border-color: #ffffff!important;
    color: #00E90B !important;
}
h2.sw_con_heading {
    color: #fff;
    text-align: center!important;
    font-size: 42px;
    font-family: GothamBook;
    font-weight: bolder;
    margin: 0 auto;
    line-height: 46px;
}
h3.sw_con_su_heading {
    font-size: 22px;
    color: #fff;
    font-family: GothamBook;
    text-align: center!important;
    margin: 0 auto;
    line-height: 29px;
}
.sw_btn_div {
    text-align: center;
    margin: 0 auto;
}
.sw_posu_text {
    font-size: 23px;
    line-height: 33px;
    color: #292929;
    font-family: Bariol;
    text-align: center;
    width: 100%;
}

.po_icon {
    text-align: center;
    padding-bottom: 15px;
}
h3.sw_suheading {
    text-align: center;
    font-size: 22px;
    font-family: 'Gotham-Medium';
    line-height: 30px;
}
.col-center {
    margin: 0 auto;
    float: none!important;
}
#custom-footer {
    display: block!important;
}
.horizontal_seprator {
    border: 1px solid #a1a1a1;
}
.sw_rimg{
width: 100%;
float: right;
}
.sw_limg{
    margin-top: -60px;
    width: 100%;
    height: 100%;
}
@media only screen and (max-width:1600px) and (min-width:1200px) {
    .sw_slider_heading_redesign {
        font-size: 30px!important;
    }
    .sw_online_heading,.sw_s4_heading_center {
        font-size: 28px!important;
    }
    .sw_s4_heading{
        font-size: 24px !important; 
    }
    p.sw_slider_subtext_redesign,.sw_s4_text,ul.redesign_list li{
        font-size:20px;
    }
}
@media only screen and (max-width:1366px) and (min-width:1024px) {
.row.sw_section_one {
    padding: 50px 11px;
}
}
@media only screen and (max-width:1024px) and (min-width:768px) {
section#sw_home {
    margin-top: 0!important;
}
.sw_slider_heading_redesign {
    font-size: 26px!important;
    line-height: 1.3!important;
}
p.sw_slider_subtext_redesign {
    font-size: 18px!important;
    line-height: 1.2!important;
    margin: 20px 0 !important;
}
.sw_s4_text{
    font-size: 18px !important;
}
ul.redesign_list li {
    font-size: 18px;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px;
}
.sw_s4_heading,.sw_s4_heading_center {
    font-size: 20px;
}
.sw_online_heading {
    font-size: 24px!important;
}
.sw_online_heading br{
display: none !important;
}
.vertical_seprator {
    display: none!important;
}
h3.sw_suheading {
    font-size: 18px !important;
    line-height: 25px !important;
}
.sw_redesign_button,.sw_redesign_button_color {
    display: block !important;
    margin: 10px auto 10px auto !important;
    padding: 10px !important;
    font-size: 16px;
    width: 230px;
}
.sw_redesign_button_sc{
    /*display: block !important;*/
    margin: 10px 0 10px 0 !important;
    padding: 10px !important;
    font-size: 16px;
    width: 230px;
}
}
@media only screen and (max-width:1023px) and (min-width:768px) {
.header-custom {
    background-color: #ffffff !important;
}
.section_seprator {
    width: 80px;
    height: 40px;
}
.sw_slider_heading_redesign {
    font-size: 26px!important;
    line-height: 1.3!important;
    margin-bottom: 10px !important;
}
}

@media only screen and (max-width:767px) and (min-width:280px) {
a.sw-brand-logo img {
    width: 155px!important;
    padding: 10px 0 12px 24px!important;
}
.header-custom {
    padding: 4px!important;
}
section#sw_home {
    margin-top: 0!important;
}
.sw_online_heading {
    font-size: 22px!important;
    line-height: 1.3;
}
.sw_wk_rating_icon img {
    width: 90px!important;
    height: 81px!important;
}
.sw_s4_heading,.sw_s4_heading_center {
    font-size: 20px!important;
}
.sw_s4_text {
    font-size: 18px!important;
    margin: 0!important;
}
.sw_posu_heading, h2.sw_con_heading {
    font-size: 28px!important;
}
.sw_redesign_button {
    display: block!important;
    margin: 10px 0!important;
}
.sw_slider_heading_redesign {
    font-size: 24px!important;
    margin: 6px 0 !important;
}
.sw_slider_heading_redesign br,.sw_slider_subtext_redesign br{
    display: none !important;
}
p.sw_slider_subtext_redesign {
    font-size: 18px!important;
    line-height: 1.2!important;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px!important;
}
.sw_ol_sub_text {
    width: 100%}
.sw_wk_heading {
    font-size: 26px!important;
    line-height: 34px!important;
}
.section_seprator {
    width: 80px!important;
    height: 30px!important;
}
.po_icon img {
    width: 100px !important;
}
.sw_redesign_button,.sw_redesign_button_color, .sw_redesign_button_sc {
    display: block !important;
    margin: 10px 0 !important;
    font-size: 16px;
}
 .sw_redesign_button_color{
    margin: 10px 0 20px 0 !important;
 }
h3.sw_suheading {
    font-size: 18px !important;
}
a.sw_tour_sac,a.sw_tour_succeed{
    margin: 0 !important;
    display: block !important;
}
.header-custom {
    background-color: #ffffff !important;
}
ul.redesign_list li {
    font-size: 19px !important;
}
.mb-col-sep{
width: 100%;
height: 40px;
}
.flex-switch{
    display: flex !important;
    flex-direction: column-reverse;
}
.section_seprator.fl-spxs{
height: 0px !important;
}
}
@media only screen and (max-width: 1366px) and (min-width: 280px){
  /* img{
    width: 100% !important;
} */
.po_icon img {
    width: 100px !important;
}
}