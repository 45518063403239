

@font-face {
    font-family: Bariol;
    src: url('../../assets/process/bariol_regular-webfont.eot');
    src: url('../../assets/process/bariol_regular-webfontd41d.eot?#iefix') format("embedded-opentype"),
     url('../../assets/process/bariol_regular-webfont.svg') format('svg'),
      url('../../assets/process/bariol_regular-webfont.ttf') format('truetype'),
       url('../../assets/process/bariol_regular-webfont.woff') format('woff'),
        url('../../assets/process/Bariol_Regular.otf') format('otf'),
         url('../../assets/process/bariol_regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: GothamBook;
    src: url('../../assets/process/Gotham-Book.eot');
    src: url('../../assets/process/Gotham-Bookd41d.eot?#iefix') format("embedded-opentype"),
     url('../../assets/process/Gotham-Book.woff') format("woff"), 
     url('../../assets/process/Gotham-Book.ttf') format('truetype'),
      url('../../assets/process/Gotham-Book.svg#Gotham-Book') format("svg"), 
      url('../../assets/process/Gotham-Book.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url('../../assets/process/GothamMedium.woff') format('woff'),
    url('../../assets/process/GothamMedium.woff2') format('woff2'),
    url('../../assets/process/GothamMedium.eot'),
    url('../../assets/process/GothamMediumd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/GothamMedium.ttf') format('truetype'),
    url('../../assets/process/GothamMedium.svg#Gotham-Book') format('svg'),
    url('../../assets/process/GothamMedium.otf') format('opentype'); 
}
  @font-face {
    font-family: BariolBold;
    src: url('../../assets/process/bariol_bold-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

    /******************* Timeline Demo - 1 *****************/
    html {
      scroll-behavior: smooth;
    }
    .main-header.header-custom.header-transparency {
      background: #fff !important;
    }
    a{text-decoration:none}
    h4{text-align:center;margin:15px 0;color:#444}
    .main-timeline{position:relative}
    .main-timeline:before{content:"";width:5px;height:100%;border-radius:20px;margin:0 auto;background:#242922;position:absolute;top:0;left:0;right:0}
    .main-timeline .timeline{display:inline-block;margin-bottom:50px;position:relative}
    .main-timeline .timeline:before{content:"";width:20px;height:20px;border-radius:50%;border:4px solid #fff;background:#ec496e;position:absolute;top:50%;left:50%;z-index:1;transform:translate(-50%,-50%)}
    .main-timeline .timeline-icon{position:absolute;left:0;}
    .main-timeline .timeline-icon i{display:block;border-radius:50%;background:#ec496e;font-size:64px;color:#fff;line-height:100px;z-index:1;position:relative}
    /*.main-timeline .timeline-icon:after,.main-timeline .timeline-icon:before{content:"";width:100px;height:4px;background:#ec496e;position:absolute;top:50%;right:-100px;transform:translateY(-50%)}*/
    .main-timeline .timeline-icon:after{width:70px;height:50px;background:#fff;top:89px;right:-30px}
    .main-timeline .timeline-content{width:50%;padding:4px 50px;margin:2px 0 0;float:right;position:relative}
    .main-timeline .timeline-content1{width:50%;padding:0 50px 0 0;margin:2px 0 0;position:relative}
    /*.main-timeline .timeline-content:before{content:"";width:70%;height:100%;border:3px solid #ec496e;border-top:none;border-right:none;position:absolute;bottom:-13px;left:35px}*/
    .main-timeline .timeline-content:after{content: "";width: 25px;height: 3px;background: #c2c2c2;position: absolute;top: 17px;left: 19px;}
    .main-timeline .timeline-content1:after{content: "";width: 25px;height: 3px;background: #c2c2c2;position: absolute;top: 17px;right: 19px;}
    .main-timeline .title{font-size:20px;font-weight:600;color:#ec496e;text-transform:uppercase;margin:0 0 5px}
    ul.description li{    display: inline-block;font-family: 'Bariol' !important;margin: 10px 0;font-size: 23px;line-height: 32px;color: #222;}
    ul.description {margin-left: 0;padding-left: 0 !important;}
    .main-timeline .timeline:nth-child(even) .timeline-icon{left:auto;right:0;}
    .main-timeline .timeline:nth-child(even) .timeline-icon:before{right:auto;left:-100px}
    .main-timeline .timeline:nth-child(even) .timeline-icon:after{right:auto;left:-30px}
    .main-timeline .timeline:nth-child(even) .timeline-content{float:left}
    /*.main-timeline .timeline:nth-child(even) .timeline-content:before{left:auto;right:35px;transform:rotateY(180deg)}*/
    .main-timeline .timeline:nth-child(even) .timeline-content:after{left:auto;right:22px;}
    .main-timeline .timeline:nth-child(2n) .timeline-content:after,.main-timeline .timeline:nth-child(2n) .timeline-icon i,.main-timeline .timeline:nth-child(2n) .timeline-icon:before,.main-timeline .timeline:nth-child(2n):before{background:#c2c2c2}
    .main-timeline .timeline:nth-child(2n) .timeline-icon{border-color:#f9850f}
    .main-timeline .timeline:nth-child(2n) .title{color:#f9850f}
    /*.main-timeline .timeline:nth-child(2n) .timeline-content:before{border-left-color:#f9850f;border-bottom-color:#f9850f}*/
    .main-timeline .timeline:nth-child(3n) .timeline-content:after,.main-timeline .timeline:nth-child(3n) .timeline-icon i,.main-timeline .timeline:nth-child(3n) .timeline-icon:before,.main-timeline .timeline:nth-child(3n):before{background:#c2c2c2}
    .main-timeline .timeline:nth-child(3n) .timeline-icon{border-color:#8fb800}
    .main-timeline .timeline:nth-child(3n) .title{color:#8fb800}
    .main-timeline .timeline:nth-child(3n) .timeline-content:before{border-left-color:#8fb800;border-bottom-color:#8fb800}
    .main-timeline .timeline:nth-child(4n) .timeline-content:after,.main-timeline .timeline:nth-child(4n) .timeline-icon i,.main-timeline .timeline:nth-child(4n) .timeline-icon:before,.main-timeline .timeline:nth-child(4n):before{background:#c2c2c2}
    .main-timeline .timeline:nth-child(4n) .timeline-icon{border-color:#2fcea5}
    .main-timeline .timeline:nth-child(4n) .title{color:#2fcea5}
    .main-timeline .timeline:nth-child(4n) .timeline-content:before{border-left-color:#2fcea5;border-bottom-color:#2fcea5}
    @media only screen and (max-width:1200px){.main-timeline .timeline-icon:before{width:50px;right:-50px}
    .main-timeline .timeline:nth-child(even) .timeline-icon:before{right:auto;left:-50px}
    .main-timeline .timeline-content{margin-top:0px}
    }
    @media only screen and (max-width:990px){.main-timeline .timeline{margin:0 0 10px}
    .main-timeline .timeline-icon{left:25%}
    .main-timeline .timeline:nth-child(even) .timeline-icon{right:25%}
    .main-timeline .timeline-content{margin-top:115px}
    }
    @media only screen and (max-width:767px){.main-timeline{padding-top:50px}
    .main-timeline:before{left:80px;right:0;margin:0}
    .main-timeline .timeline{margin-bottom:70px}
    .main-timeline .timeline:before{top:0;left:83px;right:0;margin:0}
    .main-timeline .timeline-icon{width:60px;height:60px;line-height:40px;padding:5px;top:0;left:0}
    .main-timeline .timeline:nth-child(even) .timeline-icon{left:0;right:auto}
    .main-timeline .timeline-icon:before,.main-timeline .timeline:nth-child(even) .timeline-icon:before{width:25px;left:auto;right:-25px}
    .main-timeline .timeline-icon:after,.main-timeline .timeline:nth-child(even) .timeline-icon:after{width:25px;height:30px;top:44px;left:auto;right:-5px}
    .main-timeline .timeline-icon i{font-size:30px;line-height:45px}
    .main-timeline .timeline-content,.main-timeline .timeline:nth-child(even) .timeline-content{width:100%;margin-top:-15px;padding-left:130px;padding-right:5px}
    .main-timeline .timeline:nth-child(even) .timeline-content{float:right}
    .main-timeline .timeline-content:before,.main-timeline .timeline:nth-child(even) .timeline-content:before{width:50%;left:120px}
    .main-timeline .timeline:nth-child(even) .timeline-content:before{right:auto;transform:rotateY(0)}
    .main-timeline .timeline-content:after,.main-timeline .timeline:nth-child(even) .timeline-content:after{left:85px}
    }

    /*css start */
    .list1{
      padding: 20px;
    }
    .process-pad-top{
      margin: 20px 0px 10px 0px;
    }
    .listpoint{
    font-size: 15px;
    font-family: 'Poppins';
    color: #fff;
    font-weight: 300;
    margin-bottom: 10px;
    }
    .listpoint::marker {
      color: #fbc504;
      font-size: 25px;
  }
  .process-hr {
    border: 1px solid #868181;
    margin: 0 auto;
    width: 80%;
}
    /*css start */

    #standard, #ultimate {
        counter-reset: my-sec-counter;
      }
      #process .main-timeline { 
        position: relative; 
        padding-top: 50px;
      }
      #process .main-timeline:before{
        content: '';
        height: 95.5%;
        width: 1px;
        border-left: 2px solid #909090;
        transform: translatex(-50%);
        position: absolute;
        left: 0;
        top: 90px;
      }
      #process .main-timeline .timeline{
        margin-bottom: 80px;
        padding: 20px 0;
        position: relative;
        z-index: 1;
      }
      #process .main-timeline .timeline:before{
        height: 35px;
        width: 35px;
        background-color: #898989;
        border-radius: 50%;
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        left: 50%;
        top: 11%;
        z-index: -1;
        text-align: center;
        line-height: 35px;
        color: #fff;
      }
      #process .main-timeline .timeline:after{
        width: 35%;
        height: 100px;
        border-radius: 0;
        left: auto;
        right: 0;
        top: 50%;
        transform: translateX(0) translateY(-50%);
        -webkit-clip-path: polygon(10% 0, 0 50%, 10% 100%, 100% 100%, 100% 0);
        clip-path: polygon(10% 0, 0 50%, 10% 100%, 100% 100%, 100% 0);
      }
      #process .main-timeline .timeline-content{
        display: block;
        padding-bottom: 0px;
      }
      #process .main-timeline .timeline-content:hover{text-decoration: none;}
      #process .main-timeline .timeline-content:after{
        content: '';
        display: block;
        clear: both;
      }
      #process .main-timeline .content{
        display: inline-block !important;
        width: 45% !important;
        color: #909090 !important;
        position: relative !important;
        border: none !important;
        padding-top: 0;
      }
      #process .main-timeline p.description{
        font-size: 18px;
        margin: 0;
        line-height: 1.5;
        font-family: "GothamLight" !important;
        color: #2a2a2a !important;
      }
      #process .main-timeline .title{
        margin: 0 0 5px 0;
        text-transform: capitalize;
        margin-bottom: 15px !important;
        position: relative;
        font-family: 'Gotham-Medium'!important;
        font-weight: 700!important;
        font-size: 36px;
        color: #292929;
      }
    
      #process .main-timeline .timeline:nth-child(even):after{
        right: auto;
        left: 0;
        transform: translateX(0) translateY(-50%) rotate(180deg);
      }
      #process .main-timeline .timeline:nth-child(even) .content{float: right;}
      #process.main-timeline .timeline:nth-child(even) .timeline-icon{
        right: auto;
        left: 2%;
      }
      #process .main-timeline .timeline:nth-child(4n+1):after{
        background: linear-gradient(to right,#01C0F7,#01C0F7,#01C0F7,#01C0F7,#01C0F7,transparent,#868686);
      }  
      #process .main-timeline .timeline:nth-child(4n+2) .timeline-icon{
        color: #0076B0;
        border-color: #0076B0;
      }
      #process .main-timeline .timeline:nth-child(4n+3) .timeline-icon{
        color: #00B09E;
        border-color: #00B09E;
      }
      #process .main-timeline .timeline:nth-child(4n+4) .timeline-icon{
        color: #22237E;
        border-color: #22237E;
      }
    
      #process .content-heading1 {
        font-size: 38px!important;
        color: #222;
        font-family: 'Gotham-Medium' !important;
        line-height: 56px;
        font-weight: 700;
        text-align: center;
        margin-top: 0;
      }
      #process .content-cont {
        font-family: 'Bariol' !important;
        font-size: 23px;
        line-height: 32px;
        color: #4d545b;
        text-align: center;
      }
      #process .left-auto {
        margin-right: auto !important;
        margin-left: unset !important;
      }
      #process .timeline-icon.right {
        float: right;
        width: 42%;
        text-align: left;
        padding: 0 !important;
      }
      #process .timeline-icon.left {
        float: none;
        width: 42%;
        text-align: right;
        display: inline-block;
      }
      #process .main-timeline .timeline.no-number:before {
        content: '' !important;
        height: 22px;
        width: 22px;
      }
      #process .main-timeline .title.no-margin {
        margin-bottom: 0 !important;
      }
      #process .content p.sub-title {
        margin-top: 2px;
        text-align: left;
        width: 55%;
        margin-left: auto !important;
        margin-right: 0 !important;
      }
      #process .process-btn {
        text-align: center;
        line-height: 2;
        padding-top: 40px;
      }
    
      #process div#ultimate-content,#process div#ultimate {
        display: none;
      }
      #process div#process-selection {
        text-align: center;
        padding-bottom: 40px;
      }
      #process div#process-selection label {
        font-size: 23px !important;
        margin-right: 40px;
        margin-left: 40px;
        cursor: pointer;
        position: relative;
        display: inline-block;
        color: #222222;
        text-align: center;
        font-family: 'Gotham-Medium' !important;
      }
      #process input[type=radio] {
        position: absolute;
        opacity: 0;
      }
      #process .alrdomain-checkmark {
        position: absolute;
        top: 0px;
        left: -20px;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 50%;
        border: 3px solid #636363;
        background: #fcfcfc;
        cursor: pointer;
      }
      #process .alrdomain-label-checkbox {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        font-size: 20px !important;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-family: 'GothamLight' !important;
        text-align: left;
        color: #222222;
        text-align: left;
        font-weight: lighter !important;
      }
      #process .alrdomain-label-checkbox input:checked ~ .alrdomain-checkmark:after {
        display: block;
        background: #222222 !important;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        top: 3px;
        left: 3px;
      }
    
      /* When the checkbox is checked, add a blue background */
    #process input:checked ~ .checkmark {
      background-color: #187cd4;
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .alrdomain-checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the checkmark when checked */
    #process input:checked ~ .checkmark:after {
      display: block;
    }
      .timeline-content img {
       max-height: 140px;
          margin-top: -20px;
      }
      .timeline.no-number {
          padding: 0 !important;
      }
      h3.title.right-align {
       
          text-align: left !important;
          margin-right: 0 !important;
          margin-left: auto !important;
      }
      div#ultimate-content p.content-cont:nth-child(2) {
          padding-bottom: 15px !important;
      }
      /*Media Query Start*/
      a.sw_process_button{
        color: #ffffff !important;
        background-color: #197cd3 !important;
        border: 1px solid #197cd3 !important;
        padding: 10px 30px;
        text-decoration: none!important;
        border-radius: 5px;
        font-family: Bariol;
        font-weight: bold;
        font-size: 19px;
        max-width: 270px!important;
        text-align: center;
        background: 0 0;
        width: initial;
        transition: all .5s ease-in-out;
        text-transform: uppercase;
        text-shadow: none !important;
        margin-right: 10px !important;
      }
      .sw_process_button:hover {
        background: #ffffff !important;
        text-decoration: none!important;
        border-color: #197cd3 !important;
        color: #197cd3 !important;
    }
    h2.swpr-heading-one {
      font-size: 38px!important;
      color: #222;
      font-family: 'Gotham-Medium' !important;
      line-height: 56px;
      font-weight: 700;
      text-align: center;
      margin-top: 0;
    }
    p.swpr-text-one {
      font-family: 'Bariol' !important;
      font-size: 23px;
      line-height: 32px;
      color: #4d545b;
    }
    .sw-lg-sep {
        width: 100%;
        height: 50px;
    }
    .section_seprator {
      width: 100%;
      height: 80px;
    }
    hr.sw-line-sep {
        border-top: 1px solid #a1a1a1;
        display: block;
        width: 100%;
        margin: 0 !important;
    }
    a.timeline {
        text-decoration: none !important;
    }
    h3.title {
      text-transform: capitalize;
      margin-bottom: 15px !important;
      position: relative;
      font-family: 'Gotham-Medium'!important;
      font-weight: 700!important;
      font-size: 36px;
      color: #292929;
    }
    section#why-to-choose-godiggo {
        background: #197cd3;
    }
    h2.section_five_heading {
        font-family: 'Gotham-Medium' !important;
        color: #fff;
        text-align: center;
        font-size: 38px;
        margin: 0 !important;
    }
    #sw_table tr th {
        border: none;
        width: 30%;
    }
    th.tb_heading {
        color: #fff;
        font-size: 18px;
        font-family: 'Gotham-Medium';
        text-align: center;
        text-transform: capitalize;
    }
    #sw_table tbody tr td {
        border: none !important;
        border-right: 1px solid #fff !important;
        font-family: 'Bariol';
        font-weight: 100 !important;
        font-size: 18px;
        padding: 15px 0 !important;
    }
    #sw_table tbody tr td:last-child {
        border: none !important;
        border-right: none !important;
    }
    td.tb_left_heading {
        color: #fff;
        font-family: 'Bariol';
        font-size: 22px;
        padding: 0 15px;
        vertical-align: middle;
    }
    td.tb_image {
        text-align: center;
    }
    #sw_table tbody tr {
        vertical-align: middle !important;
    }
    td.tb_image img {
        width: 30px;
    }
    th.tb_heading {
        font-family: 'Gotham-Medium' !important;
        font-weight: lighter;
        font-size: 18px;
    }
    .sw-choose-container {
        max-width: 600px;
        margin: 0 auto;
    }
    td:first-child {
        width: 245px !important;
        max-width: 245px !important;
        min-width: 245px;
    }
    .sw_process_sec {
        color: #197cd3 !important;
        border: 1px solid #197cd3 !important;
        padding: 8px 30px;
        text-decoration: none!important;
        border-radius: 5px;
        font-family: Bariol;
        font-weight: bold;
        font-size: 19px;
        max-width: 270px!important;
        display: block;
        text-align: center;
        background: 0 0;
        width: initial;
        transition: all .5s ease-in-out;
        display: inline-block;
        text-transform: uppercase;
    }
    .sw_process_sec:hover {
        background: #197cd3  !important;
        text-decoration: none!important;
        border-color: #197cd3 !important;
        color: #fff!important;
    }
    .sw_btn_div {
        margin-top: 10px;
        text-align: center;
    }
    .sw-sm-sep{
    width: 100%;
    height: 20px;
    }
    .sw-pricing-nav nav {
        width: 100%;
        background: #0f0f0f !important;
        margin-top: 0px;
        padding: 20px;
    }
    .nav-col-div {
        height: 0px;
        background: #fff;
    }
    .sw-pricing-nav ul li a {
      padding: 10px 25px;
      color: #fff !important;
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      transition: all 0.2s ease;
      font-family: 'poppins';
    }
    .nav-item.active .nav-link {
        color: #fbc504 !important;
        /* border-bottom: 4px solid #fff; */
    }
    .nav>li>a:focus, .nav>li>a:hover {
        background-color: transparent !important;
    }
    .sw-pricing-nav ul {
        padding: 0px;
        margin: 0 auto;
        list-style: none;
        text-align: center;
        width: 90%;
        /* justify-content: center; */
        justify-content: space-evenly;
    }
    .sticky.is-sticky {
        position: fixed;
        left: 0;
        right: 0;
        top: 0px;
        z-index: 2;
        width: 100%;
    }
    .sw_sep_15{
      width: 100%;
      height: 15px;
    }
    .radio-box {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .radio-box input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkmark {
      position: absolute;
      top: 4px;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #868181;
      border-radius: 50%;
    }
    .radio-box:hover input ~ .checkmark {
      background-color: #ccc;
    }
    .radio-box input:checked ~ .checkmark {
      background-color: rgb(251, 197, 4);
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    .radio-box input:checked ~ .checkmark:after {
      display: block;
    }
    .radio-box .checkmark:after {
         top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }
    .sw-pricing-main .col-md-12 {
      padding: 0 !important;
      margin: 0 !important;
    }
    @media only screen and (max-width:1600px) and (min-width:1200px) {
      h2.swpr-heading-one,#process .content-heading1,h2.section_five_heading {
        font-size: 28px!important;
      }
      p.swpr-text-one,#process .content-cont,ul.description li {
        font-size: 20px !important;
      }
      #process .main-timeline .title,h3.title{
        font-size: 24px !important;
      }
    }
    @media only screen and (max-width:1024px) and (min-width:768px) {
      .section_seprator {
        width: 100%;
        height: 40px;
    }
    #process .timeline-icon img {
        max-height: 100% !important;
        width: auto !important;
        margin-left: 20px;
    }
    #process .main-timeline .title {
      font-size: 20px !important;
    }
    ul.description li {
      margin: 0px 0 !important;
      font-size: 18px !important;
    }
    h3.title {
      font-size: 24px !important;
    }
    .main-timeline .timeline-content {
        margin-top: 25px !important
    }
    .main-timeline .timeline-content {
        width: 100% !important;
    }
    .main-timeline .timeline-icon {
        left: 5% !important;
    }
    .main-timeline .timeline-icon {
        position: initial !important;
    }
    .main-timeline:before {
        margin: 0 !important;
    }
    #process .main-timeline .timeline:before{
      left: 0 !important;
    }
    .main-timeline .timeline-content1 {
        padding: 0 0px 0 50px !important;
    }
    .sw-pricing-nav ul li a {
        padding: 10px 14px !important;
        font-size: 13px !important;
    }
    /* span.navbar-toggler-icon {
        display: none !important;
    } */
    p.swpr-text-one,#process .content-cont {
        font-size: 18px !important;
        margin: 10px auto !important;
    }
    .main-timeline .timeline-content{
      margin-top: 20px !important;
    }
    .sw-lg-sep {
        width: 100%;
        height: 30px !important;
    }
    .main-timeline .timeline-content1 {
        width: 100% !important;
    }
    #process .content-heading1 {
      margin: 0px !important;
      font-size: 24px!important;
    }
    h2.swpr-heading-one, h2.section_five_heading {
        font-size: 26px !important;
        line-height: 1.3;
    }
    #process .process-btn{
      padding-top: 0 !important;
    }
    #process .main-timeline {
        padding-top: 0px !important;
    }
    .timeline-icon img {
        max-width: 100% !important;
    }
    section#change-request img {
        max-width: 100% !important;
    }
    .main-timeline .timeline-content1:after {
        left: 10px !important;
    }
    #process div#process-selection label {
        font-size: 18px !important;
    }
    }
    @media only screen and (max-width:767px) and (min-width:280px) {
      .sw-schedule-call-formdiv{
        padding: 20px !important;
      }
      .sw_posu_heading{
        font-size: 15px !important;
      }
      a.sw_home_succeed{
        display: inline-block !important;
      }
      .section_seprator {
        width: 100%;
        height: 30px;
    }
    .checkmark {
      top: 0px !important;
    }
    #sw_table tbody tr td {
      font-size: 14px;
    }
    td.tb_image img {
      width: 20px;
    }
    #process div#process-selection {
      padding-top: 20px;
    }
    #process .timeline-icon img {
        max-width: 100% !important;
    }
    .main-timeline .timeline-icon {
        width: 100% !important;
        height: auto !important;
    }
    .main-timeline .timeline {
        display: contents !important;
    }
    #process div#process-selection label {
        font-size: 18px !important;
    }
    #process .main-timeline:before {
        height: 117% !important;
    }
    section#change-request img {
        width: 100% !important;
        max-width: 100% !important; 
    }
    .main-timeline .timeline-icon {
        position: relative !important;
    }
    .main-timeline .timeline-content, .main-timeline .timeline:nth-child(2n) .timeline-content {
        padding-left: 20px !important;
    }
    .main-timeline .timeline-content1 {
        width: 100% !important;
        padding: 0 0px 0 38px !important;
    }
    .sw-lg-sep {
        width: 100%;
        height: 30px !important;
    }
    h2.swpr-heading-one,h2.section_five_heading,#process .content-heading1 {
        font-size: 22px !important;
        line-height: 1.3;
    }
    p.swpr-text-one,#process .content-cont {
        font-size: 18px !important;
    }
    ul.description li{
      display: block !important;
      font-size: 18px !important;
    }
    .main-timeline .timeline-content:after, .main-timeline .timeline:nth-child(2n) .timeline-content:after {
        left: 0 !important;
        top: 30px !important;
    }
    ul.description {
        margin-left: 0;
        padding-left: 0px !important;
    }
    a.sw_process_sec {
        display: block!important;
        margin: 10px auto !important;
    }
    a.sw_process_button {
        margin: 0 auto !important;
        display: block !important;
    }
    td:first-child {
        width: 150px !important;
        max-width: 150px !important;
        min-width: 150px !important;
    }
    #process .main-timeline .title,h3.title {
        font-size: 20px !important;
    }
    .main-timeline .timeline-content, .main-timeline .timeline:nth-child(2n) .timeline-content {
        padding-left: 38px !important;
        padding-top: 19px !important;
    }
    .main-timeline .timeline-content1:after {
        left: 0px !important;
    }
    th.tb_heading {
        font-size: 12px !important; 
    }
    .mb-col-sep{
      width:40px;
      height: 40px;
    }
    nav#menu-center {
      display: none;
    }
    }
    @media (max-width:1024px) and (min-width:768px) and (orientation:landscape) {
    #process .main-timeline .timeline {
        margin-bottom: 25px !important;
    }
    .timeline-icon img {
        max-width: 88% !important;
    }
    .main-timeline .timeline:nth-child(even) .timeline-icon,.main-timeline .timeline:nth-child(odd) .timeline-icon {
        max-width: 50% !important;
    }
    }
    @media (max-width:1366px) and (min-width:1024px) {
    .timeline-icon img {
        max-width: 88% !important;
    }
    .main-timeline .timeline:nth-child(even) .timeline-icon,.main-timeline .timeline:nth-child(odd) .timeline-icon {
        max-width: 50% !important;
    }
    }
    @media (max-width:767) and (min-width:280px) and (orientation:landscape) {
    }
    @media only screen and (max-width:1024px) and (min-width:280px) {
      nav#menu-center {
        display: none;
      }
      .sticky-wrapper {
        height: 0px !important;
    }
      }