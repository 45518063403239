/* RMI - General Classes */
.cart-sticky {
    position: fixed;
    left: 62.2%;
    right: 0;
    top: 0px;
    z-index: 2;
    width: 100%;
    width: 400px;
}

.bill-option-padd {
    margin-right: 10px !important;
}

.label-inline {
    display: inline !important;
}
.cart-section {
    padding: 10px 20px 10px 20px;
    background-color: #f7f7f7;
    /* width: 400px; */
}

.heading-section {
    font-family: 'Gotham-Medium';
    font-size: 24px;
    text-transform: uppercase;
    color: #161616;
}

.section-title {
    font-family: 'poppins';
    font-size: 18px;
    font-weight: 400;
}

.section-subtext {
    font-family: 'Bariol-Regular';
    font-size: 18px;
}

.section-subtext.b-bold {
    font-family: 'Bariol-Bold';
}

.subscription-features-section .feature-title {
    font-family: 'Bariol-Bold';
    font-size: 22px;
}

.subscription-features-section .feature-list-billing {
    list-style: none;
    padding: 0;
}

.subscription-features-section .feature-list-billing li {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    line-height: 1.7;
}

/* .subscription-features-section .feature-list li:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0 0;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 16px;
    color: #04cc09;
} */

.subscription-features-section .feature-list-billing li.cross:before {
    content: "\f00d";
    color: #c20d0d;
    font-size: 18px;
}

.card_options {
    background-color: #fff;
	outline: none;
	
	padding: 10px 10px !important;
    border-radius: 8px;
	border-bottom: 4px solid #fbc504;
	
	color: #333;
		
}
.cart-section,
.cart-section td {
    font-family: 'poppins';
    font-size: 17px;
    color: #555555;
}

.cart-section .thick-line {
    color: #161616;
}

.terms-section {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    color: #555555;
}

hr {
    border-top: 1px solid rgba(0, 0, 0, .3);
}

/* RMI - Form control styling */
.payment-section {
    padding-right: 50px;
}

.payment-form-sub .form-control {
    border: 1px solid #222222;
    padding: 8px 14px !important;
    height: calc(1.9em + .75rem + 2px);
    color: #222222;
}

.payment-form-sub .form-control:focus {
    box-shadow: 0 4px 4px 0px rgba(128, 128, 128, 0.42);
}

.payment-form-sub .form-control.error-txt:focus {
    box-shadow: none;
}

.payment-section .img-cards-accepted {
    margin-top: -10px;
}

.payment-form-sub .btn_place_order {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 18px;
    border: 2px solid #21bdbb;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}

.payment-form-sub .btn_place_order:hover {
    background: #0e9e9d;
    border: 2px solid #0e9e9d;
}

.payment-form-sub .btn_place_order:focus {
    box-shadow: none;
}

.payment-form-sub .btn_place_order i {
    opacity: 0;
    visibility: hidden;
    margin-left: -25px;
    font-size: 20px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.payment-form-sub .btn_place_order:hover i {
    margin-right: 10px;
    opacity: 1;
    visibility: visible;
}

.payment-form-sub .btn_place_order i.fa-spin {
    margin-right: 10px;
    opacity: 1;
    visibility: visible;
}

.first-opt {
    color: #a20000 !important;
}

.error-txt {
    border: 2px solid #ef4836 !important;
}

.error-checkout {
    color: red;
    font-size: 14px;
    text-align: center;
    font-family: 'Gotham-Book';
}

#error_check_terms {
    margin-top: 12px;
    color: red;
    font-size: 14px;
    font-family: 'Gotham-Book';
}

.rs-enabled {
    display: none;
}

#hideAll-1 {
    display: none;
}

#seeAll-1,
#hideAll-1 {
    width: 100%;
    text-transform: none;
    font-size: 20px;
}

#seeAll-1:hover {
    cursor: pointer;
}

.general-footer-actions .step-previous {
    margin-right: 0px !important;
}

.op-sb-terms {
    width: 100% !important;
}

.cls-termspolicy:before {
    min-width: 1.8em !important;
}

.subcription-pg-heading {
    font-family: 'poppins';
    font-size: 30px;
    color: #212529;
}

/* Media Queries - S */
@media screen and (max-width: 1200px) {

    .package-col-1,
    .package-col-2 {
        margin: 0 !important;
    }
}

@media screen and (max-width: 767px) {

    .feature-list-billing {
        display: none;
    }

    #seeAll-1 {
        display: block;
    }

    .rs-disabled {
        display: none;
    }

    .rs-enabled {
        display: block;
    }

    .supported-card-col {
        display: none;
    }

    .payment-section {
        padding-right: 0px;
    }

    .payment-section .mb-3 {
        margin-bottom: 0px !important;
    }

    .payment-section .secure-payment {
        width: 100%;
    }

    .general-footer-actions .step-previous {
        margin: 0 auto !important;
        text-align: center;
        float: none !important;
    }

    .cart-section {
        border-top: 1px solid #dee2e6;
    }

    .cart-amount-item {
    position: sticky;
    }
}

@media screen and (max-width: 480px) {
    .cart-section {
        padding: 20px 0px 20px 0px;
    }

    .subcription-pg-heading {
        font-size: 25px;
    }

    .cart-amount-item {
        position: sticky;
    }

    .payment-sub-padd-mob {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}

.err {
    margin-top: 0px !important;
}


@media screen and (max-width: 768px) {
    .display {
        display: none;
    }
    .cart-mobile{
        display: block !important;
    }
}

@media screen and (min-width: 768px) {
    .display {
        display: block !important;
    }
    .cart-mobile{
        display: none !important;
    }
}