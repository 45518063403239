
@font-face {
    font-family: Bariol;
    src: url('../../assets/process/bariol_regular-webfont.eot');
    src: url('../../assets/process/bariol_regular-webfontd41d.eot?#iefix') format("embedded-opentype"),
     url('../../assets/process/bariol_regular-webfont.svg') format('svg'),
      url('../../assets/process/bariol_regular-webfont.ttf') format('truetype'),
       url('../../assets/process/bariol_regular-webfont.woff') format('woff'),
        url('../../assets/process/Bariol_Regular.otf') format('otf'),
         url('../../assets/process/bariol_regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: GothamBook;
    src: url('../../assets/process/Gotham-Book.eot');
    src: url('../../assets/process/Gotham-Bookd41d.eot?#iefix') format("embedded-opentype"),
     url('../../assets/process/Gotham-Book.woff') format("woff"), 
     url('../../assets/process/Gotham-Book.ttf') format('truetype'),
      url('../../assets/process/Gotham-Book.svg#Gotham-Book') format("svg"), 
      url('../../assets/process/Gotham-Book.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url('../../assets/process/GothamMedium.woff') format('woff'),
    url('../../assets/process/GothamMedium.woff2') format('woff2'),
    url('../../assets/process/GothamMedium.eot'),
    url('../../assets/process/GothamMediumd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/GothamMedium.ttf') format('truetype'),
    url('../../assets/process/GothamMedium.svg#Gotham-Book') format('svg'),
    url('../../assets/process/GothamMedium.otf') format('opentype'); 
}
  @font-face {
    font-family: BariolBold;
    src: url('../../assets/process/bariol_bold-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  .main-templates{
      overflow-x: hidden;
  }
  a.sw_process_button{
    color: #ffffff !important;
    background-color: #197cd3 !important;
    border: 1px solid #197cd3 !important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px;
    max-width: 270px!important;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    text-transform: uppercase;
    text-shadow: none !important;
    margin-right: 10px !important;
  }
  .sw_process_button:hover {
    background: #ffffff !important;
    text-decoration: none!important;
    border-color: #197cd3 !important;
    color: #197cd3 !important;
} 

.revisions_text{
    font-size: 20px !important;
    font-family: "Poppins";
    line-height: 30px;
    color: #fff;
}
.revisions_desc{
    line-height: 23px;
    margin: 0 auto;
    padding-left: 0;
    font-size: 14px!important;
    font-family: "Poppins"!important;
    color: #fff;
}












/*Features New Page Starts*/
.header_bg{
    background: #ffffff !important; 
}
#main_section .container{
padding: 0px 0px 0 0px !important;
}
#main_section_two .container{
padding: 0px 0px 0 0px !important;
}
#main_section .flex_column.av_one_half.flex_column_table_cell.av-equal-height-column.av-align-middle.av-zero-column-padding.avia-builder-el-4.el_after_av_one_half.avia-builder-el-last {
    background: #1880dd;
    background: -webkit-linear-gradient(to right, #1880dd, #1880dd);
    background: linear-gradient(to right, #1880dd, #1880dd);
    padding: 100px 0 100px 100px;
}
div#main_section {
    padding-top: 40px;
}
.swf_content {
    margin-left: 49%;
    width: 55%;
    margin-top: 25%;
}
.swf_content_two {
    margin-left: 14%;
    width: 55%;
}
h1.swf_main_heading {
    font-family: 'Gotham-Medium' !important;
    color: #000;
    font-size: 40px;
    line-height: 1.0;
    /*font-weight: 400 !important;*/
}
.features_heading{
    font-family: 'Gotham-Medium' !important;
    text-align: center !important;
    font-size: 38px!important;
    color: #222 !important;
    line-height: 1.2 !important;
    font-weight: 700 !important;
}

.mobileTabs{
    display: none !important;
}
p.swf_main_subtext_features {
    /*font-family: 'GothamLight' !important;*/
    font-size: 18px;
    line-height: 1.7em;
    margin: 0 0 20px 0;
    /*font-weight: bold;*/
    color: #545454 !important;
}
.swf_page_btn{
    color: #fff !important;
   background: #1e1a25 !important;
    border: 1px solid #1e1a25 !important;
    padding: 10px 30px;
    text-decoration: none !important;
    border-radius: 5px;
    font-family: 'BariolBold';
    font-size: 19px;
    max-width: 225px !important;
    display: inline-block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
}
a.swf_page_btn:hover {
    background: #fff !important;
    text-decoration: none !important;
    border-color: #1e1a25 !important;
    color: #1e1a25 !important;
}
.swf_fp_page_btn{
    color: #fff !important;
    background: #e47458 !important;
    border: 1px solid #e47458 !important;
    padding: 10px 30px;
    text-decoration: none !important;
    border-radius: 5px;
    font-family: 'BariolBold';
    font-size: 19px;
    max-width: 225px !important;
    display: inline-block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
}
a.swf_fp_page_btn:hover {
    background: #fff !important;
    text-decoration: none !important;
    border-color: #e47458 !important;
    color: #e47458 !important;
}

div#swfc_section .container, div#swfc_section_two .container, div#swfc_section_three .container, div#swfc_section_four .container  {
    max-width: 1130px !important;
}
.swfc_heading {
    text-align: left;
    font-family: 'Gotham-Medium' !important;
    color: #000 !important;
    font-size: 30px;
}
.swfc_heading_sub_text{
    font-family: 'GothamLight' !important;
    font-size: 21px;
    line-height: 1.2;
}
h2.swfc_footer_heading{
    text-align: center;
    font-family: 'GothamLight' !important;
    font-size: 24px;
    line-height: 1.7em;
    text-transform: capitalize !important;
    color: #fff;
}
h2.swfc_sub_heading {
    color: #000;
    text-transform: capitalize !important;
    font-size: 22px;
    font-family: 'Gotham-Medium' !important;
    font-weight: 400 !important;
    height: 45px;
    margin-bottom: 0 !important;
    display: inline-block;
}
.staric{
    display: inline-block;
    color: #1880dd !important;    
}
/*.swfc_one_third_box{
    -webkit-box-shadow: -26px -7px 121px -20px rgba(219,219,219,0.88);
    -moz-box-shadow: -26px -7px 121px -20px rgba(219,219,219,0.88);
    box-shadow: 4px 4px 25px 1px rgba(234, 234, 234, 0.88);
    padding: 15px;
    border-radius: 5px;
}*/
p.swfc_sub_text {
    font-family: 'Bariol' !important;
    font-size: 18px;
    line-height: 1.5em;
    margin: 10px 0;
}
.swfc_one_third_box i {
color: #37d399 !important;
    font-size: 60px;
    padding-bottom: 20px;
    display: none;
}
#swfc_section span.hr-inner.inner-border-av-border-thin, div#swfc_section_two span.hr-inner.inner-border-av-border-thin, div#swfc_section_three span.hr-inner.inner-border-av-border-thin{
    border: 0;
    height: 1px;
    background: #333;
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,hsla(0,0%,0%,0)), color-stop(50%,hsla(0,0%,63%,.75)), color-stop(100%,hsla(0,0%,0%,0)));
    background: -webkit-linear-gradient(left, hsla(0,0%,0%,0) 0%, hsla(0,0%,63%,.75) 50%, hsla(0,0%,0%,0) 100%);
    background:    -moz-linear-gradient(left, hsla(0,0%,0%,0) 0%, hsla(0,0%,0%,.75) 50%, hsla(0,0%,0%,0) 100%);
    background:     -ms-linear-gradient(left, hsla(0,0%,0%,0) 0%, hsla(0,0%,0%,.75) 50%, hsla(0,0%,0%,0) 100%);
    background:      -o-linear-gradient(left, hsla(0,0%,0%,0) 0%, hsla(0,0%,0%,.75) 50%, hsla(0,0%,0%,0) 100%);
    background:         linear-gradient(left, hsla(0,0%,0%,0) 0%, hsla(0,0%,0%,.75) 50%, hsla(0,0%,0%,0) 100%);
}
#main_section_two .flex_column.av_one_half.flex_column_table_cell.av-equal-height-column.av-align-middle.av-zero-column-padding.first.avia-builder-el-62.el_before_av_one_half.avia-builder-el-first{
    background: #1880dd;
    background: -webkit-linear-gradient(to right, #093637, #1880dd);
    background: linear-gradient(to right, #1880dd, #1880dd);
    padding: 100px 100px 100px 0px;
}
.swf_page_btn{
    color: #1e1a25 !important;
   background: #fff !important;
    border: 1px solid #1e1a25 !important;
    padding: 10px 30px;
    text-decoration: none !important;
    border-radius: 5px;
    font-family: 'BariolBold';
    font-size: 19px;
    max-width: 225px !important;
    display: inline-block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
}
a.swf_page_btn:hover {
    background: #1e1a25 !important;
    text-decoration: none !important;
    border-color: #fff !important;
    color: #fff !important;
}
.swf_lg_seprator,.swf_lg1_seprator{
width:100%;
height:50px;
}
.swf_sm_seprator{
width:100%;
height:30px;
}

.button_row{
    margin: 50px auto 0 auto;
    text-align: center;
}
.section_seprator {
    width: 80px;
    height: 80px;
}
.horizontal_seprator {
    border: 1px solid #a1a1a1;
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
    .section_seprator_20{
        height: 5px !important;
    }
    /* i ahve added this  */
    .tabs{
        display: none;
    }
    .xs_fsep{
        width: 100%;
        height: 30px;
    }
    .xs_fstfeature_seprator{
        width: 100%;
        height: 150px;
    }
    .swf_content {
        margin-top: 0 !important;
    }
    .second_head{
        width: auto !important;
    }
.section_seprator {
    width: 80px;
    height: 30px;
}
.swf_content_two {
    margin-left: 0;
    width: 100%;
}
.swf_content {
    margin-left: 0;
    width: 100%;
}

div#swfc_section,div#swfc_section_two,div#swfc_section_three{
background-image: none !important;
}
#main_section_two .flex_column.av_one_half.flex_column_table_cell.av-equal-height-column.av-align-middle.av-zero-column-padding.first.avia-builder-el-62.el_before_av_one_half.avia-builder-el-first{
    padding: 20px 20px 20px 0px;
}
#main_section .flex_column.av_one_half.flex_column_table_cell.av-equal-height-column.av-align-middle.av-zero-column-padding.avia-builder-el-4.el_after_av_one_half.avia-builder-el-last{
    padding: 20px 0 20px 20px;
}
h1.swf_main_heading {
    font-size: 30px;
}
div#swfc_section_two,div#main_section,div#main_section_two,div#swfc_section,div#swfc_section_three {
    background-image: none; 
}
.swf_lg_seprator {
    width: 100%;
    height: 30px;
}
.swf_lg1_seprator {
    width: 100%;
    height: 0px !important;
}
.swf_sm_seprator {
    width: 100%;
    height: 15px;
}
.swfc_heading_sub_text{
    font-size: 18px !important;
}
div .av_one_half,div .av_one_third {
    margin-left: 0;
    width: 100%;
}
div#main_section {
    padding: 0 30px;
}
.hr.hr-custom.hr-center.hr-icon-no.avia-builder-el-67.el_before_av_codeblock.avia-builder-el-first {
    margin-bottom: 10px !important;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
.section_seprator {
    width: 80px;
    height: 40px;
}
}
@media only screen and (min-width: 768px) and (max-width: 996px) {
#main_section .flex_column.av_one_half.flex_column_table_cell.av-equal-height-column.av-align-middle.av-zero-column-padding.avia-builder-el-4.el_after_av_one_half.avia-builder-el-last {
    padding: 50px 0 50px 50px;
}
#main_section_two .flex_column.av_one_half.flex_column_table_cell.av-equal-height-column.av-align-middle.av-zero-column-padding.first.avia-builder-el-62.el_before_av_one_half.avia-builder-el-first {
    background: #1880dd;
    background: -webkit-linear-gradient(to right, #093637, #1880dd);
    background: linear-gradient(to right, #37d399, #0ccd70);
    padding: 50px 50px 50px 0px;
}
p.swfc_sub_text {
    margin: 26px 0px 0 0;
}
div#swfc_section,div#swfc_section_two,div#swfc_section_three{
background-image: none !important;
}
div#swfc_section_two,div#main_section,div#main_section_two,div#swfc_section,div#swfc_section_three {
    background-image: none; 
}
.swf_content {
    margin-left: 14%;
    width: 80%;
}
.swf_content_two {
    margin-left: 8%;
    width: 80%;
}
.swfc_heading_sub_text{
    font-size: 18px !important;
}
}
@media (max-width: 1024px) and (min-width: 768px){
span.logo {
    float: none !important;
    display: inline-table !important;
}
}
@media (max-width: 1024px) and (min-width: 768px) and (orientation: landscape){
div#swfc_section,div#swfc_section_two,div#swfc_section_three{
background-image: none !important;
}
div#swfc_section_two,div#main_section,div#main_section_two,div#swfc_section,div#swfc_section_three {
    background-image: none; 
}
.swf_content {
    margin-left: 12%;
    width: 80%;
}
.swf_content_two {
    margin-left: 8%;
    width: 80%;
}
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
div#swfc_section,div#swfc_section_two,div#swfc_section_three{
background-image: none !important;
}
div#swfc_section_two,div#main_section,div#main_section_two,div#swfc_section,div#swfc_section_three {
    background-image: none; 
}
.swf_content {
    margin-left: 12%;
    width: 80%;
}
.swf_content_two {
    margin-left: 8%;
    width: 80%;
}
}
@media (max-width: 1366px) and (min-width: 1025px) and (orientation: landscape){
.swf_content {
    margin-left: 19%;
    width: 80%;
}
.mobileTabs{
    display: none !important;
}
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
.swf_content {
    margin-left: 30% !important;
    width: 55%;
}
.mobileTabs{
    display: none !important;
}
}
/*Features New Page End*/

.sw_unfill_btn {
    color: #ffffff!important;
    border: 1px solid #1880dd!important;
    background: #1880dd !important;   
}

.sw_unfill_btn:hover {
    color: #1880dd !important;
    border: 1px solid #1880dd!important;
    background: 0 0 !important;   
}

/* styles.css */
.tab-list {
    list-style: none;
    padding: 0;
    display: flex;
  }
  
  .tab-list li {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ccc;
    margin-right: 5px;
    transition: transform 0.3s ease-in-out;
  }
  
  .tab-list li.active {
    background-color: #007bff;
    color: white;
    transition: transform 0.3s ease-in-out;
  }
  
  .tab-list li.slide-left {
    transition: transform 1.3ms ease-in-out;
    transform: translateX(-100%);
  }
  
  .tab-list li.slide-right {
    transition: transform 5.3ms ease-in-out;
    transform: translateX(100%);
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ccc;
  }
  
  @media only screen and (max-width:480px) and (min-width:320px) {
    .logo{
        width: 60px !important;
        height: 60px !important
    }
    #heading{
        font-size: 40px !important;
        width: auto !important;
    }
    #subHeading{
        width: auto !important;
    }
    .mobileTabs{
        display: inline !important;
    }
    
    
}

@media only screen and (max-width:1042px) and (min-width:481px) {
   #heading{
    font-size: 45px !important;
    width: auto !important;
   }
   #subHeading{
    width: auto !important;
}
.scroller{
font-size: 15px !important;
/* margin-left: '20px' */
}
.tabs{
    padding: 20px;
}
.logo{
    width: 100 !important;
    height: 100 !important;
}
.revisionBox{
    /* width:'360px' !important; */
}
.mobileTabs{
    display: none !important;
}

}
  
/* 
style={{
    position: 'absolute', color: '#000000', fontFamily: 'poppins', zIndex: '999', top: '50%', marginLeft: '12%', transform: 'translate(-50%, -50%)'
    , fontSize: '20px', display: isFocused ? '' : 'none'
  }} */

  @media only screen and (min-width: 1366px) and (max-width: 2024px) {
    .mobileTabs{
        display: none !important;
    }
}
  
 