/* RMI - General Classes */
.package-table {
    border: 2px solid #161616;
}
.package-table:hover {
    box-shadow: 0 2px 20px 2px rgba(128, 128, 128, 0.32);
}
.card-title.pricing-card-title {
    font-size: 80px;
    font-family: 'Bariol-Regular';
    color: #000000;
}
.card-title.pricing-card-title small {
    font-size: 16px;
    font-family: 'Bariol-Regular';
    font-weight: 600;
    color: #000000 !important;
}
.currency-symbol {
    font-size: 30px;
    font-family: 'Bariol-Regular';
    color: #000000;
    vertical-align: text-top;
}
.card-header {
    background: #161616;
    color: #ffffff;
    font-family: 'poppins';
}
.card-header h4 {
    font-size: 22px;
    color: #fff;
    font-family: 'poppins';
    
}
.apple-os .card-header h4 {
    padding-top: 12px;
}
.card-header .ribbon {
    display: none;
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}
.card-header .ribbon.selected {
    display: inline;
}
.card-header .ribbon span {
    font-size: 12px;
    font-family: 'Gotham-Book';
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #21bdbb;
    background: linear-gradient(#21bdbb 0%, #21bdbb 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
}
.apple-os .card-header .ribbon span {
    line-height: 24px;
}
.card-header .ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #21bdbb;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #21bdbb;
}
.card-header .ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #21bdbb;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #21bdbb;
}
.package-info {
    border: 2px solid #222222;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding: 8px 0;
}
.package-info .info-line1 {
    font-family: 'poppins';
    font-size: 14px;
    padding: 8px 0px;
}
.package-info .info-line2 {
    font-family: 'Gotham-Medium';
    font-size: 16px;
    padding: 8px 0px;
}
.btn-package {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 18px;
    border: 2px solid #21bdbb;
}
.btn-package:hover {
    background: #0e9e9d;
    border: 2px solid #0e9e9d;
}
.btn-package.highlighted {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 18px;
    border: 2px solid #21bdbb;
}
.btn-package.highlighted:hover {
    background: #0e9e9d;
    border: 2px solid #0e9e9d;
}
.btn-package.highlighted.blackborder {
    background: transparent;
    border: 2px solid #161616;
    color: #161616;
}
.btn-package i{
    opacity: 1;
    visibility: visible;
    margin-left: 2px;
    font-size: 20px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
/* .btn-package:hover i {  
    margin-right: 10px;
    opacity: 1;
    visibility: visible;  
} */

.btn-package:focus {
    box-shadow: none;
}
.package-feature-list li {
    text-align: left;
    font-family: 'Bariol-Regular';
    font-size: 18px;
    color: #000000;
}
/* .package-feature-list li:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0 0;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 16px;
    color: #04cc09;
} */
.package-feature-list li.premium:before {
    /* content: "\f00d"; */
    color: #c20d0d;
    /* font-size: 18px; */
}
.regular-price {
    display: block;
    font-size: 20px;
    font-family: 'Gotham-Book';
    color: #161616 !important;
    text-decoration: line-through;
}

#seeAll-1, #seeAll-2 {
    display: none;
}

#hideAll-1, #hideAll-2 {
    display: none;
}

.cls-view-features {
    font-family: 'Gotham-Medium';
    cursor: pointer;
}

.cls-view-features i {
    color: #21bdbb;
}
/* Media Queries - S */
@media screen and (max-width: 1200px) {
    .package-col-1, .package-col-2 {
        margin: 0!important;
    }
}

@media screen and (max-width: 767px) {
    .package-feature-list {
        /* display: none; */
    }
    #seeAll-1, #seeAll-2 {
        display: block;
    }
    .btn-bottom {
        display: none;
    }
}

@media screen and (max-width: 480px) {

}