
input.PhoneInputInput::placeholder{
    color: #bcbcbc !important;
    font-size: 16px;
}
.feilds{
    border-radius: 16px !important;
    border-color: '#818A98';
}
button#btn_sqignup {
    padding-top:14px ;
    padding-bottom: 14px;
    margin: 10px 0px 10px 0px;
    background-color: #fbc504 !important;
    border-radius: 50px;
    color: #000000;
    border: none;
    font-size: 20px !important;
    font-family: 'poppins';
    font-weight: 500;
    padding: 10px !important;
}
.form-group{
    padding: 5px 20px 5px 20px;
}

header {
    background: #ffffff;
    display: none !important;
    visibility: hidden !important;
}

body {
    background: #f7f7f7;
    margin: 0;
}
.login100-more {
    background-color: #161616;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: calc(100% - 600px);
    position: relative;
    z-index: -1;
    background-image: url("../../assets/img/tour/SignRight.svg");
}

.error-terms {
    font-family: 'Poppins';
    color: red;
    font-size: 12px;
}
.login100-more::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 520px;
    background: linear-gradient(bottom,#e8519e,#c77ff2);
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: calc(100% - 520px);
    z-index: 1;
}
.wrap-login100 {
    /* width: 730px; */
   width: 600px !important;
    min-height: 100vh;
    background: #fff;
    border-radius: 2px;
    position: relative;
}
.container-login100 {
    width: 100%;
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-wrap: wrap;
}
.card{
    border:none !important;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
}

.card-signin .card-title {
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
    padding: 0;
    width: 458px;
    margin: 0 auto;
}
.form-control{
/*    background: #f1f1f1 !important;*/
       margin-bottom: 0px !important;  
      height: 45px !important;
      font-family: 'Poppins' !important;
}
.form-label-group{
    padding-bottom: 0px !important;
    /* height: 85px !important; */
}
.form-control:focus{
    color: #495057;
    background-color: #fff;
    border-color: #ced4da;
    outline: 0;
    box-shadow: none !important;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
      border-color: transparent !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgb(84, 84, 84,0.15) !important;
}
.sw-logo{
    margin: 0 auto;
    height: 73.41px;
    max-width:201px ;
    text-align: center;
}
.sw-logo img {
    width: 220px;
}
.form-col {
  min-height: 100% !important;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh !important; /* These two lines are counted as one :-)       */
  display: flex !important;
  align-items: center !important;
  width: 90%;
    margin: 0 auto;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
}
.myflex-5{
  flex: 1;
}
.btn-login{
    color: #fff;
    background-color: #1b1b1b !important;
    border-color: #1b1b1b !important;
}
a.forgot-password-link,.query-link,.create-account-link {
    color: #38adab !important;
    font-weight: 500;
    font-family: 'Poppins';
    line-height: 2;
}
.query-text {
    margin-bottom: 0 !important;
    font-family: 'Poppins';
}
p.sw-login {
    font-family: 'Poppins';
    font-size: 14px;
    margin-bottom: 55px;
}
.error-login {
    color: #C54D51;
    font-family: 'Poppins';
    font-size: 14px;
    margin-bottom: 0;
}
div#error_login a {
    color: #c54d51;
}
#btn_login {
    margin-bottom: 10px;
}
.op-lg-sep {
    width: 100%;
    height: 40px !important;
}
.dsl-inline{
    width: 50%;
    float: left;
}
.dsr-inline{
    /* float: right; */
  display: inline-block;

}
div#error_login {
    border: 1px solid #C54D51 !important;
    margin-bottom: 30px;
}

input {
    font-family: 'Poppins';
}
/* Stylish Check and Radion Button Css - START */
input[type=radio].with-font,
input[type=checkbox].with-font {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
    
input[type=radio].with-font ~ label:before,
input[type=checkbox].with-font ~ label:before {
    font-family: 'Poppins';
    display: inline-block;
    content: "\f1db";
    letter-spacing: 15px;
    font-size: 1.2em;
    color: #535353;
    width: 1.2em;
}

input[type=radio].with-font:checked ~ label:before,
input[type=checkbox].with-font:checked ~ label:before  {
    content: "\f192";
    font-size: 1.2em;
    color: #333333;
    letter-spacing: 15px;
}
input[type=checkbox].with-font ~ label:before {        
    content: "\f096";
}
input[type=checkbox].with-font:checked ~ label:before {
    content: "\f046";        
    color: #333333;
}
input[type=radio].with-font:focus ~ label:before,
input[type=checkbox].with-font:focus ~ label:before,
input[type=radio].with-font:focus ~ label,
input[type=checkbox].with-font:focus ~ label
{                
    color: #333333;
}
/* Stylish Check and Radion Button Css - END */
.pl-0 {
    padding-top: 10px;
}
.tac, .pp{
color: #000 !important;
font-weight: 500;
font-family: 'Poppins';
}
.border-red {
    border: 1px solid #ff0000;
}
input::placeholder {
  font-family: 'Poppins';
}
button#btn_signup {
    font-family: 'Poppins';
    font-weight: 600;
    padding: 10px !important;
}
.error-field{
    border: 1px solid #ff0000 !important;
}
/*Slider Text Starts*/
.thumbnail {
    display: block !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
    border: none !important;
}
.item {
    background: #fff;
    text-align: center;
    height: 150px;
}






 .item {
  opacity: 0;
  left: 0;
  transition: opacity 0.1s ease-out !important;
}
/*i.fa {
    margin-top: 50px;
}*/
/*Slider Text End*/
label.form-check-label.cls-theme-label {
    font-size: 14px;
    color: #161616;
    font-weight: normal;
    font-family: 'Poppins' !important;
}
.query-link{
    text-shadow: none !important;
}
.grecaptcha-badge {
    z-index: 1;
}
@media only screen and (max-width:767px) and (min-width:280px) {
.hand img{
    height: 29px !important;
    width: 29px !important;
}
    .grecaptcha-badge {
    display: none;
}   
.form-col,.login100-more,.wrap-login100{
  width: 100% !important;
}
.wrap-login100 {
    min-width: 100%;
}
.form-col{
    display: block !important;
    width: 100% !important; 
}
button#btn_signup{
  font-size: 16px;
  padding: 13px !important;
}
.op-lg-sep {
    width: 100%;
    height: 10px !important;
}

p.sw-login {
    margin-bottom: 25px !important;
}
.card-signin .card-title {
  margin-bottom: 0.5rem !important;
}
}
@media only screen and (max-width:360px) and (min-width:280px) {
.card-signin .card-body {
    margin: 0 auto;
    width: 100%;
    padding: 10px 20px 20px 20px; 
}
.my-5 {
    margin-top: 0 !important;
}
}
@media only screen and (max-width:767px) and (min-width:361px) {
.card-signin .card-body {
    margin: 0 auto;
    width: 100%;
    padding: 20px; 
}
}

@media only screen and (max-width:996px) and (min-width:768px) {
.form-col,.login100-more,.wrap-login100{
  width: 100% !important;
}
.wrap-login100 {
    min-width: 100%;
}
.form-col{
    display: block !important;
    width: 100% !important; 
}
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}
.PhoneInputInput{
    height: 30px !important;
    border: none !important; 
    outline: none;
}
.error-email{
    margin-top: -24px !important;
}
