
.padding {
    padding: 3rem !important
}
.flex-middle{
    display: none;
}
.reply::placeholder{
font-size: 14px;
color: #d9d9d9;
}
.search input[type="search"].filenew{
display: none !important;  
}
.file{
  width: 70px !important;
}
.emoji-mart-search { display: none !important} 
.info-box{
    background-color: #fff;

    padding: 20px;
    height: 410px;
    /* width: 250px !important; */
}
.frind{
    border: 1px solid #d9d9d9;
    outline: none;
    font-size: 14px;
}

.frind::placeholder{
    color: #807d72;
    font-size: medium;
    font-size: 14px;
    
}
.dashboard-list a{
 
    font-family: 'poppins' !important;
    font-size: 15px !important;
    text-decoration: none !important;
    transition-duration: .2s;
}
.card-background{
    background-color: #fbc504;
    padding: 10px;
}


.d-list-item a{
color: '#D9D9D9f' !important;
} 
.design-category-list .dashboard-list{
    
}
.user-profile-head{
    font-size: 25px;
    font-family: 'poppins';
    font-weight: 400;
    padding: 6px 0px 4px 15px;
    /* margin: 0px; */
    border-left: 4px solid #fbc504;
}
.icon-padd{
    padding: 20px 0px 20px 0px;
}
.user-dash-head{
    font-size: 25px;
    font-family: 'poppins';
    font-weight: 500;
    margin-bottom: 20px;
}
.card-background{
    background-color: #fbc504;
}
.cont-centr{
    align-self: center;
}
.card-head{
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 400;
    margin-bottom: 0px;
}
.user-card-full {
    overflow: hidden
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px
}

.m-r-0 {
    margin-right: 0px
}

.m-l-0 {
    margin-left: 0px
}

.user-card-full .user-profile {
    border-radius: 5px 0 0 5px
}

.bg-c-lite-green {
    background: linear-gradient(to right, #000000, #40352f);
}

.user-profile {
    padding: 20px 0
}

.card-block {
    padding: 1.25rem
}

.m-b-25 {
    margin-bottom: 25px
}

.img-radius {
    border-radius: 5px
}

h6 {
    font-size: 14px
}

.card .card-block p {
    line-height: 25px
}


@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px
    }
}
@media screen and (max-width: 1024px) {
    .card-background{
        padding: 5px !important;
    }
}

.card-block {
    padding: 1.25rem
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.m-b-20 {
    margin-bottom: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.card .card-block p {
    line-height: 25px
}

.m-b-10 {
    margin-bottom: 10px
}

.text-muted {
    color: #919aa3 !important
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.f-w-600 {
    font-weight: 600
}

.m-b-20 {
    margin-bottom: 20px
}

.m-t-40 {
    margin-top: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.m-b-10 {
    margin-bottom: 10px
}

.m-t-40 {
    margin-top: 20px
}

.user-card-full .social-link li {
    display: inline-block
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}


/* orderCard CSS */





.order-card {
    background-color: #fff;
    width: 300px;
    border: none;
    border-radius: 16px
}

.info {
    line-height: 19px
}

.name {
    color: #4c40e0;
    font-size: 18px;
    font-weight: 600
}

.order {
    font-size: 14px;
    font-weight: 400;
    color: #b7b5b5
}

.detail {
    line-height: 19px
}

.summery {
    color: #d2cfcf;
    font-weight: 400;
    font-size: 13px
}


.text {
    line-height: 15px
}

.new {
    color: #000;
    font-size: 14px;
    font-weight: 600
}

.money {
    font-size: 14px;
    font-weight: 500
}

.address {
    color: #d2cfcf;
    font-weight: 500;
    font-size: 14px
}

.last {
    font-size: 10px;
    font-weight: 500
}

.address-line {
    color: #4C40E0;
    font-size: 11px;
    font-weight: 700
}

.log-div{
    margin: 25px auto;
}
@media only screen and (max-width:700px) {
    .user-dashb{
        margin-top: 40px;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .mainuser{
        padding: 0 !important;
    }
    .headingtxt{
        font-size: 11px !important;
    }
    .topsec img{
   width: 70px !important;
   height: 70px;
    }
    .secondaryhead{
        font-size: 14px !important;
    }
    .secondaryhead2{
        font-size: 12px !important;
    }
    .orders{
        margin-bottom: 20px !important;
    }
    .logo_img{
        width: 40px !important;
        height: 40px !important;
    }
    .nooopadding{
        padding: 0 !important;
    }
}
