.btn-logout{
    margin-top: 0px !important;
    align-self: center !important;
}
.btn-clr{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 50px;
    font-family: "Poppins" !important;
    font-weight: 400;
    font-size: 18px;
    min-width: 220px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    /* margin: 0 5px 0 5px !important; */
    text-shadow: none !important;
}
.btn-clr:hover{
    background: transparent !important;
    text-decoration: none!important;
    color: #030303!important;
    border-color: #020202!important;
}
.number{
    color: #131313;
}
.number:hover{
    color: #131313;
    text-decoration: none !important;
}
@media only screen and (max-width:480px) and (min-width:1024px) {
    .mag{
        margin-left: 10px;
    }
    


}

@media only screen and (max-width:320px) and (min-width:480px) {
    .history{
        font-size: 12px !important;
    padding-left: 10px !important;
    }
    .historydis{
        font-size:8px !important ;
    }


}