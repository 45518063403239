@font-face {
    font-family: Bariol;
    src: url('../../assets/process/bariol_regular-webfont.eot');
    src: url('../../assets/process/bariol_regular-webfontd41d.eot?#iefix') format("embedded-opentype"),
     url('../../assets/process/bariol_regular-webfont.svg') format('svg'),
      url('../../assets/process/bariol_regular-webfont.ttf') format('truetype'),
       url('../../assets/process/bariol_regular-webfont.woff') format('woff'),
        url('../../assets/process/Bariol_Regular.otf') format('otf'),
         url('../../assets/process/bariol_regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Book';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Book'), 
    url('../../assets/process/Gotham-Book.woff') format('woff'),
    url('../../assets/process/Gotham-Book.eot'),
    url('../../assets/process/Gotham-Bookd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/Gotham-Book.ttf') format('truetype'),
    url('../../assets/process/Gotham-Book.svg#Gotham-Book') format('svg'),
    url('../../assets/process/Gotham-Book.otf') format('opentype');   
}

@font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url('../../assets/process/GothamMedium.woff') format('woff'),
    url('../../assets/process/GothamMedium.woff2') format('woff2'),
    url('../../assets/process/GothamMedium.eot'),
    url('../../assets/process/GothamMediumd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/GothamMedium.ttf') format('truetype'),
    url('../../assets/process/GothamMedium.svg#Gotham-Book') format('svg'),
    url('../../assets/process/GothamMedium.otf') format('opentype'); 
}
  @font-face {
    font-family: 'Bariol-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Bold'), 
    url('../../assets/process/Bariol-Bold.woff') format('woff'),
    url('../../assets/process/Bariol-Bold.eot'),
    url('../../assets/process/Bariol-Boldd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/Bariol-Bold.ttf') format('truetype'),
    url('../../assets/process/Bariol-Bold.svg#Bariol-Regular') format('svg');
}

/*TZ Starts*/

.cal-clr{
    color: #fbc504;
}
.cal-clr:hover{
    color: #000000;
    text-decoration: none;
}
.placeholder-pos{
   padding-top: 18px;
}
.centr-btn{
    text-align: center !important;
}
/* css */
.sldate {
    color: #ff0000 !important;
    font-weight: 500;
    font-size: 13px;
    font-family: 'GothamBook';
}
#sw-schedulecall-message{
    margin-top: 15px !important;
}
/*TZ End*/
.container {
    width: 1270px!important;
    max-width: 1249px !important;
}
.section_seprator {
    width: 80px;
    height: 80px;
}
.sw_schedule_call {
    background: #131313;
    padding: 0px 0px;
}
.sw-schedule-call-intro{
    text-align: center;
}
.sw-schedule-head{
    color: #292929!important;
    font-family: 'Gotham-Medium' !important;
    font-size: 42px !important;
    text-transform: capitalize!important;
    font-weight: bolder!important;
    text-shadow: none!important;
    line-height: 1.3 !important;
    margin: 0 0 20px 0 !important;
    text-transform: inherit !important;
}
.sw-schedule-text {
    font-size: 23px !important;
    line-height: 1.3 !important;
    font-family: Bariol !important;
    color: #222 !important;
    margin: 20px 0 !important;
}
.sw-schedule-text a{
    color: #e47458;
    text-decoration: none;
}
.faq-btn {
    color: #002536!important;
    border: 1px solid #002536!important;
    padding: 10px 30px !important;
    text-decoration: none!important;
    border-radius: 5px !important;
    font-family: 'Bariol-Bold' !important;
    font-size: 19px !important;
    min-width: 240px!important;
    display: block !important;
    text-align: center !important;
    background: 0 0 !important;
    width: initial !important;
    transition: all .5s ease-in-out !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    text-shadow: none !important;
}

.faq-btn:hover{
    background-color:#002536 !important;
    transition:1s;
    color: #fff !important;
}
.faq-btn:focus{
    box-shadow: none;
}
.live-btn {
    color: #002536!important;
    border: 1px solid #002536!important;
    padding: 10px 30px !important;
    text-decoration: none!important;
    border-radius: 5px !important;
    font-family: 'Bariol-Bold' !important;
    font-size: 19px !important;
    min-width: 240px!important;
    display: block !important;
    text-align: center !important;
    background: 0 0 !important;
    width: initial !important;
    transition: all .5s ease-in-out !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    text-shadow: none !important;
    margin-left: 16px !important;
}
.live-btn:hover{
    background-color:#002536 !important;
    transition:1s;
    color: #fff !important;
}
.live-btn:focus{
    box-shadow: none;
}
.sw-schedule-call-formdiv{
    margin: 0 auto;
    border-radius: 0px;
    padding: 4em;
    background: #131313;
}
.sw-schedule-para {
    color: #ffffff!important;
    font-family: "Poppins" !important;
    font-size: 23px;
    font-weight: 500!important;
    line-height: 1.3;
    text-transform: inherit !important;
}
.sw-schedule-call-formdiv label {
    font-weight: 500;
}
.sw-schedule-call-formdiv ::placeholder {
    font-size: 14px !important;
    font-family: 'poppins';
    color: #bcbcbc !important;
}
.sw-schedule-call-formdiv .form-control{
    font-size: 14px !important;
    font-family: 'poppins';
    background-color: rgba(255, 255, 255, 0);
}
.form-col1{
    padding-left: 0px;
}
.form-col2{
    padding-right: 0px;
    padding-left: 0px;
}
.form-col3{
    padding-right: 0px;
    padding-left: 0px;
}
.schedule-btn{
    background: #002536!important;
    color: #fff !important;
    border: 1px solid #002536!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: 'Bariol-Bold';
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: block;
    text-transform: uppercase;
    margin: 0 auto !important;
    text-shadow: none !important;
}
.schedule-btn:hover{
    background: transparent !important;
    text-decoration: none!important;
    color: #002536!important;
    border-color: #002536!important;
}
#callRequestForm .error {
    color: #ff0000;
    font-weight: 500;
    font-size: 13px;
    font-family: 'GothamBook';
}
.red-border{
    border: 2px solid #ef4836!important;
}
[placeholder]:focus::-webkit-input-placeholder {
    color: transparent !important ;
    transition: 0.5s;
}
.form-control:focus {
    border-color: #fbc504;
    box-shadow: none;
}
.sw-schedule-call-formdiv textarea{
    height: 100px;
}
.sw-schedule-call-formdiv .alert-success{
    text-align: center;
    margin-top: 10px;
}
.section_seprator {
    width: 100% !important;
    height: 80px;
}
p.sw_slider_subtext.text-center a {
    color: #ffffff;
    text-decoration: none;
}
@media screen and (max-width: 1440px) {
.sw-schedule-text {
    font-size: 20px;
}
}
@media screen and (max-width: 1200px) {
.sw-schedule-head {
    font-size: 29px;
}
.faq-btn {
    padding: 10px 21px 10px;
    font-size: 13px;
}
.live-btn{
    padding: 10px 21px 10px;
    font-size: 13px;
}
.schedule-btn{
    padding: 10px 21px 10px;
    font-size: 13px;
}
}
@media screen and (max-width: 990px) {
.sw-schedule-text {
    font-size: 18px;
    line-height: 1.5;
}
.sw-schedule-call-formdiv ::placeholder {
    font-size: 10px !important;
}
.schedule-btn{
    padding: 10px 21px 10px;
    font-size: 13px;
    max-width: 40%;
}
#callRequestForm .error {
    font-size: 11px;
}
.sw-schedule-call-formdiv .form-control{
    font-size: 10px !important;
}
}
@media screen and (max-width: 767px) {
.sw-schedule-head {
        font-size: 22px;
}
.sw-schedule-text {
    font-size: 15px;
    line-height: 1.5;
}
.faq-btn {
    padding: 10px 21px 10px;
    font-size: 13px;
    margin-bottom: 10px;
    width: 100%;
}
.live-btn{
    width: 100%;
    margin-left: 0px;
}
.sw-schedule-call-formdiv {
    box-shadow: 0px 0px 46px 0px rgba(0,0,0,0.15);
    padding: 30px 5px 30px 5px !important;
    margin: 0;
}
.sw-schedule-para {
    font-family: 'GothamBook';
    color: #000;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
}
.form-col1 {
    padding-right: 0px;
}
.sw-schedule-call-formdiv label {
    font-size: 13px;
}
.schedule-btn {
    max-width: 100%;
}
}
@media only screen and (max-width: 1366px) and (min-width: 280px){
    .container {
        width: 100%!important;
        max-width: 100%!important;
        padding: 0 30px !important;
    }
    }
    .sw_slider_heading {
        color: #292929!important;
        font-family: 'Gotham-Medium' !important;
        font-size: 42px !important;
        text-transform: capitalize!important;
        font-weight: bolder!important;
        text-shadow: none!important;
        line-height: 1.3 !important;
        margin: 0 0 20px 0 !important;
        text-transform: inherit !important;
    }
    .sw_content_heading {
        font-size: 38px!important;
        color: #ffffff  !important;
        font-family: 'Gotham-Medium' !important;
        line-height: 1.2 !important;
        font-weight: 700 !important;
        text-align: center !important;
    }
    .sw_subheading_heading {
        font-family: 'Gotham-Medium'!important;
        font-weight: 700!important;
        font-size: 36px !important;
        color: #292929 !important;
        width: 100% !important;
    }
    .sw_fea_subheading_heading{
        font-family: 'Gotham-Medium'!important;
        font-weight: 700!important;
        font-size: 25px !important;
        color: #292929 !important;
        width: 100% !important;
    }
    .sw_slider_subtext {
        color: #ffffff !important;
        font-family: Bariol !important;
        font-size: 23px !important;
        line-height: 1.5 !important;
        text-shadow: none!important;
        margin: 0 0 20px 0 !important;
    }
    .sw_heading_sub_text {
        font-size: 23px !important;
        font-family: Bariol !important;
        text-align: center !important;
        color: #4d545b !important;
        line-height: 1.3 !important;
        margin: 0 auto !important;
    }
    .sw_content_text {
        font-size: 23px !important;
        line-height: 1.3 !important;
        font-family: Bariol !important;
        color: #222 !important;
        margin: 20px 0 !important;
    }
    .sw_fill_btn {
        background: #002536!important;
        color: #fff !important;
        border: 1px solid #002536!important;
        padding: 10px 30px !important;
        text-decoration: none!important;
        border-radius: 5px !important;
        font-family: 'Bariol-Bold' !important;
        font-size: 19px !important;
        min-width: 240px!important;
        display: block !important;
        text-align: center !important;
        background: 0 0 !important;
        width: initial !important;
        transition: all .5s ease-in-out !important;
        display: inline-block !important;
        text-transform: uppercase !important;
        margin: 0 15px 0 0 !important;
        text-shadow: none !important;
    }
    .sw_fill_btn:hover {
        background: transparent !important;
        text-decoration: none!important;
        color: #002536!important;
        border-color: #002536!important;
    }
    .sw_unfill_btn {
        color: #ffffff !important;
        border: 1px solid #ffffff !important;
        padding: 10px 30px !important;
        text-decoration: none!important;
        border-radius: 5px !important;
        font-family: 'Bariol-Bold' !important;
        font-size: 19px !important;
        min-width: 240px!important;
        display: block !important;
        text-align: center !important;
        background: #187fdb !important;
        width: initial !important;
        transition: all .5s ease-in-out !important;
        display: inline-block !important;
        text-transform: uppercase !important;
        text-shadow: none !important;
    }
    .sw_unfill_btn:hover {
        background: #ffffff!important;
        text-decoration: none!important;
        border-color: #187fdb!important;
        color: #187fdb !important;
    }
    .sw_unfill_conbtn {
        color: #ffffff!important;
        border: 1px solid #ffffff!important;
        padding: 10px 30px !important;
        text-decoration: none!important;
        border-radius: 5px !important;
        font-family: BariolBold !important;
        font-size: 19px !important;
        min-width: 240px!important;
        display: block !important;
        text-align: center !important;
        background: 0 0 !important;
        width: initial !important;
        transition: all .5s ease-in-out !important;
        display: inline-block !important;
        text-transform: uppercase !important;
        text-shadow: none !important;
    }
    .sw_unfill_conbtn:hover {
        background: #002536!important;
        text-decoration: none!important;
        border-color: #002536!important;
        color: #fff!important;
    }
    .section_sp_ft {
        width: 100%;
        height: 80px;
    }
    .white{
        color:#fff !important;
    }
    .txt-left{
    text-align: left !important;
    }
    .border-clr{
        border: 1px solid #fff !important;
    }
    @media only screen and (max-width: 1600px) and (min-width: 1200px){
    .sw_slider_heading {
        font-size: 30px !important;
    }
    .sw_content_heading {
        font-size: 28px!important;
    }
    .sw_subheading_heading {
        font-size: 24px!important;
    }
    .sw_fea_subheading_heading{
        font-size: 21px!important;
    }
    .sw_slider_subtext {
        font-size: 20px !important;
    }
    .sw_content_text,.sw_heading_sub_text{
        font-size: 20px !important;
    }
    }
    @media only screen and (max-width: 1024px) and (min-width: 768px){
    .sw_slider_heading {
        font-size: 26px!important;
        line-height: 1.3!important;
        margin: 0 0 20px 0 !important;
    }
    .sw_content_heading {
        font-size: 24px!important;
    }
    .sw_subheading_heading {
        font-size: 20px !important;
    }
    .sw_fea_subheading_heading{
        font-size: 21px!important;
    }
    .sw_slider_subtext {
        font-size: 18px !important;
    }
    .sw_content_text,.sw_heading_sub_text{
        font-size: 18px!important;
    }
    .section_sp_ft {
        width: 100%;
        height: 30px;
    }
    .hmbg-overlay .closebtn {
        position: absolute;
        top: 32px;
        right: 40px;
    }
    .section_seprator {
        width: 100% !important;
        height: 40px;
    }
    }
    @media only screen and (max-width: 767px) and (min-width: 280px){
    .section_seprator {
        width: 100%!important;
        height: 30px!important;
    }
    .sw_slider_heading {
        font-size: 24px!important;
        margin: 6px 0;
    }
    .sw_content_heading {
        font-size: 22px!important;
        line-height: 35px!important;
    }
    .sw_subheading_heading {
        font-size: 20px!important;
        margin: 0 !important;
    }
    .sw_fea_subheading_heading{
        font-size: 21px!important;
    }
    .sw_slider_subtext {
        font-size: 18px!important;
        line-height: 1.2!important;
    }
    .sw_content_text,.sw_heading_sub_text{
        font-size: 18px !important;
    }
    a.sw_fill_btn,.sw_unfill_btn,.sw_unfill_conbtn {
        margin: 10px 0!important;
        display: block !important;
        font-size: 16px !important;
    }
    .section_sp_ft {
        width: 100%;
        height: 30px;
    }
    /*.hmbg-overlay .closebtn {
        position: absolute;
        top: 32px;
        right: 40px;
    }*/
    }
    /*TZ NEW STYLE FOR PAGES END*/
    @supports (-moz-appearance:none) {
  select
  {
  -moz-appearance:none !important;
  background: transparent url('data:image/gif;base64,R0lGODlhBgAGAKEDAFVVVX9/f9TU1CgmNyH5BAEKAAMALAAAAAAGAAYAAAIODA4hCDKWxlhNvmCnGwUAOw==') right center no-repeat !important;
  background-position: calc(100% - 5px) center !important;
  }

  .error-style{
      color: red !important;
  }
}
    