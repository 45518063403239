@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

You can add global styles to this file, and also import other style files
/* RMI - Bootstrap Customized Css */
body {
    font-family: 'Poppins';
          
}


.container-fluid {border-bottom: 1px solid #ddd;}
@media (min-width: 1200px) { .container {max-width: 1200px;} }

/* RMI - General Classes */
/* .sw-navbar{padding: 0px 40px;} */
.sw-navbar {padding: 0px 10vw;}
.sw-brand-logo{display: inline-block;}
.sw-brand-logo img{width: 250px;}
.sw-nav-actions {padding: 18px 0px;}
/* .sw-nav-actions .sw-livechat{background:#161616;color:#fff;font-family:Bariol-Bold;font-size:18px;padding:8px 10px;width:244px;display:inline-block;text-align:center;border-radius:35px;}
.sw-nav-actions .sw-phone{background:#161616;color:#fff;font-family:Bariol-Bold;font-size:18px;padding:8px 10px;width:244px;display:inline-block;text-align:center;border-radius:35px} */
.sw-nav-actions .sw-phone, .sw-nav-actions .sw-livechat, .sw-nav-actions .sw-faqs{
    background: #ffffff;
    color: #161616;
    font-family: 'Bariol-Bold';
    font-size: 18px;
    padding: 8px 10px;
    width: 215px;
    display: inline-block;
    text-align: center;
    border-radius: 35px;
    border: 1px solid #a0a0a0;
}
.sw-nav-actions .sw-livechat, .sw-nav-actions .sw-faqs {
    margin-right: 15px;
}
.sw-nav-actions .sw-livechat i{ 
    padding-right: 6px;
    font-weight: 400 !important;
}
.sw-nav-actions .sw-phone i{ 
    padding-right: 6px;
    font-weight: 400 !important;
}
.sw-nav-actions .sw-faqs i{ 
    padding-right: 6px;
    font-weight: 400 !important;
}
.sw-nav-actions .sw-phone:hover, .sw-nav-actions .sw-livechat:hover, .sw-nav-actions .sw-faqs:hover {
    background: #fbc504;
    color: #131212;
    text-decoration: none!important;
}

.heading-step {
    font-family: 'poppins';
    font-size: 40px;
    font-weight: 400;
}

.apple-os .heading-step {
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 30px;
}

.heading-subtext {
    font-family: 'Poppins' !important;
    font-size: 22px;
    color: #6c6c6c;
}

/* RMI - Wizard Timeline - S */
.sw-wizard {
    margin: 40px 0;
 }

.sw-timeline {
    margin: 0 auto;
    text-align: center;
}

.sw-timeline .step {
    display: inline-block;
    padding: 14px 24px;
    border-bottom: 4px solid #131313;
    margin: 0px 6px;
    width: 170px;
    vertical-align: top;
    min-height: 85px;
}

.sw-timeline .step.active {
    border-bottom: 4px solid #fbc504;
    position: relative;
}

.sw-timeline .step.completed {
    border-bottom: 4px solid #fbc504;
}

.sw-timeline .step.active.completed {
    border-bottom: 4px solid #e7b603;
}
.learn-more{
    color: #21bdbb;
}

.sw-timeline .step.completed:hover {
    cursor: pointer;
}

.sw-timeline .step.completed .step-title {
    color: #212121;
    font-family: 'poppins';
    font-weight: 400;
    font-size: 16px;
}

.sw-timeline .step .step-title {
    font-size: 13px;
    font-family: 'poppins';
    line-height: 1.2;
    color: #646464;
    vertical-align: -webkit-baseline-middle;
}

.sw-timeline .step.active .step-title {
    font-size: 16px;
    font-family: 'poppins';
    color: #000000;
    font-weight: 600;
}

.apple-os .sw-timeline .step.active .step-title {
    font-weight: normal;
}

.sw-timeline .step.active:after {
    content: "\f0de";
    font-family: 'Font Awesome 5 Free';
    position: absolute;
    bottom: 0;
    font-size: 22px;
    height: 14px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #21bdbb;
}

/* RMI - Temp Timeline - E */

@font-face {
    font-family: 'Gotham-Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Light'), 
    url('./assets/process/GothamLight.woff') format('woff'),
    url('./assets/process/GothamLight.eot'),
    url('./assets/process/GothamLight.eot?#iefix') format('embedded-opentype'),
    url('./assets/process/GothamLight.ttf') format('truetype'),
    url('./assets/process/GothamLight.svg#Gotham-Light') format('svg'),
    url('./assets/process/GothamLight.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham-Book';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Book'), 
    url('./assets/process/Gotham-Book.woff') format('woff'),
    url('./assets/process/Gotham-Book.eot'),
    url('./assets/process/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('./assets/process/Gotham-Book.ttf') format('truetype'),
    url('./assets/process/Gotham-Book.svg#Gotham-Book') format('svg'),
    url('./assets/process/Gotham-Book.otf') format('opentype');   
}

@font-face {
    font-family: 'Gotham-Black';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Black'), url('./assets/process/Gotham-Black.woff') format('woff');
}

@font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url('./assets/process/GothamMedium.woff') format('woff'),
    url('./assets/process/GothamMedium.woff2') format('woff2'),
    url('./assets/process/GothamMedium.eot'),
    url('./assets/process/GothamMedium.eot?#iefix') format('embedded-opentype'),
    url('./assets/process/GothamMedium.ttf') format('truetype'),
    url('./assets/process/GothamMedium.svg#Gotham-Book') format('svg'),
    url('./assets/process/GothamMedium.otf') format('opentype'); 
}

@font-face {
    font-family: 'Bariol-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Regular'), 
    url('./assets/process/Bariol-Regular.woff') format('woff'),
    url('./assets/process/Bariol-Regular.eot'),
    url('./assets/process/Bariol-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/process/Bariol-Regular.ttf') format('truetype'),
    url('./assets/process/Bariol-Regular.svg#Bariol-Regular') format('svg'),
    url('./assets/process/Bariol-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Bariol-Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Thin'), url('./assets/process/Bariol-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Bariol-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Bold'), 
    url('./assets/process/Bariol-Bold.woff') format('woff'),
    url('./assets/process/Bariol-Bold.eot'),
    url('./assets/process/Bariol-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/process/Bariol-Bold.ttf') format('truetype'),
    url('./assets/process/Bariol-Bold.svg#Bariol-Regular') format('svg');
}


/* RMI - Screen loader spinner */
input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.8;
    /* filter: invert(0.8); */
    color: #fff;
  }
  input[type=date].form-control, input[type=datetime-local].form-control, input[type=month].form-control, input[type=time].form-control{
    color: #ACB9AC;
  }
  input[type=date].form-control, input[type=datetime-local].form-control, input[type=month].form-control, input[type=time].form-control::-webkit-calendar-picker-indicator{
      filter: contrast(0.7);
  }
  .sw-schedule-call-formdiv .form-control {
    color: #ACB9AC;
  }
.screen-overlay {
    display: block;
    /* position: absolute; */
    position: fixed;
    z-index: 100;
    background-color: #fff;
    opacity: 0.97;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    /* height: 100%; */
    height: 120vh;
}

.screen-loader {
    position: sticky;
    left: 50%;
    top: 50%;
    height: 60px;
    width: 60px;
    margin: 0 auto;
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left: 6px solid rgba(128, 195, 194,.15);
    border-right: 6px solid rgba(128, 195, 194,.15);
    border-bottom: 6px solid rgba(128, 195, 194,.15);
    border-top: 6px solid rgba(33, 189, 187,.8);

    border-radius: 100%;
    margin-top: 50vh;
}

.noScroll {
    overflow: hidden;
}

/* RMI - Page loader - S */
.page-loader .screen-overlay {
    margin: 0 0;
}
@-webkit-keyframes rotation {
from {-webkit-transform: rotate(0deg);}
to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
from {-moz-transform: rotate(0deg);}
to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
from {-o-transform: rotate(0deg);}
to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
from {transform: rotate(0deg);}
to {transform: rotate(359deg);}
}
/* RMI - Page loader - E */

/* Stylish Check and Radion Button Css - START */
input[type=radio].with-font,
input[type=checkbox].with-font {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
    
input[type=radio].with-font ~ label:before,
input[type=checkbox].with-font ~ label:before {
    font-family: FontAwesome;
    display: inline-block;
    content: "\f1db";
    letter-spacing: 15px;
    font-size: 1.2em;
    color: #535353;
    width: 1.2em;
}

input[type=radio].with-font:checked ~ label:before,
input[type=checkbox].with-font:checked ~ label:before  {
    content: "\f192";
    font-size: 1.2em;
    color: #333333;
    letter-spacing: 15px;
}
input[type=checkbox].with-font ~ label:before {        
    content: "\f096";
}
input[type=checkbox].with-font:checked ~ label:before {
    content: "\f046";        
    color: #333333;
}
input[type=radio].with-font:focus ~ label:before,
input[type=checkbox].with-font:focus ~ label:before,
input[type=radio].with-font:focus ~ label,
input[type=checkbox].with-font:focus ~ label
{                
    color: #333333;
}
/* Stylish Check and Radion Button Css - END */

/* RMI - General Button - Next and Previous - S */
.general-footer-actions {
    padding-bottom: 50px !important;
}
.general-footer-actions .step-previous {
    border:0;
    border-bottom: 2px solid #524940;
    width: 180px; 
    padding: 4px 6px;
    background: transparent;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    margin-right: 28px !important;
    text-transform: uppercase;
    border-radius: 0 !important;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.general-footer-actions .step-previous:focus {
    box-shadow: none!important;
}
.general-footer-actions .step-previous i { 
    color: #21bdbb;
    margin-right: 10px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.general-footer-actions .step-previous:hover i { 
    margin-right: 20px;
}
.general-footer-actions .step-next {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    border: 2px solid #21bdbb;
    width: 180px; 
    padding: 4px 6px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.general-footer-actions .step-next:hover {
    background: #21bdbb;border: 2px solid #21bdbb;
}
.general-footer-actions .step-next:focus {
    box-shadow: none!important;
}
.general-footer-actions .step-next i {    
    opacity: 0;
    visibility: hidden;
    margin-left: -15px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.general-footer-actions .step-next:hover i {  
    margin-left: 10px;
    opacity: 1;
    visibility: visible;  
}
/* RMI - General Button - Next and Previous - E */

.footer-copyright {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    font-size: 14px;
    font-family: 'Gotham-Book';
    color: #6b655a;
}

/* RMI - Popover Styles - S */
.popover-body {
    text-align: center;
    padding: .5rem .75rem;
    color: #212529;
    font-weight: 600;
    font-family: 'Gotham-Book';
}



/* Media Queries - S */
@media screen and (max-width: 1280px) {
    .sw-navbar {
        padding: 0px 4vw;
    }

    .help-box .open-button{
        display: none;
    }
}
@media screen and (max-width: 1120px) {
    .sw-nav-actions .sw-faqs {
        display: none;
    }
}
@media screen and (max-width: 1024px) {
    .sw-navbar {
        padding: 0px 1vw;
    }    
    main.container { /* Temporary  */
        max-width: 100%;
    }
    .sw-timeline .step {
        width: 140px;
        padding: 14px 0px;
    }
}
@media screen and (max-width: 820px) {

    .sw-nav-actions .sw-livechat {
        display: none;
    }

    /* .sw-timeline {margin: 0 2vw;} */
    .sw-timeline .step {display: none;}
    .sw-timeline .step.active .step-title {font-size: 28px;}
    .sw-timeline .step.active {
        display: block;
        text-align: left;
        width: 100%;
        border: none;
    }
    .sw-timeline .step.active:after {
        content: '';
        width: 60px;
        height: 3px;
        background: #21bdbb;
        margin: 0;
        left: 25px;
        bottom: 25px;
    }
    .sw-timeline .step:before {
        content: '1/5';
        font-size: 20px !important;
        font-weight: 500;
        /* position: absolute; */
        top: 10px;
        right: 20;
    }
    .sw-timeline .step.stp-2.active:before {content: '2/5';}
    .sw-timeline .step.stp-3.active:before {content: '3/5';}
    .sw-timeline .step.stp-4.active:before {content: '4/5';}
    .sw-timeline .step.stp-5.active:before {content: '5/5';}

    
}
@media screen and (max-width: 767px) {
  
    .sw-wizard {
        margin: 20px 0;
        margin-bottom: 40px;
    }

    .sw-timeline .step.active:after {
        background: transparent;
    }
    
    .sw-timeline .step.active {
        border-bottom: 4px solid #fbc504;
    }
    
    .pg-step-heading {
        display: none;
    }

    .general-footer-actions .float-right {
        float: none !important;
        margin: 0 auto;
        text-align:center;
    }

    .general-footer-actions .btn-step {
        width: 90%;
        padding: 4px 2px;
        /* margin: 0 auto !important; */
    }        
}

@media screen and (max-width: 600px) {
    .sw-navbar .logo-section {
        padding: .9rem!important;
    }
    .sw-brand-logo img {
        width: 220px;
    }
    .sw-nav-actions .sw-phone, .sw-nav-actions .sw-livechat {
        width: 194px;
    }
}
@media screen and (max-width: 510px) {
    .sw-brand-logo img {
        width: 200px;
    }
    .sw-nav-actions .sw-phone {
        width: 165px;
        margin-right: 0;
    }
}
@media screen and (max-width: 480px) {
    .sw-brand-logo img {
        width: 200px;
    }
}
@media screen and (max-width: 445px) {
    header.container-fluid {
        padding: 0px;
    }
    .sw-brand-logo img {
        width: 180px;
    }
    .sw-navbar .logo-section {
        padding: 1rem!important;
    }
    .sw-navbar .note-section {
        display: none;
    }
    .sw-nav-actions .sw-phone {
        font-size: 15px;
    }
}
@media screen and (max-width: 400px) {
    .sw-navbar .support-section {
        display: none;
    }
    .sw-timeline .step.active .step-title, .sw-timeline .step:before {
        font-size: 24px;
    }
    
    .sw-timeline .step {
        min-height: 70px;
    }
}
@media screen and (max-width: 370px) {
    .sw-navbar .logo-section {
        padding: .5rem!important;
    }
    .sw-nav-actions {
        padding: 10px 0px;
    }
    .sw-timeline .step.active .step-title, .sw-timeline .step:before {
        font-size: 20px;
    }
}
@media screen and (max-width: 320px) {
}
/* Media Queries - E */


.log-out{
    margin: 0 auto;
    margin-top: 28px;
}


.log-btn{
    background: #1880dd !important;
    color: #fff !important;
    border: 1px solid #1880dd;
    padding: 8px 35px;
    border-radius: 4px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none !important;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    margin-right: 10px !important;
}

/* width */
::-webkit-scrollbar{
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track{
    box-shadow: inset 0 0 0px rgb(36, 36, 36); 
   

  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb{
    background: #fbc504;
   
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fbc504; 

  }
  .load-color{
    color: #FBC504 !important;
  }

  /* ::-webkit-calendar-picker-indicator {
    filter: invert(1);
} */
/* ::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  }


 */

 input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
    filter: invert(0.8);
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1
  }











