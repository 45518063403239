
.dropdown-menu{
  border-radius: 2px solid gray !important
}
.regionSelect{
  color: white !important;
}
.new_link{
  text-decoration: none !important;
  font-size: 16px !important;
  font-family: "poppins" !important;
  color: "white" !important;
}
.region{
  font-size: 16px !important;
  font-family: "poppins" !important;
  color: "white" !important;
}
.new_link:hover{
  color: #fbc504 !important;
}


.regionOption:hover{
  background-color: #fbc504 !important;
}

  span.nav-link:hover{
  color: #fbc504 !important;
}
.dropdown-item:focus, .dropdown-item:hover{
  background-color: transparent !important; 
}
.main-header.header-custom {
    box-shadow: 0 19px 30px 0 rgba(25, 25, 25, .1);
    -webkit-box-shadow: 0 19px 30px 0 rgba(25, 25, 25, .1);
    -moz-box-shadow: 0 19px 30px 0 rgba(25, 25, 25, .1);
  }
  p.sw_slider_subtext.text-center a {
  color: #e47458;
  text-decoration: none;
  }
  .sw-head.container{
  padding: 0 !important;
  }
  .header-transparency {
      z-index: 9999999;
      background: 0 0!important;
  }
  .header-custom {
      background-color: #fff0;
      position: absolute;
      width: 100%;
      z-index: 99;
      /* position: fixed!important; */
      transition: all .1s ease-in-out;
      height: 100px;
      padding:10px 2px 10px 2px;
  }
  .header-custom ul {
      background-color: #fff0;
  }
  ul.sub-menu {
      position: absolute;
      background: #080808 !important;
      padding: 0 10px 20px 10px !important;
      margin:0 !important;
  }
  ul.sub-menu {
      background: #050505 !important;
      padding: 0 20px;
      box-shadow: 0 19px 30px 0 rgba(25, 25, 25, .1);
  }
    .head-container{
      /*max-width: 1920px;*/
      padding: 0px 40px;
    }
    .hmbg-overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    background-color: rgb(255,255,255);
    background-color: rgba(255,255,255, 0.97);
    overflow-x: hidden;
    transition: 0.5s;
  }
  
  .hmbg-overlay-content {
    position: relative;
    top: 10%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  
  .hmbg-overlay a {
    font-size: 18px;
    font-family: "Poppins";
    font-weight: bolder;
    color: #000000 !important;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  .hmbg-overlay a:hover, .hmbg-overlay a:focus {
    color: #f1f1f1;
  }
  
  .hmbg-overlay .closebtn {
    position: absolute;
    top: 0px;
    right: 30px;
    font-size: 60px !important;
    text-decoration: none;
    font-weight: bold;
    color: #000 !important;
  }
  .header-custom ul#menu-sw-menu li a {
    font-size: 16px;
      font-family: "Poppins";
      font-weight: 400;
      color: #ffffff !important;
      text-decoration: none;
  }
  .header-transparency ul#menu-sw-menu .sub-menu li a {
    color: #454545 !important;
  }
  .header-transparency ul#menu-sw-menu li a {
    font-size: 18px;
      font-family: "Poppins";
      font-weight: bolder;
      color: #ffffff !important;
      text-decoration: none;
      text-transform: uppercase;
  }
  ul.sub-menu li a {
      font-size: 15px !important;
  }
  ul#menu-sw-menu li {
      display: inline-block;
      padding: 0 10px;
  }
  ul#menu-sw-menu li {
    display: inline-block;
      padding: 0 25px;
      height: 88px;
      line-height: 88px;
  }
  ul.sub-menu {
      display: none;
      
  }
  .menu-item-has-children:hover ul.sub-menu {
  display: block;
  background-color: rgb(0, 0, 0);
  
  }
  ul.sub-menu {
  padding-left: 0;
  background-color: rgb(5, 5, 5);
  }
  ul.sub-menu li {
      display: block !important;
      background-color: rgb(5, 5, 5);
  }
  ul.sub-menu li {
      padding: 0 !important;
  }
  ul#menu-sw-menu {
      margin: 0;
      padding: 0 0 0 60px;
  }
  .hmbg-icon{
    font-size:30px;
    cursor:pointer;
  }
  #hmbg-icon{
  display:none;
  }
  .menu-btns li{
      display: block;
      padding: 0 10px;
  }
  .dropdown-menu {
    min-width: 155px !important;
  }
  .dropdown-menu>li>a {
    padding: 3px 0px !important;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: bold;
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
  }
  ul.dropdown-menu.show {
    background: #fff !important;
    margin: 0 !important;
  }
  .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
    color: #000 !important;
    text-decoration: none !important;
    background-color: #ffffff !important;
  }
  .header-custom a.menu-btn-lg {
    background: #00E90B !important;
    color: #fff !important;
    border: 1px solid #00E90B;
    padding: 8px 35px;
    border-radius: 4px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none !important;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    margin-right: 10px !important;
  }
  .header-transparency a.menu-btn-lg {
      background: transparent !important;
      color: #fff !important;
      border: 1px solid #ffffff;
      padding: 8px 35px;
      border-radius: 4px;
      font-family: "Poppins";
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
      text-decoration: none !important;
      width: initial;
      transition: all .5s ease-in-out;
      display: inline-block;
      margin-right: 10px !important;
  }
  
  a.dropdown-toggle.menu-btn-lg:hover,a.dropdown-toggle.menu-btn-lg:active,a.dropdown-toggle.menu-btn-lg:focus {
    background: transparent !important;
  }
  .header-custom a.menu-btn-sg{
      background: #00E90B !important;
      color: #fff !important;
      border: 1px solid #00E90B;
      padding: 8px 35px;
      border-radius: 4px;
      font-family: "Poppins";
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
      text-decoration: none !important;  
      width: initial;
      transition: all .5s ease-in-out;
      display: inline-block;
  }
  .header-transparency a.menu-btn-sg{
    background: transparent !important;
    color: #fff !important;
    border: 1px solid #ffffff;
    padding: 8px 35px;
    border-radius: 4px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none !important;  
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
  }
  a.menu-btn-lg:hover,a.menu-btn-lg:active,a.menu-btn-sg:hover,a.menu-btn-sg:active {
  color:#fff !important;
  }
  .header-custom a.menu-btn-lg:hover, .header-custom a.menu-btn-lg:active, .header-custom a.menu-btn-lg:focus {
    background: #00E90B !important;
    color: #Ffffff!important;
  }
  
  .menu-btns {
      margin-top: 10px;
  }
  #newRegion{
    display: none;
  }
  .navmenu-btns.navbar-nav>li>a {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    line-height: 20px !important;
    position: relative;
    display: block;
    padding: 10px 15px;
  }
  div#hmbg-myNav {
      display: none;
      opacity: 0;
  }
  .custom-menu-class {
      text-align: center;
      margin-top: -8px;
  }
  img.sw-logo {
    width: auto !important;
    /* max-width: 195px !important; */
    outline: none !important;
    margin-top: 0px !important;
  }
  ul.sub-menu li {
      text-align: left;
      line-height: 56px;
      height: 48px !important;
  }
  .row:before, .row:after {display: none !important;}
  .dropdown-menu-one>li>a {
    display: inline-block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 2;
    color: #000;
    white-space: nowrap;
    font-family: 'Poppins';
    font-size: 18px;
    text-transform: capitalize;
  }
  .logout-icon-one {
    width: 20px;
    margin: 10px 6px -5px -5px;
    display: inline-block;
  }
  .visit-icon-one{
    width: 20px;
    margin: 10px 6px -5px -4px;
    display: inline-block;
  }
  a.logout-link-one{
  width: 100%;
  height: 100%;
  }
  .dropdown-menu-one {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    line-height: 1.4;
    display: none;
    float: left;
    min-width: 100%;
    padding: 0px 0;
    margin: -10px -4px 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc !important;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
  }
  .dropdown-menu-one>li:focus, .dropdown-menu-one>li:hover{
    color: #262626 !important;
    text-decoration: none;
    background: #f7f7f7 !important;
    outline: none;
    cursor: pointer;
  }
  .dropdown-menu-one li:nth-child(1){
    border-bottom: 1px solid #e47558 !important;
    line-height: 0.7;
  }
  .dropdown-menu-one li:nth-child(2) {
    border-bottom: 3px solid #e47558 !important;
    line-height: 0.7;
  }
  
  .drp-menu-one:before,
  .drp-menu-one:after{
    bottom: 100%;
    left: 85%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .drp-menu-one:before {
      border-bottom-color: #ccc;
    border-width: 9px;
    margin-left: 5px;
  }
  .drp-menu-one:after{
    border-color: rgba(118, 118, 118, 0);
    border-bottom-color: #fff;
    border-width: 7px;
    margin-left: 7px;
  }
  a.myaccount-one {
    border: 1px solid #000 !important;
    border-radius: 25px;
    padding: 10px 20px;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    font-size: 1vw;
  
  }
  .signin-one{
  border: 1px solid #000 !important;
    border-radius: 25px;
    padding: 10px 20px;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    font-size: 1vw;
  
  }
  .signin-one:hover{
      background: #e47558 !important;
    color: #fff !important;
    border: 1px solid #e47558 !important;
  }
  a.myaccount-one:hover{
   background: #e47558 !important;
    color: #fff !important;
    border: 1px solid #e47558 !important;
  }
  .dvLoading {
    background: #000 url("../../../assets/img/preloader.gif") no-repeat center center;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    margin: 0;
    opacity: 0.98;
    background-color: #ffffff;
  }
  .d-tc{
    margin-top: auto;
    margin-bottom: auto;
    /*display: table-cell;*/
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .d-tc{
     height:100%;
    }
  }
  .sub-menu li:nth-child(2) a {
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    padding: 15px 24px 15px 0;
  }
  .sub-menu li:nth-child(4) a {
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    padding: 15px 0 15px 0px;
  }
  .sub-menu li:nth-last-child(1) {
    height: 56px !important;
  }
  .xsf-sep{
    width: 100%;
    height: 150px;
  }
  @media only screen and (max-width:1600px) and (min-width:1200px) {
  ul#menu-sw-menu li a {
    font-size: 16px;
  }
  ul.sub-menu li a {
      font-size: 15px !important;
  }
  }

  @media only screen and (max-width:1366px) and (min-width:1024px) {
    img.sw-logo {
      width: auto !important;
      max-width: 250px !important;
      /* margin-top: 7px !important; */
  }
  ul#menu-sw-menu li a {
    font-size: 15px;
  }
  ul.sub-menu li a {
      font-size: 14px !important;
  }
  a.menu-btn-lg,a.menu-btn-sg{
    font-size: 14px;
    padding: 8px 20px !important;
  }
  img.sw-logo{
    width: auto !important;
    /* max-width: 185px !important; */
    /* margin-top: 17px !important; */
  }
  ul#menu-sw-menu {
    display: flex;
    justify-content: space-between;
      margin: 0;
      padding: 0 40px 0 40px !important;
  }
  }
  @media only screen and (max-width:1023px) and (min-width:280px) {
    ul.sub-menu {
      padding: 0 10px 0px 10px !important;
    }
    .hmbg-overlay .closebtn {
      top: -5px !important;
      right: 36px !important;
    }
    .avia_mobile .hmbg-overlay .closebtn {
    top: 28px !important;
    right: 50px !important;
    }
    div#hmbg-myNav {
      display: block !important;
      opacity: 1 !important;
  }
  .col-md-8 .custom-menu-class {
      display: none;
  }
  .menu-btns {
      margin-top: 0;
      display: none;
  }
  #hmbg-icon{
    display: block !important;
  }
  .head-container {
      padding: 0px 30px;
  }
  ul#menu-sw-menu-1 li {
      list-style: none;
  }
  ul#menu-sw-menu-1 li {
      list-style: none;
      line-height: 3.0;
  }
  ul#menu-sw-menu-1 {
      padding-left: 0;
  }
  .hmbg-overlay-content .custom-menu-class {
      display: block;
  }
  .custom-menu-class,.menu-btns {
      display: none;
  }
  .sw-head.container {
    padding: 0 30px !important; 
  }
  ul.sub-menu {
    position: relative !important;
  }
  ul.sub-menu li a {
    text-align: center !important;
    display: inherit !important;
  }
  .hmbg-overlay a.menu-btn-lg, .hmbg-overlay a.menu-btn-sg {
    font-size: 16px !important;
    display: block !important;
    margin: 10px 20px !important;
  }
  ul.dropdown-menu.show {
    width: 90% !important;
  }
  .dropdown-menu>li>a {
    padding: 3px 15px !important;
    text-align: center !important;
  }
  .sub-menu li:nth-child(2) a {
    padding: 0px 0 !important;
  }
  .sub-menu li:nth-child(4) a {
    padding: 0px 0 !important;
  }
  }
  @media only screen and (max-width:1280px) {
  .dropdown-menu {
    min-width: 139px !important;
  }
  }
  @media only screen and (max-width: 1366px) and (min-width: 280px){
  .sw-head.container {
    padding: 0 30px !important; 
  }
  }
  .container {
    width: 1270px!important;
    max-width: 1249px !important;
  }
  @media only screen and (max-width: 1366px) and (min-width: 280px){
    .container {
        width: 100%!important;
        max-width: 100%!important;
        padding: 0 30px !important;
    }
    }
    @media only screen and (max-width: 375px) and (min-width: 280px){
    .hmbg-overlay a {
      font-size: 16px !important;
    }
    ul.sub-menu li a{
      font-size: 14px !important;
    }
   
  }
  @media (min-width:280px) and (max-width:767px) and (orientation:landscape) {
    .section_seprator {
      width: 80px!important;
      height: 40px!important;
  }
  }
  @media (min-width: 480px) and (max-width: 1024px) {
    .RegionSwitcher{
      background-color: rgb(88, 88, 88);
      border-radius: 8px;
      padding: 10px;
      margin-right: 10px;
      margin-top: 0px !important;
      width: 26% !important;
    }
}
  @media (min-width: 480px) and (max-width: 1020px) {
    .RegionSwitcher{
      display: none !important;
    }
    #newRegion{
      display: inline !important;
    margin-right: 10px !important;
    /* margin-top: 10px !important; */
    margin-left: 250px !important;
    }
}
  @media (min-width:280px) and (max-width:767px) {
  /*.fl-ct {
    display: block !important;
  }*/
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  span#hmbg-icon {
    display: inline-block;
    position: absolute;
      top: 20px;
      right: 30px !important;
      font-size: 39px;
  }
  img.sw-logo {
    width: auto !important;
    max-width: 180px !important;
    /* margin-top: 18px !important; */
  }
  }
  @media (min-width:768px) and (max-width:1023px) {
  span#hmbg-icon {
    display: inline-block;
    position: absolute;
      top: 20px;
      right: 30px !important;
      font-size: 39px;
  }
  img.sw-logo {
    width: auto !important;
    /* max-width: 185px !important; */
    /* margin-top: 18px !important; */
  }
  }
  @media (min-width:601px) and (max-width:767px) and (orientation:landscape) {
  h3.sw-ft-title,p.sw-ft-nav-text,a.sw-ft-anch {
    font-size: 14px !important;
  }
  }
  @media (min-width:480px) and (max-width:600px) and (orientation:landscape) {
    h3.sw-ft-title,p.sw-ft-nav-text,a.sw-ft-anch {
      font-size: 13px !important;
    }
  }
  ul#menu-sw-menu li a {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 400;
    color: #ffffff !important;
    text-decoration: none;
  }
  .color{
    background-color: #fff0;
    position: absolute;
    /* width: 100%; */
    z-index: 99;
    /* position: fixed!important; */
    transition: all .1s ease-in-out;
    height: 100px;
    padding: 10px 2px 10px 2px;
  }


  .head-login{
    background-color: #fff0;
    /* position: absolute; */
    /* width: 100%; */
    z-index: 99;
    /* position: fixed!important; */
    transition: all .1s ease-in-out;
    height: 100px;
    padding: 10px 2px 10px 2px;
  }
  .custom-class{
    padding: 2px 15px 2px 15px !important; 
  }
  .custom-nav{
    margin-right: 25px;
  }
  .color{
    background-color: #131313 !important;
  }
  .nav-link{
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 400;
    color: white !important;
    text-decoration: none;

  }

  .center-main{
    width: 700px !important;
    justify-content: space-evenly !important;

}

@media only screen and (max-width:700px) {
    .center-main{
        width: 400px !important;
        justify-content: center;
    
    }
}

.glassy-background {
  
  background-color: rgba(57, 57, 57, 0.2); /* Set the background color with transparency */
  backdrop-filter: blur(60px); /* Apply a blur effect */
  
  border-radius: 10px; /* Add rounded corners for a glassy effect */
  text-align: center;
  color: #181818; /* Text color */
}

.glassy-background2 {
  background-color: rgba(57, 57, 57, 0.2); /* Set the background color with transparency */
border-radius: 10px;
height: auto;  
   /* Add rounded corners for a glassy effect */
  text-align: center;
  color: #181818;
}
/* .main-head-css{
  position: absolute;
} */



@media (min-width: 768px) and (max-width: 1024px) {
  .navbar-nav{
  padding: 25px 0px ;
    display: flex !important;
    flex-direction:row !important;
    
  }
  .navbar{
    padding-bottom: 30px;
  }
  
  .responsive{
padding: 10px 20px !important;
margin-left: 55px !important;
font-size: medium !important;
   
  }
  .homeslide{
    font-size: 14px !important;
  }
  .homeSliderBtn{
    padding: 7px 20px !important;
    margin-top: 0px  !important; 

font-size: 13px !important;
  } 


  /* CSS */
  
}

@media (min-width: 481px) and (max-width: 1022px) {
  
}

@media (min-width: 320px) and (max-width: 480px) {
 
.nav-item{
  display: flex;
  margin-bottom: 20px !important;
  text-decoration: none;
}
/* .region {
  display: flex;
} */
.RegionSwitcher{
    width: 11rem !important;
    margin-bottom: 20px !important;
} 
.new_icon{
  display: flex !important;
  flex-direction: row !important;
  /* width: 30% !important; */
}
.globe_icon{
  width: 30px !important;
  height: 15px !important;
  margin-top: 7px !important;
}
.arrow_icon{
  width: 20px !important;
  height: 7px !important;
  margin-top: 12px !important;
}
.region{
  margin-top: 3px !important;
}

}

.wrapper{
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.wrapper .title{
  font-weight: 700;
  font-size: 24px;
  color: #fff;
}

.select_wrap{
  width: 225px;
  margin: 15px auto;
  position: relative;
  user-select: none;
}

.select_wrap .default_option{
  background: #fff;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.select_wrap .default_option li{
    padding: 10px 20px;
}

.select_wrap .default_option:before{
    content: "";
    position: absolute;
    top: 18px;
    right: 18px;
    width: 6px;
    height: 6px;
    border: 2px solid;
    border-color: transparent transparent #555555 #555555;
    transform: rotate(-45deg);
}

.select_wrap .select_ul{
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  display: none;
}

.select_wrap .select_ul li{
  padding: 10px 20px;
  cursor: pointer;
}

.select_wrap .select_ul li:first-child:hover{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.select_wrap .select_ul li:last-child:hover{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.select_wrap .select_ul li:hover{
  background: #fff4dd;
}

.select_wrap .option{
  display: flex;
  align-items: center;
}

.select_wrap .option .icon{
  background: url('https://i.imgur.com/oEZu0sK.png') no-repeat 0 0;
	width: 32px;
	height: 32px;
  margin-right: 15px;
}

.select_wrap .option.pizza .icon{
  background-position: 0 0;
}

.select_wrap .option.burger .icon{
  background-position:  0 -35px;
}

.select_wrap .option.ice .icon{
  background-position: 0 -72px;
}

.select_wrap .option.fries .icon{
 background-position: 0 -111px;
  width: 25px;
}

.select_wrap.active .select_ul{
  display: block;
}

.select_wrap.active .default_option:before{
  top: 25px;
  transform: rotate(-225deg);
}
/* Ipad Pro */


.img.sw-logo{
  margin-left: 20px !important;
}