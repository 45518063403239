.btn-logout{
    margin-top: 0px !important;
    align-self: center !important;
}
.back:hover{
   cursor: pointer;
    border-radius: 10px;
    background-color: #FBC504;
}

.btn-clr{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 50px;
    font-family: "Poppins" !important;
    font-weight: 400;
    font-size: 18px;
    min-width: 220px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    /* margin: 0 5px 0 5px !important; */
    text-shadow: none !important;
}
.btn-clr:hover{
    background: transparent !important;
    text-decoration: none!important;
    color: #030303!important;
    border-color: #020202!important;
}
.number{
    color: #131313;
}
.number:hover{
    color: #131313;
    text-decoration: none !important;
}
.center-nav{
    width: 650px !important;
    justify-content: flex-end !important;

}
.search::placeholder {
    color: #808080;

    font-weight: 400;

    opacity: 1; /* Firefox */
  }
.search:focus{ 
 outline: none; 
    
}

@media only screen and (max-width:700px) {
    .center-nav{
        width: 400px !important;
        justify-content: center;
    
    }
}


@media only screen and  (min-width:1200px) {
    .center-nav{
        /* width: 700px !important; */
        justify-content: flex-end;
    
    }
}

@media screen and (min-width:950px) and (max-width:1250px) {
    .center-nav{
        width: auto !important;
        justify-content: center;
    
    }
}

@media screen and (min-width:480px) and (max-width:1024px) {
   
 .searchcol{
    display: none;
 } 
 .left{
   display: none;
 }
 .mobilesearch{
    display: none !important;
 }
}

@media screen and  (min-width:1025px) {
.tablets-mobile{
    display: none;
}
}

@media screen and (min-width:280px) and (max-width:480px) {
    .profile {
       height: 40px !important;
       width: 40px !important;
    }
     .profile_sec{
        padding-right: 0px !important;
     }
}

@media (min-width: 480px) and (max-width: 1024px) {
    .order {
        display: none !important;
    }
    
}
    @media (min-width: 320px) and (max-width: 480px) {
  
    .category_card{
        display: flex;
        justify-content: center;
    }
    .Search{
        display: none;
    }
    .left{
        display: none;
    }
    .main{

        padding-top: 0;
   
    }
    .profilemobile{
        
        padding-right:  0 !important;
        justify-content: end !important;
        flex-direction: column-reverse !important;
  
        
    }
    .cont{
        padding: 0 !important;
    }
    .main{

        padding-top: 0;;
   
    }
    .sidebar{
        display: none !important;
    }
    
  }




@media (min-width: 320px) and (max-width: 480px) {

    
    .category_card{
        display: flex;
        justify-content: center;
    }
    .Search{
        display: none;
    }
    .left{
        display: none;
    }
    .main{

        padding-top: 0;
   
    }
    .profilemobile{
        
        padding-right:  0 !important;
        justify-content: end !important;
        flex-direction: column-reverse !important;
  
        
    }
    .cont{
        padding: 0 !important;
    }
    .main{

        padding-top: 0;;
   
    }
    .sidebar{
        display: none !important;
    }
    
  }

@media (min-width: 320px) and (max-width: 480px) {
  
 .searchcol {
        display: none;
  }

.two{
    display: none !important;
}

.profileimg {
     width: 45px !important;
     height: 45px !important;
  }

.main{
    padding-left:0 !important;
}

.tranparent{
    
    
    margin-right: 10px !important ;
    padding: 0 !important;
    height: 45px !important;
    width: 45px !important;

}
.profilemobiledropdow{
left: -90px;
}
.mobile{
display: none !important;
}
.logoimg{
    width: 130px !important;

}
.noti{
    margin-left:35px !important;
    margin-top:-35px  !important;

   }
  }
