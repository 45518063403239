@font-face {
    font-family: Bariol;
    src: url('../../assets/process/bariol_regular-webfont.eot');
    src: url('../../assets/process/bariol_regular-webfontd41d.eot?#iefix') format("embedded-opentype"),
     url('../../assets/process/bariol_regular-webfont.svg') format('svg'),
      url('../../assets/process/bariol_regular-webfont.ttf') format('truetype'),
       url('../../assets/process/bariol_regular-webfont.woff') format('woff'),
        url('../../assets/process/Bariol_Regular.otf') format('otf'),
         url('../../assets/process/bariol_regular-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: GothamBook;
    src: url('../../assets/process/Gotham-Book.eot');
    src: url('../../assets/process/Gotham-Bookd41d.eot?#iefix') format("embedded-opentype"),
     url('../../assets/process/Gotham-Book.woff') format("woff"), 
     url('../../assets/process/Gotham-Book.ttf') format('truetype'),
      url('../../assets/process/Gotham-Book.svg#Gotham-Book') format("svg"), 
      url('../../assets/process/Gotham-Book.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url('../../assets/process/GothamMedium.woff') format('woff'),
    url('../../assets/process/GothamMedium.woff2') format('woff2'),
    url('../../assets/process/GothamMedium.eot'),
    url('../../assets/process/GothamMediumd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/GothamMedium.ttf') format('truetype'),
    url('../../assets/process/GothamMedium.svg#Gotham-Book') format('svg'),
    url('../../assets/process/GothamMedium.otf') format('opentype'); 
}
  @font-face {
    font-family: BariolBold;
    src: url('../../assets/process/bariol_bold-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  /* @font-face {
    font-family: 'Gotham-Bold';
    src: url('../../assets/process/Gotham-Bold.html') format('opentype');
  src: url('../../assets/process/gothambold.html') format('truetype');
    font-weight: normal;
    font-style: normal;
  } */
  html {
    scroll-behavior: smooth;
  }
  .main-header.header-custom.header-transparency {
    background: #fff !important;
  }
  #custom-footer {
      display: block!important;
  }
  .section_seprator {
      width: 100%;
      height: 80px;
  }
  .list-scroll{
    overflow-y: scroll;
    height: 200px;
  }
  /* afroz css */
  .container-fluid
  {
    border-bottom: none !important;
  }
  .second_head{
    color: #ffffff!important;
    font-family: "Poppins" !important;
    font-size: 35px;
    font-weight: 500!important;
    line-height: 1.3;
    text-transform: inherit !important;
  }
  .sw_rdw_three_clr{
    background-color: rgb(20, 20, 20);
  }
  .subscriptiondiv{
    border: 1px solid #25262A;
    border-radius: 10px;
  }
  .subcription-head{
    font-family: 'Poppins' !important;
    font-size: 20px;
    color: #fbc503;
    font-weight: 500;
    margin-bottom: 0px;
  }
/* afroz css end */


  a.sw_pricing_button {
      color: #1880dd !important;
      border: 1px solid #1880dd !important;
      padding: 10px 30px;
      text-decoration: none!important;
      border-radius: 5px;
      font-family: Bariol;
      font-weight: bold;
      font-size: 19px;
      min-width: 240px!important;
      text-align: center;
      background: 0 0;
      width: initial;
      transition: all .5s ease-in-out;
      display: inline-block;
      text-transform: uppercase;
      margin-right: 10px;
  }
  a.sw_pricing_button:hover{
      background: #1880dd !important;
      text-decoration: none!important;
      border-color: #1880dd !important;
      color: #fff!important;
  }
  .pricingpage{
    overflow: hidden;
  }
  .sw-pricing-main {
    margin-top: 0px;
      padding: 0px !important;
  }
  .nav-col-div{
    height: 0px;
    background: #fff;
  }
  .ps-pricing-nav nav {
    width: 100%;
    background: #0f0f0f !important;
    padding: 20px;
  }
  .ps-pricing-nav ul {
    padding: 0px;
    margin: 0 auto;
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: center;
  }
  .bg{
      background-color: #161616 !important;
  }
  .ps-pricing-nav li {
    display: inline-block;
    margin: 0 42px
  }
  .ps-pricing-nav ul li a {
    padding: 10px 25px;
    color: #fff !important;
    font-size: 16px;
    text-decoration: none;
    font-weight: 400;
    transition: all 0.2s ease;
    font-family: 'poppins';
  }
  .ps-pricing-nav ul li a:hover{
    color: #fbc504 !important;
  }
  .psnav-item.active .psnav-link {
    color: #fbc504 !important;
    /* border-bottom: 4px solid #fff; */
  }
  .nav>li>a:focus, .nav>li>a:hover {
    background-color: transparent !important;
  }
  #top .sw-pricing-tables .container {
    max-width: 60% !important;
  }
  .sw-pricing-section{
  width: 100%;
  background: #fff;
  text-align: center;
  }
  .sw-pricing-heading{
      font-size: 38px!important;
      color: #222;
      font-family: 'Gotham-Medium' !important;
      line-height: 56px;
      font-weight: 700;
      text-align: center;
  }
  .sw-pricing-text {
      font-family: 'Bariol' !important;
      font-size: 23px;
      line-height: 32px;
      color: #4d545b;
      margin: 0 !important;
  }
  .sw-pricing-subheading{
       font-size: 38px!important;
      color: #222;
      font-family: 'Gotham-Medium' !important;
      line-height: 1.3;
      font-weight: 700;
      text-align: center;
      margin: 0 !important;
  }
  .sw-pricing-tables1{
   background-color: #25262A;
    border-radius: 8px;
    /* margin: 10px; */
   
  }
  .ultimate-icon {
    padding: 0px 0px 0px 0px;
    height: 50px;
    width: 50px;
    background-color: #1880dd;
    border-radius: 50%;
    color: #000000;
    text-align: center;
    font-size: 23px;
    line-height: 50px;
    margin-bottom: 5px;
    float: left;
    position: absolute;
    margin: 0 auto;
    margin-top: -57px;
    display: block;
    width: 100%;
    max-width: 50px;
    left: 0;
    right: 0;
    box-shadow: -2px 4px 19px -9px rgba(0, 0, 0, 0.65)
  
  }
  .ultimate-icon i{
    line-height: 47px;
  }
  .sw-pricing-tables2{
    border: 2px solid #1880dd;
    padding: 30px 0px 34px !important;
   -webkit-box-shadow: -2px 12px 22px -9px rgba(0,0,0,0.33);
    -moz-box-shadow: -2px 12px 22px -9px rgba(0,0,0,0.75);
    box-shadow: -2px 12px 22px -9px rgba(0, 0, 0, 0.33);
    float: left;
  }
  .sw-pricing-tables-head {
      font-family: 'poppins';
      font-size: 28px;
      font-weight: 400;
      padding-top: 10px;
      padding-bottom: 10px;
      color: #fff;
  }
  .pr-content{
    margin-bottom: 0px !important;
  }
  /* .sw-pricing-table-text {
    font-family: 'Bariol' !important;
    color: #000000;
  } */
  .sw-pricing-tables-box {
      -webkit-box-shadow: -2px 12px 22px -9px rgba(0,0,0,0.75);
      -moz-box-shadow: -2px 12px 22px -9px rgba(0,0,0,0.75);
      box-shadow: -2px 12px 22px -9px rgba(0,0,0,0.75);
      display: block;
      padding: 34px;
      width: 71%;
      margin: 0 auto;
      margin-top: -34px;
      display: inline-block;
      background: #fff;
      border-radius: 8px;
  }
  .sw-pricing-tables-section {
      background-color: #70C78E;
      border-radius: 10px 10px 0px 0px;
      text-align: center;
  }
  .sw-pricing-table-digit {
      font-size: 50px;
      font-family: 'Gotham-Medium';
  }
  .sw-pricing-table-smalltext {
      font-size: 17px;
      font-family: Bariol;
      font-weight: bold;
  }
  .sw-pricing-table-pricesign{
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    position: relative;
    font-family: 'poppins';
  }
  .pr-amount{
    font-family: 'poppins';
    font-size: 50px;
    color: #fff;
    font-weight: 600;
  }
  .sw-pricing-table-strike{
        text-decoration: line-through;
      text-decoration-color: #161616;
      font-size: 22px;
      padding-top: 22px;
      padding-bottom: 22px;
      color: #161616;
      font-family: 'Bariol' !important;
  }
  .sw-pricing-btn {
      width: 80%;
      margin: 0 auto;
      background: #1880dd;
      display: block;
      padding: 10px 30px;
      color: #fff;
      font-family: Bariol;
      font-weight: bold;
      border-radius: 7px;
      font-size: 19px;
      text-align: center;
  }
  .sw-pricing-btn:hover {
      transition: 1s;
      color: #1880dd;
      border: 1px solid #1880dd;
      text-decoration: none;
      background: transparent;
  }
  .sw-pricing-requirement{
    color: #fff;
    font-family: 'poppins' !important;
    font-size: 18px !important;
    font-weight: 300;
    text-align: center;
    margin: 10px;
  }
  .sw-pricing-line{
    border: 1px solid #ffffffa1;
    width: 80%;
    margin: 25px auto;
  }
  .requirement-list {
      width: 90%;
      margin: 0 auto;
      text-align: left;
  }
  .requirement-list ul li{
    color: #fff;
    font-family: 'poppins' !important;
    font-size: 15px;
    font-weight: 300;
    line-height: 2.2em;
    list-style: none;
  }
  .fa-check{
     color: #fbc504;
  }
  /* .svg-inline--fa{
    margin-right: 20px;
  } */
  .section_seprator_40{
    height: 40px;
    width: 100%;
  }
  .requirement-list i {
      width: 8%;
  }
  .requirement-list i.fas.fa-check {
      color: #2dc824;
  }
  .requirement-list i.fas.fa-times {
     /* font-size: 26px;*/
      color: #c00404;
  }
  .sw-pricing-tables-section-ultimate{
    background-color: #1880dd;
    margin-bottom: 36px;
    text-align: center;
  }
  .sw-pricing-btn1{
   width: 80%;
    margin: 0 auto;
    background: #1880dd;
    display: block;
    padding: 10px 30px;
    color: #fff;
    font-family: Bariol;
      font-weight: bold;
    border-radius: 7px;
    font-size: 19px;
    text-align: center;
  }
  .sw-pricing-btn1:hover{
    transition: 1s;
    color: #1880dd;
    border: 1px solid #1880dd;
    text-decoration: none;
    background: transparent;
  }
  .subscription-hr {
      border: 1px solid #868181;
      margin: 0 auto;
  }
  .monthly-subscription {
      font-size: 31px !important;
      font-family: Bariol;
      font-weight: bold;
      width: 100%;
      text-align: left;
      padding-bottom: 25px;
      border-bottom: 2px solid #868181;
  }
  .annual-subscription{
      font-size: 31px !important;
      font-family: Bariol;
      font-weight: bold;
      width: 100%;
      text-align: left;
  
  }
  .subscription-radiospan {
      font-size: 16px !important;
      color: #fff;
      font-weight: 300;
      font-family: 'poppins' !important;
      display: block;
      text-align: left;
       padding-top: 14px;
      line-height: 1.6em;
  }
  .subscriptioncol1 {
    float: left;
    flex-direction: column;
    align-self: center;
  }
  .ssubscriptioncol2 {
    float: left;
  }
  .subscriptiondiv .btn-group{
      display: block;
  }
  .sw-subciption-text{
      font-size: 30px;
      font-family: 'poppins' !important;
      color: #fbc504;
      text-align: left;
      padding-left: 24px;
  }
  .sw-subciption-text-save{
      font-size: 20px;
      color: #fff;
      font-weight: 500;
      font-family: 'poppins' !important;
      text-align: left;
  }
  .subcription-list li, .subcription-list-1 li {
    font-family: 'poppins' !important;
    font-size: 15px;
    color: #fff;
    font-weight: 300;
    line-height: 2.2em;
      list-style: none;
  }
  .subscriptioncol2{
        padding-left: 146px;
  }
  ul.subcription-list,ul.subcription-list-1 {
      text-align: left;
      padding-left:26px;
  }
  .subcription-list i.fas.fa-check {
    color: #2dc824;
  }
  .subcription-list i.fas.fa-times{
  color:#c20d0d;
  }
  .subcription-list i {
    width: 7%;
  }
  ul.subcription-list-1 {
  text-align: left;
  }
  .subcription-list-1 i.fas.fa-check {
  color: #2dc824;
  }
  .subcription-list-1 i {
    width: 7%;
  }
  #monthly-subscription {
    margin-right: 11px;
  }
  #annual-subscription {
    margin-right: 11px;
  }
  #faqs #accordion {
    width: 55%;
    margin: 0 auto;
  }
  .card-position{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #faqs .panel-title{
    color: #fff;
    font-family: 'poppins' !important;
    font-size: 15px !important;
    font-weight: 400;
    text-transform: none !important;
    margin-bottom: 0px !important;
  }
  #faqs a:hover {
    text-decoration: none;
  }
  #faqs .card-body{
    border-top: 1px solid #2a2a2a;
    background-color: #393939;
    color: #ffffff;
    font-family: 'poppins' !important;
    font-size: 15px !important;
    font-weight: 300;
    padding: 10px 18px 25px 18px;
    border-radius: 0px 0px 5px 5px;
  }
  #faqs .card{
    border: 1px solid rgb(255, 255, 255);
  }
  .card-bg{
    background-color: #161616;
    margin-top: 25px;
  }
  #faqs .card-header{
    background-color: #393939;
    padding: 23px 18px;
  }
  #faqs i {
    float: right;
  }
  #faqs .panel-default {
    border-color: #222;
    border: none;
    border-bottom: 1px solid;
  }
  .panel-heading .accordion-toggle:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content: "\e114";    /* adjust as needed, taken from bootstrap.css */
    float: right;        /* adjust as needed */
    color: grey;         /* adjust as needed */
  }
  .panel-heading .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\e080";    /* adjust as needed, taken from bootstrap.css */
  }
  .schedule-a-Call-bg{
    background-color: #7D69FF;
  }
  .btn-space{
    margin: 0px 20px 0px 20px;
  }
  #schedule-a-Call .row {
    margin-bottom: 0px;
  }
  .sw-pricing-div {
    text-align: center;
  }
  .sw-question-head{
      padding-bottom: 12px;
      font-size: 38px!important;
      color: #222;
      font-family: 'Gotham-Medium' !important;
      line-height: 56px;
      font-weight: 700;
      text-align: center;
  }
  .call-btn {
    margin-left: 49px;
  }
  .chat-btn {
    background-color: transparent !important;
    border-color: #ffffff !important;
    font-size: 19px !IMPORTANT;
    font-family: Bariol;
      font-weight: bold;
    padding: 10px 42px !important;
  }
  .call-btn {
    font-size: 19px !IMPORTANT;
    font-family: Bariol;
    font-weight: bold;
    padding: 10px 42px !important;
  }
  .newClass{
    background : red;
    color: #fff;
  }
  /*sticky header position*/
  .sticky.is-sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    z-index: 1;
    width: 100%;
  }

  .radio-box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .radio-box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 9px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #868181;
    border-radius: 50%;
  }
  .radio-box:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .radio-box input:checked ~ .checkmark {
    background-color: #1880dd;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .radio-box input:checked ~ .checkmark:after {
    display: block;
  }
  .radio-box .checkmark:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }
  span.ultimate-icon {
    display: none;
  }
  @media only screen and (max-width: 1600px) and (min-width: 1200px){
    .plan-card{
      display:flex;
      flex-wrap: nowrap;
    }
    .sw-pricing-heading,.sw-pricing-subheading,.sw-question-head {
      font-size: 28px!important;
    }
    .sw-pricing-text,#faqs .card-body,.sw-pricing-requirement {
      font-size: 20px;
    }
    .sw-pricing-tables-head,.sw-subciption-text,.monthly-subscription,.annual-subscription {
      font-size: 24px !important;
    }
    .monthly-subscription {
      padding-top: 0 !important;
  }
  .sw-question-head{
    line-height:40px !important;
  }
  }
  @media only screen and (max-width: 1024px) and (min-width: 768px){
    .plan-card{
      display:flex;
      flex-wrap: nowrap;
    }
  .sw-pricing-heading,.sw-pricing-subheading,.sw-subciption-text,.monthly-subscription,.annual-subscription{
        font-size: 24px!important;
  }
  .sw-question-head{
      font-size: 24px!important;
      line-height: 1.3;
  }
  .section_seprator {
      width: 100%;
      height: 40px;
  }
  .mb-col-sep{
    width: 100% !important;
    height: 40px!important;
  }
  .sw-pricing-text,.sw-pricing-requirement {
      font-size: 18px;
  }
  .requirement-list ul li{
    font-size: 14px;
  }
  .subcription-list li,.subcription-list-1 li,.subscription-radiospan {
    font-size: 14px !important;
  }
  #faqs .card-body,#faqs .panel-title {
      font-size: 18px;
  }
  .sw-pricing-tables-head {
      font-size: 22px;
  }
  .requirement-list {
      width: 95%;
  }
  .sw-pricing-tables-box{
    width: 100%;
  }
  a.sw_pricing_button {
      font-size: 16px;
  }
  .navbar-toggler:not(:disabled):not(.disabled) {
    background-color: #ffffff;
  }
  .navbar-light .navbar-toggler-icon {
  background-color: #ffffff;
  }
  .ps-pricing-nav nav ul li a {
  padding: 7px 8px;
  font-size: 0.7rem;
  }
  nav#menu-center {
    display: none;
  }
  ul.subcription-list-1 li {
    font-size: 14px !important;
  }
  }
  @media only screen and (max-width: 767px) and (min-width: 280px){
    .sw_home_succeed{
      display: inline-block;
    }
    #faqs .card-header{
      padding: 15px 15px;
    }
    .sw-subciption-text{
      margin-bottom: 10px;
      margin-top: 20px;
    }
    .subscription-radiospan{
      font-size: 12px !important;
    }
    .subcription-head{
      font-size: 18px;
    }
    .plan-card{
      display: flex;
      flex-wrap: wrap;
    }
  .section_seprator {
      width: 100% !important;
      height: 30px!important;
  }
  .monthly-subscription {
      padding-top: 0;
  }
  .sw-pricing-heading,.sw-question-head{
    font-size: 22px !important;
    line-height: 1.3
  }
  .sw-pricing-text {
      font-size: 18px!important;
      line-height: 1.2!important;
  }
  .sw-pricing-text br{
    display: none;
  }
  .sw-pricing-subheading {
      font-size: 22px !important;
      margin-bottom: 0px !important;
  }
  .sw-pricing-tables-head {
      font-size: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
  }
  ul.subcription-list,ul.subcription-list-1 {
      padding-left: 0;
  }
  /* div#headingOne1 {
    padding-top: 0 !important;
  } */
  .subcription-list li,.subcription-list-1 li,.requirement-list ul li {
      font-size: 14px;
  }
  a.sw_pricing_button {
      display: block !important;
      margin: 10px 0 !important;
      font-size: 16px;
  }
  .sw-pricing-requirement {
      font-size: 18px;
  }
  .mb-col-sep{
    width:40px;
    height: 40px;
  }
  .sw-pricing-tables2 {
    margin-top: 40px !important;
  }
  .ultimate-icon {
    margin-top: -17%;
  }
  .requirement-list ul {
    padding-left: 0 !important;
  }
  .ps-pricing-nav nav {
    z-index: 9;
  }
  .navbar-preheader .navbar-toggle:hover .icon-bar {
  background-color: #4d4d4d;
  }
  .navbar-toggler:not(:disabled):not(.disabled){
  background-color: #ffffff;
  }
  .navbar-light .navbar-toggler-icon{
  background-color: #ffffff;
  }
  .ps-pricing-nav li {
  display: block;
  margin: 0 0px;
  width: 100%;
  }
  .psnav-item.active .psnav-link {
  width: 39%;
  margin: 0 auto; 
  }
  .nav-col-div{
  height: 0px;
  }
  .sw-pricing-table-pricesign {
    font-size: 30px !important;
    font-weight: 300;
  }
  .monthly-subscription,.annual-subscription {
    font-size: 18px !important;
    padding-top: 6px !important;
  }
  .sw-subciption-text {
    font-size: 18px !important;
    padding-left: 0;
  }
  .sw-subciption-text-save {
      font-size: 14px;
  }
  #faqs .card-body {
    font-size: 14px !important;
  }
  #faqs .panel-title {
    font-size: 14px !important;
    font-weight: 400;
  }
  .sw-pricing-table-pricesign {
    bottom: 10px !important;
  }
  }
  @media only screen and (max-width: 1024px) and (min-width: 280px){
  nav#menu-center {
    display: none;
  }
  .sticky-wrapper {
    height: 0 !important;
  }
  }

  @media (min-width: 320px) and (max-width: 480px) {
  .second_head {
    font-size: 22px !important;
  }
    }

  @media only screen and (max-width: 780px) and (min-width: 280px){

    }