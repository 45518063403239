#sw-ft-nav {
    padding: 60px 0px;
    background: #141414 ;
    display: block;
  }

  #sw-ft-cr{
    padding: 20px 0px;
    background: fixed;
    background-color: #000000;
  }
  .social-footer{
    list-style: none;
    display: inline;
  }
  .icon-siz-footer{
    color: #fff;
    font-size: 25px;
    margin: 10px 20px 5px 0px;
  }
  .icon-siz-footer:hover{
    color:#fbc504;
  }
  .sw-ft-col a {
      display: block;
  }
  h3.sw-ft-title {
      color: white;
      font-size: 22px;
      font-family: 'Poppins';
      font-weight: "500";
  }
  span.sw-ft-anch {
      color: rgb(189, 189, 189);
      font-size: 18px;
      font-family: 'Poppins';
      line-height: 2.0;
      text-decoration:none;
      font-weight: "400";
      outline:none;
  }
  span.sw-ft-anch:hover{
    color: #fbc504;
    text-decoration: none;
  }
  p.sw-ft-nav-text {
      color: white;
      font-size: 16px;
      font-family: 'Poppins' !important;
      line-height: 1.5;
      margin: 0;
  }
  .sw-ft-sep{
    width: 100%;
    height: 30px;
  }
  ul.social_icons_list li {
    display: inline-block;
    padding-right: px;
  }
  ul.social_icons_list {
    padding-left: 0 !important;
    color: #fff;
    font-size: 30px;
    padding-top: 15px;
  }
  ul.social_icons_list li a {
    color: #fff;
  }
  @media only screen and (max-width: 1024px) and (min-width:768px) {
    .footer-logo{
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) and (min-width:280px) {
    .footer-logo{
      width: 60% !important;
    }
  h3.sw-ft-title {
    padding-top: 30px;
    margin: 0;
  }
  }
  @media only screen and (max-width: 1024px) and (min-width:280px) {
 
  #sw-ft-nav .container {
  width: 100% !important;
  max-width: 100% !important;
  }
  
  h3.sw-ft-title {
      font-size: 16px;
  }

  #sw-ft-nav {
      padding: 50px 0px !important;
  }

  span.sw-ft-anch {
    font-size: 14px !important;
        margin: 10px 0 !important;
  }

  p.sw-ft-nav-text,h3.sw-ft-title {
    font-size: 14px !important;
  }

  }