
body {
 
  
  font-family: "Exo", sans-serif;
 
}
.step-count{
  color: #ffffff;
  margin: auto auto;
}
.step-description{
  font-size: 14px;
}


.progress-bar {
 
  text-align: center;
  width: 97%;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(20px, 2fr)); */
  grid-template-columns: 20px 20px auto;
  background-color: transparent !important;
  justify-content: space-between;
}

.progress-step {
  text-align: center;
  position: relative;
}
.progress-step:before,
.progress-step:after {
  background-color: #FBC504;
  content: "";
  height: 8px;
  position: absolute;
  color: #ffffff !important;
  z-index: -1;
  top: 30px;
  
}
#Domain{
  width: fit-content;

}
.progress-step:after {
  left: 50%;
  width: 50%;
  
  font-weight: 550 !important;
  
}

.progress-step{
  z-index: 999;
}

.progress-step:last-of-type.is-active:after {
  background-color: #FBC504;
  color: #000000 !important;
}

.progress-step:first-of-type:before {
  width: 30rem !important;
}

@media screen and (min-width: 640px) {
  .progress-step:first-of-type:before {
    right: 50%;
    width: 50%;
  }
  
}
@media screen and (max-width: 640px) {
  .progress-step:first-of-type:before {
    right: 50%;
    width: 50%;
  }
  .progress-step:last-of-type:after {
    
    background-color: white !important;
  }
}
.progress-step:after {
  left: 0% !important;
  width: 20rem;
  font-weight: bold !important;
}

.progress-step:last-of-type:after {
  left: 0%;
  width: 10%;
}
.progress-step .step-count:before {
display: none;
}
#material::after{
left: 0px !important;
}
#Domain{
  right: 0px !important;

}
.progress-step .step-count {
  height: 62px;
  background-color: #FBC504;
  width: 62px;
  border-radius: 50%;
  color: white;
  line-height: 25px;
  align-items: center;
  z-index: 100;
  border: 7px solid #ffffff;
  font-size: 0.8rem;
  font-weight: bold;
  font-family: poppins;
  
}
.progress-step .step-count:before {
  counter-increment: step-count;
  content: counter(step-count);
}
.progress-step.is-active .step-description {
  
  font-weight: 550 !important;
  

}
.progress-step.is-active:after {
  background-color: #D9D9D9;
}
.progress-step.is-active ~ .progress-step .step-count {
  background-color: #D9D9D9;
}
.progress-step.is-active ~ .progress-step:after {
  background-color: #D9D9D9;
  /* width: 0; */
}

.step-description {
    color: black !important;
}





.progress-container {
  width: 97.5%;
  height: 5px;
  background-color: #ccc;
  border-radius: 20px !important; 
}

.progress-bar-bottom {
  height: 100%;
  background-color: #FBC504;
  width: 0;
  transition: width 0.3s ease-in-out;
  border-radius: 20px !important; 

}
/* Rest of your CSS styles */
.progressbar-BOT{
  
  width: 90%;
  margin: 20px auto;
  border-radius: 20px !important; 
 
}

@media (min-width: 768px) and (max-width: 1024px)  {
  .step-description{
    display: none !important;
   }
  /* CSS */
  
}

@media (min-width: 481px) and (max-width: 767px) {
  
  /* CSS */
  .step-description{
    display: none !important;
   } 
   


}


@media (min-width: 320px) and (max-width: 480px) {
 .step-description{
  display: none !important;
 }


  .category_card{
      display: flex;
      justify-content: center;
  }
}
