@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,800);
You can add global styles to this file, and also import other style files
/* RMI - Bootstrap Customized Css */
body {
    font-family: 'Poppins';
          
}


.container-fluid {border-bottom: 1px solid #ddd;}
@media (min-width: 1200px) { .container {max-width: 1200px;} }

/* RMI - General Classes */
/* .sw-navbar{padding: 0px 40px;} */
.sw-navbar {padding: 0px 10vw;}
.sw-brand-logo{display: inline-block;}
.sw-brand-logo img{width: 250px;}
.sw-nav-actions {padding: 18px 0px;}
/* .sw-nav-actions .sw-livechat{background:#161616;color:#fff;font-family:Bariol-Bold;font-size:18px;padding:8px 10px;width:244px;display:inline-block;text-align:center;border-radius:35px;}
.sw-nav-actions .sw-phone{background:#161616;color:#fff;font-family:Bariol-Bold;font-size:18px;padding:8px 10px;width:244px;display:inline-block;text-align:center;border-radius:35px} */
.sw-nav-actions .sw-phone, .sw-nav-actions .sw-livechat, .sw-nav-actions .sw-faqs{
    background: #ffffff;
    color: #161616;
    font-family: 'Bariol-Bold';
    font-size: 18px;
    padding: 8px 10px;
    width: 215px;
    display: inline-block;
    text-align: center;
    border-radius: 35px;
    border: 1px solid #a0a0a0;
}
.sw-nav-actions .sw-livechat, .sw-nav-actions .sw-faqs {
    margin-right: 15px;
}
.sw-nav-actions .sw-livechat i{ 
    padding-right: 6px;
    font-weight: 400 !important;
}
.sw-nav-actions .sw-phone i{ 
    padding-right: 6px;
    font-weight: 400 !important;
}
.sw-nav-actions .sw-faqs i{ 
    padding-right: 6px;
    font-weight: 400 !important;
}
.sw-nav-actions .sw-phone:hover, .sw-nav-actions .sw-livechat:hover, .sw-nav-actions .sw-faqs:hover {
    background: #fbc504;
    color: #131212;
    text-decoration: none!important;
}

.heading-step {
    font-family: 'poppins';
    font-size: 40px;
    font-weight: 400;
}

.apple-os .heading-step {
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 30px;
}

.heading-subtext {
    font-family: 'Poppins' !important;
    font-size: 22px;
    color: #6c6c6c;
}

/* RMI - Wizard Timeline - S */
.sw-wizard {
    margin: 40px 0;
 }

.sw-timeline {
    margin: 0 auto;
    text-align: center;
}

.sw-timeline .step {
    display: inline-block;
    padding: 14px 24px;
    border-bottom: 4px solid #131313;
    margin: 0px 6px;
    width: 170px;
    vertical-align: top;
    min-height: 85px;
}

.sw-timeline .step.active {
    border-bottom: 4px solid #fbc504;
    position: relative;
}

.sw-timeline .step.completed {
    border-bottom: 4px solid #fbc504;
}

.sw-timeline .step.active.completed {
    border-bottom: 4px solid #e7b603;
}
.learn-more{
    color: #21bdbb;
}

.sw-timeline .step.completed:hover {
    cursor: pointer;
}

.sw-timeline .step.completed .step-title {
    color: #212121;
    font-family: 'poppins';
    font-weight: 400;
    font-size: 16px;
}

.sw-timeline .step .step-title {
    font-size: 13px;
    font-family: 'poppins';
    line-height: 1.2;
    color: #646464;
    vertical-align: -webkit-baseline-middle;
}

.sw-timeline .step.active .step-title {
    font-size: 16px;
    font-family: 'poppins';
    color: #000000;
    font-weight: 600;
}

.apple-os .sw-timeline .step.active .step-title {
    font-weight: normal;
}

.sw-timeline .step.active:after {
    content: "\f0de";
    font-family: 'Font Awesome 5 Free';
    position: absolute;
    bottom: 0;
    font-size: 22px;
    height: 14px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #21bdbb;
}

/* RMI - Temp Timeline - E */

@font-face {
    font-family: 'Gotham-Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Light'), 
    url(/static/media/GothamLight.6debfb6e.woff) format('woff'),
    url(/static/media/GothamLight.b1103438.eot),
    url(/static/media/GothamLight.b1103438.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamLight.03e7ff60.ttf) format('truetype'),
    url(/static/media/GothamLight.35bf7b57.svg#Gotham-Light) format('svg'),
    url(/static/media/GothamLight.9cea7310.otf) format('opentype');
}

@font-face {
    font-family: 'Gotham-Book';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Book'), 
    url(/static/media/Gotham-Book.65288315.woff) format('woff'),
    url(/static/media/Gotham-Book.7fe2d669.eot),
    url(/static/media/Gotham-Book.7fe2d669.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Gotham-Book.79ab7007.ttf) format('truetype'),
    url(/static/media/Gotham-Book.92032bfd.svg#Gotham-Book) format('svg'),
    url(/static/media/Gotham-Book.e37cf599.otf) format('opentype');   
}

@font-face {
    font-family: 'Gotham-Black';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Black'), url(/static/media/Gotham-Black.d979fc88.woff) format('woff');
}

@font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url(/static/media/GothamMedium.d23ef85d.woff) format('woff'),
    url(/static/media/GothamMedium.de228587.woff2) format('woff2'),
    url(/static/media/GothamMedium.ed646164.eot),
    url(/static/media/GothamMedium.ed646164.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamMedium.c3f574b2.ttf) format('truetype'),
    url(/static/media/GothamMedium.9faf290f.svg#Gotham-Book) format('svg'),
    url(/static/media/GothamMedium.bcd733e6.otf) format('opentype'); 
}

@font-face {
    font-family: 'Bariol-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Regular'), 
    url(/static/media/Bariol-Regular.38937286.woff) format('woff'),
    url(/static/media/Bariol-Regular.e4213a39.eot),
    url(/static/media/Bariol-Regular.e4213a39.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Bariol-Regular.6c235190.ttf) format('truetype'),
    url(/static/media/Bariol-Regular.7d0cf79e.svg#Bariol-Regular) format('svg'),
    url(/static/media/Bariol-Regular.6a64a521.otf) format('opentype');
}

@font-face {
    font-family: 'Bariol-Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Thin'), url(/static/media/Bariol-Thin.63c5fefa.woff) format('woff');
}

@font-face {
    font-family: 'Bariol-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Bold'), 
    url(/static/media/Bariol-Bold.bc734d95.woff) format('woff'),
    url(/static/media/Bariol-Bold.d458cb75.eot),
    url(/static/media/Bariol-Bold.d458cb75.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Bariol-Bold.fa0ddc94.ttf) format('truetype'),
    url(/static/media/Bariol-Bold.0ed2ea7a.svg#Bariol-Regular) format('svg');
}


/* RMI - Screen loader spinner */
input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.8;
    /* filter: invert(0.8); */
    color: #fff;
  }
  input[type=date].form-control, input[type=datetime-local].form-control, input[type=month].form-control, input[type=time].form-control{
    color: #ACB9AC;
  }
  input[type=date].form-control, input[type=datetime-local].form-control, input[type=month].form-control, input[type=time].form-control::-webkit-calendar-picker-indicator{
      -webkit-filter: contrast(0.7);
              filter: contrast(0.7);
  }
  .sw-schedule-call-formdiv .form-control {
    color: #ACB9AC;
  }
.screen-overlay {
    display: block;
    /* position: absolute; */
    position: fixed;
    z-index: 100;
    background-color: #fff;
    opacity: 0.97;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    /* height: 100%; */
    height: 120vh;
}

.screen-loader {
    position: -webkit-sticky;
    position: sticky;
    left: 50%;
    top: 50%;
    height: 60px;
    width: 60px;
    margin: 0 auto;
    -webkit-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear;
    border-left: 6px solid rgba(128, 195, 194,.15);
    border-right: 6px solid rgba(128, 195, 194,.15);
    border-bottom: 6px solid rgba(128, 195, 194,.15);
    border-top: 6px solid rgba(33, 189, 187,.8);

    border-radius: 100%;
    margin-top: 50vh;
}

.noScroll {
    overflow: hidden;
}

/* RMI - Page loader - S */
.page-loader .screen-overlay {
    margin: 0 0;
}
@-webkit-keyframes rotation {
from {-webkit-transform: rotate(0deg);}
to {-webkit-transform: rotate(359deg);}
}
@keyframes rotation {
from {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
to {-webkit-transform: rotate(359deg);transform: rotate(359deg);}
}
/* RMI - Page loader - E */

/* Stylish Check and Radion Button Css - START */
input[type=radio].with-font,
input[type=checkbox].with-font {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
    
input[type=radio].with-font ~ label:before,
input[type=checkbox].with-font ~ label:before {
    font-family: FontAwesome;
    display: inline-block;
    content: "\f1db";
    letter-spacing: 15px;
    font-size: 1.2em;
    color: #535353;
    width: 1.2em;
}

input[type=radio].with-font:checked ~ label:before,
input[type=checkbox].with-font:checked ~ label:before  {
    content: "\f192";
    font-size: 1.2em;
    color: #333333;
    letter-spacing: 15px;
}
input[type=checkbox].with-font ~ label:before {        
    content: "\f096";
}
input[type=checkbox].with-font:checked ~ label:before {
    content: "\f046";        
    color: #333333;
}
input[type=radio].with-font:focus ~ label:before,
input[type=checkbox].with-font:focus ~ label:before,
input[type=radio].with-font:focus ~ label,
input[type=checkbox].with-font:focus ~ label
{                
    color: #333333;
}
/* Stylish Check and Radion Button Css - END */

/* RMI - General Button - Next and Previous - S */
.general-footer-actions {
    padding-bottom: 50px !important;
}
.general-footer-actions .step-previous {
    border:0;
    border-bottom: 2px solid #524940;
    width: 180px; 
    padding: 4px 6px;
    background: transparent;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    margin-right: 28px !important;
    text-transform: uppercase;
    border-radius: 0 !important;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.general-footer-actions .step-previous:focus {
    box-shadow: none!important;
}
.general-footer-actions .step-previous i { 
    color: #21bdbb;
    margin-right: 10px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.general-footer-actions .step-previous:hover i { 
    margin-right: 20px;
}
.general-footer-actions .step-next {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    border: 2px solid #21bdbb;
    width: 180px; 
    padding: 4px 6px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.general-footer-actions .step-next:hover {
    background: #21bdbb;border: 2px solid #21bdbb;
}
.general-footer-actions .step-next:focus {
    box-shadow: none!important;
}
.general-footer-actions .step-next i {    
    opacity: 0;
    visibility: hidden;
    margin-left: -15px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.general-footer-actions .step-next:hover i {  
    margin-left: 10px;
    opacity: 1;
    visibility: visible;  
}
/* RMI - General Button - Next and Previous - E */

.footer-copyright {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    font-size: 14px;
    font-family: 'Gotham-Book';
    color: #6b655a;
}

/* RMI - Popover Styles - S */
.popover-body {
    text-align: center;
    padding: .5rem .75rem;
    color: #212529;
    font-weight: 600;
    font-family: 'Gotham-Book';
}



/* Media Queries - S */
@media screen and (max-width: 1280px) {
    .sw-navbar {
        padding: 0px 4vw;
    }

    .help-box .open-button{
        display: none;
    }
}
@media screen and (max-width: 1120px) {
    .sw-nav-actions .sw-faqs {
        display: none;
    }
}
@media screen and (max-width: 1024px) {
    .sw-navbar {
        padding: 0px 1vw;
    }    
    main.container { /* Temporary  */
        max-width: 100%;
    }
    .sw-timeline .step {
        width: 140px;
        padding: 14px 0px;
    }
}
@media screen and (max-width: 820px) {

    .sw-nav-actions .sw-livechat {
        display: none;
    }

    /* .sw-timeline {margin: 0 2vw;} */
    .sw-timeline .step {display: none;}
    .sw-timeline .step.active .step-title {font-size: 28px;}
    .sw-timeline .step.active {
        display: block;
        text-align: left;
        width: 100%;
        border: none;
    }
    .sw-timeline .step.active:after {
        content: '';
        width: 60px;
        height: 3px;
        background: #21bdbb;
        margin: 0;
        left: 25px;
        bottom: 25px;
    }
    .sw-timeline .step:before {
        content: '1/5';
        font-size: 20px !important;
        font-weight: 500;
        /* position: absolute; */
        top: 10px;
        right: 20;
    }
    .sw-timeline .step.stp-2.active:before {content: '2/5';}
    .sw-timeline .step.stp-3.active:before {content: '3/5';}
    .sw-timeline .step.stp-4.active:before {content: '4/5';}
    .sw-timeline .step.stp-5.active:before {content: '5/5';}

    
}
@media screen and (max-width: 767px) {
  
    .sw-wizard {
        margin: 20px 0;
        margin-bottom: 40px;
    }

    .sw-timeline .step.active:after {
        background: transparent;
    }
    
    .sw-timeline .step.active {
        border-bottom: 4px solid #fbc504;
    }
    
    .pg-step-heading {
        display: none;
    }

    .general-footer-actions .float-right {
        float: none !important;
        margin: 0 auto;
        text-align:center;
    }

    .general-footer-actions .btn-step {
        width: 90%;
        padding: 4px 2px;
        /* margin: 0 auto !important; */
    }        
}

@media screen and (max-width: 600px) {
    .sw-navbar .logo-section {
        padding: .9rem!important;
    }
    .sw-brand-logo img {
        width: 220px;
    }
    .sw-nav-actions .sw-phone, .sw-nav-actions .sw-livechat {
        width: 194px;
    }
}
@media screen and (max-width: 510px) {
    .sw-brand-logo img {
        width: 200px;
    }
    .sw-nav-actions .sw-phone {
        width: 165px;
        margin-right: 0;
    }
}
@media screen and (max-width: 480px) {
    .sw-brand-logo img {
        width: 200px;
    }
}
@media screen and (max-width: 445px) {
    header.container-fluid {
        padding: 0px;
    }
    .sw-brand-logo img {
        width: 180px;
    }
    .sw-navbar .logo-section {
        padding: 1rem!important;
    }
    .sw-navbar .note-section {
        display: none;
    }
    .sw-nav-actions .sw-phone {
        font-size: 15px;
    }
}
@media screen and (max-width: 400px) {
    .sw-navbar .support-section {
        display: none;
    }
    .sw-timeline .step.active .step-title, .sw-timeline .step:before {
        font-size: 24px;
    }
    
    .sw-timeline .step {
        min-height: 70px;
    }
}
@media screen and (max-width: 370px) {
    .sw-navbar .logo-section {
        padding: .5rem!important;
    }
    .sw-nav-actions {
        padding: 10px 0px;
    }
    .sw-timeline .step.active .step-title, .sw-timeline .step:before {
        font-size: 20px;
    }
}
@media screen and (max-width: 320px) {
}
/* Media Queries - E */


.log-out{
    margin: 0 auto;
    margin-top: 28px;
}


.log-btn{
    background: #1880dd !important;
    color: #fff !important;
    border: 1px solid #1880dd;
    padding: 8px 35px;
    border-radius: 4px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none !important;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    margin-right: 10px !important;
}

/* width */
::-webkit-scrollbar{
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track{
    box-shadow: inset 0 0 0px rgb(36, 36, 36); 
   

  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb{
    background: #fbc504;
   
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fbc504; 

  }
  .load-color{
    color: #FBC504 !important;
  }

  /* ::-webkit-calendar-picker-indicator {
    filter: invert(1);
} */
/* ::-webkit-calendar-picker-indicator {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  }


 */

 input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
    -webkit-filter: invert(0.8);
            filter: invert(0.8);
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1
  }












.loader {
 position: relative;
 width: 2.5em;
 height: 2.5em;
 -webkit-transform: rotate(165deg);
         transform: rotate(165deg);
}

.loader:before, .loader:after {
 content: "";
 position: absolute;
 top: 50%;
 left: 50%;
 display: block;
 width: 0.5em;
 height: 0.5em;
 border-radius: 0.25em;
 -webkit-transform: translate(-50%, -50%);
         transform: translate(-50%, -50%);
}

.loader:before {
 -webkit-animation: before8 2s infinite;
         animation: before8 2s infinite;
}

.loader:after {
 -webkit-animation: after6 2s infinite;
         animation: after6 2s infinite;
}

@-webkit-keyframes before8 {
 0% {
  width: 0.5em;
  box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
 }

 35% {
  width: 2.5em;
  box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75), 0 0.5em rgba(111, 202, 220, 0.75);
 }

 70% {
  width: 0.5em;
  box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75), 1em 0.5em rgba(111, 202, 220, 0.75);
 }

 100% {
  box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
 }
}

@keyframes before8 {
 0% {
  width: 0.5em;
  box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
 }

 35% {
  width: 2.5em;
  box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75), 0 0.5em rgba(111, 202, 220, 0.75);
 }

 70% {
  width: 0.5em;
  box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75), 1em 0.5em rgba(111, 202, 220, 0.75);
 }

 100% {
  box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
 }
}

@-webkit-keyframes after6 {
 0% {
  height: 0.5em;
  box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
 }

 35% {
  height: 2.5em;
  box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75), -0.5em 0 rgba(233, 169, 32, 0.75);
 }

 70% {
  height: 0.5em;
  box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75), -0.5em 1em rgba(233, 169, 32, 0.75);
 }

 100% {
  box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
 }
}

@keyframes after6 {
 0% {
  height: 0.5em;
  box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
 }

 35% {
  height: 2.5em;
  box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75), -0.5em 0 rgba(233, 169, 32, 0.75);
 }

 70% {
  height: 0.5em;
  box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75), -0.5em 1em rgba(233, 169, 32, 0.75);
 }

 100% {
  box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
 }
}

.loader {
 position: absolute;
 top: calc(50% - 1.25em);
 left: calc(50% - 1.25em);
}


@media (min-width: 481px) and (max-width: 1024px) {
  
  
  
  
  }
  
  
  @media (min-width: 320px) and (max-width: 480px) {
  
  
    .loader {
        position: absolute;
        top: calc(70% - 1.25em);
        left: calc(50% - 1.25em);
       }
    
  }
  
.btn-logout{
    margin-top: 0px !important;
    align-self: center !important;
}
.back:hover{
   cursor: pointer;
    border-radius: 10px;
    background-color: #FBC504;
}

.btn-clr{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 50px;
    font-family: "Poppins" !important;
    font-weight: 400;
    font-size: 18px;
    min-width: 220px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    /* margin: 0 5px 0 5px !important; */
    text-shadow: none !important;
}
.btn-clr:hover{
    background: transparent !important;
    text-decoration: none!important;
    color: #030303!important;
    border-color: #020202!important;
}
.number{
    color: #131313;
}
.number:hover{
    color: #131313;
    text-decoration: none !important;
}
.center-nav{
    width: 650px !important;
    justify-content: flex-end !important;

}
.search::-webkit-input-placeholder {
    color: #808080;

    font-weight: 400;

    opacity: 1; /* Firefox */
  }
.search::placeholder {
    color: #808080;

    font-weight: 400;

    opacity: 1; /* Firefox */
  }
.search:focus{ 
 outline: none; 
    
}

@media only screen and (max-width:700px) {
    .center-nav{
        width: 400px !important;
        justify-content: center;
    
    }
}


@media only screen and  (min-width:1200px) {
    .center-nav{
        /* width: 700px !important; */
        justify-content: flex-end;
    
    }
}

@media screen and (min-width:950px) and (max-width:1250px) {
    .center-nav{
        width: auto !important;
        justify-content: center;
    
    }
}

@media screen and (min-width:480px) and (max-width:1024px) {
   
 .searchcol{
    display: none;
 } 
 .left{
   display: none;
 }
 .mobilesearch{
    display: none !important;
 }
}

@media screen and  (min-width:1025px) {
.tablets-mobile{
    display: none;
}
}

@media screen and (min-width:280px) and (max-width:480px) {
    .profile {
       height: 40px !important;
       width: 40px !important;
    }
     .profile_sec{
        padding-right: 0px !important;
     }
}

@media (min-width: 480px) and (max-width: 1024px) {
    .order {
        display: none !important;
    }
    
}
    @media (min-width: 320px) and (max-width: 480px) {
  
    .category_card{
        display: flex;
        justify-content: center;
    }
    .Search{
        display: none;
    }
    .left{
        display: none;
    }
    .main{

        padding-top: 0;
   
    }
    .profilemobile{
        
        padding-right:  0 !important;
        justify-content: end !important;
        flex-direction: column-reverse !important;
  
        
    }
    .cont{
        padding: 0 !important;
    }
    .main{

        padding-top: 0;;
   
    }
    .sidebar{
        display: none !important;
    }
    
  }




@media (min-width: 320px) and (max-width: 480px) {

    
    .category_card{
        display: flex;
        justify-content: center;
    }
    .Search{
        display: none;
    }
    .left{
        display: none;
    }
    .main{

        padding-top: 0;
   
    }
    .profilemobile{
        
        padding-right:  0 !important;
        justify-content: end !important;
        flex-direction: column-reverse !important;
  
        
    }
    .cont{
        padding: 0 !important;
    }
    .main{

        padding-top: 0;;
   
    }
    .sidebar{
        display: none !important;
    }
    
  }

@media (min-width: 320px) and (max-width: 480px) {
  
 .searchcol {
        display: none;
  }

.two{
    display: none !important;
}

.profileimg {
     width: 45px !important;
     height: 45px !important;
  }

.main{
    padding-left:0 !important;
}

.tranparent{
    
    
    margin-right: 10px !important ;
    padding: 0 !important;
    height: 45px !important;
    width: 45px !important;

}
.profilemobiledropdow{
left: -90px;
}
.mobile{
display: none !important;
}
.logoimg{
    width: 130px !important;

}
.noti{
    margin-left:35px !important;
    margin-top:-35px  !important;

   }
  }

.error-container {
  text-align: center;
  font-size: 180px;
  font-family: 'Catamaran', sans-serif;
  font-weight: 800;
  margin: 20px 15px;
}
.error-container > span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #FFB485;
}
.error-container > span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.error-container > span:nth-of-type(1) {
  color: #D1F2A5;
  -webkit-animation: colordancing 4s infinite;
          animation: colordancing 4s infinite;
}
.error-container > span:nth-of-type(3) {
  color: #F56991;
  -webkit-animation: colordancing2 4s infinite;
          animation: colordancing2 4s infinite;
}
.error-container > span:nth-of-type(2) {
  width: 120px;
  height: 120px;
  border-radius: 999px;
}
.error-container > span:nth-of-type(2):before,
.error-container > span:nth-of-type(2):after {
	border-radius: 0%;
	content:"";
	position: absolute;
	top: 0; left: 0;
	width: inherit; height: inherit;
  border-radius: 999px;
	box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  -webkit-animation: shadowsdancing 4s infinite;
          animation: shadowsdancing 4s infinite;
}
.error-container > span:nth-of-type(2):before {
	-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
}

.screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}
@-webkit-keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
				inset 0 30px 0 rgba(209, 242, 165, 0.4),
				inset -30px 0 0 rgba(239, 250, 180, 0.4),	
				inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
     box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
				inset 0 30px 0 rgba(245, 105, 145, 0.4),
				inset -30px 0 0 rgba(209, 242, 165, 0.4),	
				inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
   box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
				inset 0 30px 0 rgba(255, 196, 140, 0.4),
				inset -30px 0 0 rgba(245, 105, 145, 0.4),	
				inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}
@keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
				inset 0 30px 0 rgba(209, 242, 165, 0.4),
				inset -30px 0 0 rgba(239, 250, 180, 0.4),	
				inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
     box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
				inset 0 30px 0 rgba(245, 105, 145, 0.4),
				inset -30px 0 0 rgba(209, 242, 165, 0.4),	
				inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
   box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
				inset 0 30px 0 rgba(255, 196, 140, 0.4),
				inset -30px 0 0 rgba(245, 105, 145, 0.4),	
				inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}
@-webkit-keyframes colordancing {
  0% {
    color: #D1F2A5;
  }
  25% {
    color: #F56991;
  }
  50% {
    color: #FFC48C;
  }
  75% {
    color: #EFFAB4;
  }
  100% {
    color: #D1F2A5;
  }
}
@keyframes colordancing {
  0% {
    color: #D1F2A5;
  }
  25% {
    color: #F56991;
  }
  50% {
    color: #FFC48C;
  }
  75% {
    color: #EFFAB4;
  }
  100% {
    color: #D1F2A5;
  }
}
@-webkit-keyframes colordancing2 {
  0% {
    color: #FFC48C;
  }
  25% {
    color: #EFFAB4;
  }
  50% {
    color: #D1F2A5;
  }
  75% {
    color: #F56991;
  }
  100% {
    color: #FFC48C;
  }
}
@keyframes colordancing2 {
  0% {
    color: #FFC48C;
  }
  25% {
    color: #EFFAB4;
  }
  50% {
    color: #D1F2A5;
  }
  75% {
    color: #F56991;
  }
  100% {
    color: #FFC48C;
  }
}

/* demo stuff */
* {
    box-sizing: border-box;
}
body {
  background-color: #131313;
  margin-bottom: 50px;
}
html, button, input, select, textarea {
    font-family: 'Montserrat', Helvetica, sans-serif;
    color: #92a4ad;
}
h1 {
  text-align: center;
  margin: 30px 15px;
}
.zoom-area { 
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}
.link-container {
  text-align: center;
}
a.more-link {
  text-transform: uppercase;
  font-size: 13px;
    background-color: #92a4ad;
    padding: 10px 15px;
    border-radius: 0;
    color: #416475;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
  margin-top: 50px;
  letter-spacing: 1px;
}
/* css start */
.blog-category{
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 400;
    color: #fff;
}

.blog-heading{
    font-family: 'Poppins';
    font-size: 25px;
    font-weight: 600;
    color: #fff;
}
.blog-heading:hover{
    color: #FBC504;
    text-decoration: none;
}
.blog-details{
    font-size: 14px;
    font-family: 'Poppins' !important;
    color: #fff;
    font-weight: 300;
}
.img-heigh{
    height: 100%;
}
.l-partner{
    overflow: hidden;
}
.l-partner:hover img {
    -webkit-transform: scale(1.09);
            transform: scale(1.09);
    transition: .3s;
    -webkit-filter: opacity(0.2);
            filter: opacity(0.2);
}
.l-partner img {
    transition: .3s;
}
.l-partner:hover .btn_display{
    display: block;
}
.btn_display{
    position: absolute;
    display: none;
}
.pos1{
    top: 27%;
}
.pos2{
    top: 72%;
    right: 32%;
}
.pos3{
    top: 45%;
    right: 32%;
}
.logistics-btn{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 15px 30px;
    border-radius: 50px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    text-decoration: none !important;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
}
.process-flow{
    background-color: #131313;
}
.process-bg{
    padding: 30px;
    border-radius: 8px;
    height: 350px;
}
.clrbg-1{
background-color: #7D69FF;
}
.clrbg-2{
background-color: #70C78E;
}
.clrbg-3{
background-color: #BC5ADF;
}
.clrbg-4{
background-color: #DB5555;
}
.process-head{
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins' !important;
    font-weight: 600;
    margin: 20px 10px 20px 10px !important;
}
.process-description{
    font-size: 14px;
    font-family: 'Poppins' !important;
    color: #fff;
    font-weight: 300;
    margin: 10px 0px 10px 0px;
}
.col-rev-padd{
    padding: 0px 80px 0px 80px;
}
.reviewbox-details{
    display: flex;
    align-items: center;
}
.clientreview-heading{
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins' !important;
    font-weight: 600;
    margin: 2px 0px 2px 0px !important;
}
.cl-details{
    padding-left: 20px;
}
.reviewbox-bg{
    padding: 25px;
    background-color: #707070;
    border-radius: 8px;
    /* height: 280px; */
}

/* css start */


.template-page {
    /* overflow-x: hidden; */
    font-family: "Poppins";
}
.slider-container
{
    width: 1920px;
    padding: 0 50px;
}
.sw_ind{
    font-size: 23px;
    line-height: 33px;
    color: #292929;
    font-family: Bariol !important;
    text-align: center;
}
ul.sw_ind_list li {
    font-size: 23px;
    display: inline-block;
    margin: 0 50px 0 0;
    font-family: 'GothamBook' !important;
    font-weight: bold;
}
.inner_container {
    width: 950px!important;
    max-width: 950px !important;
    margin: 0 auto;
}
img.in_blg_icon {
    width: 100%;
}
p.sw_ind_name {
    margin: 0;
    text-align: center;
    font-family: 'Bariol' !important;
    font-weight: bolder;
    color: #ffffff !important;
    font-size: 19px;
}
p.sw_blg_text {
    color: #ffffff;
    font-style: italic;
    font-family: bariol !important;
    font-size: 19px;
    font-weight: bold;
    line-height: 2.0;
}
p.sw_ind_dgn {
    text-align: center;
    color: #9fa3a7;
    font-size: 14px;
    font-family: 'Bariol' !important;
}
div#sw_ind_blg {
    padding: 30px 0;
    margin: 30px 0;
    background: #1881dd;
}
p.sw_ind_list1 {
    padding: 3px 18px;
    text-align: center;
    font-size: 19px;
    font-family: 'GothamBook' !important;
    font-weight: bold;
    position: relative;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin: 0 auto;
}
p.sw_ind_list1:after {
    content: attr(data-content);
    position: absolute;
    color: #000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #ff899d;
    z-index: -1;
}

p.sw_ind_list1::selection {
     background: #d4fffb;
}
p.sw_ind_list2 {
    padding: 3px 10px;
    text-align: center;
    font-size: 19px;
    font-family: 'GothamBook' !important;
    font-weight: bold;
    position: relative;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin: 0 auto;
}
p.sw_ind_list2:after {
    content: attr(data-content);
    position: absolute;
    color: #000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #79c0ff;
    z-index: -1;
}

p.sw_ind_list2::selection {
     background: #d4ffd9;
}
p.sw_ind_list3 {
    padding: 3px 18px;
    text-align: center;
    font-size: 19px;
    font-family: 'GothamBook' !important;
    font-weight: bold;
    position: relative;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin: 0 auto;
}
p.sw_ind_list3:after {
    content: attr(data-content);
    position: absolute;
    color: #000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #f7d843;
    z-index: -1;
}

p.sw_ind_list3::selection {
     background: #fcffd4;
}
p.sw_ind_list4 {
    padding: 3px 18px;
    text-align: center;
    font-size: 19px;
    font-family: 'GothamBook' !important;
    font-weight: bold;
    position: relative;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin: 0 auto;
}
p.sw_ind_list4:after {
    content: attr(data-content);
    position: absolute;
    color: #000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #ddd9fc;
    z-index: -1;
}
p.sw_ind_list4::selection {
     background: #64f767;
}

img {
    max-width: 100%;
}
img.lg-hide{
    width: 100%;
}
.sw_rdw_cp{
   padding: 0 74px 0 150px;
}
.fl_image{
float: right;
}
#sw_rdw{
padding-bottom: 100px;
padding-top: 30px;
}
.sla-mid {
    vertical-align: bottom;
}
.sp-30{
    width: 30px;
    height: 30px;
}

section#sw_rdw_one_caption {
    background: #e7c001;
}
.sw-tour-slider{
  position: relative;
  text-align: center;
  color: white;
}
p.icon_sub_text {
    font-size: 18px;
    font-family: 'Bariol' !important;
    text-align: center;
}
ul.redesign_list li {
    font-size: 23px;
    font-family: Bariol !important;
    color: #4d545b;
    line-height: 1.8;
    list-style: disc;
}
ul.redesign_list {
    padding-left: 18px;
}
section#sw_tour_one_image .col-sm {
    padding: 0 !important;
    margin-top: -1px !important;
}
.container-fluid.sw-tour-slider {
    padding: 0 !important;
}
p.sw_slider_sup {
    font-size: 20px;
    color: #000;
    font-family: Bariol!important;
    text-transform: uppercase;
    text-shadow: none!important;
}
.logistics_heading {
    color: #ffffff!important;
    font-family: 'Gotham-Medium' !important;
    font-size: 42px;
    text-transform: capitalize!important;
    font-weight: bolder!important;
    text-shadow: none!important;
    line-height: 1.3;
    margin: 0 0 20px 0 !important;
    text-transform: inherit !important;
    text-align: left !important;
}
p.sw_slider_subtext {
    color: #ffffff;
    font-family: Bariol !important;
    font-size: 23px;
    line-height: 1.5;
    text-shadow: none!important;
    margin: 0 0 20px 0 !important;
    text-align: left !important;
}
.sw_blg_seprator {
    width: 100%;
    height: 40px;
}
.row.sw_section_one {
    padding: 50px 40px;
    border-radius: 9px;
    box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
}
.section_seprator {
    width: 80px;
    height: 80px;
}
.sw_online_heading {
    font-size: 38px!important;
    color: #222;
    font-family: GothamMedium !important;
    line-height: 1.5;
    font-weight: 700;
    text-align: center;
}
.sw_div_center{
text-align: center !important;
}
.white{
    color: #ffffff !important;
}
.sw_sep_30 {
    width: 100%;
    height: 30px;
}
.sw_sep_15 {
    width: 100%;
    height: 15px;
}
.sw_s4_sup {
    color: #80c3c2!important;
    font-size: 21px;
    font-family: GothamMedium!important;
}
.sw_s4_heading {
    font-family: GothamMedium!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
}
.sw_s4_heading_center{
   font-family: GothamMedium!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
    text-align: center;
}
.sw_s4_text {
    font-size: 23px;
    line-height: 32px;
    font-family: Bariol !important;
    color: #4d545b;
    margin: 20px 0;
}
.sw_industry_button {
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol !important;
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 10px;
    margin-bottom: 10px;
}
.sw_industry_button:hover {
    background: #ffffff !important;
    text-decoration: none!important;
    border-color: #ffffff !important;
    color: #e7c000!important;
}
.sw_industry_button.gs_bottom {
    color: #1881dd !important;
    border: 1px solid #1881dd !important;
}
.sw_industry_button.gs_bottom:hover {
    background: #1881dd !important;
    text-decoration: none!important;
    border-color: #1881dd !important;
    color: #ffffff!important;
}
h2.sw_con_heading {
    color: #fff;
    text-align: center!important;
    font-size: 42px;
    font-family: GothamBook;
    font-weight: bolder;
    margin: 0 auto;
    line-height: 46px;
}
h3.sw_con_su_heading {
    font-size: 22px;
    color: #fff;
    font-family: GothamBook;
    text-align: center!important;
    margin: 0 auto;
    line-height: 29px;
}
.sw_btn_div {
    text-align: center;
    margin: 0 auto;
}
.po_icon {
    text-align: center;
}
h3.sw_suheading {
    text-align: center;
    font-size: 22px;
    font-family: GothamMedium;
    line-height: 30px;
}
.col-center {
    margin: 0 auto;
    float: none!important;
}
#custom-footer {
    display: block!important;
}
.horizontal_seprator {
    border: 0.5px solid #a1a1a1;
}
p.sw_ind_dgn {
    display: none;
}
@media only screen and (max-width:1600px) and (min-width:1200px) {
.sw_rdw_cp {
        padding: 0 30px 0 88px;
}
.logistics_heading {
    font-size: 30px!important;
}
p.sw_slider_subtext {
    font-size: 20px!important;
}
.sw_online_heading {
    font-size: 28px!important;
}
.sw_ind,.sw_s4_text {
    font-size: 20px;
}
.sw_s4_heading {
    font-size: 24px;
}
}
@media only screen and (max-width:1366px) and (min-width:1024px) {
.row.sw_section_one {
    padding: 50px 11px;
}
}
@media only screen and (max-width:1199px) and (min-width:1025px) {
.sw_rdw_cp {
    padding: 0 30px 0 30px;
}
}
@media only screen and (max-width:1024px) and (min-width:768px) {
.sw_rdw_cp {
    width: 100%;
    padding: 0 20px 0 72px;
    /*margin-top: 40px;*/
}
    .inner_container {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 auto;
}
p.sw_ind_list1,p.sw_ind_list2,p.sw_ind_list3,p.sw_ind_list4{
    font-size: 16px !important;
}
ul.sw_ind_list li {
    display: block;
    margin: 0 50px 15px 0;
}
section#sw_home {
    margin-top: 0!important;
}
.logistics_heading {
    font-size: 26px!important;
    line-height: 1.3!important;
}
p.sw_slider_subtext {
    font-size: 18px!important;
    line-height: 1.2!important;
   /* margin: 20px 0 !important;*/
}
.sw_s4_text{
    font-size: 18px !important;
}
ul.redesign_list li {
    font-size: 18px;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px;
}
.sw_s4_heading,.sw_s4_heading_center {
    font-size: 20px;
}
.sw_online_heading {
    font-size: 24px!important;
}
.sw_online_heading br{
display: none !important;
}
h3.sw_suheading {
    font-size: 18px !important;
    line-height: 25px !important;
}
.po_icon img {
    width: 100px !important;
}
.sw_industry_button,.sw_industry_button_color {
    padding: 10px !important;
    font-size: 16px;
    /*width: 230px;*/
}
.sw_ind {
    font-size: 18px !important;
}
}
@media only screen and (max-width:1023px) and (min-width:768px) {
.section_seprator {
    width: 80px;
    height: 40px;
}
.logistics_heading {
    font-size: 26px!important;
    line-height: 1.3!important;
    /*margin-bottom: 10px !important;*/
}
p.sw_slider_subtext br {
    display: none;
}
.tb-heading{
padding: 0 !important;
}
.md-hide{
    display:none;
}
}

@media only screen and (max-width:767px) and (min-width:280px) {
.slide_caption {
    margin-left: 0 !important;
}
    .sw_ind {
    font-size: 18px !important;
}
    .sw_rdw_cp {
    padding: 0 30px;
}
ul.sw_ind_list li {
    font-size: 18px;
    display: block;
    margin: 16px 0px 0 0;
}
ul.sw_ind_list {
    padding: 0;
}
.sw_rdw_cp_dsn{
display: none !important;
}
    .inner_container {
    width: 100% !important;
}
img {
    max-width: 100%;
}
img.in_blg_icon {
    width: 100px;
    padding-bottom: 20px;
}
.flex-switch{
    display: flex !important;
    flex-direction: column-reverse;
}
.sw_rdw_cp {
    width: 100%;
}
.sl-xs{
    padding-bottom: 50px !important;
}
.xs-hide{
display: none !important;
}
a.sw-brand-logo img {
    width: 155px!important;
    padding: 10px 0 12px 24px!important;
}
.header-custom {
    padding: 4px!important;
}
section#sw_home {
    margin-top: 0!important;
}
.sw_online_heading {
    font-size: 22px!important;
    line-height: 1.3;
}
.sw_online_heading br{
  display: none !important;
}
.sw_wk_rating_icon img {
    width: 90px!important;
    height: 81px!important;
}
.sw_s4_heading,.sw_s4_heading_center {
    font-size: 20px!important;
}
.sw_s4_text {
    font-size: 18px!important;
    margin: 0!important;
}
.sw_posu_heading, h2.sw_con_heading {
    font-size: 28px;
}
.sw_industry_button {
    display: block!important;
    margin: 10px 0!important;
}
.logistics_heading {
    font-size: 24px!important;
    /*margin: 6px 0 !important;*/
}
.logistics_heading br,.sw_slider_subtext br{
    display: none !important;
}
p.sw_slider_subtext {
    font-size: 18px!important;
    line-height: 1.2!important;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px!important;
}
.sw_ol_sub_text {
    width: 100%}
.sw_wk_heading {
    font-size: 26px!important;
    line-height: 34px!important;
}
.section_seprator {
    width: 80px!important;
    height: 30px!important;
}
.sw_industry_button,.sw_industry_button_color {
    display: block !important;
    margin: 10px 0 !important;
    font-size: 16px;
}
h3.sw_suheading {
    font-size: 18px !important;
}
a.sw_tour_sac,a.sw_tour_succeed{
    margin: 0 !important;
    display: block !important;
}
ul.redesign_list li {
    font-size: 19px !important;
}
.mb-col-sep{
width: 100%;
height: 40px;
}
.sw_blg_text{
padding: 10px !important;
}
}
@media only screen and (max-width: 1366px) and (min-width: 280px){
}



  

body {
 
  
  font-family: "Exo", sans-serif;
 
}
.step-count{
  color: #ffffff;
  margin: auto auto;
}
.step-description{
  font-size: 14px;
}


.progress-bar {
 
  text-align: center;
  width: 97%;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(20px, 2fr)); */
  grid-template-columns: 20px 20px auto;
  background-color: transparent !important;
  justify-content: space-between;
}

.progress-step {
  text-align: center;
  position: relative;
}
.progress-step:before,
.progress-step:after {
  background-color: #FBC504;
  content: "";
  height: 8px;
  position: absolute;
  color: #ffffff !important;
  z-index: -1;
  top: 30px;
  
}
#Domain{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

}
.progress-step:after {
  left: 50%;
  width: 50%;
  
  font-weight: 550 !important;
  
}

.progress-step{
  z-index: 999;
}

.progress-step:last-of-type.is-active:after {
  background-color: #FBC504;
  color: #000000 !important;
}

.progress-step:first-of-type:before {
  width: 30rem !important;
}

@media screen and (min-width: 640px) {
  .progress-step:first-of-type:before {
    right: 50%;
    width: 50%;
  }
  
}
@media screen and (max-width: 640px) {
  .progress-step:first-of-type:before {
    right: 50%;
    width: 50%;
  }
  .progress-step:last-of-type:after {
    
    background-color: white !important;
  }
}
.progress-step:after {
  left: 0% !important;
  width: 20rem;
  font-weight: bold !important;
}

.progress-step:last-of-type:after {
  left: 0%;
  width: 10%;
}
.progress-step .step-count:before {
display: none;
}
#material::after{
left: 0px !important;
}
#Domain{
  right: 0px !important;

}
.progress-step .step-count {
  height: 62px;
  background-color: #FBC504;
  width: 62px;
  border-radius: 50%;
  color: white;
  line-height: 25px;
  align-items: center;
  z-index: 100;
  border: 7px solid #ffffff;
  font-size: 0.8rem;
  font-weight: bold;
  font-family: poppins;
  
}
.progress-step .step-count:before {
  counter-increment: step-count;
  content: counter(step-count);
}
.progress-step.is-active .step-description {
  
  font-weight: 550 !important;
  

}
.progress-step.is-active:after {
  background-color: #D9D9D9;
}
.progress-step.is-active ~ .progress-step .step-count {
  background-color: #D9D9D9;
}
.progress-step.is-active ~ .progress-step:after {
  background-color: #D9D9D9;
  /* width: 0; */
}

.step-description {
    color: black !important;
}





.progress-container {
  width: 97.5%;
  height: 5px;
  background-color: #ccc;
  border-radius: 20px !important; 
}

.progress-bar-bottom {
  height: 100%;
  background-color: #FBC504;
  width: 0;
  transition: width 0.3s ease-in-out;
  border-radius: 20px !important; 

}
/* Rest of your CSS styles */
.progressbar-BOT{
  
  width: 90%;
  margin: 20px auto;
  border-radius: 20px !important; 
 
}

@media (min-width: 768px) and (max-width: 1024px)  {
  .step-description{
    display: none !important;
   }
  /* CSS */
  
}

@media (min-width: 481px) and (max-width: 767px) {
  
  /* CSS */
  .step-description{
    display: none !important;
   } 
   


}


@media (min-width: 320px) and (max-width: 480px) {
 .step-description{
  display: none !important;
 }


  .category_card{
      display: flex;
      justify-content: center;
  }
}

.line-height{
    line-height: 20px !important;
}
.btn-clr:hover{
    background: transparent !important;
    text-decoration: none!important;
    color: #030303!important;
    border-color: #020202!important;
}

  /* div#header-custom:hover {
    background: #ffffff !important;
    transition: all .8s ease-in-out !important;
    webkit-animation: .8s ease-in-out !important;
    animation: .8s linear ease-in-out !important;
} */
.main-header.header-custom {
    box-shadow: 0 19px 30px 0 rgba(25, 25, 25, .1);
    -webkit-box-shadow: 0 19px 30px 0 rgba(25, 25, 25, .1);
    -moz-box-shadow: 0 19px 30px 0 rgba(25, 25, 25, .1);
  }
  p.sw_slider_subtext.text-center a {
  color: #e47458;
  text-decoration: none;
  }
  .sw-head.container{
  padding: 0 !important;
  }
  .header-transparency {
      z-index: 9999999;
      background: 0 0!important;
  }
  .header-custom {
      background-color: #fff;
      position: relative;
      width: 100%;
      z-index: 99;
      position: fixed!important;
      transition: all .1s ease-in-out;
      height: 90px;
  }
  .header-custom ul {
      background-color: white;
  }
  ul.sub-menu {
      position: absolute;
      background: #fff !important;
      padding: 0 10px 20px 10px !important;
      margin:0 !important;
  }
  ul.sub-menu {
      background: #fff !important;
      padding: 0 20px;
      box-shadow: 0 19px 30px 0 rgba(25, 25, 25, .1);
  }
    .head-container{
      /*max-width: 1920px;*/
      padding: 0px 40px;
    }
    .hmbg-overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    background-color: rgb(255,255,255);
    background-color: rgba(255,255,255, 0.97);
    overflow-x: hidden;
    transition: 0.5s;
  }
  
  .hmbg-overlay-content {
    position: relative;
    top: 10%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  
  .hmbg-overlay a {
    font-size: 18px;
    font-family: Bariol;
    font-weight: bolder;
    color: #000000 !important;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  .hmbg-overlay a:hover, .hmbg-overlay a:focus {
    color: #f1f1f1;
  }
  
  .hmbg-overlay .closebtn {
    position: absolute;
    top: 0px;
    right: 30px;
    font-size: 60px !important;
    text-decoration: none;
    font-weight: bold;
    color: #000 !important;
  }
  .header-custom ul#menu-sw-menu li a {
    font-size: 18px;
      font-family: Bariol;
      font-weight: bolder;
      color: #454545 !important;
      text-decoration: none;
      text-transform: uppercase;
  }
  .header-transparency ul#menu-sw-menu .sub-menu li a {
    color: #454545 !important;
  }
  .header-transparency ul#menu-sw-menu li a {
    font-size: 18px;
      font-family: Bariol;
      font-weight: bolder;
      color: #ffffff !important;
      text-decoration: none;
      text-transform: uppercase;
  }
  ul.sub-menu li a {
      font-size: 15px !important;
  }
  ul#menu-sw-menu li {
      display: inline-block;
      padding: 0 10px;
  }
  ul#menu-sw-menu li {
    display: inline-block;
      padding: 0 10px;
      height: 88px;
      line-height: 88px;
  }
  ul.sub-menu {
      display: none;
      
  }
  .menu-item-has-children:hover ul.sub-menu {
  display: block;
  background-color: white;
  
  }
  ul.sub-menu {
  padding-left: 0;
  background-color: white;
  }
  ul.sub-menu li {
      display: block !important;
      background-color: white;
  }
  ul.sub-menu li {
      padding: 0 !important;
  }
  ul#menu-sw-menu {
      margin: 0;
      padding: 0 0 0 60px;
  }
  .hmbg-icon{
    font-size:30px;
    cursor:pointer;
  }
  #hmbg-icon{
  display:none;
  }
  .menu-btns li{
      display: block;
      padding: 0 10px;
  }
  .dropdown-menu {
    min-width: 155px !important;
  }
  .dropdown-menu>li>a {
    padding: 3px 0px !important;
    font-size: 18px;
    font-family: Bariol;
    font-weight: bold;
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
  }
  ul.dropdown-menu.show {
    background: #fff !important;
    margin: 0 !important;
  }
  .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
    color: #000 !important;
    text-decoration: none !important;
    background-color: #ffffff !important;
  }
  .header-custom a.menu-btn-lg {
    background: #1880dd !important;
    color: #fff !important;
    border: 1px solid #1880dd;
    padding: 8px 35px;
    border-radius: 4px;
    font-family: Bariol;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none !important;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    margin-right: 10px !important;
  }
  .header-transparency a.menu-btn-lg {
      background: transparent !important;
      color: #fff !important;
      border: 1px solid #ffffff;
      padding: 8px 35px;
      border-radius: 4px;
      font-family: Bariol;
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
      text-decoration: none !important;
      width: initial;
      transition: all .5s ease-in-out;
      display: inline-block;
      margin-right: 10px !important;
  }
  
  a.dropdown-toggle.menu-btn-lg:hover,a.dropdown-toggle.menu-btn-lg:active,a.dropdown-toggle.menu-btn-lg:focus {
    background: transparent !important;
  }
  .header-custom a.menu-btn-sg{
      background: #1880dd !important;
      color: #fff !important;
      border: 1px solid #1880dd;
      padding: 8px 35px;
      border-radius: 4px;
      font-family: Bariol;
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
      text-decoration: none !important;  
      width: initial;
      transition: all .5s ease-in-out;
      display: inline-block;
  }
  .header-transparency a.menu-btn-sg{
    background: transparent !important;
    color: #fff !important;
    border: 1px solid #ffffff;
    padding: 8px 35px;
    border-radius: 4px;
    font-family: Bariol;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none !important;  
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
  }
  a.menu-btn-lg:hover,a.menu-btn-lg:active,a.menu-btn-sg:hover,a.menu-btn-sg:active {
  color:#fff !important;
  }
  .header-custom a.menu-btn-lg:hover, .header-custom a.menu-btn-lg:active, .header-custom a.menu-btn-lg:focus {
    background: #187fdb !important;
    color: #Ffffff!important;
  }
  /* a.menu-btn-lg:hover,a.menu-btn-lg:active{
    background: transparent !important;
  } */
  .menu-btns {
      margin-top: 25px;
  }
  .navmenu-btns.navbar-nav>li>a {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    line-height: 20px !important;
    position: relative;
    display: block;
    padding: 10px 15px;
  }
  div#hmbg-myNav {
      display: none;
      opacity: 0;
  }
  .custom-menu-class {
      text-align: center;
  }
  img.sw-logo {
    width: auto !important;
    max-width: 150px !important;
    margin-top: 16px !important;
    outline: none !important;
  }
  ul.sub-menu li {
      text-align: left;
      line-height: 56px;
      height: 48px !important;
  }
  .row:before, .row:after {display: none !important;}
  .dropdown-menu-one>li>a {
    display: inline-block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 2;
    color: #000;
    white-space: nowrap;
    font-family: 'GothamLight';
    font-size: 18px;
    text-transform: capitalize;
  }
  .logout-icon-one {
    width: 20px;
    margin: 10px 6px -5px -5px;
    display: inline-block;
  }
  .visit-icon-one{
    width: 20px;
    margin: 10px 6px -5px -4px;
    display: inline-block;
  }
  a.logout-link-one{
  width: 100%;
  height: 100%;
  }
  .dropdown-menu-one {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    line-height: 1.4;
    display: none;
    float: left;
    min-width: 100%;
    padding: 0px 0;
    margin: -10px -4px 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc !important;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
  }
  .dropdown-menu-one>li:focus, .dropdown-menu-one>li:hover{
    color: #262626 !important;
    text-decoration: none;
    background: #f7f7f7 !important;
    outline: none;
    cursor: pointer;
  }
  .dropdown-menu-one li:nth-child(1){
    border-bottom: 1px solid #e47558 !important;
    line-height: 0.7;
  }
  .dropdown-menu-one li:nth-child(2) {
    border-bottom: 3px solid #e47558 !important;
    line-height: 0.7;
  }
  
  .drp-menu-one:before,
  .drp-menu-one:after{
    bottom: 100%;
    left: 85%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .drp-menu-one:before {
      border-bottom-color: #ccc;
    border-width: 9px;
    margin-left: 5px;
  }
  .drp-menu-one:after{
    border-color: rgba(118, 118, 118, 0);
    border-bottom-color: #fff;
    border-width: 7px;
    margin-left: 7px;
  }
  a.myaccount-one {
    border: 1px solid #000 !important;
    border-radius: 25px;
    padding: 10px 20px;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    font-size: 1vw;
  
  }
  .signin-one{
  border: 1px solid #000 !important;
    border-radius: 25px;
    padding: 10px 20px;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    font-size: 1vw;
  
  }
  .signin-one:hover{
      background: #e47558 !important;
    color: #fff !important;
    border: 1px solid #e47558 !important;
  }
  a.myaccount-one:hover{
   background: #e47558 !important;
    color: #fff !important;
    border: 1px solid #e47558 !important;
  }
  .dvLoading {
    /* background: #000 url(assets/preloader.gif) no-repeat center center; */
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    margin: 0;
    opacity: 0.98;
    background-color: #ffffff;
  }
  .fl-ct{
   /* display: table-cell !important;
    table-layout: fixed;
    width: 100%;*/
  }
  .d-tc{
    margin-top: auto;
    margin-bottom: auto;
    /*display: table-cell;*/
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .d-tc{
     height:100%;
    }
  }
  .sub-menu li:nth-child(2) a {
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    padding: 15px 24px 15px 0;
  }
  .sub-menu li:nth-child(4) a {
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    padding: 15px 0 15px 0px;
  }
  .sub-menu li:nth-last-child(1) {
    height: 56px !important;
  }
  .xsf-sep{
    width: 100%;
    height: 150px;
  }
  @media only screen and (max-width:1600px) and (min-width:1200px) {
  ul#menu-sw-menu li a {
    font-size: 16px;
  }
  ul.sub-menu li a {
      font-size: 15px !important;
  }
  }
  @media only screen and (max-width:1366px) and (min-width:1024px) {
    img.sw-logo {
      width: auto !important;
      max-width: 250px !important;
      margin-top: 7px !important;
  }
  ul#menu-sw-menu li a {
    font-size: 15px;
  }
  ul.sub-menu li a {
      font-size: 14px !important;
  }
  a.menu-btn-lg,a.menu-btn-sg{
    font-size: 14px;
    padding: 8px 20px !important;
  }
  img.sw-logo{
    width: auto !important;
    max-width: 185px !important;
    margin-top: 17px !important;
  }
  ul#menu-sw-menu {
      margin: 0;
      padding: 0 0 0 30px !important;
  }
  }
  @media only screen and (max-width:1023px) and (min-width:280px) {
    ul.sub-menu {
      padding: 0 10px 0px 10px !important;
    }
    .hmbg-overlay .closebtn {
      top: -5px !important;
      right: 36px !important;
    }
    .avia_mobile .hmbg-overlay .closebtn {
    top: 28px !important;
    right: 50px !important;
    }
    div#hmbg-myNav {
      display: block !important;
      opacity: 1 !important;
  }
  .col-md-8 .custom-menu-class {
      display: none;
  }
  .menu-btns {
      margin-top: 0;
      display: none;
  }
  #hmbg-icon{
    display: block !important;
  }
  .head-container {
      padding: 0px 30px;
  }
  ul#menu-sw-menu-1 li {
      list-style: none;
  }
  ul#menu-sw-menu-1 li {
      list-style: none;
      line-height: 3.0;
  }
  ul#menu-sw-menu-1 {
      padding-left: 0;
  }
  .hmbg-overlay-content .custom-menu-class {
      display: block;
  }
  .custom-menu-class,.menu-btns {
      display: none;
  }
  .sw-head.container {
    padding: 0 30px !important; 
  }
  ul.sub-menu {
    position: relative !important;
  }
  ul.sub-menu li a {
    text-align: center !important;
    display: inherit !important;
  }
  .hmbg-overlay a.menu-btn-lg, .hmbg-overlay a.menu-btn-sg {
    font-size: 16px !important;
    display: block !important;
    margin: 10px 20px !important;
  }
  ul.dropdown-menu.show {
    width: 90% !important;
  }
  .dropdown-menu>li>a {
    padding: 3px 15px !important;
    text-align: center !important;
  }
  .sub-menu li:nth-child(2) a {
    padding: 0px 0 !important;
  }
  .sub-menu li:nth-child(4) a {
    padding: 0px 0 !important;
  }
  }
  @media only screen and (max-width:1280px) {
  .dropdown-menu {
    min-width: 139px !important;
  }
  }
  @media only screen and (max-width: 1366px) and (min-width: 280px){
  .sw-head.container {
    padding: 0 30px !important; 
  }
  }
  .container {
    width: 1270px!important;
    max-width: 1249px !important;
  }
  @media only screen and (max-width: 1366px) and (min-width: 280px){
    .container {
        width: 100%!important;
        max-width: 100%!important;
        padding: 0 30px !important;
    }
    }
    @media only screen and (max-width: 375px) and (min-width: 280px){
    .hmbg-overlay a {
      font-size: 16px !important;
    }
    ul.sub-menu li a{
      font-size: 14px !important;
    }
  }
  @media (min-width:280px) and (max-width:767px) and (orientation:landscape) {
    .section_seprator {
      width: 80px!important;
      height: 40px!important;
  }
  }
  @media (min-width:280px) and (max-width:767px) {
  /*.fl-ct {
    display: block !important;
  }*/
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  span#hmbg-icon {
    display: inline-block;
    position: absolute;
      top: 20px;
      right: 30px !important;
      font-size: 39px;
  }
  img.sw-logo {
    width: auto !important;
    max-width: 180px !important;
    margin-top: 18px !important;
  }
  }
  @media (min-width:768px) and (max-width:1023px) {
  span#hmbg-icon {
    display: inline-block;
    position: absolute;
      top: 20px;
      right: 30px !important;
      font-size: 39px;
  }
  img.sw-logo {
    width: auto !important;
    max-width: 185px !important;
    margin-top: 18px !important;
  }
  }
  @media (min-width:601px) and (max-width:767px) and (orientation:landscape) {
  h3.sw-ft-title,p.sw-ft-nav-text,a.sw-ft-anch {
    font-size: 14px !important;
  }
  }
  @media (min-width:480px) and (max-width:600px) and (orientation:landscape) {
    h3.sw-ft-title,p.sw-ft-nav-text,a.sw-ft-anch {
      font-size: 13px !important;
    }
  }

  .custom-log{
    width: 45%  !important;
    height: 42px !important;
  }

  .custom-up{
    font-size: 18px !important;
    height: 43px !important;
  }

.padding {
    padding: 3rem !important
}
.flex-middle{
    display: none;
}
.reply::-webkit-input-placeholder{
font-size: 14px;
color: #d9d9d9;
}
.reply::placeholder{
font-size: 14px;
color: #d9d9d9;
}
.search input[type="search"].filenew{
display: none !important;  
}
.file{
  width: 70px !important;
}
.emoji-mart-search { display: none !important} 
.info-box{
    background-color: #fff;

    padding: 20px;
    height: 410px;
    /* width: 250px !important; */
}
.frind{
    border: 1px solid #d9d9d9;
    outline: none;
    font-size: 14px;
}

.frind::-webkit-input-placeholder{
    color: #807d72;
    font-size: medium;
    font-size: 14px;
    
}

.frind::placeholder{
    color: #807d72;
    font-size: medium;
    font-size: 14px;
    
}
.dashboard-list a{
 
    font-family: 'poppins' !important;
    font-size: 15px !important;
    text-decoration: none !important;
    transition-duration: .2s;
}
.card-background{
    background-color: #fbc504;
    padding: 10px;
}


.d-list-item a{
color: '#D9D9D9f' !important;
} 
.design-category-list .dashboard-list{
    
}
.user-profile-head{
    font-size: 25px;
    font-family: 'poppins';
    font-weight: 400;
    padding: 6px 0px 4px 15px;
    /* margin: 0px; */
    border-left: 4px solid #fbc504;
}
.icon-padd{
    padding: 20px 0px 20px 0px;
}
.user-dash-head{
    font-size: 25px;
    font-family: 'poppins';
    font-weight: 500;
    margin-bottom: 20px;
}
.card-background{
    background-color: #fbc504;
}
.cont-centr{
    align-self: center;
}
.card-head{
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 400;
    margin-bottom: 0px;
}
.user-card-full {
    overflow: hidden
}

.card {
    border-radius: 5px;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px
}

.m-r-0 {
    margin-right: 0px
}

.m-l-0 {
    margin-left: 0px
}

.user-card-full .user-profile {
    border-radius: 5px 0 0 5px
}

.bg-c-lite-green {
    background: linear-gradient(to right, #000000, #40352f);
}

.user-profile {
    padding: 20px 0
}

.card-block {
    padding: 1.25rem
}

.m-b-25 {
    margin-bottom: 25px
}

.img-radius {
    border-radius: 5px
}

h6 {
    font-size: 14px
}

.card .card-block p {
    line-height: 25px
}


@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px
    }
}
@media screen and (max-width: 1024px) {
    .card-background{
        padding: 5px !important;
    }
}

.card-block {
    padding: 1.25rem
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.m-b-20 {
    margin-bottom: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.card .card-block p {
    line-height: 25px
}

.m-b-10 {
    margin-bottom: 10px
}

.text-muted {
    color: #919aa3 !important
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.f-w-600 {
    font-weight: 600
}

.m-b-20 {
    margin-bottom: 20px
}

.m-t-40 {
    margin-top: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.m-b-10 {
    margin-bottom: 10px
}

.m-t-40 {
    margin-top: 20px
}

.user-card-full .social-link li {
    display: inline-block
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    transition: all 0.3s ease-in-out
}


/* orderCard CSS */





.order-card {
    background-color: #fff;
    width: 300px;
    border: none;
    border-radius: 16px
}

.info {
    line-height: 19px
}

.name {
    color: #4c40e0;
    font-size: 18px;
    font-weight: 600
}

.order {
    font-size: 14px;
    font-weight: 400;
    color: #b7b5b5
}

.detail {
    line-height: 19px
}

.summery {
    color: #d2cfcf;
    font-weight: 400;
    font-size: 13px
}


.text {
    line-height: 15px
}

.new {
    color: #000;
    font-size: 14px;
    font-weight: 600
}

.money {
    font-size: 14px;
    font-weight: 500
}

.address {
    color: #d2cfcf;
    font-weight: 500;
    font-size: 14px
}

.last {
    font-size: 10px;
    font-weight: 500
}

.address-line {
    color: #4C40E0;
    font-size: 11px;
    font-weight: 700
}

.log-div{
    margin: 25px auto;
}
@media only screen and (max-width:700px) {
    .user-dashb{
        margin-top: 40px;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .mainuser{
        padding: 0 !important;
    }
    .headingtxt{
        font-size: 11px !important;
    }
    .topsec img{
   width: 70px !important;
   height: 70px;
    }
    .secondaryhead{
        font-size: 14px !important;
    }
    .secondaryhead2{
        font-size: 12px !important;
    }
    .orders{
        margin-bottom: 20px !important;
    }
    .logo_img{
        width: 40px !important;
        height: 40px !important;
    }
    .nooopadding{
        padding: 0 !important;
    }
}

.btn-logout{
    margin-top: 0px !important;
    align-self: center !important;
}
.btn-clr{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 50px;
    font-family: "Poppins" !important;
    font-weight: 400;
    font-size: 18px;
    min-width: 220px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    /* margin: 0 5px 0 5px !important; */
    text-shadow: none !important;
}
.btn-clr:hover{
    background: transparent !important;
    text-decoration: none!important;
    color: #030303!important;
    border-color: #020202!important;
}
.number{
    color: #131313;
}
.number:hover{
    color: #131313;
    text-decoration: none !important;
}
@media only screen and (max-width:480px) and (min-width:1024px) {
    .mag{
        margin-left: 10px;
    }
    


}

@media only screen and (max-width:320px) and (min-width:480px) {
    .history{
        font-size: 12px !important;
    padding-left: 10px !important;
    }
    .historydis{
        font-size:8px !important ;
    }


}

@font-face {
    font-family: Bariol;
    src: url(/static/media/bariol_regular-webfont.e4213a39.eot);
    src: url(/static/media/bariol_regular-webfontd41d.e4213a39.eot?#iefix) format("embedded-opentype"),
     url(/static/media/bariol_regular-webfont.7d0cf79e.svg) format('svg'),
      url(/static/media/bariol_regular-webfont.6c235190.ttf) format('truetype'),
       url(/static/media/bariol_regular-webfont.38c3b9e9.woff) format('woff'),
        url(/static/media/Bariol_Regular.6a64a521.otf) format('otf'),
         url(/static/media/bariol_regular-webfont.6577b355.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: GothamBook;
    src: url(/static/media/Gotham-Book.7fe2d669.eot);
    src: url(/static/media/Gotham-Bookd41d.7fe2d669.eot?#iefix) format("embedded-opentype"),
     url(/static/media/Gotham-Book.65288315.woff) format("woff"), 
     url(/static/media/Gotham-Book.79ab7007.ttf) format('truetype'),
      url(/static/media/Gotham-Book.92032bfd.svg#Gotham-Book) format("svg"), 
      url(/static/media/Gotham-Book.e37cf599.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url(/static/media/GothamMedium.d23ef85d.woff) format('woff'),
    url(/static/media/GothamMedium.de228587.woff2) format('woff2'),
    url(/static/media/GothamMedium.ed646164.eot),
    url(/static/media/GothamMediumd41d.ed646164.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamMedium.c3f574b2.ttf) format('truetype'),
    url(/static/media/GothamMedium.9faf290f.svg#Gotham-Book) format('svg'),
    url(/static/media/GothamMedium.bcd733e6.otf) format('opentype'); 
}
  @font-face {
    font-family: BariolBold;
    src: url(/static/media/bariol_bold-webfont.fa0ddc94.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  .main-templates{
      overflow-x: hidden;
  }
  a.sw_process_button{
    color: #ffffff !important;
    background-color: #197cd3 !important;
    border: 1px solid #197cd3 !important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px;
    max-width: 270px!important;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    text-transform: uppercase;
    text-shadow: none !important;
    margin-right: 10px !important;
  }
  .sw_process_button:hover {
    background: #ffffff !important;
    text-decoration: none!important;
    border-color: #197cd3 !important;
    color: #197cd3 !important;
} 

.revisions_text{
    font-size: 20px !important;
    font-family: "Poppins";
    line-height: 30px;
    color: #fff;
}
.revisions_desc{
    line-height: 23px;
    margin: 0 auto;
    padding-left: 0;
    font-size: 14px!important;
    font-family: "Poppins"!important;
    color: #fff;
}












/*Features New Page Starts*/
.header_bg{
    background: #ffffff !important; 
}
#main_section .container{
padding: 0px 0px 0 0px !important;
}
#main_section_two .container{
padding: 0px 0px 0 0px !important;
}
#main_section .flex_column.av_one_half.flex_column_table_cell.av-equal-height-column.av-align-middle.av-zero-column-padding.avia-builder-el-4.el_after_av_one_half.avia-builder-el-last {
    background: #1880dd;
    background: linear-gradient(to right, #1880dd, #1880dd);
    padding: 100px 0 100px 100px;
}
div#main_section {
    padding-top: 40px;
}
.swf_content {
    margin-left: 49%;
    width: 55%;
    margin-top: 25%;
}
.swf_content_two {
    margin-left: 14%;
    width: 55%;
}
h1.swf_main_heading {
    font-family: 'Gotham-Medium' !important;
    color: #000;
    font-size: 40px;
    line-height: 1.0;
    /*font-weight: 400 !important;*/
}
.features_heading{
    font-family: 'Gotham-Medium' !important;
    text-align: center !important;
    font-size: 38px!important;
    color: #222 !important;
    line-height: 1.2 !important;
    font-weight: 700 !important;
}

.mobileTabs{
    display: none !important;
}
p.swf_main_subtext_features {
    /*font-family: 'GothamLight' !important;*/
    font-size: 18px;
    line-height: 1.7em;
    margin: 0 0 20px 0;
    /*font-weight: bold;*/
    color: #545454 !important;
}
.swf_page_btn{
    color: #fff !important;
   background: #1e1a25 !important;
    border: 1px solid #1e1a25 !important;
    padding: 10px 30px;
    text-decoration: none !important;
    border-radius: 5px;
    font-family: 'BariolBold';
    font-size: 19px;
    max-width: 225px !important;
    display: inline-block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
}
a.swf_page_btn:hover {
    background: #fff !important;
    text-decoration: none !important;
    border-color: #1e1a25 !important;
    color: #1e1a25 !important;
}
.swf_fp_page_btn{
    color: #fff !important;
    background: #e47458 !important;
    border: 1px solid #e47458 !important;
    padding: 10px 30px;
    text-decoration: none !important;
    border-radius: 5px;
    font-family: 'BariolBold';
    font-size: 19px;
    max-width: 225px !important;
    display: inline-block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
}
a.swf_fp_page_btn:hover {
    background: #fff !important;
    text-decoration: none !important;
    border-color: #e47458 !important;
    color: #e47458 !important;
}

div#swfc_section .container, div#swfc_section_two .container, div#swfc_section_three .container, div#swfc_section_four .container  {
    max-width: 1130px !important;
}
.swfc_heading {
    text-align: left;
    font-family: 'Gotham-Medium' !important;
    color: #000 !important;
    font-size: 30px;
}
.swfc_heading_sub_text{
    font-family: 'GothamLight' !important;
    font-size: 21px;
    line-height: 1.2;
}
h2.swfc_footer_heading{
    text-align: center;
    font-family: 'GothamLight' !important;
    font-size: 24px;
    line-height: 1.7em;
    text-transform: capitalize !important;
    color: #fff;
}
h2.swfc_sub_heading {
    color: #000;
    text-transform: capitalize !important;
    font-size: 22px;
    font-family: 'Gotham-Medium' !important;
    font-weight: 400 !important;
    height: 45px;
    margin-bottom: 0 !important;
    display: inline-block;
}
.staric{
    display: inline-block;
    color: #1880dd !important;    
}
/*.swfc_one_third_box{
    -webkit-box-shadow: -26px -7px 121px -20px rgba(219,219,219,0.88);
    -moz-box-shadow: -26px -7px 121px -20px rgba(219,219,219,0.88);
    box-shadow: 4px 4px 25px 1px rgba(234, 234, 234, 0.88);
    padding: 15px;
    border-radius: 5px;
}*/
p.swfc_sub_text {
    font-family: 'Bariol' !important;
    font-size: 18px;
    line-height: 1.5em;
    margin: 10px 0;
}
.swfc_one_third_box i {
color: #37d399 !important;
    font-size: 60px;
    padding-bottom: 20px;
    display: none;
}
#swfc_section span.hr-inner.inner-border-av-border-thin, div#swfc_section_two span.hr-inner.inner-border-av-border-thin, div#swfc_section_three span.hr-inner.inner-border-av-border-thin{
    border: 0;
    height: 1px;
    background: #333;
    background:         linear-gradient(left, hsla(0,0%,0%,0) 0%, hsla(0,0%,0%,.75) 50%, hsla(0,0%,0%,0) 100%);
}
#main_section_two .flex_column.av_one_half.flex_column_table_cell.av-equal-height-column.av-align-middle.av-zero-column-padding.first.avia-builder-el-62.el_before_av_one_half.avia-builder-el-first{
    background: #1880dd;
    background: linear-gradient(to right, #1880dd, #1880dd);
    padding: 100px 100px 100px 0px;
}
.swf_page_btn{
    color: #1e1a25 !important;
   background: #fff !important;
    border: 1px solid #1e1a25 !important;
    padding: 10px 30px;
    text-decoration: none !important;
    border-radius: 5px;
    font-family: 'BariolBold';
    font-size: 19px;
    max-width: 225px !important;
    display: inline-block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
}
a.swf_page_btn:hover {
    background: #1e1a25 !important;
    text-decoration: none !important;
    border-color: #fff !important;
    color: #fff !important;
}
.swf_lg_seprator,.swf_lg1_seprator{
width:100%;
height:50px;
}
.swf_sm_seprator{
width:100%;
height:30px;
}

.button_row{
    margin: 50px auto 0 auto;
    text-align: center;
}
.section_seprator {
    width: 80px;
    height: 80px;
}
.horizontal_seprator {
    border: 1px solid #a1a1a1;
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
    .section_seprator_20{
        height: 5px !important;
    }
    /* i ahve added this  */
    .tabs{
        display: none;
    }
    .xs_fsep{
        width: 100%;
        height: 30px;
    }
    .xs_fstfeature_seprator{
        width: 100%;
        height: 150px;
    }
    .swf_content {
        margin-top: 0 !important;
    }
    .second_head{
        width: auto !important;
    }
.section_seprator {
    width: 80px;
    height: 30px;
}
.swf_content_two {
    margin-left: 0;
    width: 100%;
}
.swf_content {
    margin-left: 0;
    width: 100%;
}

div#swfc_section,div#swfc_section_two,div#swfc_section_three{
background-image: none !important;
}
#main_section_two .flex_column.av_one_half.flex_column_table_cell.av-equal-height-column.av-align-middle.av-zero-column-padding.first.avia-builder-el-62.el_before_av_one_half.avia-builder-el-first{
    padding: 20px 20px 20px 0px;
}
#main_section .flex_column.av_one_half.flex_column_table_cell.av-equal-height-column.av-align-middle.av-zero-column-padding.avia-builder-el-4.el_after_av_one_half.avia-builder-el-last{
    padding: 20px 0 20px 20px;
}
h1.swf_main_heading {
    font-size: 30px;
}
div#swfc_section_two,div#main_section,div#main_section_two,div#swfc_section,div#swfc_section_three {
    background-image: none; 
}
.swf_lg_seprator {
    width: 100%;
    height: 30px;
}
.swf_lg1_seprator {
    width: 100%;
    height: 0px !important;
}
.swf_sm_seprator {
    width: 100%;
    height: 15px;
}
.swfc_heading_sub_text{
    font-size: 18px !important;
}
div .av_one_half,div .av_one_third {
    margin-left: 0;
    width: 100%;
}
div#main_section {
    padding: 0 30px;
}
.hr.hr-custom.hr-center.hr-icon-no.avia-builder-el-67.el_before_av_codeblock.avia-builder-el-first {
    margin-bottom: 10px !important;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
.section_seprator {
    width: 80px;
    height: 40px;
}
}
@media only screen and (min-width: 768px) and (max-width: 996px) {
#main_section .flex_column.av_one_half.flex_column_table_cell.av-equal-height-column.av-align-middle.av-zero-column-padding.avia-builder-el-4.el_after_av_one_half.avia-builder-el-last {
    padding: 50px 0 50px 50px;
}
#main_section_two .flex_column.av_one_half.flex_column_table_cell.av-equal-height-column.av-align-middle.av-zero-column-padding.first.avia-builder-el-62.el_before_av_one_half.avia-builder-el-first {
    background: #1880dd;
    background: linear-gradient(to right, #37d399, #0ccd70);
    padding: 50px 50px 50px 0px;
}
p.swfc_sub_text {
    margin: 26px 0px 0 0;
}
div#swfc_section,div#swfc_section_two,div#swfc_section_three{
background-image: none !important;
}
div#swfc_section_two,div#main_section,div#main_section_two,div#swfc_section,div#swfc_section_three {
    background-image: none; 
}
.swf_content {
    margin-left: 14%;
    width: 80%;
}
.swf_content_two {
    margin-left: 8%;
    width: 80%;
}
.swfc_heading_sub_text{
    font-size: 18px !important;
}
}
@media (max-width: 1024px) and (min-width: 768px){
span.logo {
    float: none !important;
    display: inline-table !important;
}
}
@media (max-width: 1024px) and (min-width: 768px) and (orientation: landscape){
div#swfc_section,div#swfc_section_two,div#swfc_section_three{
background-image: none !important;
}
div#swfc_section_two,div#main_section,div#main_section_two,div#swfc_section,div#swfc_section_three {
    background-image: none; 
}
.swf_content {
    margin-left: 12%;
    width: 80%;
}
.swf_content_two {
    margin-left: 8%;
    width: 80%;
}
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
div#swfc_section,div#swfc_section_two,div#swfc_section_three{
background-image: none !important;
}
div#swfc_section_two,div#main_section,div#main_section_two,div#swfc_section,div#swfc_section_three {
    background-image: none; 
}
.swf_content {
    margin-left: 12%;
    width: 80%;
}
.swf_content_two {
    margin-left: 8%;
    width: 80%;
}
}
@media (max-width: 1366px) and (min-width: 1025px) and (orientation: landscape){
.swf_content {
    margin-left: 19%;
    width: 80%;
}
.mobileTabs{
    display: none !important;
}
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
.swf_content {
    margin-left: 30% !important;
    width: 55%;
}
.mobileTabs{
    display: none !important;
}
}
/*Features New Page End*/

.sw_unfill_btn {
    color: #ffffff!important;
    border: 1px solid #1880dd!important;
    background: #1880dd !important;   
}

.sw_unfill_btn:hover {
    color: #1880dd !important;
    border: 1px solid #1880dd!important;
    background: 0 0 !important;   
}

/* styles.css */
.tab-list {
    list-style: none;
    padding: 0;
    display: flex;
  }
  
  .tab-list li {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ccc;
    margin-right: 5px;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }
  
  .tab-list li.active {
    background-color: #007bff;
    color: white;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }
  
  .tab-list li.slide-left {
    transition: -webkit-transform 1.3ms ease-in-out;
    transition: transform 1.3ms ease-in-out;
    transition: transform 1.3ms ease-in-out, -webkit-transform 1.3ms ease-in-out;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  
  .tab-list li.slide-right {
    transition: -webkit-transform 5.3ms ease-in-out;
    transition: transform 5.3ms ease-in-out;
    transition: transform 5.3ms ease-in-out, -webkit-transform 5.3ms ease-in-out;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ccc;
  }
  
  @media only screen and (max-width:480px) and (min-width:320px) {
    .logo{
        width: 60px !important;
        height: 60px !important
    }
    #heading{
        font-size: 40px !important;
        width: auto !important;
    }
    #subHeading{
        width: auto !important;
    }
    .mobileTabs{
        display: inline !important;
    }
    
    
}

@media only screen and (max-width:1042px) and (min-width:481px) {
   #heading{
    font-size: 45px !important;
    width: auto !important;
   }
   #subHeading{
    width: auto !important;
}
.scroller{
font-size: 15px !important;
/* margin-left: '20px' */
}
.tabs{
    padding: 20px;
}
.logo{
    width: 100 !important;
    height: 100 !important;
}
.revisionBox{
    /* width:'360px' !important; */
}
.mobileTabs{
    display: none !important;
}

}
  
/* 
style={{
    position: 'absolute', color: '#000000', fontFamily: 'poppins', zIndex: '999', top: '50%', marginLeft: '12%', transform: 'translate(-50%, -50%)'
    , fontSize: '20px', display: isFocused ? '' : 'none'
  }} */

  @media only screen and (min-width: 1366px) and (max-width: 2024px) {
    .mobileTabs{
        display: none !important;
    }
}
  
 

#sw-ft-nav {
    padding: 60px 0px;
    background: #141414 ;
    display: block;
  }

  #sw-ft-cr{
    padding: 20px 0px;
    background: fixed;
    background-color: #000000;
  }
  .social-footer{
    list-style: none;
    display: inline;
  }
  .icon-siz-footer{
    color: #fff;
    font-size: 25px;
    margin: 10px 20px 5px 0px;
  }
  .icon-siz-footer:hover{
    color:#fbc504;
  }
  .sw-ft-col a {
      display: block;
  }
  h3.sw-ft-title {
      color: white;
      font-size: 22px;
      font-family: 'Poppins';
      font-weight: "500";
  }
  span.sw-ft-anch {
      color: rgb(189, 189, 189);
      font-size: 18px;
      font-family: 'Poppins';
      line-height: 2.0;
      text-decoration:none;
      font-weight: "400";
      outline:none;
  }
  span.sw-ft-anch:hover{
    color: #fbc504;
    text-decoration: none;
  }
  p.sw-ft-nav-text {
      color: white;
      font-size: 16px;
      font-family: 'Poppins' !important;
      line-height: 1.5;
      margin: 0;
  }
  .sw-ft-sep{
    width: 100%;
    height: 30px;
  }
  ul.social_icons_list li {
    display: inline-block;
    padding-right: px;
  }
  ul.social_icons_list {
    padding-left: 0 !important;
    color: #fff;
    font-size: 30px;
    padding-top: 15px;
  }
  ul.social_icons_list li a {
    color: #fff;
  }
  @media only screen and (max-width: 1024px) and (min-width:768px) {
    .footer-logo{
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) and (min-width:280px) {
    .footer-logo{
      width: 60% !important;
    }
  h3.sw-ft-title {
    padding-top: 30px;
    margin: 0;
  }
  }
  @media only screen and (max-width: 1024px) and (min-width:280px) {
 
  #sw-ft-nav .container {
  width: 100% !important;
  max-width: 100% !important;
  }
  
  h3.sw-ft-title {
      font-size: 16px;
  }

  #sw-ft-nav {
      padding: 50px 0px !important;
  }

  span.sw-ft-anch {
    font-size: 14px !important;
        margin: 10px 0 !important;
  }

  p.sw-ft-nav-text,h3.sw-ft-title {
    font-size: 14px !important;
  }

  }

.dropdown-menu{
  border-radius: 2px solid gray !important
}
.regionSelect{
  color: white !important;
}
.new_link{
  text-decoration: none !important;
  font-size: 16px !important;
  font-family: "poppins" !important;
  color: "white" !important;
}
.region{
  font-size: 16px !important;
  font-family: "poppins" !important;
  color: "white" !important;
}
.new_link:hover{
  color: #fbc504 !important;
}


.regionOption:hover{
  background-color: #fbc504 !important;
}

  span.nav-link:hover{
  color: #fbc504 !important;
}
.dropdown-item:focus, .dropdown-item:hover{
  background-color: transparent !important; 
}
.main-header.header-custom {
    box-shadow: 0 19px 30px 0 rgba(25, 25, 25, .1);
    -webkit-box-shadow: 0 19px 30px 0 rgba(25, 25, 25, .1);
    -moz-box-shadow: 0 19px 30px 0 rgba(25, 25, 25, .1);
  }
  p.sw_slider_subtext.text-center a {
  color: #e47458;
  text-decoration: none;
  }
  .sw-head.container{
  padding: 0 !important;
  }
  .header-transparency {
      z-index: 9999999;
      background: 0 0!important;
  }
  .header-custom {
      background-color: #fff0;
      position: absolute;
      width: 100%;
      z-index: 99;
      /* position: fixed!important; */
      transition: all .1s ease-in-out;
      height: 100px;
      padding:10px 2px 10px 2px;
  }
  .header-custom ul {
      background-color: #fff0;
  }
  ul.sub-menu {
      position: absolute;
      background: #080808 !important;
      padding: 0 10px 20px 10px !important;
      margin:0 !important;
  }
  ul.sub-menu {
      background: #050505 !important;
      padding: 0 20px;
      box-shadow: 0 19px 30px 0 rgba(25, 25, 25, .1);
  }
    .head-container{
      /*max-width: 1920px;*/
      padding: 0px 40px;
    }
    .hmbg-overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    background-color: rgb(255,255,255);
    background-color: rgba(255,255,255, 0.97);
    overflow-x: hidden;
    transition: 0.5s;
  }
  
  .hmbg-overlay-content {
    position: relative;
    top: 10%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  
  .hmbg-overlay a {
    font-size: 18px;
    font-family: "Poppins";
    font-weight: bolder;
    color: #000000 !important;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  .hmbg-overlay a:hover, .hmbg-overlay a:focus {
    color: #f1f1f1;
  }
  
  .hmbg-overlay .closebtn {
    position: absolute;
    top: 0px;
    right: 30px;
    font-size: 60px !important;
    text-decoration: none;
    font-weight: bold;
    color: #000 !important;
  }
  .header-custom ul#menu-sw-menu li a {
    font-size: 16px;
      font-family: "Poppins";
      font-weight: 400;
      color: #ffffff !important;
      text-decoration: none;
  }
  .header-transparency ul#menu-sw-menu .sub-menu li a {
    color: #454545 !important;
  }
  .header-transparency ul#menu-sw-menu li a {
    font-size: 18px;
      font-family: "Poppins";
      font-weight: bolder;
      color: #ffffff !important;
      text-decoration: none;
      text-transform: uppercase;
  }
  ul.sub-menu li a {
      font-size: 15px !important;
  }
  ul#menu-sw-menu li {
      display: inline-block;
      padding: 0 10px;
  }
  ul#menu-sw-menu li {
    display: inline-block;
      padding: 0 25px;
      height: 88px;
      line-height: 88px;
  }
  ul.sub-menu {
      display: none;
      
  }
  .menu-item-has-children:hover ul.sub-menu {
  display: block;
  background-color: rgb(0, 0, 0);
  
  }
  ul.sub-menu {
  padding-left: 0;
  background-color: rgb(5, 5, 5);
  }
  ul.sub-menu li {
      display: block !important;
      background-color: rgb(5, 5, 5);
  }
  ul.sub-menu li {
      padding: 0 !important;
  }
  ul#menu-sw-menu {
      margin: 0;
      padding: 0 0 0 60px;
  }
  .hmbg-icon{
    font-size:30px;
    cursor:pointer;
  }
  #hmbg-icon{
  display:none;
  }
  .menu-btns li{
      display: block;
      padding: 0 10px;
  }
  .dropdown-menu {
    min-width: 155px !important;
  }
  .dropdown-menu>li>a {
    padding: 3px 0px !important;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: bold;
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
  }
  ul.dropdown-menu.show {
    background: #fff !important;
    margin: 0 !important;
  }
  .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
    color: #000 !important;
    text-decoration: none !important;
    background-color: #ffffff !important;
  }
  .header-custom a.menu-btn-lg {
    background: #00E90B !important;
    color: #fff !important;
    border: 1px solid #00E90B;
    padding: 8px 35px;
    border-radius: 4px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none !important;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    margin-right: 10px !important;
  }
  .header-transparency a.menu-btn-lg {
      background: transparent !important;
      color: #fff !important;
      border: 1px solid #ffffff;
      padding: 8px 35px;
      border-radius: 4px;
      font-family: "Poppins";
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
      text-decoration: none !important;
      width: initial;
      transition: all .5s ease-in-out;
      display: inline-block;
      margin-right: 10px !important;
  }
  
  a.dropdown-toggle.menu-btn-lg:hover,a.dropdown-toggle.menu-btn-lg:active,a.dropdown-toggle.menu-btn-lg:focus {
    background: transparent !important;
  }
  .header-custom a.menu-btn-sg{
      background: #00E90B !important;
      color: #fff !important;
      border: 1px solid #00E90B;
      padding: 8px 35px;
      border-radius: 4px;
      font-family: "Poppins";
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
      text-decoration: none !important;  
      width: initial;
      transition: all .5s ease-in-out;
      display: inline-block;
  }
  .header-transparency a.menu-btn-sg{
    background: transparent !important;
    color: #fff !important;
    border: 1px solid #ffffff;
    padding: 8px 35px;
    border-radius: 4px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none !important;  
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
  }
  a.menu-btn-lg:hover,a.menu-btn-lg:active,a.menu-btn-sg:hover,a.menu-btn-sg:active {
  color:#fff !important;
  }
  .header-custom a.menu-btn-lg:hover, .header-custom a.menu-btn-lg:active, .header-custom a.menu-btn-lg:focus {
    background: #00E90B !important;
    color: #Ffffff!important;
  }
  
  .menu-btns {
      margin-top: 10px;
  }
  #newRegion{
    display: none;
  }
  .navmenu-btns.navbar-nav>li>a {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    line-height: 20px !important;
    position: relative;
    display: block;
    padding: 10px 15px;
  }
  div#hmbg-myNav {
      display: none;
      opacity: 0;
  }
  .custom-menu-class {
      text-align: center;
      margin-top: -8px;
  }
  img.sw-logo {
    width: auto !important;
    /* max-width: 195px !important; */
    outline: none !important;
    margin-top: 0px !important;
  }
  ul.sub-menu li {
      text-align: left;
      line-height: 56px;
      height: 48px !important;
  }
  .row:before, .row:after {display: none !important;}
  .dropdown-menu-one>li>a {
    display: inline-block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 2;
    color: #000;
    white-space: nowrap;
    font-family: 'Poppins';
    font-size: 18px;
    text-transform: capitalize;
  }
  .logout-icon-one {
    width: 20px;
    margin: 10px 6px -5px -5px;
    display: inline-block;
  }
  .visit-icon-one{
    width: 20px;
    margin: 10px 6px -5px -4px;
    display: inline-block;
  }
  a.logout-link-one{
  width: 100%;
  height: 100%;
  }
  .dropdown-menu-one {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    line-height: 1.4;
    display: none;
    float: left;
    min-width: 100%;
    padding: 0px 0;
    margin: -10px -4px 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc !important;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
  }
  .dropdown-menu-one>li:focus, .dropdown-menu-one>li:hover{
    color: #262626 !important;
    text-decoration: none;
    background: #f7f7f7 !important;
    outline: none;
    cursor: pointer;
  }
  .dropdown-menu-one li:nth-child(1){
    border-bottom: 1px solid #e47558 !important;
    line-height: 0.7;
  }
  .dropdown-menu-one li:nth-child(2) {
    border-bottom: 3px solid #e47558 !important;
    line-height: 0.7;
  }
  
  .drp-menu-one:before,
  .drp-menu-one:after{
    bottom: 100%;
    left: 85%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .drp-menu-one:before {
      border-bottom-color: #ccc;
    border-width: 9px;
    margin-left: 5px;
  }
  .drp-menu-one:after{
    border-color: rgba(118, 118, 118, 0);
    border-bottom-color: #fff;
    border-width: 7px;
    margin-left: 7px;
  }
  a.myaccount-one {
    border: 1px solid #000 !important;
    border-radius: 25px;
    padding: 10px 20px;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    font-size: 1vw;
  
  }
  .signin-one{
  border: 1px solid #000 !important;
    border-radius: 25px;
    padding: 10px 20px;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    font-size: 1vw;
  
  }
  .signin-one:hover{
      background: #e47558 !important;
    color: #fff !important;
    border: 1px solid #e47558 !important;
  }
  a.myaccount-one:hover{
   background: #e47558 !important;
    color: #fff !important;
    border: 1px solid #e47558 !important;
  }
  .dvLoading {
    background: #000 url(/static/media/preloader.a2ea1e10.gif) no-repeat center center;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    margin: 0;
    opacity: 0.98;
    background-color: #ffffff;
  }
  .d-tc{
    margin-top: auto;
    margin-bottom: auto;
    /*display: table-cell;*/
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .d-tc{
     height:100%;
    }
  }
  .sub-menu li:nth-child(2) a {
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    padding: 15px 24px 15px 0;
  }
  .sub-menu li:nth-child(4) a {
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    padding: 15px 0 15px 0px;
  }
  .sub-menu li:nth-last-child(1) {
    height: 56px !important;
  }
  .xsf-sep{
    width: 100%;
    height: 150px;
  }
  @media only screen and (max-width:1600px) and (min-width:1200px) {
  ul#menu-sw-menu li a {
    font-size: 16px;
  }
  ul.sub-menu li a {
      font-size: 15px !important;
  }
  }

  @media only screen and (max-width:1366px) and (min-width:1024px) {
    img.sw-logo {
      width: auto !important;
      max-width: 250px !important;
      /* margin-top: 7px !important; */
  }
  ul#menu-sw-menu li a {
    font-size: 15px;
  }
  ul.sub-menu li a {
      font-size: 14px !important;
  }
  a.menu-btn-lg,a.menu-btn-sg{
    font-size: 14px;
    padding: 8px 20px !important;
  }
  img.sw-logo{
    width: auto !important;
    /* max-width: 185px !important; */
    /* margin-top: 17px !important; */
  }
  ul#menu-sw-menu {
    display: flex;
    justify-content: space-between;
      margin: 0;
      padding: 0 40px 0 40px !important;
  }
  }
  @media only screen and (max-width:1023px) and (min-width:280px) {
    ul.sub-menu {
      padding: 0 10px 0px 10px !important;
    }
    .hmbg-overlay .closebtn {
      top: -5px !important;
      right: 36px !important;
    }
    .avia_mobile .hmbg-overlay .closebtn {
    top: 28px !important;
    right: 50px !important;
    }
    div#hmbg-myNav {
      display: block !important;
      opacity: 1 !important;
  }
  .col-md-8 .custom-menu-class {
      display: none;
  }
  .menu-btns {
      margin-top: 0;
      display: none;
  }
  #hmbg-icon{
    display: block !important;
  }
  .head-container {
      padding: 0px 30px;
  }
  ul#menu-sw-menu-1 li {
      list-style: none;
  }
  ul#menu-sw-menu-1 li {
      list-style: none;
      line-height: 3.0;
  }
  ul#menu-sw-menu-1 {
      padding-left: 0;
  }
  .hmbg-overlay-content .custom-menu-class {
      display: block;
  }
  .custom-menu-class,.menu-btns {
      display: none;
  }
  .sw-head.container {
    padding: 0 30px !important; 
  }
  ul.sub-menu {
    position: relative !important;
  }
  ul.sub-menu li a {
    text-align: center !important;
    display: inherit !important;
  }
  .hmbg-overlay a.menu-btn-lg, .hmbg-overlay a.menu-btn-sg {
    font-size: 16px !important;
    display: block !important;
    margin: 10px 20px !important;
  }
  ul.dropdown-menu.show {
    width: 90% !important;
  }
  .dropdown-menu>li>a {
    padding: 3px 15px !important;
    text-align: center !important;
  }
  .sub-menu li:nth-child(2) a {
    padding: 0px 0 !important;
  }
  .sub-menu li:nth-child(4) a {
    padding: 0px 0 !important;
  }
  }
  @media only screen and (max-width:1280px) {
  .dropdown-menu {
    min-width: 139px !important;
  }
  }
  @media only screen and (max-width: 1366px) and (min-width: 280px){
  .sw-head.container {
    padding: 0 30px !important; 
  }
  }
  .container {
    width: 1270px!important;
    max-width: 1249px !important;
  }
  @media only screen and (max-width: 1366px) and (min-width: 280px){
    .container {
        width: 100%!important;
        max-width: 100%!important;
        padding: 0 30px !important;
    }
    }
    @media only screen and (max-width: 375px) and (min-width: 280px){
    .hmbg-overlay a {
      font-size: 16px !important;
    }
    ul.sub-menu li a{
      font-size: 14px !important;
    }
   
  }
  @media (min-width:280px) and (max-width:767px) and (orientation:landscape) {
    .section_seprator {
      width: 80px!important;
      height: 40px!important;
  }
  }
  @media (min-width: 480px) and (max-width: 1024px) {
    .RegionSwitcher{
      background-color: rgb(88, 88, 88);
      border-radius: 8px;
      padding: 10px;
      margin-right: 10px;
      margin-top: 0px !important;
      width: 26% !important;
    }
}
  @media (min-width: 480px) and (max-width: 1020px) {
    .RegionSwitcher{
      display: none !important;
    }
    #newRegion{
      display: inline !important;
    margin-right: 10px !important;
    /* margin-top: 10px !important; */
    margin-left: 250px !important;
    }
}
  @media (min-width:280px) and (max-width:767px) {
  /*.fl-ct {
    display: block !important;
  }*/
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  span#hmbg-icon {
    display: inline-block;
    position: absolute;
      top: 20px;
      right: 30px !important;
      font-size: 39px;
  }
  img.sw-logo {
    width: auto !important;
    max-width: 180px !important;
    /* margin-top: 18px !important; */
  }
  }
  @media (min-width:768px) and (max-width:1023px) {
  span#hmbg-icon {
    display: inline-block;
    position: absolute;
      top: 20px;
      right: 30px !important;
      font-size: 39px;
  }
  img.sw-logo {
    width: auto !important;
    /* max-width: 185px !important; */
    /* margin-top: 18px !important; */
  }
  }
  @media (min-width:601px) and (max-width:767px) and (orientation:landscape) {
  h3.sw-ft-title,p.sw-ft-nav-text,a.sw-ft-anch {
    font-size: 14px !important;
  }
  }
  @media (min-width:480px) and (max-width:600px) and (orientation:landscape) {
    h3.sw-ft-title,p.sw-ft-nav-text,a.sw-ft-anch {
      font-size: 13px !important;
    }
  }
  ul#menu-sw-menu li a {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 400;
    color: #ffffff !important;
    text-decoration: none;
  }
  .color{
    background-color: #fff0;
    position: absolute;
    /* width: 100%; */
    z-index: 99;
    /* position: fixed!important; */
    transition: all .1s ease-in-out;
    height: 100px;
    padding: 10px 2px 10px 2px;
  }


  .head-login{
    background-color: #fff0;
    /* position: absolute; */
    /* width: 100%; */
    z-index: 99;
    /* position: fixed!important; */
    transition: all .1s ease-in-out;
    height: 100px;
    padding: 10px 2px 10px 2px;
  }
  .custom-class{
    padding: 2px 15px 2px 15px !important; 
  }
  .custom-nav{
    margin-right: 25px;
  }
  .color{
    background-color: #131313 !important;
  }
  .nav-link{
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 400;
    color: white !important;
    text-decoration: none;

  }

  .center-main{
    width: 700px !important;
    justify-content: space-evenly !important;

}

@media only screen and (max-width:700px) {
    .center-main{
        width: 400px !important;
        justify-content: center;
    
    }
}

.glassy-background {
  
  background-color: rgba(57, 57, 57, 0.2); /* Set the background color with transparency */
  -webkit-backdrop-filter: blur(60px);
          backdrop-filter: blur(60px); /* Apply a blur effect */
  
  border-radius: 10px; /* Add rounded corners for a glassy effect */
  text-align: center;
  color: #181818; /* Text color */
}

.glassy-background2 {
  background-color: rgba(57, 57, 57, 0.2); /* Set the background color with transparency */
border-radius: 10px;
height: auto;  
   /* Add rounded corners for a glassy effect */
  text-align: center;
  color: #181818;
}
/* .main-head-css{
  position: absolute;
} */



@media (min-width: 768px) and (max-width: 1024px) {
  .navbar-nav{
  padding: 25px 0px ;
    display: flex !important;
    flex-direction:row !important;
    
  }
  .navbar{
    padding-bottom: 30px;
  }
  
  .responsive{
padding: 10px 20px !important;
margin-left: 55px !important;
font-size: medium !important;
   
  }
  .homeslide{
    font-size: 14px !important;
  }
  .homeSliderBtn{
    padding: 7px 20px !important;
    margin-top: 0px  !important; 

font-size: 13px !important;
  } 


  /* CSS */
  
}

@media (min-width: 481px) and (max-width: 1022px) {
  
}

@media (min-width: 320px) and (max-width: 480px) {
 
.nav-item{
  display: flex;
  margin-bottom: 20px !important;
  text-decoration: none;
}
/* .region {
  display: flex;
} */
.RegionSwitcher{
    width: 11rem !important;
    margin-bottom: 20px !important;
} 
.new_icon{
  display: flex !important;
  flex-direction: row !important;
  /* width: 30% !important; */
}
.globe_icon{
  width: 30px !important;
  height: 15px !important;
  margin-top: 7px !important;
}
.arrow_icon{
  width: 20px !important;
  height: 7px !important;
  margin-top: 12px !important;
}
.region{
  margin-top: 3px !important;
}

}

.wrapper{
  position: absolute;
  top: 35%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

.wrapper .title{
  font-weight: 700;
  font-size: 24px;
  color: #fff;
}

.select_wrap{
  width: 225px;
  margin: 15px auto;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}

.select_wrap .default_option{
  background: #fff;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.select_wrap .default_option li{
    padding: 10px 20px;
}

.select_wrap .default_option:before{
    content: "";
    position: absolute;
    top: 18px;
    right: 18px;
    width: 6px;
    height: 6px;
    border: 2px solid;
    border-color: transparent transparent #555555 #555555;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.select_wrap .select_ul{
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  display: none;
}

.select_wrap .select_ul li{
  padding: 10px 20px;
  cursor: pointer;
}

.select_wrap .select_ul li:first-child:hover{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.select_wrap .select_ul li:last-child:hover{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.select_wrap .select_ul li:hover{
  background: #fff4dd;
}

.select_wrap .option{
  display: flex;
  align-items: center;
}

.select_wrap .option .icon{
  background: url('https://i.imgur.com/oEZu0sK.png') no-repeat 0 0;
	width: 32px;
	height: 32px;
  margin-right: 15px;
}

.select_wrap .option.pizza .icon{
  background-position: 0 0;
}

.select_wrap .option.burger .icon{
  background-position:  0 -35px;
}

.select_wrap .option.ice .icon{
  background-position: 0 -72px;
}

.select_wrap .option.fries .icon{
 background-position: 0 -111px;
  width: 25px;
}

.select_wrap.active .select_ul{
  display: block;
}

.select_wrap.active .default_option:before{
  top: 25px;
  -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
}
/* Ipad Pro */


.img.sw-logo{
  margin-left: 20px !important;
}
@font-face {
    font-family: Bariol;
    src:
     url(/static/media/bariol_regular-webfont.e4213a39.eot);
    
    src:
     url(/static/media/bariol_regular-webfont.e4213a39.eot?#iefix) format("embedded-opentype"),
     url(/static/media/bariol_regular-webfont.7d0cf79e.svg) format('svg'),
     url(/static/media/bariol_regular-webfont.6c235190.ttf) format('truetype'),
     url(/static/media/bariol_regular-webfont.38c3b9e9.woff) format('woff'),
     url(/static/media/Bariol_Regular.6a64a521.otf) format('otf'),
     url(/static/media/bariol_regular-webfont.6577b355.woff2) format('woff2');
     font-weight: 400;
     font-style: normal;


}


@font-face {
    font-family: GothamBook;
    src: url(/static/media/Gotham-Book.7fe2d669.eot);
    src: url(/static/media/Gotham-Book.7fe2d669.eot?#iefix) format("embedded-opentype"), 
    url(/static/media/Gotham-Book.65288315.woff) format("woff"),
     url(/static/media/Gotham-Book.79ab7007.ttf) format('truetype'), 
     url(/static/media/Gotham-Book.92032bfd.svg#Gotham-Book) format("svg"),
      url(/static/media/Gotham-Book.e37cf599.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: BariolBold;
    src: url(/static/media/bariol_bold-webfont.fa0ddc94.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

section#sw_home_three{
display: none !important;
}
.new_spin{
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.head-left{
    text-align: left;
}
img#deskstop_slider {
    display: block;
}
img#mobile_slider {
    display: none;
}
p.sw_slider_sup {
    font-size: 20px;
    color: #000;
    font-family: "Poppins"!important;
    text-transform: uppercase;
    text-shadow: none!important;
}
.middle_heading {
    color: #ffffff!important;
    font-family: "Product Sans";
    font-size: 100px;

    font-weight: bold!important;
    line-height: 1.3;
    margin: 0 0 40px 0 !important;
    text-transform: inherit !important;
}
p.middle_subtext {
    color: #ffffff;
    font-family: 'Poppins' !important;
    font-size: 18px;
    line-height: 1.5;
    margin: 0 0 40px 0 !important;
    font-weight: 300 !important;
}




input#sw_slider_email {
    padding: 9px;
    border-radius: 5px;
    box-shadow: none!important;
    border: none!important;
    box-shadow: 0 9px 5px 0 rgba(0, 0, 0, .18)!important;
    outline: 0;
    width: 60%;
    font-family: Bariol!important;
    color: #000!important;
}
.sw_slider_submit {
    background: #1880dd;
    border: none;
    padding: 11px 20px;
    border-radius: 6px;
    width: 130px;
    box-shadow: 0 9px 13px 0 rgba(0, 0, 0, .16)!important;
    outline: 0;
    font-family: "Poppins";
    margin: 0 0 0 10px;
    text-transform: uppercase;
    font-weight: bold !important; 
}
#myCarousel img {
    width: 100%!important;
}
/*.container {
    width: 1270px!important;
    max-width: 1249px !important;
}*/

.fa-check{

    color: green;
}
section#sw_home .col-sm {
    padding: 0;
}
.sw_features_div {
    padding: 16px 0 40px 98px;
}
.vertical_seprator {
    background: #e5e5e5;
    bottom: 0;
    content: " ";
    margin-left: -15px;
    margin-bottom: 20px;
    position: absolute;
    top: 0;
    width: .3ch;
}
h3.sw_features_heading {
    margin: 0;
    font-size: 16px;
    font-family: "Poppins";
    color: #292929!important;
    font-weight: 700;
}
ul.sw_features_list {
    margin-left: 0!important;
    padding-left: 0;
    font-size: 14px!important;
    font-family: "Poppins" !important;
    color: #292929;
}
.sw_features_seprator {
    width: 100%;
    height: 30px;
}
.row.sw_section_one {
    padding: 50px 40px;
    border-radius: 9px;
    box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
}
#myCarousel >.item-middle {
    position: relative;
    display: block!important;
}
section#main_banner{
    background-color: #131313;
    /* //padding-top: 65px; h*/
}
.mainbanner_padd{
    padding: 20px 20px 80px 60px;
    align-self: center;
}
.mainbannerimg_padd{
    /* padding: 80px;h */
    text-align: right;
}
section#sw_home .container {
    background: #fff!important;
}
section#sw_home {
    margin-top: -120px;
    position: relative;
    left: 0;
    right: 0;
}
ul.sw_features_list li {
    list-style-type: none;
    line-height: 2.2;
}
#sw_home i {
    color: #62abaa!important;
    font-size: 14px!important;
}
.section_seprator {
    width: 80px;
    height: 80px;
}
.sw_online_heading {
    font-size: 38px!important;
    color: #222;
    font-family: "Poppins" !important;
    line-height: 56px;
    font-weight: 700;
    text-align: center;
}
.sw_ol_sub_text {
    font-size: 23px;
    font-family: "Poppins" !important;
    text-align: center;
    color: #4d545b;
    line-height: 33px;
    width: 80%;
    margin: 0 auto;
}
.sw_fm_heading {
    font-size: 21px;
    line-height: 26px;
    color: #010101;
    text-align: center;
    font-family: "Poppins"!important;
    font-weight: bolder!important;
    margin: 0!important;
}
.sw_fm {
    margin: 0 auto;
    text-align: center;
}
input#email_address, input#name, input#phone_no {
    padding: 10px;
    border-radius: 5px;
    box-shadow: none!important;
    border: none!important;
    box-shadow: 0 9px 5px 0 rgba(0, 0, 0, .18)!important;
    outline: 0;
    color: #000!important;
    border: 2px solid #000!important;
    width: 220px;
    background: #e2f2fb!important;
    font-family: "Poppins" !important;
    font-size: 16px;
    margin: 0 15px;
}

input#sw_fm_submit {
    background: #1880dd;
    border: none;
    padding: 12px 20px;
    border-radius: 6px;
    width: 130px;
    box-shadow: 0 9px 13px 0 rgba(0, 0, 0, .16)!important;
    outline: 0;
    color: #fff!important;
}
.sw_sep_30 {
    width: 100%;
    height: 30px;
}
.sw_sep_15 {
    width: 100%;
    height: 15px;
}
.sw_sep_20 {
    width: 100%;
    height: 20px;
}
::-webkit-input-placeholder {
    font-size: 16px;
    font-family: Bariol;
    color: #222;
}
::placeholder {
    font-size: 16px;
    font-family: Bariol;
    color: #222;
}
.sw_s4_sup {
    color: #FBC504!important;
    font-size: 20px;
    font-family: "Poppins"!important;
}
.ds_s4_sup{
    color: #fff!important;
    font-size: 18px;
    font-family: "Poppins"!important;
    font-weight:300;
}
.sw_s4_heading {
    font-family: "Poppins"!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #fff;
    width: 100%}
.sw_s4_text {
    font-size: 18px;
    font-weight: 300 !important;
    line-height: 32px;
    font-family: "poppins" !important ;
    color: #fff !important;
    margin: 30px 0;
}
.sw_portfo_sz{
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 20px;
    font-family: "poppins" !important ;
    color: #fff !important;
    margin: 0px !important;
}
.txt_mid{
    align-self: center;
    text-align: right;
}
.view_padd{
    padding-right: 40px;
    padding-right: 40px;
}
.sw_home_button {
    color: #ffffff!important;
    border: 1px solid #ffffff!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    text-shadow:none !important;
}
.sw_home_button:hover {
    background: #ffffff!important;
    text-decoration: none!important;
    border-color: #ffffff!important;
    color: #1880dd !important;
}
.sw_home_button_bk {
    color: #1880dd!important;
    border: 1px solid #1880dd!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    text-shadow:none !important;
}
.sw_home_button_bk:hover {
    background: #1880dd!important;
    text-decoration: none!important;
    border-color: #1880dd!important;
    color: #ffffff !important;
}
.sw_work_button {
    color: #ffffff!important;
    border: 1px solid #ffffff!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
}
.sw_work_button:hover {
    background: #ffffff!important;
    text-decoration: none!important;
    border-color: #ffffff!important;
    color: #1880dd!important;
}
.mainclr{
    color: #FBC504;
}
.mainclr:hover{
    text-decoration: none !important;
    color: #FBC504 !important;
}
section#ourprocess_bg{
    background-color: #141414;
    border: none
}
.sw_home_succeed{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 15px 30px;
    text-decoration: none!important;
    border-radius: 50px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    /* margin: 0 5px 0 5px !important; */
    text-shadow: none !important;
}
.sw_home_succeed:hover {
    background: transparent !important;
    text-decoration: none!important;
    color: #ffffff!important;
    border-color: #ffffff!important;
}
.sw_portfolio_succeed:hover{
    background: transparent !important;
    text-decoration: none!important;
    color: #ffffff!important;
    border-color: #ffffff!important;
}
.sw_home_succeed_bk{
    background: #1880dd!important;
    color: #ffffff !important;
    border: 1px solid #1880dd!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    margin: 0 15px 0 0 !important;
    text-shadow: none !important;
}
.sw_home_succeed_bk:hover {
    background: transparent !important;
    text-decoration: none!important;
    color: #1880dd!important;
    border-color: #1880dd!important;
}
section#sw_home_seven {
    background-image: url(/static/media/make-decision.15149530.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
h2.sw_con_heading {
    text-align: center!important;
    margin: 0 auto;
    line-height: 46px;
    font-family: "Poppins"!important;
    font-weight: 700!important;
    font-size: 38px;
    color: #ffffff;
    width: 100%;
}
h3.sw_con_su_heading {
    font-size: 22px;
    color: #ffffff;
    font-family: "Poppins";
    text-align: center!important;
    margin: 0 auto;
    line-height: 29px;
}
p.sw_con_su_text {
    font-family: "Poppins" !important;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    margin: 0 auto;
    line-height: 37px;
    width: 100%}
.sw_btn_div {
    text-align: center;
    margin: 0 auto;
}
a.sw_home_sac {
    color: #fff!important;
    border: 1px solid #fff!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    margin: 0 15px 0 0;
}
.sw_home_sac:hover {
    background: #fff!important;
    text-decoration: none!important;
    border-color: #fff!important;
    color: #1880dd!important;
}
.row {
    --bs-gutter-x: 0rem !important;
  }
.process_set{
    display: "inline";
}
.process_pad{
    margin-top: 40px;
}
.sw_posu_text {
    font-size: 18px !important;
    font-weight: 300 !important;
    line-height: 33px;
    color: #ffffff !important;
    font-family: "Poppins" !important;
    text-align: center;
    width: 100%
}
.sw_posu_heading {
    font-family: "Poppins"!important;
    font-weight: 400!important;
    font-size: 35px;
    color: #ffffff;
    width: 100%;
}
.hd_centr{
    text-align: center;
}
.hd_left{
    text-align: left;
}
section#sw_home_threea img {
    width: auto!important;
}
.po_icon {
    margin: 30px auto;
    text-align: center;
    height: 101px;
    width: 101px;
    padding: 30px;
    
    border-radius: 50%;
}
h3.sw_suheading {
    text-align: center;
    font-size: 16px;
    font-family: "Poppins";
    line-height: 30px;
}
.process_suheading{
    text-align: center;
    font-size: 20px;
    font-family: "Poppins";
    line-height: 30px;
    color: #fff;
}
p.sw_sutext {
    text-align: center;
    line-height: 25px;
    margin: 0 auto;
    padding-left: 0;
    font-size: 14px!important;
    font-family: "Poppins"!important;
    color: #292929;
    width: 70%;
}
.process_sutext{
    text-align: center;
    line-height: 23px;
    margin: 0 auto;
    padding-left: 0;
    font-size: 14px!important;
    font-family: "Poppins"!important;
    color: #fff;
    width: 90%;
}
.col-center {
    margin: 0 auto;
    float: none!important;
}

 .item-middle {
    color: #999;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    min-height: 290px;
}
 .item-middle .img-box {
    width: auto;
    height: auto;
    margin: 0;
    padding: 5px;
    display: table-cell;
}
 .testimonial {
    padding: 20px 10px;
}
.overview b {
    text-transform: uppercase;
    color: #7aa641;
}



div#myCarousel1 {
    background: #fff;
    padding: 0 0px;
    border-radius: 5px!important;
}

#testimonial_background a.carousel-control {
    display: none;
}

p.overview {
    display: table-cell;
    vertical-align: middle;
}
.sw_owner_name {
    font-family: "Poppins";
    font-size: 18px;
    line-height: 28px;
    color: #000!important;
}
p.overview {
    font-family: "Poppins";
    font-size: 13px;
    color: #000!important;
}
p.testimonial {
    line-height: 2;
    color: #000!important;
    text-align: left;
    margin-left: 0!important;
    padding-left: 0;
    font-size: 18px!important;
    font-family: Bariol!important;
}
.testimonial_pvl {
    font-style: italic;
    color: #777 !important;
    font-family: "Poppins";
    text-align: left;
    text-decoration: underline;
    font-size: 15px;
}
#custom-footer {
    display: block!important;
}
.multipletab {
    width: 100%;
    margin: 0 auto;
    background: #131313;
    overflow: hidden;
    position: relative;
}
.tab-buttons {
    display: inline-block;
    width: 100%;
    border-bottom: 2px solid #666;
}
.tab-buttons>span {
    display: block;
    float: left;
    cursor: pointer;
    color: #fff;
    background: #7d69ff;
    padding: 0 15px 0 0;
    line-height: 50px!important;
    font-size: 16px;
    font-family: "Poppins";
}
.tab-buttons span {
    /* border-bottom: 3px solid #ffffff; */
    margin: 0 30px;
}
.tab-buttons>span.active {
    background: #1880dd;
    color: #fff;
    font-family: "Poppins";
    font-size: 20px;
    line-height: 50px!important;
    border-bottom: 3px solid #fff;
}
.tab-content {
    position: relative;
}
.tab-content>div {
    position: absolute;
    color: #666;
    font-family: 'pt sans', sans-serif;
}
.tab-nav {
    opacity: 1;
    transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
}
.multipletab:hover .tab-nav {
    opacity: 1;
}
.tab-nav>span {
    position: absolute;
    top: 0;
    cursor: pointer;
    display: block;
    height: 35px;
    width: 35px;
}
.tab-nav>span.next {
    right: 0;
    background-position: 5px;
    color: #fff;
    font-size: 34px;
    padding-top: 3px;
}
.tab-nav>span.prev {
    right: 50px;
    background-position: 5px;
    color: #fff;
    font-size: 34px;
    padding-top: 3px;
}
section#sw_home_work {
    background: #7D69FF;
    padding: 80px 40px 80px 40px;
}
.col_padd{
    padding-left: 40px;
    padding-right: 40px;
}
.cont-mid{
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-self: center;
}
.sw_wk_heading {
    font-size: 38px;
    line-height: 40px;
    font-family: "Poppins";
    color: #fff;
}
.sw_wk_text {
    font-size: 23px;
    line-height: 29px;
    font-family: "Poppins" !important;
    color: #fff;
}
.tab-content {
    background: #1880dd;
}
.tab-buttons {
    background: #7d69ff;
    border: none;
}
.tab-content {
    height: 550px!important;
}
.sw_wk_stars {
    display: inline-block;
}
.sw_wk_stars i {
    font-size: 24px;
    color: #FBC504;
}
.sw_portfolio_succeed{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 8px 15px;
    text-decoration: none!important;
    border-radius: 50px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 14px;
    min-width: 150px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    /* margin: 0 5px 0 5px !important; */
    text-shadow: none !important;
}
.horizontal_seprator {
    border: 1px solid #a1a1a1;
  
    margin-left: 60px;
    margin-right: 60px;
    text-align: center;
}
#sw_home_threea .col-sm:nth-child(2):before {
    content: "";
    position: absolute;
    left: -45px;
    top: 105px;
    display: inline-block;
    width: 80px;
    margin: 0 auto;
    text-align: center;
    height: 3px;
    border-bottom: 1px solid #272230;
}
#sw_home_threea .col-sm:nth-child(3):before {
    content: "";
    position: absolute;
    left: -45px;
    top: 105px;
    display: inline-block;
    width: 80px;
    margin: 0 auto;
    text-align: center;
    height: 3px;
    border-bottom: 1px solid #272230;
}
#sw_home_threea .col-sm:nth-child(4):before {
    content: "";
    position: absolute;
    left: -30px;
    top: 105px;
    display: inline-block;
    width: 80px;
    margin: 0 auto;
    text-align: center;
    height: 3px;
    border-bottom: 1px solid #272230;
}
#sw_home_threea .col-sm:nth-child(5):before {
    content: "";
    position: absolute;
    left: -48px;
    top: 105px;
    display: inline-block;
    width: 80px;
    margin: 0 auto;
    text-align: center;
    height: 3px;
    border-bottom: 1px solid #272230;
}
.slide-viewer {
    position: relative;
    overflow: hidden;
    height: 300px;
    background: #fff;
    padding: 20px;
    text-align: left;
}
.slide-group {
    width: 100%}
.slide {
    width: 100%;
    /*height: 100%;*/
    display: none;
}
.slide:first-child {
    display: block;
}
.slide-buttons {
    text-align: center;
    margin: 10px 0;
}
.slide-btn {
    width: 20px;
    height: 20px;
    background-color: #666;
}
ol.carousel-indicators li.active {
    background-color: #000 !important;
}
.bs-example {
    background: #fff;
    padding: 0 20px;
}
.slide-btn {
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 2px solid #000!important;
    margin: 0 2px;
    outline: 0!important;
}
.slide-buttons {
    text-align: center;
    margin: 10px 0;
    position: absolute;
    top: -38px;
    left: 0;
}
.img-box {
    text-align: left;
    display: table-cell;
}
.sw_wk_rating_icon {
    margin: 20px 0;
}
.sw_lg {
    display: block!important;
}
.sw_md {
    display: none!important;
}
.sw_xs {
    display: none!important;
}
.sw_xsp {
    display: none!important;
}
.sw_xsp1 {
    display: none!important;
}
 
.sw_rimg{
width: 100%;
float: right;
}
.sw_limg{
width: 100%;
}
.img_siz{
    height: auto;
    width: 600px;
}
.section_box
    {
        padding: 50px 40px;
        border-radius: 9px;
        box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
    }

.myimg{
    width: auto!important;
}
.myimg1{
    width: auto!important;      
}


.align-middle-top{
vertical-align: top !important;
}
div#content1, div#content2, div#content3, div#content4.div#content5 {
    width: 100% !important;
}

@media only screen and (max-width:1600px) and (min-width:1200px) {
    section#sw_home_work {
        background: #7D69FF;
        padding: 80px 40px 80px 40px;
    }
    #business>.a {
        grid-column: 1;
      }
      
      #business>.b {
        grid-column: 2;
      }
    .cont-mid{
        padding: 40px;
        padding-top: 0px !important;
    }
    .process_pad{
        margin-top: 40px;
    }
    .mainbannerimg_padd{
        display: block;
    }
    .mainbanner_padd {
        padding: 20px 20px 80px 60px !important;
}
    .middle_heading {
        font-size: 40px;
    }
    p.middle_subtext {
        font-size: 18px;
    }
    .sw_online_heading,.sw_wk_heading,.sw_posu_heading,h2.sw_con_heading {
        font-size: 32px !important;
    }
    .sw_ol_sub_text,.sw_wk_text,.sw_posu_text,p.sw_con_su_text,.sw_s4_text {
        font-size: 20px;
    }
    .sw_s4_heading{
        font-size: 24px!important;
    }
    .tab-nav>span.next,.tab-nav>span.prev {
        font-size: 25px;
    }

}
@media only screen and (max-width:1366px) and (min-width:1024px) {
 .row.sw_section_one {
    padding: 50px 11px;
}
 .tab-buttons span {
     margin: 0 15px;
 }
 .sw_posu_heading, h2.sw_con_heading {
     font-size: 32px;
 }
 .testimonial {
     padding: 0 0 10px!important;
 }
 
 }

@media only screen and (max-width:1199px) and (min-width:768px) {
 
    .slide-viewer {
    height: 100%!important;
}
 .testimonial {
    padding: 0 0 10px!important;
}
#sw_home_threea .col-sm:nth-child(2):before {
    content: "";
    position: absolute;
    left: -28px!important;
    width: 35px!important;
}
#sw_home_threea .col-sm:nth-child(3):before {
    content: "";
    position: absolute;
    left: -16px!important;
    width: 40px!important;
}
#sw_home_threea .col-sm:nth-child(4):before {
    content: "";
    position: absolute;
    left: -24px!important;
    width: 40px!important;
}
#sw_home_threea .col-sm:nth-child(5):before {
    content: "";
    position: absolute;
    left: -33px!important;
    width: 40px!important;
}
section#sw_home {
    margin-top: 0!important;
}
.middle_heading {
    font-size: 26px!important;
    line-height: 1.3!important;
    margin: 0 0 20px 0 !important;
}
p.middle_subtext {
    font-size: 18px;
    line-height: 1.2!important;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px 0 10px;
}
.tab-buttons span {
    margin: 0 5px;
}
input#email_address, input#name, input#phone_no {
    width: 160px!important;
    font-size: 14px!important;
    margin: 0 4px!important;
}
.sw_s4_heading {
    font-size: 20px;
}
.tab-content .col-sm {
    padding: 0 80px!important;
}
.vertical_seprator {
    display: none!important;
}
.sw_online_heading,.sw_posu_heading {
    font-size: 20px !important;
    padding-top: 40px;
    

}
.sw_con_heading{
    font-size: 24px!important;
}
.sw_wk_heading{
    font-size: 20px!important;
}
.sw_ol_sub_text,.sw_wk_text,.sw_posu_text,.sw_s4_text,.sw_s4_sup,p.sw_con_su_text {
    font-size: 18px !important;
}
#sw_home_four img, #sw_home_five img{
width: 100% !important;
}
.icon1, .icon2, .icon3 {
    background-position: top left;
    padding: 80px 0;
}
.sw_features_div {
    padding: 16px 0 0 8px;
}
#testimonial_background {
    padding: 12px !important;
}
.slide-viewer {
    padding: 5px 10px !important;
}
/* .section_seprator {
    width: 80px;
    height: 40px;
} */
.sw_sl_btn a.sw_home_succeed {
    margin: 0 10px 10px 0 !important;
}
.sw_sl_btn a.sw_home_succeed_bk {
    margin: 0 10px 10px 0 !important;
}
}
@media only screen and (max-width:1023px) and (min-width:768px) {

.mainbanner_padd {
        padding: 20px 20px 80px 60px !important;
}



.tab-nav>span.prev{
    display: none;
}
div#content1, div#content2, div#content3, div#content4.div#content5 {
    width: 100% !important;
}
#sw_home_threea .col-sm:nth-child(4):before {
    content: "";
    position: absolute;
    left: -24px!important;
    width: 40px!important;
    display: none;
}
}
@media only screen and (max-width:767px) and (min-width:280px) {
    section#sw_home_work {
        background: #7D69FF;
        padding: 40px 20px 40px 20px;
    }
    #business.reverse>.a {
        grid-column: 2;
      }
      #business.reverse>.b {
        grid-column: 1;
      }
    .cont-mid{
        padding: 5px;
    }
    .process_pad{
        margin-top: 20px;
    }
    /* i have Chnage here */
    /* .mainbannerimg_padd{
        display: none;
    } */
    .sw_wk_rating_icon{
        margin: 10px 0 !important;
    }
   
    .bs-example {
        padding: 0 0px !important;
    }
   
    div#content1, div#content2, div#content3, div#content4.div#content5 {
        width: 100% !important;
    }

.sw_slider_submit {
    width: 98px !important;
}

a.sw-brand-logo img {
    width: 155px!important;
    padding: 10px 0 12px 24px!important;
}
.header-custom {
    padding: 4px!important;
}
section#sw_home {
    margin-top: 0!important;
}
img {
    width: 100%!important;
}
input#email_address, input#name, input#phone_no {
    margin: 12px 15px!important;
}
ul.sw_features_list li {
    line-height: 1.6!important;
}
.sw_ol_sub_text {
    font-size: 18px;
}
.sw_online_heading {
    font-size: 22px!important;
    line-height: 35px!important;
}
.tab-buttons span {
    margin: 0 30px;
    float: none!important;
}
.tab-content {
    height: 705px!important;
}
.tab-content .col-sm {
    padding: 0 0 0 40px !important;
}
.sw_wk_rating_icon img {
    width: 90px!important;
    height: 81px!important;
}
.sw_s4_heading {
    font-size: 20px!important;
    margin: 0 !important;
}
.sw_s4_text,.sw_posu_text,.sw_wk_text,.sw_con_su_text {
    font-size: 18px!important;
}
.sw_con_su_heading{
font-size: 16px!important;
}
#sw_home_threea .col-sm:nth-child(2):before, #sw_home_threea .col-sm:nth-child(3):before, #sw_home_threea .col-sm:nth-child(4):before, #sw_home_threea .col-sm:nth-child(5):before {
    border-bottom: none!important;
}
#testimonial_background {
    margin-top: 100px!important;
    padding: 40px 15px!important;
    /*left: 15px;*/
}
.sw_posu_heading, h2.sw_con_heading {
    font-size: 22px!important;
    line-height: 35px!important;
    /* padding: 20px !important; */
}
.sw_home_button {
    display: block!important;
    /*margin: 10px 0!important;*/
    font-size: 16px !important;
}
.sw_work_button {
    display: block!important;
    margin: 20px 0!important;
    font-size: 16px !important;
    line-height: 2.0 !important;
}
.sw_btn_div {
    text-align: center;
    margin: 0 auto;
    width: 92% !important;
}
img#deskstop_slider {
    display: none;
}
img#mobile_slider {
    display: block;
}
.middle_heading {
    font-size: 30px!important;
    margin: 6px 0;
}
input#sw_slider_email {
    width: 55%!important;
}
p.middle_subtext {
    font-size: 16px!important;
    line-height: 1.2!important;
    margin-bottom: 10px !important;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px!important;
}
::-webkit-input-placeholder {
    font-size: 12px!important;
}
::placeholder {
    font-size: 12px!important;
}
.sw_ol_sub_text {
    width: 100%}
.sw_wk_heading {
    font-size: 22px!important;
    line-height: 34px!important;
}
.section_seprator {
    width: 80px!important;
    height: 30px!important;
}

.tab-nav>span.prev {
    right: 30px!important;
    font-size: 17px!important;
    padding-top: 17px!important;
    display:none !important;
}
.tab-nav>span.next {
    font-size: 17px!important;
    padding-top: 17px!important;
}
.slide-viewer {
    height: auto !important;
}
.sw_xs {
    display: block!important;
}
.sw_md {
    display: none!important;
}
.sw_lg {
    display: none!important;
}
.sw_xsp {
    display: none!important;
}
.sw_xsp1 {
    display: none!important;
}
a.sw_home_sac,a.sw_home_succeed,a.sw_home_succeed_bk,a.sw_home_button_bk{
    margin: 10px 0!important;
    display: block !important;
    font-size: 16px !important;
}
.sw_s4_sup {
    font-size: 16px;
}
.mb-col-sep{
    width: 40px;
    height: 40px;
}
.flex-switch{
    display: flex !important;
    flex-direction: column-reverse;
}
span#content1,span#content2,span#content3,span#content4,span#content5 {
    display: none;
}
.tab-buttons .active{
    display: block !important;
}
}
@media only screen and (max-width: 375px) {
    #myCarousel >.item-middle {
        padding-top: 150px;
        background: #1881dd;
    }
}
@media (min-width:320px) and (max-width:480px) and (orientation:landscape) {
    .sw_lg {
    display: none!important;
}
.sw_md {
    display: none!important;
}
.sw_xs {
    display: none!important;
}
.sw_xsp {
    padding-top: 50px!important;
    display: block!important;
}
.sw_xsp1 {
    display: none!important;
}
}
@media (max-width:767px) and (min-width:481px) and (orientation:landscape) {
    .sw_xsp1 {
    padding-top: 210px!important;
    display: block!important;
    background: #1881dd;
}
.sw_md {
    display: none!important;
}
.sw_lg {
    display: none!important;
}
.sw_xs {
    display: none!important;
}
.sw_xsp {
    display: none!important;
}
}
@media (max-width:1023px) and (min-width:768px) and (orientation:landscape) {


}
@media only screen and (max-width:1023px) and (min-width:768px) {
    .sw_md {
    display: block!important;
}
.sw_lg {
    display: none!important;
}
.sw_xs {
    display: none!important;
}
.sw_xsp {
    display: none!important;
}
.sw_xsp1:fo {
    display: none!important;
}
}
@media (min-width:480px) and (max-width:767px) and (orientation:landscape) {
.tab-content {
    height: 860px!important;
}
}

@media (min-width:280px) and (max-width:767px) and (orientation:landscape) {


}


#readmore :hover {
    background-color: #010101;
    transition: width 1s, border-radius 0.7s;
    width: 200px; /* Increases the width to create a rectangle effect */
    border-radius: 0; /* Removes border-radius to make it a rectangle */
  }
  .input[type="date"].form-control {
    display: none !important;
  }
/*Created By: TZ */
/*Created On: 2020-03-02*/
/*File Description: CSS */
/*TZ CSS FOR login Starts*/
/* @font-face {
    font-family: 'Gotham-Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Light'), 
    url('../../assets/process/GothamLight.woff') format('woff'),
    url('../../assets/process/GothamLight.eot') format('woff'),
    url('../../assets/process/GothamLightd41d.eot?#iefix') format('embedded-opentype'),
    url('../../assets/process/GothamLight.ttf') format('truetype'),
    url('../../assets/process/GothamLight.svg#Gotham-Light') format('woff'),
    url('../../assests/process/GothamLight.woff') format('svg'),
    url('../../assests/process/GothamLight.otf') format('opentype');
} */

@font-face {
    font-family: 'Gotham-Book';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Book'), 
    url(/static/media/Gotham-Book.65288315.woff) format('woff'),
    url(/static/media/Gotham-Book.7fe2d669.eot),
    url(/static/media/Gotham-Bookd41d.7fe2d669.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Gotham-Book.79ab7007.ttf) format('truetype'),
    url(/static/media/Gotham-Book.92032bfd.svg#Gotham-Book) format('svg'),
    url(/static/media/Gotham-Book.e37cf599.otf) format('opentype');   
}

@font-face {
    font-family: 'Gotham-Black';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Black'), url(/static/media/Gotham-Black.d979fc88.woff) format('woff');
}
.new{

    margin-top:"13px" ;  
    position :'absolute'; 
    right:'60' !important;

}
@font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url(/static/media/GothamMedium.d23ef85d.woff) format('woff'),
    url(/static/media/GothamMedium.de228587.woff2) format('woff2'),
    url(/static/media/GothamMedium.ed646164.eot),
    url(/static/media/GothamMediumd41d.ed646164.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamMedium.c3f574b2.ttf) format('truetype'),
    url(/static/media/GothamMedium.9faf290f.svg#Gotham-Book) format('svg'),
    url(/static/media/GothamMedium.bcd733e6.otf) format('opentype'); 
}

@font-face {
    font-family: 'Bariol-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Regular'), 
    url(/static/media/Bariol-Regular.38937286.woff) format('woff'),
    url(/static/media/Bariol-Regular.e4213a39.eot),
    url(/static/media/Bariol-Regulard41d.e4213a39.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Bariol-Regular.6c235190.ttf) format('truetype'),
    url(/static/media/Bariol-Regular.7d0cf79e.svg#Bariol-Regular) format('svg'),
    url(/static/media/Bariol-Regular.6a64a521.otf) format('opentype');
}

@font-face {
    font-family: 'Bariol-Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Thin'), url(/static/media/Bariol-Thin.63c5fefa.woff) format('woff');
}

@font-face {
    font-family: 'Bariol-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Bold'), 
    url(/static/media/Bariol-Bold.bc734d95.woff) format('woff'),
    url(/static/media/Bariol-Bold.d458cb75.eot),
    url(/static/media/Bariol-Boldd41d.d458cb75.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Bariol-Bold.fa0ddc94.ttf) format('truetype'),
    url(/static/media/Bariol-Bold.0ed2ea7a.svg#Bariol-Regular) format('svg');
}

/* css new */
.form-label-group{
    padding-bottom: 25px;
    height: 85px !important;
}
.placeholder-clr::-webkit-input-placeholder {
    color: #bcbcbc !important;
    font-size: 16px;
}
.placeholder-clr::placeholder {
    color: #bcbcbc !important;
    font-size: 16px;
}
.create-forget-account{
    color: #123C66;
    font-weight: 500;
    font-family: 'poppins';
    font-size: 13px;
}
.section_seprator_20{
    height: 20px;
    width: 100%;
}
.gotquerytext{
    font-family: 'poppins' !important;
    font-size: 17.39px;
    font-weight: 300;
    color: #202020;
}
.mailtext{
    color: #fbc504;
    background-color: #fff !important;
    border: none;
    font-size: 18px;
    font-weight: 500;
    font-family: 'poppins';
}
/* css end */


header {
    background: #ffffff;
    display: none !important;
    visibility: hidden !important;
}

body {
    background: #f7f7f7;
    margin: 0;
}
.login100-more {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* width: calc(100% - 730px); */
    width: 50%;
    position: relative;
    z-index: 1;
    background-image: url(/static/media/loginbg.2daa1bdf.png);
}
.login100-more::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 520px;
    background: linear-gradient(bottom,#e8519e,#c77ff2);
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: calc(100% - 520px);
    z-index: 1;
}
.wrap-login100 {
    /* width: 50% !important; */
    min-height: 100vh;
    background: #fff;
    border-radius: 2px;
    position: relative;
}
.container-login100 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-wrap: wrap;
}
.card{
    border:none !important;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
}

.card-signin .card-title {
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
    padding: 0;
    width: 458px;
    margin: 0 auto;
}

.form-control{
  /*  background: #f1f1f1 !important;*/
    border: 1px solid #707070;
    border-radius: 0.55rem !important;
    margin-bottom: 25px !important;
    height: 60px !important;
    font-family: 'poppins' !important;
}
.form-control:focus{
    color: #495057;
    background-color: #fff;
    border-color: #fbc504 !important;
    outline: 0;
    box-shadow: none !important;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
      border-color: transparent !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgb(84, 84, 84,0.15) !important;
}
.sw-logo{
    margin: 0 auto;
    text-align: center;
}
.sw-logo img {
    width: 220px;
}
.form-col {
  min-height: 100% !important;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh !important; /* These two lines are counted as one :-)       */
  
  align-items: center !important;
  width: 100%;
    margin: 0 auto;
    display: flex !important;
}
.myflex-5{
  flex: 1 1;
}
.btn-login{
    color: #fff;
    background-color: #1980dd !important;
    border-color: #1980dd !important;
}
a.forgot-password-link,.query-link,.create-account-link {
    color: #222222 !important;
    font-weight: 800;
    font-family: 'Gotham-Book';
    line-height: 2;

}
.query-text {
    margin-bottom: 0 !important;
    font-family: 'Gotham-Book';
}

p.sw-login {
    font-family: 'poppins' !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    margin-bottom: 40px !important;
}
.error-login {
    color: #C54D51;
    font-family: 'Gotham-Book';
    font-size: 14px;
    padding: 15px;
    margin-bottom: 30px;
}
#btn_login {
    margin-bottom: 10px;
}
.op-lg-sep {
    width: 100%;
    height: 60px !important;
}
.dsl-inline{
    width: 50%;
    float: left;
}
.dsr-inline{
  display: inline-block;
  float: right;
}
div#error_login {
    border: 1px solid #C54D51 !important;
}
#fa-icon{
    padding-right: 10px;
    padding-top: 5px;
    display: flex;
    font-size: 24px;
        color: #58adab;
}
#fa-text{
      display: flex;
      color: #000000 !important;
      text-shadow: none;
      margin: 0 !important;
}
input::-webkit-input-placeholder {
  font-family: 'poppins';
}
input::placeholder {
  font-family: 'poppins';
}
button#btn_login {
    margin: 10px 0px 10px 0px;
    background-color: #fbc504 !important;
    border-radius: 50px;
    color: #000000;
    border: none;
    font-family: 'poppins';
    font-weight: 400;
    padding: 10px !important;
}
input::-webkit-input-placeholder {
  font-family: 'poppins';
}
input::placeholder {
  font-family: 'poppins';
}
.error-field{
    border: 1px solid #ff0000 !important;
}

/*Slider Text Starts*/
.thumbnail {
    display: block !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
    border: none !important;
}
.item {
    background: #fff;
    text-align: center;
    height: 150px;
}
/* /*i.fa {
    margin-top: 50px;
}*/
/*Slider Text End*/
@media only screen and (max-width:767px) and (min-width:280px) {
.form-col,.login100-more,.wrap-login100{
  width: 100% !important;
}
.wrap-login100 {
    min-width: 100%;
}
.form-col{
    display: block !important;
    width: 100% !important; 
}
a.forgot-password-link, .query-link, .create-account-link {
    font-size: 13px !important;
}
button#btn_signup{
  font-size: 16px;
}
.op-lg-sep {
    width: 100%;
    height: 10px !important;
}
button#btn_login {
    font-size: 16px;
    padding: 13px !important;
}
p.sw-login {
    margin-bottom: 25px !important;
}
.card-signin .card-title {
  margin-bottom: 0.5rem !important;
}
}


@media only screen and (max-width:360px) and (min-width:280px) {
.card-signin .card-body {
    margin: 0 auto;
    width: 100%;
    padding: 10px 20px 20px 20px; 
}
@media only screen and (max-width:320px) and (min-width:480px) {
    .sect{
text-align: center !important;
margin:  0 auto !important;
    }
    }

.my-5 {
    margin-top: 0 !important;
}
}
@media only screen and (max-width:767px) and (min-width:361px) {
.card-signin .card-body {
    margin: 0 auto;
    width: 100%;
    padding: 20px; 
}
}



@media only screen and (max-width:996px) and (min-width:768px) {
.form-col,.login100-more,.wrap-login100{
  width: 100% !important;
}
.new{
    margin-top:"13px" ;  
    position :'absolute'; 
    right:'20' !important;

}
.wrap-login100 {
    min-width: 100%;
}
.form-col{
    display: block !important;
    width: 100% !important; 
}
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #BCBCBC;
  }
}
@media only screen and (max-width:480px) and (min-width:320px) {
    .new{
        margin-top:"13px" !important ;  
        position :'absolute' !important; 
        right:'20' !important;
    
    }
}
@media only screen and (max-width:1024px) and (min-width:481px) {
    .new{
        margin-top:"13px" !important ;  
        position :'absolute' !important; 
        right:'20' !important;
    
    }
}
/* css start */
.img-heigh{
    height: 100%;
}
.l-partner{
    overflow: hidden;
}
.l-partner:hover img {
    -webkit-transform: scale(1.09);
            transform: scale(1.09);
    transition: .3s;
    -webkit-filter: opacity(0.2);
            filter: opacity(0.2);
}
.l-partner img {
    transition: .3s;
}
.l-partner:hover .btn_display{
    display: block;
}
.btn_display{
    position: absolute;
    display: none;
}
.pos1{
    top: 18%;
    right: 32%;
}
.pos2{
    top: 72%;
    right: 32%;
}
.pos3{
    top: 45%;
    right: 32%;
}
.logistics-btn{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 15px 30px;
    border-radius: 50px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    text-decoration: none !important;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
}
.process-flow{
    background-color: #131313;
}
.process-bg{
    padding: 30px;
    border-radius: 8px;
    height: 350px;
}
.clrbg-1{
background-color: #7D69FF;
}
.clrbg-2{
background-color: #70C78E;
}
.clrbg-3{
background-color: #BC5ADF;
}
.clrbg-4{
background-color: #DB5555;
}
.process-head{
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins' !important;
    font-weight: 600;
    margin: 20px 10px 20px 10px !important;
}
.process-description{
    font-size: 14px;
    font-family: 'Poppins' !important;
    color: #fff;
    font-weight: 300;
    margin: 10px 0px 10px 0px;
}
.col-rev-padd{
    padding: 0px 80px 0px 80px;
}
.reviewbox-details{
    display: flex;
    align-items: center;
}
.clientreview-heading{
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins' !important;
    font-weight: 600;
    margin: 2px 0px 2px 0px !important;
}
.cl-details{
    padding-left: 20px;
}
.reviewbox-bg{
    padding: 25px;
    background-color: #707070;
    border-radius: 8px;
    height: 280px;
}
.tawk-button-circle{
    bottom: 161px !important;
    
}

/* css start */


.template-page {
    overflow-x: hidden;
    font-family: "Poppins";
}
.slider-container
{
    width: 1920px;
    padding: 0 50px;
}
.sw_ind{
    font-size: 23px;
    line-height: 33px;
    color: #292929;
    font-family: Bariol !important;
    text-align: center;
}
ul.sw_ind_list li {
    font-size: 23px;
    display: inline-block;
    margin: 0 50px 0 0;
    font-family: 'GothamBook' !important;
    font-weight: bold;
}
.inner_container {
    width: 950px!important;
    max-width: 950px !important;
    margin: 0 auto;
}
img.in_blg_icon {
    width: 100%;
}
p.sw_ind_name {
    margin: 0;
    text-align: center;
    font-family: 'Bariol' !important;
    font-weight: bolder;
    color: #ffffff !important;
    font-size: 19px;
}
p.sw_blg_text {
    color: #ffffff;
    font-style: italic;
    font-family: bariol !important;
    font-size: 19px;
    font-weight: bold;
    line-height: 2.0;
}
p.sw_ind_dgn {
    text-align: center;
    color: #9fa3a7;
    font-size: 14px;
    font-family: 'Bariol' !important;
}
div#sw_ind_blg {
    padding: 30px 0;
    margin: 30px 0;
    background: #1881dd;
}
p.sw_ind_list1 {
    padding: 3px 18px;
    text-align: center;
    font-size: 19px;
    font-family: 'GothamBook' !important;
    font-weight: bold;
    position: relative;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin: 0 auto;
}
p.sw_ind_list1:after {
    content: attr(data-content);
    position: absolute;
    color: #000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #ff899d;
    z-index: -1;
}

p.sw_ind_list1::selection {
     background: #d4fffb;
}
p.sw_ind_list2 {
    padding: 3px 10px;
    text-align: center;
    font-size: 19px;
    font-family: 'GothamBook' !important;
    font-weight: bold;
    position: relative;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin: 0 auto;
}
p.sw_ind_list2:after {
    content: attr(data-content);
    position: absolute;
    color: #000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #79c0ff;
    z-index: -1;
}

p.sw_ind_list2::selection {
     background: #d4ffd9;
}
p.sw_ind_list3 {
    padding: 3px 18px;
    text-align: center;
    font-size: 19px;
    font-family: 'GothamBook' !important;
    font-weight: bold;
    position: relative;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin: 0 auto;
}
p.sw_ind_list3:after {
    content: attr(data-content);
    position: absolute;
    color: #000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #f7d843;
    z-index: -1;
}

p.sw_ind_list3::selection {
     background: #fcffd4;
}
p.sw_ind_list4 {
    padding: 3px 18px;
    text-align: center;
    font-size: 19px;
    font-family: 'GothamBook' !important;
    font-weight: bold;
    position: relative;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin: 0 auto;
}
p.sw_ind_list4:after {
    content: attr(data-content);
    position: absolute;
    color: #000;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #ddd9fc;
    z-index: -1;
}
p.sw_ind_list4::selection {
     background: #64f767;
}

img {
    max-width: 100%;
}
img.lg-hide{
    width: 100%;
}
.sw_rdw_cp{
   padding: 0 74px 0 150px;
}
.fl_image{
float: right;
}
#sw_rdw{
padding-bottom: 100px;
padding-top: 30px;
}
.sla-mid {
    vertical-align: bottom;
}
.sp-30{
    width: 30px;
    height: 30px;
}

section#sw_rdw_one_caption {
    background: #e7c001;
}
.sw-tour-slider{
  position: relative;
  text-align: center;
  color: white;
}
p.icon_sub_text {
    font-size: 18px;
    font-family: 'Bariol' !important;
    text-align: center;
}
ul.redesign_list li {
    font-size: 23px;
    font-family: Bariol !important;
    color: #4d545b;
    line-height: 1.8;
    list-style: disc;
}
ul.redesign_list {
    padding-left: 18px;
}
section#sw_tour_one_image .col-sm {
    padding: 0 !important;
    margin-top: -1px !important;
}
.container-fluid.sw-tour-slider {
    padding: 0 !important;
}
p.sw_slider_sup {
    font-size: 20px;
    color: #000;
    font-family: Bariol!important;
    text-transform: uppercase;
    text-shadow: none!important;
}
.logistics_heading {
    color: #ffffff!important;
    font-family: 'Gotham-Medium' !important;
    font-size: 42px;
    text-transform: capitalize!important;
    font-weight: bolder!important;
    text-shadow: none!important;
    line-height: 1.3;
    margin: 0 0 20px 0 !important;
    text-transform: inherit !important;
    text-align: left !important;
}
p.sw_slider_subtext {
    color: #ffffff;
    font-family: Bariol !important;
    font-size: 23px;
    line-height: 1.5;
    text-shadow: none!important;
    margin: 0 0 20px 0 !important;
    text-align: left !important;
}
.sw_blg_seprator {
    width: 100%;
    height: 40px;
}
.row.sw_section_one {
    padding: 50px 40px;
    border-radius: 9px;
    /* -webkit-box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3); */
    /* -moz-box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3); */
    /* box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3); */
}
.section_seprator {
    width: 80px;
    height: 80px;
}
.sw_online_heading {
    font-size: 38px!important;
    color: #222;
    font-family: GothamMedium !important;
    line-height: 1.5;
    font-weight: 700;
    text-align: center;
}
.sw_div_center{
text-align: center !important;
}
.white{
    color: #ffffff !important;
}
.sw_sep_30 {
    width: 100%;
    height: 30px;
}
.sw_sep_15 {
    width: 100%;
    height: 15px;
}
.sw_s4_sup {
    color: #80c3c2!important;
    font-size: 21px;
    font-family: GothamMedium!important;
}
.sw_s4_heading {
    font-family: GothamMedium!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
}
.sw_s4_heading_center{
   font-family: GothamMedium!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
    text-align: center;
}
.sw_s4_text {
    font-size: 23px;
    line-height: 32px;
    font-family: Bariol !important;
    color: #4d545b;
    margin: 20px 0;
}
.sw_industry_button {
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol !important;
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 10px;
    margin-bottom: 10px;
}
.sw_industry_button:hover {
    background: #ffffff !important;
    text-decoration: none!important;
    border-color: #ffffff !important;
    color: #e7c000!important;
}
.sw_industry_button.gs_bottom {
    color: #1881dd !important;
    border: 1px solid #1881dd !important;
}
.sw_industry_button.gs_bottom:hover {
    background: #1881dd !important;
    text-decoration: none!important;
    border-color: #1881dd !important;
    color: #ffffff!important;
}
h2.sw_con_heading {
    color: #fff;
    text-align: center!important;
    font-size: 42px;
    font-family: GothamBook;
    font-weight: bolder;
    margin: 0 auto;
    line-height: 46px;
}
h3.sw_con_su_heading {
    font-size: 22px;
    color: #fff;
    font-family: GothamBook;
    text-align: center!important;
    margin: 0 auto;
    line-height: 29px;
}
.sw_btn_div {
    text-align: center;
    margin: 0 auto;
}
.po_icon {
    text-align: center;
}
h3.sw_suheading {
    text-align: center;
    font-size: 22px;
    font-family: GothamMedium;
    line-height: 30px;
}
.col-center {
    margin: 0 auto;
    float: none!important;
}
#custom-footer {
    display: block!important;
}
.horizontal_seprator {
    border: 0.5px solid #a1a1a1;
}
p.sw_ind_dgn {
    display: none;
}
@media only screen and (max-width:1600px) and (min-width:1200px) {
.sw_rdw_cp {
        padding: 0 30px 0 88px;
}
.logistics_heading {
    font-size: 30px!important;
}
p.sw_slider_subtext {
    font-size: 20px!important;
}
.sw_online_heading {
    font-size: 28px!important;
}
.sw_ind,.sw_s4_text {
    font-size: 20px;
}
.sw_s4_heading {
    font-size: 24px;
}
}
@media only screen and (max-width:1366px) and (min-width:1024px) {
.row.sw_section_one {
    padding: 50px 11px;
}
}
@media only screen and (max-width:1199px) and (min-width:1025px) {
.sw_rdw_cp {
    padding: 0 30px 0 30px;
}
}
@media only screen and (max-width:1024px) and (min-width:768px) {
.sw_rdw_cp {
    width: 100%;
    padding: 0 20px 0 72px;
    /*margin-top: 40px;*/
}
    .inner_container {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 auto;
}
p.sw_ind_list1,p.sw_ind_list2,p.sw_ind_list3,p.sw_ind_list4{
    font-size: 16px !important;
}
ul.sw_ind_list li {
    display: block;
    margin: 0 50px 15px 0;
}
section#sw_home {
    margin-top: 0!important;
}
.logistics_heading {
    font-size: 26px!important;
    line-height: 1.3!important;
}
p.sw_slider_subtext {
    font-size: 18px!important;
    line-height: 1.2!important;
   /* margin: 20px 0 !important;*/
}
.sw_s4_text{
    font-size: 18px !important;
}
ul.redesign_list li {
    font-size: 18px;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px;
}
.sw_s4_heading,.sw_s4_heading_center {
    font-size: 20px;
}
.sw_online_heading {
    font-size: 24px!important;
}
.sw_online_heading br{
display: none !important;
}
h3.sw_suheading {
    font-size: 18px !important;
    line-height: 25px !important;
}
.po_icon img {
    width: 100px !important;
}
.sw_industry_button,.sw_industry_button_color {
    padding: 10px !important;
    font-size: 16px;
    /*width: 230px;*/
}
.sw_ind {
    font-size: 18px !important;
}
}
@media only screen and (max-width:1023px) and (min-width:768px) {
.section_seprator {
    width: 80px;
    height: 40px;
}
.logistics_heading {
    font-size: 26px!important;
    line-height: 1.3!important;
    /*margin-bottom: 10px !important;*/
}
p.sw_slider_subtext br {
    display: none;
}
.tb-heading{
padding: 0 !important;
}
.md-hide{
    display:none;
}
}

@media only screen and (max-width:767px) and (min-width:280px) {
.slide_caption {
    margin-left: 0 !important;
}
    .sw_ind {
    font-size: 18px !important;
}
    .sw_rdw_cp {
    padding: 0 30px;
}
ul.sw_ind_list li {
    font-size: 18px;
    display: block;
    margin: 16px 0px 0 0;
}
ul.sw_ind_list {
    padding: 0;
}
.sw_rdw_cp_dsn{
display: none !important;
}
    .inner_container {
    width: 100% !important;
}
img {
    max-width: 100%;
}
img.in_blg_icon {
    width: 100px;
    padding-bottom: 20px;
}
.flex-switch{
    display: flex !important;
    flex-direction: column-reverse;
}
.sw_rdw_cp {
    width: 100%;
}
.sl-xs{
    padding-bottom: 50px !important;
}
.xs-hide{
display: none !important;
}
a.sw-brand-logo img {
    width: 155px!important;
    padding: 10px 0 12px 24px!important;
}
.header-custom {
    padding: 4px!important;
}
section#sw_home {
    margin-top: 0!important;
}
.sw_online_heading {
    font-size: 22px!important;
    line-height: 1.3;
}
.sw_online_heading br{
  display: none !important;
}
.sw_wk_rating_icon img {
    width: 90px!important;
    height: 81px!important;
}
.sw_s4_heading,.sw_s4_heading_center {
    font-size: 20px!important;
}
.sw_s4_text {
    font-size: 18px!important;
    margin: 0!important;
}
.sw_posu_heading, h2.sw_con_heading {
    font-size: 28px;
}
.sw_industry_button {
    display: block!important;
    margin: 10px 0!important;
}
.logistics_heading {
    font-size: 24px!important;
    /*margin: 6px 0 !important;*/
}
.logistics_heading br,.sw_slider_subtext br{
    display: none !important;
}
p.sw_slider_subtext {
    font-size: 18px!important;
    line-height: 1.2!important;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px!important;
}
.sw_ol_sub_text {
    width: 100%}
.sw_wk_heading {
    font-size: 26px!important;
    line-height: 34px!important;
}
.section_seprator {
    width: 80px!important;
    height: 30px!important;
}
.sw_industry_button,.sw_industry_button_color {
    display: block !important;
    margin: 10px 0 !important;
    font-size: 16px;
}
h3.sw_suheading {
    font-size: 18px !important;
}
a.sw_tour_sac,a.sw_tour_succeed{
    margin: 0 !important;
    display: block !important;
}
ul.redesign_list li {
    font-size: 19px !important;
}
.mb-col-sep{
width: 100%;
height: 40px;
}
.sw_blg_text{
padding: 10px !important;
}
}
/* @media only screen and (max-width: 1366px) and (min-width: 280px){
} */

@media only screen and (max-width:800px) {
    .reviewbox-bg {
        
        height: auto !important
    }
}

@media only screen and (max-width:760px) {
    .margin-card {
        
        margin-top: 3%;
    }
}
@media (min-width:769px) and (max-width:1024px){
    .process-bg{
        height: auto !important;
    }
}
@font-face {
    font-family: Bariol;
    src: url(/static/media/bariol_regular-webfont.e4213a39.eot);
    src: url(/static/media/bariol_regular-webfontd41d.e4213a39.eot?#iefix) format("embedded-opentype"),
     url(/static/media/bariol_regular-webfont.7d0cf79e.svg) format('svg'),
     url(/static/media/bariol_regular-webfont.6c235190.ttf) format('truetype'),
     url(/static/media/bariol_regular-webfont.38c3b9e9.woff) format('woff'),
     url(/static/media/Bariol_Regular.6a64a521.otf) format('otf'),
     url(/static/media/bariol_regular-webfont.6577b355.woff2) format('woff2');
      font-weight: 400;
      font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Book';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Book'), 
    url(/static/media/Gotham-Book.65288315.woff) format('woff'),
    url(/static/media/Gotham-Book.7fe2d669.eot),
    url(/static/media/Gotham-Bookd41d.7fe2d669.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Gotham-Book.79ab7007.ttf) format('truetype'),
    url(/static/media/Gotham-Book.92032bfd.svg#Gotham-Book) format('svg'),
    url(/static/media/Gotham-Book.e37cf599.otf) format('opentype');   
}

  @font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url(/static/media/GothamMedium.d23ef85d.woff) format('woff'),
    url(/static/media/GothamMedium.de228587.woff2) format('woff2'),
    url(/static/media/GothamMedium.ed646164.eot),
    url(/static/media/GothamMediumd41d.ed646164.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamMedium.c3f574b2.ttf) format('truetype'),
    url(/static/media/GothamMedium.9faf290f.svg#Gotham-Book) format('svg'),
    url(/static/media/GothamMedium.bcd733e6.otf) format('opentype'); 
}

  @font-face {
    font-family: 'Bariol-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Bold'), 
    url(/static/media/Bariol-Bold.bc734d95.woff) format('woff'),
    url(/static/media/Bariol-Bold.d458cb75.eot),
    url(/static/media/Bariol-Boldd41d.d458cb75.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Bariol-Bold.fa0ddc94.ttf) format('truetype'),
    url(/static/media/Bariol-Bold.0ed2ea7a.svg#Bariol-Regular) format('svg');
}










/* afroz css*/
.mainbanner_padd{
    padding: 20px 20px 40px 60px;
    align-self: center;
}
section#port_main_banner{
    padding-top: 65px;
    background-image: url(/static/media/make-decision.15149530.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.head_centr{
    text-align: center;
}
.middle_heading {
    color: #ffffff!important;
    font-family: "san serif" !important;
    font-size: 50px;
    font-weight: bold!important;
    line-height: 1.3;
    margin: 0 0 40px 0 !important;
    text-transform: inherit !important;
}
p.middle_subtext {
    color: #ffffff;
    font-family: 'Poppins' !important;
    font-size: 22px;
    line-height: 1.5;
    font-weight: 'Regular'!important;
}
main#main {
    padding: 80px 0px 80px 0px !important;
}
.bg-clr{
    background-color: #161616;
}
.category-heading {
    border-bottom: 1px solid #bfbfbf2e;
    font-size: 20px;
    font-weight: 500;
    font-family: 'poppins';
    color: #fff;
    padding: 30px 0px 15px 0px;
}
.category-sidebar{
    overflow-y: visible !important;
}
.design-category-list .cl-item {
    padding: 10px 0px;
    list-style: none;
    /* border-bottom: 1px solid #ddd; */
}
.spinner{
    position: absolute !important;
    top: 100% !important;
    left: 50% !important;
}
.cl-item a {
    color: #fff;
    font-family: 'poppins';
    font-size: 15px;
    text-decoration: none !important;
    transition-duration: .2s;
    transition-property: padding-left text-indent;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

/* RMI - Category Sidebar - S */
.design-selection-box {
    display: flex;
}

/* .category-sidebar {
    max-height: 600px;
    overflow-y: scroll;
} */

.category-sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.category-sidebar::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.category-sidebar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.3);
    background-color: #dddddd;
}


/* RMI - Category Sidebar - E */

.design-category-list {
    padding: 0;
}

.cl-item:hover .cl-item-name {
    padding-left: 5px;    
}
.cl-item .cl-item-name:hover {
    color: #fbc504;
    cursor: pointer;
}

.cl-item:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0px 6px;
    content: "\f054";
    font-family: FontAwesome;
    font-size: 16px;
    color: #fbc504;
}

.design-items, .design-items > div {
    display: block;
    float: left;
 }

.design-items {
    /* padding: 5px 30px; */
    width: 100%;
    min-height: 1200px;
}

.design-items.minheight {
    min-height: 1200px;
}



.item-details img {
    width: 100%;
}

.design-box {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    border: 1px solid #e0e0e0;
}
.design-box img {
    height: 300px;
    width: 310px;
}
.design-box .design-inner {
    background: #ffffff;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    padding: 0;
    transition: opacity .5s;
}
.design-box .design-inner p {
    color: #fff;
    line-height: 150px;
    font-family: 'arial';
    text-align: center;
}


.design-inner .inner-content {
    float: left;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: relative;
}

.noScroll {
    overflow-y: hidden;
}

.stickySidebar {
    position: fixed;
    width: 290px;
    max-height: 100%;
    height: calc(100vh - 50px);
    bottom: 0px;
    top: auto;
}

.search-by-tag {
    padding-bottom: 10px;
    border-bottom: 3px solid #21bdbb;
    width: 250px;
}
.search-by-tag:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0px 6px;
    content: "\f002";
    font-family: FontAwesome;
    font-size: 18px;
    color: #21bdbb;
}
.search-by-tag .tag-input {
    outline: 0;
    border: 0;
    box-shadow: none !important;
    width: 210px;
}
.search-by-tag .tag-input:hover {
    outline: 0;
    border: 0;
    box-shadow: none !important;
}

/* RMI - Screen loader spinner */
.preview-progressbar .screen-overlay {
    display: block;
    z-index: 100;
    background-color: #fff;
    opacity: 0.97;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: 0 0;
    height: auto;
    position: absolute;
}
.preview-progressbar .loader-message {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    height: 100px;
    bottom: 0;
    top: 0;
    margin: auto;
    font-family: 'Gotham-Book';
    font-size: 18px;
}

.preview-progressbar .screen-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;    
    margin: auto;
}

@-webkit-keyframes rotation {
from {-webkit-transform: rotate(0deg);}
to {-webkit-transform: rotate(359deg);}
}
@keyframes rotation {
from {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
to {-webkit-transform: rotate(359deg);transform: rotate(359deg);}
}

/* Design Preview Box - S */
.design-preview {
    background-color: #fff;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 10px;
    z-index: 250;
    transition: all 0.25s ease-in;
    display: none;
}
.design-preview.show {
    opacity: 1;
    top: 0;
    position: fixed;
    display: block;
    z-index: 999;
}

.item-preview-open {
    background: transparent;
    color: #212529;
    font-family: 'Gotham-Medium';
    font-size: 16px !important;
    text-transform: uppercase;
}

.btn-sw-theme {
    background: transparent;
    color: #212529;
    font-family: 'Gotham-Medium';
    font-size: 16px !important;
    text-transform: uppercase;
}

.apple-os .btn-sw-theme, .apple-os .item-preview-open {
    line-height: 30px;    
}

.btn-sw-theme:hover, .item-preview-open:hover {
    background: transparent;
    color: #21bdbb !important;
}

.btn-sw-theme:focus, .item-preview-open:focus {
    box-shadow: none;
}

.design-box .or-seperator {
    border-top: 2px solid #21bdbb;
    position: relative;
    margin: 26px 0 0px;
    text-align: center;
}

.design-box .or-seperator .text-seperator {
    background-color: #fff;
    padding: 0 .5em;
    position: relative;
    color: #000000;
    top: -.9em;
    font-style: italic;
    font-size: 18px;
}

.design-box:hover {
    border: 1px solid #e0e0e0;
}

.box-actions {
    border: 1px solid #e0e0e0;
    background: rgba(241, 241, 241, 0.5);
}

.box-actions a i {
    color: #21bdbb;
}

/* Design Preview Box - E */

/* Design Preview Modal Box - S */
#previewDesignModalBox .modal-content {
    min-height: 300px;
    /* padding: 22px 4px; */
}
#previewDesignModalBox #iframe-view {
    /* max-width: 100%;
    min-height: 600px;
    width: 100%;
    margin: 0 auto !important; */

    max-width: 100%;
    min-height: 600px;
    width: 100%;
    margin: 0 auto !important;
    max-height: 60vh;    
}
#previewDesignModalBox .btn-select-preview {
    color: #ffffff;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 20px;
    border: 2px solid #21bdbb;
    text-transform: uppercase;
    width: 240px;
    padding: 4px 6px;
}
#previewDesignModalBox .btn-select-preview:hover {
    background: #21bdbb;
    border: 2px solid #21bdbb;
    color: #ffffff;
}
#previewDesignModalBox .btn-select-preview i {
    margin-left: 0px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
#previewDesignModalBox .btn-select-preview:hover i { 
    margin-left: 10px;
}
#previewDesignModalBox .btn-select-preview:focus, #previewDesignModalBox .btn-select-preview:active {
    box-shadow: none;
}

#previewDesignModalBox .btn-close-preview {
    border: 0;
    border-bottom: 2px solid #524940;
    width: 180px;
    padding: 4px 6px;
    background: transparent;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    margin-right: 28px !important;
    text-transform: uppercase;
    border-radius: 0 !important;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}

#previewDesignModalBox .btn-close-preview:focus {
    box-shadow: none!important;
}
#previewDesignModalBox .btn-close-preview i { 
    color: #21bdbb;
    margin-right: 0px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
#previewDesignModalBox .btn-close-preview:hover i { 
    margin-right: 10px;
}
#previewDesignModalBox #previewDesignModalBoxLongTitle {
    line-height: 1.8;
    font-family: 'Gotham-Medium';
}

.preview-topbar {
    height: 50px;
    color: rgba(255,255,255,0.6);
    background-color: #18202e;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0px 1px 2px 0px black;
    z-index: 15;
}

.preview-topbar .brand-logo {
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.preview-topbar .brand-logo img {
    height: 30px;
    display: inline-block;
    line-height: 48px;
}

.preview-topbar .brand-logo span {
    line-height: 49px;
    overflow: hidden;
    display: inline-block;
    padding-left: 4px;
    font-family: 'Gotham-Book';
    color: #d0d0d0;
}

.preview-topbar .btn-select-design {
    background: #21bdbb;
    color: #ffffff;
    padding: 6px 50px;
    font-family: 'Gotham-Medium';
    text-transform: uppercase;
}

.apple-os .preview-topbar .btn-select-design {
    line-height: 32px;
    height: 35px;
}

.preview-topbar .btn-select-design:hover {
    background: #0e9e9d;
    color: #ffffff;
}

.preview-topbar .btn-select-design:focus {
    box-shadow: none;
}

.preview-topbar .close-preview {
    background: transparent;
    color: #e4e4e4;
    padding: 5px 18px;
    font-family: 'Bariol-Bold';
    text-transform: uppercase;
    word-spacing: 4px;
    outline:0 !important
}

.apple-os .preview-topbar .close-preview {
    line-height: 32px;
    height: 35px;
}

.preview-topbar .close-preview:hover {
    background: transparent;
    color: #ffffff;
    outline:0 !important
}
/* RMI - Preview Top Bar - E */


/* RMI - Mobile Menu Side Nav - S */

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 760;
    top: 0;
    left: 0;
    background-color:#fff;
    border-right: 1px solid #e5e5e5;
    overflow: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a {
    display: block;
    transition: 0.3s;
    white-space: nowrap;
}


.sidenav .closebtn {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 36px;
    font-family: 'Bariol-Bold';
    color: #ef6742;
    margin-left: 50px;
}

.sidenav .closebtn:hover {
    text-decoration: none !important;
}

.mbl-industry-menu {
    font-size: 20px;
    font-family: 'Gotham-Book';
    font-weight: bolder;
    text-align: center;
    display: none;
    margin-bottom: 18px;
}

.mbl-industry-menu:hover {
    cursor: pointer !important;
}

.mbl-industry-text {
    text-align: center;
    padding: 0px 16px;
    font-size: 18px;
    display: none;
}
/* RMI - Mobile Menu Side Nav - E */


/* RMI - Preview Iframe Box - S */
.preview-iframe {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50px;
    height: calc(100vh - 50px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
 }

.preview-iframe iframe {
    border: 0;
    width: 100%;
    height: 100%;
    display: block;
}
/* RMI - Preview Iframe Box - E */

.heading-section {
    font-family: 'Gotham-Medium';
    font-size: 24px;
    text-transform: uppercase;
    color: #161616;
}

#selected_design {
    display: none;
    border: 1px solid #d6d6d6;
}

#selected_industry {
    display: none;
    font-family: 'Gotham-Book';
    margin: 0 auto;
    padding-bottom: 10px;
    padding-top: 22px;
}

.selected-industry-heading {
    padding: 0px 20px;
    font-size: 20px;
    font-family: 'Gotham-Medium';
    color: #404040;
    /* border-bottom: 2px solid #21bdbb; */
    padding-bottom: 6px;
}

.selected-industry-heading .noresult-subtext {
    font-size: 18px;
    font-family: 'Gotham-Light';
    color: #404040;
    margin-top: 6px;
}

#selected_design_sticky_bar {
    background: #ffffff;
    border: 1px solid #222222;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    text-align: center;
    margin: 0 auto;
    padding: 10px;
    display: none;
}
#selected_design_sticky_bar .design-thumbnail {
    margin-right: 20px;
}
#selected_design_sticky_bar .design-thumbnail img {
    width: 80px;
}
#selected_design_sticky_bar .design-info {
    font-family: 'Gotham-Book';
    font-size: 22px;
    vertical-align: middle;
}
#selected_design_sticky_bar .step-continue {
    margin-left: 15px;
}

#selected_design_sticky_bar .mbl-show {
    display:none;
}

.step-continue {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    border: 2px solid #21bdbb;
    width: 180px; 
    padding: 4px 6px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.step-continue:hover {
    background: #0e9e9d;border: 2px solid #0e9e9d;
    color: #f7f7f7 !important;
}
.step-continue:focus {
    box-shadow: none;
}

.category-sidebar::-webkit-scrollbar {
    width: 1em;
}
 
.category-sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    
}
 
.category-sidebar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.main-sidebar {
    width: 300px;
    padding: 35px;
    box-sizing: border-box;
}
.main-design-container {
    float: right;
    width: calc(100% - 313px);
    padding: 10px;
}

.op-ie-valign{
    display: flex; 
    justify-content: center; 
    align-items: center;
}

/* RMI - Media Queries - S */
@media screen and (max-width: 1199px) and (min-width: 991px) {
    .btn-sw-theme, .item-preview-open {
        padding: 8px 4px;
    }
    .btn-sw-theme, .item-preview-open {
        font-size: 14px !important;
    }
}
@media screen and (max-width: 992px) {
    /* .main-sidebar {display:none;} */
    .main-design-container {width: 100%;}
    .mbl-industry-menu {display: block!important;
        overflow: hidden;}
    .selected-industry-heading {text-align: center;}
    #previewDesignModalBox {
        padding: 0!important;
    }
    #previewDesignModalBox .modal-dialog {
        max-width: 95%;
    }
}  
@media screen and (max-width: 989px) {
    .responsive #top #wrap_all .av_mobile_menu_tablet .main_menu {
        top: 40 !important;
        left: auto !important;
        right: 0 !important;
        display: block !important;
    }
}
@media screen and (max-width: 767px) {
    #header_main .inner-container {height: auto;}
    .selected-design-heading {
        padding: 40px 0!important;
        border-top: 1px solid #b2b2b2;
    }
    body {
        overflow: auto!important
    }

    .design-preview.show {
        overflow: auto!important;
        /* position: absolute; */
    }

    /* Fixing scroll */
    .designs-container > div {
    margin: 20px auto 0!important;
    }
    
    .designs-container > div > div {
    margin: 0!important;
    padding: 0;
    }
    
    .designs-container > div > .item-details {
    padding: 0!important;
    }

    .mbl-industry-text {display: block;}

    #selected_design_sticky_bar {
        text-align: left;
        min-height: 90px;
    }
    #selected_design_sticky_bar .design-info .design-info-text, #selected_design_sticky_bar .design-thumbnail {
        display: none!important;
    }    
    #selected_design_sticky_bar .step-continue {
        margin-left: 12px;
        margin-top: 12px;
    }
    .general-footer-actions {
        padding-bottom: 130px !important;
    }
    #selected_design_sticky_bar .mbl-show {
        display: inline;
    }
    #selected_design_sticky_bar .step-continue {
        font-size: 20px;
        width: 350px;   
    }
    #previewDesignModalBox .btn-close-preview, #previewDesignModalBox .btn-select-preview {
        width: 45%;
        display: inline-block;
    }
    #previewDesignModalBox .btn-preview-actions {
        display: block !important;
        width: 100%;


        width: 100%;
        height: 100%;
        max-width: 94%;
        max-height: 500px;
        position: absolute;
    }
    #previewDesignModalBox .modal-header {
        height: 70px;
    }
    #previewDesignModalBox .modal-body {
        overflow-y:auto !important; 
        -webkit-overflow-scrolling:touch !important;
        position: relative;
        width: 100%;
        max-height: 600px;
        height: 450px;
        padding: 0px;
    }    

    #previewDesignModalBox #iframe-view {
        min-height: 400px;        
        border: none;
    }
    #previewDesignModalBox #previewDesignModalBoxLongTitle {
        display: none;
    }
}
@media screen and (max-width: 560px) {
    #selected_design_sticky_bar .step-continue {
        font-size: 18px;
        width: 285px;
    }
}
@media screen and (max-width: 480px) {
    .screen-loader {left: 45%;}
    .preview-topbar .brand-logo span {display:none;}

    #selected_design_sticky_bar .mbl-show {
        display: none;
    }
    #selected_design_sticky_bar .step-continue {
        font-size: 20px;    
        width: 240px;
    }


    #selected_design_sticky_bar .design-info .design-info-text {
        display: block!important;
        font-size: 16px;
        margin-left: 12px;
    }    
    #selected_design_sticky_bar {
        min-height: 120px;
    }    
    #selected_design_sticky_bar .step-continue {
        margin-top: 16px;
    }

    #previewDesignModalBox .btn-close-preview, #previewDesignModalBox .btn-select-preview {
        width: 45%;
        display: inline-block;
        font-size: 18px;
        margin: 0 !important;
    }
    #previewDesignModalBox .btn-close-preview {
        margin-right: 10px !important;
    }
}
@media screen and (max-width: 430px) {
    #selected_design_sticky_bar .step-continue {
        font-size: 20px;
        width: 170px;
    }
}
@media screen and (max-width: 420px) {
    #previewDesignModalBox .btn-close-preview i, #previewDesignModalBox .btn-select-preview i {
        display: none;
    }
}
@media screen and (max-width: 400px) {
    .btn-sw-theme, .item-preview-open {
        font-size: 14px !important;
    }
    .preview-progressbar .screen-loader {
        top: 80px;
    }
    .preview-progressbar .loader-message {
        padding: 0 20px;
    }
}
@media screen and (max-width: 360px) {
    #previewDesignModalBox .btn-close-preview, #previewDesignModalBox .btn-select-preview {
        font-size: 15px;
    }
}
@media screen and (max-width: 360px) {
    .btn-sw-theme, .item-preview-open {
        padding: 4px;
    }
}
@media screen and (max-width: 350px) {
    .btn-sw-theme, .item-preview-open {
        padding: 8px 4px;
    }
}
@media screen and (max-width: 330px) {
    .box-actions a i {
        display: none;
    }
}

/* RMI - Height Media Queries */
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}     
}
@media screen and (max-height: 330px) {
    .preview-topbar .brand-logo {
        display: none;
    }
}   



@media screen and (min-device-width: 991px) and (max-device-width: 1037px) { 
    .design-box img {
      
        width: 300px !important;
    }
}

@media screen and (max-width: 992px )  {
    .main-sidebar {
      
        width: 100% !important;
    }
}


@media screen and (min-width: 1282px )  {
    .design-box img {
      
        width: 411px !important;
    }
}




@media screen and (max-width: 1175) {
    .design-box img {
      
        width: 358px !important;
    }
}

@media screen and (min-device-width: 1281px) and (max-device-width: 1231px) { 
    .design-box img {
        width: 380px !important; 
        height: auto;
    }
}

@media screen and (min-device-width: 1100) and (max-device-width: 1044px) { 
    .design-box img {
        width: 280px !important; 
        height: auto;
    }
}
.portfolio-color{
    color: white !important;
}




.column {
    float: left;
    width: 33.3333333333%;
    padding: 0 5px;
    background-color: transparent;
  }
  
  /* Remove extra left and right margins, due to padding in columns */
  .rowcard {margin: 0 -5px; background-color: transparent;}
  
  /* Clear floats after the columns */
  .rowcard:after {
    background-color: transparent;
    content: "";
    display: table;
    clear: both;
  }
  
  /* Style the counter cards */
  .card {
    /* this adds the "card" effect */
    padding: 16px;
    margin-bottom: 20px;
    text-align: center;
    background-color: transparent !important;
    border: 1px solid black ;
  }
  
  /* Responsive columns - one column layout (vertical) on small screens */
  @media screen and (max-width: 600px) {
  
  }




  .Card{
    background-color:transparent !important;
    padding: 30px;
    margin-right: 20px;
    border: #282c34;
    border-width: 2px;
  }
  

  @media (min-width: 768px) and (max-width: 1024px) {
  
    /* CSS */
    .column {
      width: 50% !important;
      display: block;
      margin-bottom: 20px;
    }
    .spinner{
     top: 50% !important;
     left: 50% !important;
    }
  }
  
  @media (min-width: 481px) and (max-width: 767px) {
    .column {
      width: 50% !important;
      display: block;
      margin-bottom: 20px;
    }
    /* CSS */
    .primary-heading{
      font-size: 25px !important;
    }
    .secondary-heading{
      font-size: 16px !important;
    }
    #back-btn{
      
      height: 30px !important;
      width: 30px !important;

      
      
    }
    .icon{
      height: 25px;
      width: 25px;
    }

  }
  

  
  @media (min-width: 320px) and (max-width: 480px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
    .category_card{
        display: flex;
        justify-content: center;
    }
    .primary-heading{
      font-size: 18px !important;
    }
    .secondary-heading{
      font-size: 12px !important;
    }
    #back-btn{
      
      height: 25px !important;
      width: 25px !important;

    }
    #btns{
      display: flex;
      justify-content: space-between;
    }
    .icon{
      height: 22px;
      width: 22px;
    }
  }
  .customselect .css-13cymwt-control{
    background-color: #18202e;
  }
  
@font-face {
    font-family: Bariol;
    src: url(/static/media/bariol_regular-webfont.e4213a39.eot);
    src: url(/static/media/bariol_regular-webfontd41d.e4213a39.eot?#iefix) format("embedded-opentype"),
     url(/static/media/bariol_regular-webfont.7d0cf79e.svg) format('svg'),
      url(/static/media/bariol_regular-webfont.6c235190.ttf) format('truetype'),
       url(/static/media/bariol_regular-webfont.38c3b9e9.woff) format('woff'),
        url(/static/media/Bariol_Regular.6a64a521.otf) format('otf'),
         url(/static/media/bariol_regular-webfont.6577b355.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: GothamBook;
    src: url(/static/media/Gotham-Book.7fe2d669.eot);
    src: url(/static/media/Gotham-Bookd41d.7fe2d669.eot?#iefix) format("embedded-opentype"),
     url(/static/media/Gotham-Book.65288315.woff) format("woff"), 
     url(/static/media/Gotham-Book.79ab7007.ttf) format('truetype'),
      url(/static/media/Gotham-Book.92032bfd.svg#Gotham-Book) format("svg"), 
      url(/static/media/Gotham-Book.e37cf599.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url(/static/media/GothamMedium.d23ef85d.woff) format('woff'),
    url(/static/media/GothamMedium.de228587.woff2) format('woff2'),
    url(/static/media/GothamMedium.ed646164.eot),
    url(/static/media/GothamMediumd41d.ed646164.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamMedium.c3f574b2.ttf) format('truetype'),
    url(/static/media/GothamMedium.9faf290f.svg#Gotham-Book) format('svg'),
    url(/static/media/GothamMedium.bcd733e6.otf) format('opentype'); 
}
  @font-face {
    font-family: BariolBold;
    src: url(/static/media/bariol_bold-webfont.fa0ddc94.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  /* @font-face {
    font-family: 'Gotham-Bold';
    src: url('../../assets/process/Gotham-Bold.html') format('opentype');
  src: url('../../assets/process/gothambold.html') format('truetype');
    font-weight: normal;
    font-style: normal;
  } */
  html {
    scroll-behavior: smooth;
  }
  .main-header.header-custom.header-transparency {
    background: #fff !important;
  }
  #custom-footer {
      display: block!important;
  }
  .section_seprator {
      width: 100%;
      height: 80px;
  }
  .list-scroll{
    overflow-y: scroll;
    height: 200px;
  }
  /* afroz css */
  .container-fluid
  {
    border-bottom: none !important;
  }
  .second_head{
    color: #ffffff!important;
    font-family: "Poppins" !important;
    font-size: 35px;
    font-weight: 500!important;
    line-height: 1.3;
    text-transform: inherit !important;
  }
  .sw_rdw_three_clr{
    background-color: rgb(20, 20, 20);
  }
  .subscriptiondiv{
    border: 1px solid #25262A;
    border-radius: 10px;
  }
  .subcription-head{
    font-family: 'Poppins' !important;
    font-size: 20px;
    color: #fbc503;
    font-weight: 500;
    margin-bottom: 0px;
  }
/* afroz css end */


  a.sw_pricing_button {
      color: #1880dd !important;
      border: 1px solid #1880dd !important;
      padding: 10px 30px;
      text-decoration: none!important;
      border-radius: 5px;
      font-family: Bariol;
      font-weight: bold;
      font-size: 19px;
      min-width: 240px!important;
      text-align: center;
      background: 0 0;
      width: initial;
      transition: all .5s ease-in-out;
      display: inline-block;
      text-transform: uppercase;
      margin-right: 10px;
  }
  a.sw_pricing_button:hover{
      background: #1880dd !important;
      text-decoration: none!important;
      border-color: #1880dd !important;
      color: #fff!important;
  }
  .pricingpage{
    overflow: hidden;
  }
  .sw-pricing-main {
    margin-top: 0px;
      padding: 0px !important;
  }
  .nav-col-div{
    height: 0px;
    background: #fff;
  }
  .ps-pricing-nav nav {
    width: 100%;
    background: #0f0f0f !important;
    padding: 20px;
  }
  .ps-pricing-nav ul {
    padding: 0px;
    margin: 0 auto;
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: center;
  }
  .bg{
      background-color: #161616 !important;
  }
  .ps-pricing-nav li {
    display: inline-block;
    margin: 0 42px
  }
  .ps-pricing-nav ul li a {
    padding: 10px 25px;
    color: #fff !important;
    font-size: 16px;
    text-decoration: none;
    font-weight: 400;
    transition: all 0.2s ease;
    font-family: 'poppins';
  }
  .ps-pricing-nav ul li a:hover{
    color: #fbc504 !important;
  }
  .psnav-item.active .psnav-link {
    color: #fbc504 !important;
    /* border-bottom: 4px solid #fff; */
  }
  .nav>li>a:focus, .nav>li>a:hover {
    background-color: transparent !important;
  }
  #top .sw-pricing-tables .container {
    max-width: 60% !important;
  }
  .sw-pricing-section{
  width: 100%;
  background: #fff;
  text-align: center;
  }
  .sw-pricing-heading{
      font-size: 38px!important;
      color: #222;
      font-family: 'Gotham-Medium' !important;
      line-height: 56px;
      font-weight: 700;
      text-align: center;
  }
  .sw-pricing-text {
      font-family: 'Bariol' !important;
      font-size: 23px;
      line-height: 32px;
      color: #4d545b;
      margin: 0 !important;
  }
  .sw-pricing-subheading{
       font-size: 38px!important;
      color: #222;
      font-family: 'Gotham-Medium' !important;
      line-height: 1.3;
      font-weight: 700;
      text-align: center;
      margin: 0 !important;
  }
  .sw-pricing-tables1{
   background-color: #25262A;
    border-radius: 8px;
    /* margin: 10px; */
   
  }
  .ultimate-icon {
    padding: 0px 0px 0px 0px;
    height: 50px;
    width: 50px;
    background-color: #1880dd;
    border-radius: 50%;
    color: #000000;
    text-align: center;
    font-size: 23px;
    line-height: 50px;
    margin-bottom: 5px;
    float: left;
    position: absolute;
    margin: 0 auto;
    margin-top: -57px;
    display: block;
    width: 100%;
    max-width: 50px;
    left: 0;
    right: 0;
    box-shadow: -2px 4px 19px -9px rgba(0, 0, 0, 0.65)
  
  }
  .ultimate-icon i{
    line-height: 47px;
  }
  .sw-pricing-tables2{
    border: 2px solid #1880dd;
    padding: 30px 0px 34px !important;
   box-shadow: -2px 12px 22px -9px rgba(0, 0, 0, 0.33);
    float: left;
  }
  .sw-pricing-tables-head {
      font-family: 'poppins';
      font-size: 28px;
      font-weight: 400;
      padding-top: 10px;
      padding-bottom: 10px;
      color: #fff;
  }
  .pr-content{
    margin-bottom: 0px !important;
  }
  /* .sw-pricing-table-text {
    font-family: 'Bariol' !important;
    color: #000000;
  } */
  .sw-pricing-tables-box {
      box-shadow: -2px 12px 22px -9px rgba(0,0,0,0.75);
      display: block;
      padding: 34px;
      width: 71%;
      margin: 0 auto;
      margin-top: -34px;
      display: inline-block;
      background: #fff;
      border-radius: 8px;
  }
  .sw-pricing-tables-section {
      background-color: #70C78E;
      border-radius: 10px 10px 0px 0px;
      text-align: center;
  }
  .sw-pricing-table-digit {
      font-size: 50px;
      font-family: 'Gotham-Medium';
  }
  .sw-pricing-table-smalltext {
      font-size: 17px;
      font-family: Bariol;
      font-weight: bold;
  }
  .sw-pricing-table-pricesign{
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    position: relative;
    font-family: 'poppins';
  }
  .pr-amount{
    font-family: 'poppins';
    font-size: 50px;
    color: #fff;
    font-weight: 600;
  }
  .sw-pricing-table-strike{
        text-decoration: line-through;
      -webkit-text-decoration-color: #161616;
              text-decoration-color: #161616;
      font-size: 22px;
      padding-top: 22px;
      padding-bottom: 22px;
      color: #161616;
      font-family: 'Bariol' !important;
  }
  .sw-pricing-btn {
      width: 80%;
      margin: 0 auto;
      background: #1880dd;
      display: block;
      padding: 10px 30px;
      color: #fff;
      font-family: Bariol;
      font-weight: bold;
      border-radius: 7px;
      font-size: 19px;
      text-align: center;
  }
  .sw-pricing-btn:hover {
      transition: 1s;
      color: #1880dd;
      border: 1px solid #1880dd;
      text-decoration: none;
      background: transparent;
  }
  .sw-pricing-requirement{
    color: #fff;
    font-family: 'poppins' !important;
    font-size: 18px !important;
    font-weight: 300;
    text-align: center;
    margin: 10px;
  }
  .sw-pricing-line{
    border: 1px solid #ffffffa1;
    width: 80%;
    margin: 25px auto;
  }
  .requirement-list {
      width: 90%;
      margin: 0 auto;
      text-align: left;
  }
  .requirement-list ul li{
    color: #fff;
    font-family: 'poppins' !important;
    font-size: 15px;
    font-weight: 300;
    line-height: 2.2em;
    list-style: none;
  }
  .fa-check{
     color: #fbc504;
  }
  /* .svg-inline--fa{
    margin-right: 20px;
  } */
  .section_seprator_40{
    height: 40px;
    width: 100%;
  }
  .requirement-list i {
      width: 8%;
  }
  .requirement-list i.fas.fa-check {
      color: #2dc824;
  }
  .requirement-list i.fas.fa-times {
     /* font-size: 26px;*/
      color: #c00404;
  }
  .sw-pricing-tables-section-ultimate{
    background-color: #1880dd;
    margin-bottom: 36px;
    text-align: center;
  }
  .sw-pricing-btn1{
   width: 80%;
    margin: 0 auto;
    background: #1880dd;
    display: block;
    padding: 10px 30px;
    color: #fff;
    font-family: Bariol;
      font-weight: bold;
    border-radius: 7px;
    font-size: 19px;
    text-align: center;
  }
  .sw-pricing-btn1:hover{
    transition: 1s;
    color: #1880dd;
    border: 1px solid #1880dd;
    text-decoration: none;
    background: transparent;
  }
  .subscription-hr {
      border: 1px solid #868181;
      margin: 0 auto;
  }
  .monthly-subscription {
      font-size: 31px !important;
      font-family: Bariol;
      font-weight: bold;
      width: 100%;
      text-align: left;
      padding-bottom: 25px;
      border-bottom: 2px solid #868181;
  }
  .annual-subscription{
      font-size: 31px !important;
      font-family: Bariol;
      font-weight: bold;
      width: 100%;
      text-align: left;
  
  }
  .subscription-radiospan {
      font-size: 16px !important;
      color: #fff;
      font-weight: 300;
      font-family: 'poppins' !important;
      display: block;
      text-align: left;
       padding-top: 14px;
      line-height: 1.6em;
  }
  .subscriptioncol1 {
    float: left;
    flex-direction: column;
    align-self: center;
  }
  .ssubscriptioncol2 {
    float: left;
  }
  .subscriptiondiv .btn-group{
      display: block;
  }
  .sw-subciption-text{
      font-size: 30px;
      font-family: 'poppins' !important;
      color: #fbc504;
      text-align: left;
      padding-left: 24px;
  }
  .sw-subciption-text-save{
      font-size: 20px;
      color: #fff;
      font-weight: 500;
      font-family: 'poppins' !important;
      text-align: left;
  }
  .subcription-list li, .subcription-list-1 li {
    font-family: 'poppins' !important;
    font-size: 15px;
    color: #fff;
    font-weight: 300;
    line-height: 2.2em;
      list-style: none;
  }
  .subscriptioncol2{
        padding-left: 146px;
  }
  ul.subcription-list,ul.subcription-list-1 {
      text-align: left;
      padding-left:26px;
  }
  .subcription-list i.fas.fa-check {
    color: #2dc824;
  }
  .subcription-list i.fas.fa-times{
  color:#c20d0d;
  }
  .subcription-list i {
    width: 7%;
  }
  ul.subcription-list-1 {
  text-align: left;
  }
  .subcription-list-1 i.fas.fa-check {
  color: #2dc824;
  }
  .subcription-list-1 i {
    width: 7%;
  }
  #monthly-subscription {
    margin-right: 11px;
  }
  #annual-subscription {
    margin-right: 11px;
  }
  #faqs #accordion {
    width: 55%;
    margin: 0 auto;
  }
  .card-position{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #faqs .panel-title{
    color: #fff;
    font-family: 'poppins' !important;
    font-size: 15px !important;
    font-weight: 400;
    text-transform: none !important;
    margin-bottom: 0px !important;
  }
  #faqs a:hover {
    text-decoration: none;
  }
  #faqs .card-body{
    border-top: 1px solid #2a2a2a;
    background-color: #393939;
    color: #ffffff;
    font-family: 'poppins' !important;
    font-size: 15px !important;
    font-weight: 300;
    padding: 10px 18px 25px 18px;
    border-radius: 0px 0px 5px 5px;
  }
  #faqs .card{
    border: 1px solid rgb(255, 255, 255);
  }
  .card-bg{
    background-color: #161616;
    margin-top: 25px;
  }
  #faqs .card-header{
    background-color: #393939;
    padding: 23px 18px;
  }
  #faqs i {
    float: right;
  }
  #faqs .panel-default {
    border-color: #222;
    border: none;
    border-bottom: 1px solid;
  }
  .panel-heading .accordion-toggle:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings';  /* essential for enabling glyphicon */
    content: "\e114";    /* adjust as needed, taken from bootstrap.css */
    float: right;        /* adjust as needed */
    color: grey;         /* adjust as needed */
  }
  .panel-heading .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\e080";    /* adjust as needed, taken from bootstrap.css */
  }
  .schedule-a-Call-bg{
    background-color: #7D69FF;
  }
  .btn-space{
    margin: 0px 20px 0px 20px;
  }
  #schedule-a-Call .row {
    margin-bottom: 0px;
  }
  .sw-pricing-div {
    text-align: center;
  }
  .sw-question-head{
      padding-bottom: 12px;
      font-size: 38px!important;
      color: #222;
      font-family: 'Gotham-Medium' !important;
      line-height: 56px;
      font-weight: 700;
      text-align: center;
  }
  .call-btn {
    margin-left: 49px;
  }
  .chat-btn {
    background-color: transparent !important;
    border-color: #ffffff !important;
    font-size: 19px !IMPORTANT;
    font-family: Bariol;
      font-weight: bold;
    padding: 10px 42px !important;
  }
  .call-btn {
    font-size: 19px !IMPORTANT;
    font-family: Bariol;
    font-weight: bold;
    padding: 10px 42px !important;
  }
  .newClass{
    background : red;
    color: #fff;
  }
  /*sticky header position*/
  .sticky.is-sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    z-index: 1;
    width: 100%;
  }

  .radio-box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
  }
  .radio-box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 9px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #868181;
    border-radius: 50%;
  }
  .radio-box:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .radio-box input:checked ~ .checkmark {
    background-color: #1880dd;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .radio-box input:checked ~ .checkmark:after {
    display: block;
  }
  .radio-box .checkmark:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }
  span.ultimate-icon {
    display: none;
  }
  @media only screen and (max-width: 1600px) and (min-width: 1200px){
    .plan-card{
      display:flex;
      flex-wrap: nowrap;
    }
    .sw-pricing-heading,.sw-pricing-subheading,.sw-question-head {
      font-size: 28px!important;
    }
    .sw-pricing-text,#faqs .card-body,.sw-pricing-requirement {
      font-size: 20px;
    }
    .sw-pricing-tables-head,.sw-subciption-text,.monthly-subscription,.annual-subscription {
      font-size: 24px !important;
    }
    .monthly-subscription {
      padding-top: 0 !important;
  }
  .sw-question-head{
    line-height:40px !important;
  }
  }
  @media only screen and (max-width: 1024px) and (min-width: 768px){
    .plan-card{
      display:flex;
      flex-wrap: nowrap;
    }
  .sw-pricing-heading,.sw-pricing-subheading,.sw-subciption-text,.monthly-subscription,.annual-subscription{
        font-size: 24px!important;
  }
  .sw-question-head{
      font-size: 24px!important;
      line-height: 1.3;
  }
  .section_seprator {
      width: 100%;
      height: 40px;
  }
  .mb-col-sep{
    width: 100% !important;
    height: 40px!important;
  }
  .sw-pricing-text,.sw-pricing-requirement {
      font-size: 18px;
  }
  .requirement-list ul li{
    font-size: 14px;
  }
  .subcription-list li,.subcription-list-1 li,.subscription-radiospan {
    font-size: 14px !important;
  }
  #faqs .card-body,#faqs .panel-title {
      font-size: 18px;
  }
  .sw-pricing-tables-head {
      font-size: 22px;
  }
  .requirement-list {
      width: 95%;
  }
  .sw-pricing-tables-box{
    width: 100%;
  }
  a.sw_pricing_button {
      font-size: 16px;
  }
  .navbar-toggler:not(:disabled):not(.disabled) {
    background-color: #ffffff;
  }
  .navbar-light .navbar-toggler-icon {
  background-color: #ffffff;
  }
  .ps-pricing-nav nav ul li a {
  padding: 7px 8px;
  font-size: 0.7rem;
  }
  nav#menu-center {
    display: none;
  }
  ul.subcription-list-1 li {
    font-size: 14px !important;
  }
  }
  @media only screen and (max-width: 767px) and (min-width: 280px){
    .sw_home_succeed{
      display: inline-block;
    }
    #faqs .card-header{
      padding: 15px 15px;
    }
    .sw-subciption-text{
      margin-bottom: 10px;
      margin-top: 20px;
    }
    .subscription-radiospan{
      font-size: 12px !important;
    }
    .subcription-head{
      font-size: 18px;
    }
    .plan-card{
      display: flex;
      flex-wrap: wrap;
    }
  .section_seprator {
      width: 100% !important;
      height: 30px!important;
  }
  .monthly-subscription {
      padding-top: 0;
  }
  .sw-pricing-heading,.sw-question-head{
    font-size: 22px !important;
    line-height: 1.3
  }
  .sw-pricing-text {
      font-size: 18px!important;
      line-height: 1.2!important;
  }
  .sw-pricing-text br{
    display: none;
  }
  .sw-pricing-subheading {
      font-size: 22px !important;
      margin-bottom: 0px !important;
  }
  .sw-pricing-tables-head {
      font-size: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
  }
  ul.subcription-list,ul.subcription-list-1 {
      padding-left: 0;
  }
  /* div#headingOne1 {
    padding-top: 0 !important;
  } */
  .subcription-list li,.subcription-list-1 li,.requirement-list ul li {
      font-size: 14px;
  }
  a.sw_pricing_button {
      display: block !important;
      margin: 10px 0 !important;
      font-size: 16px;
  }
  .sw-pricing-requirement {
      font-size: 18px;
  }
  .mb-col-sep{
    width:40px;
    height: 40px;
  }
  .sw-pricing-tables2 {
    margin-top: 40px !important;
  }
  .ultimate-icon {
    margin-top: -17%;
  }
  .requirement-list ul {
    padding-left: 0 !important;
  }
  .ps-pricing-nav nav {
    z-index: 9;
  }
  .navbar-preheader .navbar-toggle:hover .icon-bar {
  background-color: #4d4d4d;
  }
  .navbar-toggler:not(:disabled):not(.disabled){
  background-color: #ffffff;
  }
  .navbar-light .navbar-toggler-icon{
  background-color: #ffffff;
  }
  .ps-pricing-nav li {
  display: block;
  margin: 0 0px;
  width: 100%;
  }
  .psnav-item.active .psnav-link {
  width: 39%;
  margin: 0 auto; 
  }
  .nav-col-div{
  height: 0px;
  }
  .sw-pricing-table-pricesign {
    font-size: 30px !important;
    font-weight: 300;
  }
  .monthly-subscription,.annual-subscription {
    font-size: 18px !important;
    padding-top: 6px !important;
  }
  .sw-subciption-text {
    font-size: 18px !important;
    padding-left: 0;
  }
  .sw-subciption-text-save {
      font-size: 14px;
  }
  #faqs .card-body {
    font-size: 14px !important;
  }
  #faqs .panel-title {
    font-size: 14px !important;
    font-weight: 400;
  }
  .sw-pricing-table-pricesign {
    bottom: 10px !important;
  }
  }
  @media only screen and (max-width: 1024px) and (min-width: 280px){
  nav#menu-center {
    display: none;
  }
  .sticky-wrapper {
    height: 0 !important;
  }
  }

  @media (min-width: 320px) and (max-width: 480px) {
  .second_head {
    font-size: 22px !important;
  }
    }

  @media only screen and (max-width: 780px) and (min-width: 280px){

    }
@font-face {
    font-family: Bariol;
    src: url(/static/media/bariol_regular-webfont.e4213a39.eot);
    src: url(/static/media/bariol_regular-webfontd41d.e4213a39.eot?#iefix) format("embedded-opentype"),
     url(/static/media/bariol_regular-webfont.7d0cf79e.svg) format('svg'),
      url(/static/media/bariol_regular-webfont.6c235190.ttf) format('truetype'),
       url(/static/media/bariol_regular-webfont.38c3b9e9.woff) format('woff'),
        url(/static/media/Bariol_Regular.6a64a521.otf) format('otf'),
         url(/static/media/bariol_regular-webfont.6577b355.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: GothamBook;
    src: url(/static/media/Gotham-Book.7fe2d669.eot);
    src: url(/static/media/Gotham-Bookd41d.7fe2d669.eot?#iefix) format("embedded-opentype"),
     url(/static/media/Gotham-Book.65288315.woff) format("woff"), 
     url(/static/media/Gotham-Book.79ab7007.ttf) format('truetype'),
      url(/static/media/Gotham-Book.92032bfd.svg#Gotham-Book) format("svg"), 
      url(/static/media/Gotham-Book.e37cf599.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url(/static/media/GothamMedium.d23ef85d.woff) format('woff'),
    url(/static/media/GothamMedium.de228587.woff2) format('woff2'),
    url(/static/media/GothamMedium.ed646164.eot),
    url(/static/media/GothamMediumd41d.ed646164.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamMedium.c3f574b2.ttf) format('truetype'),
    url(/static/media/GothamMedium.9faf290f.svg#Gotham-Book) format('svg'),
    url(/static/media/GothamMedium.bcd733e6.otf) format('opentype'); 
}
  @font-face {
    font-family: BariolBold;
    src: url(/static/media/bariol_bold-webfont.fa0ddc94.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  /* @font-face {
    font-family: 'GothamBold';
    src: url('../../assets/process/Gotham-Bold.html') format('opentype');
  src: url('../../assets/process/gothambold.html') format('truetype');
    font-weight: normal;
    font-style: normal;
  } */
html,body {
    overflow-x: hidden;
}
.main-header.header-custom.header-transparency {
    background: #fff !important;
}
  .section_seprator {
    width: 100%;
    height: 80px;
}
.sw_heading {
    font-size: 38px!important;
    color: #222;
    font-family: 'Gotham-Medium' !important;
    line-height: 56px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.sw_tcs_heading {
    font-family: 'Gotham-Medium'!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
}
ul.sw_tcs_list li {
    color: #4d545b;
    font-family: 'Bariol' !important;
    font-size: 23px;
    padding: 10px 0px;
}
ul.sw_tcs_list {
    list-style: disc;
}
.horizontal_seprator {
    border: 1px solid #a1a1a1;
    width: 100%;
}
p.sw_tcs_text {
    font-size: 23px;
    line-height: 32px;
    font-family: Bariol !important;
    color: #4d545b;
    margin: 10px 0;
}
@media only screen and (max-width: 1600px) and (min-width: 1200px){
    .sw_tcs_heading {
        font-size: 24px !important;
    }
    .sw_heading {
        font-size: 28px!important;
    }
    ul.sw_tcs_list li, p.sw_tcs_text {
        font-size: 20px !important;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 768px){
.sw_tcs_heading {
    font-size: 20px !important;
}
.sw_heading {
    font-size: 24px!important;
}
ul.sw_tcs_list li, p.sw_tcs_text {
    font-size: 18px !important;
}
.section_seprator {
    width: 80px;
    height: 40px;
}
ul.sw_tcs_list {
    padding: 0;
}
}
@media only screen and (max-width: 767px) and (min-width: 280px){
    .sw_tcs_heading {
        font-size: 20px !important;
    }
    .sw_heading {
        font-size: 22px!important;
    }
    ul.sw_tcs_list li, p.sw_tcs_text {
        font-size: 18px !important;
    }
    .section_seprator {
        width: 80px;
        height: 30px;
    }
    ul.sw_tcs_list {
        padding: 0;
    }
    }
@media only screen and (max-width: 1366px) and (min-width: 280px){
}
/* RMI - General Classes */

.btn_place_order
 {
    box-sizing: content-box;
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    border: 2px solid #21bdbb;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.infoform-padd{
    padding: 10px 80px 10px 80px;
}
.heading-section {
    font-family: 'Gotham-Medium';
    font-size: 24px;
    text-transform: uppercase;
    color: #161616;
}
.section-title {
    font-family: 'Bariol-Bold';
    font-weight: 300;
    font-size: 19.42px;
}
.section-subtext {
    font-family: 'Bariol-Regular';
    font-size: 18px;
}

.textarea-style{
    border: 1px solid #222222;
    color: #222222;
}
.textarea-style:focus {
    box-shadow: 0 4px 4px 0px rgba(128, 128, 128, 0.42);
}
.payment-form {
    border: 1px solid #222222;
    padding: 8px 14px !important;
    height: calc(1.9em + .75rem + 6px);
    color: #222222;
}
.section-subtext.b-bold {
    font-family: 'Bariol-Bold';
}
.subscription-features-section .feature-title {
    font-family: 'Bariol-Bold';
    font-size: 22px;
}
.subscription-features-section .feature-list {
    list-style: none;
    padding: 0;
}
.subscription-features-section .feature-list li {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    line-height: 1.7;
}

.subscription-features-section .feature-list li:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0 0;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 16px;
    color: #04cc09;
}

.subscription-features-section .feature-list li.cross:before {
    content: "\f00d";
    color: #c20d0d;
    font-size: 18px;
}

.cart-section, .cart-section td {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    color: #555555;
}
.cart-section .thick-line {
    color: #161616;
}

.terms-section {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    color: #555555;
}

hr {border-top: 1px solid rgba(0,0,0,.3);}

/* RMI - Form control styling */
.payment-section {
    padding-right: 50px;
}
.payment-form .form-control {
    border: 1px solid #222222;
    padding: 8px 14px;
    height: calc(1.9em + .75rem + 2px);
    color: #222222;
}
.payment-form:focus {
    box-shadow: 0 4px 4px 0px rgba(128, 128, 128, 0.42);
}
.payment-form .form-control.error-txt:focus {
    box-shadow: none;
}
.payment-section .img-cards-accepted {
    margin-top: -10px;
}
.btn_place_order {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    border: 2px solid #21bdbb;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.btn_place_order:hover {
    background: #0e9e9d;
    border: 2px solid #0e9e9d;
}
 .btn_place_order:focus {
    box-shadow: none;
}

.btn_place_order i{
opacity: 0;
visibility: hidden;
margin-left: -25px;
font-size: 20px;
transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
-webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.btn_place_order:hover i {  
margin-right: 10px;
opacity: 1;
visibility: visible;  
}
 .btn_place_order i.fa-spin {
    margin-right: 10px;
    opacity: 1;
    visibility: visible;
}

.first-opt {
    color: #a20000 !important;
}

.error-txt {
    border: 2px solid #ef4836!important;
}

.error-checkout {
    color: red;
    font-size: 14px;
    text-align: center;
    font-family: 'Gotham-Book';
}

#error_check_terms {
    margin-top: 12px;
    color: red;
    font-size: 14px;
    font-family: 'Gotham-Book';
}

.rs-enabled {
    display: none;
}

#hideAll-1 {
    display: none;
}

#seeAll-1, #hideAll-1 {
    width: 100%;
    text-transform: none;
    font-size: 20px;
}
#seeAll-1:hover {
    cursor: pointer;
}

.general-footer-actions .step-previous {
    margin-right: 0px !important;
}

.op-sb-terms{
    width: 100% !important;
}

.cls-termspolicy:before {
    min-width: 1.8em !important;
}

/* Media Queries - S */
@media screen and (max-width: 1200px) {
    .package-col-1, .package-col-2 {
        margin: 0!important;
    }
}

@media screen and (max-width: 767px) {
    .feature-list {
        display: none;
    }
    #seeAll-1 {
        display: block;
    }
    .rs-disabled {
        display: none;
    }
    .rs-enabled {
        display: block;
    }
    .supported-card-col {
        display: none;
    }
    .payment-section {
        padding-right: 0px;
    }
    .payment-section .mb-3 {
        margin-bottom: 0px!important;
    }
    .payment-section .secure-payment {
        width: 100%;
    }

    .general-footer-actions .step-previous {
        margin: 0 auto !important;
        text-align: center;
        float: none !important;
    }
    
    .cart-section {
        border-top: 1px solid #dee2e6;
    }
}

@media screen and (max-width: 480px) {
    .infoform-padd{
        padding: 10px 10px 10px 10px;
    }
    .info-form-padd{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    }
}
/* RMI - General Classes */

.btn_place_order
 {
    box-sizing: content-box;
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    border: 2px solid #21bdbb;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.infoform-padd{
    padding: 10px 80px 10px 80px;
}
.heading-section {
    font-family: 'Gotham-Medium';
    font-size: 24px;
    text-transform: uppercase;
    color: #161616;
}
.section-title {
    font-family: 'Bariol-Bold';
    font-size: 22px;
}
.section-subtext {
    font-family: 'Bariol-Regular';
    font-size: 18px;
}

.textarea-style{
    border: 1px solid #222222;
    color: #222222;
}
.textarea-style:focus {
    box-shadow: 0 4px 4px 0px rgba(128, 128, 128, 0.42);
}
.payment-form {
    border: 1px solid #222222;
    padding: 8px 14px !important;
    height: calc(1.9em + .75rem + 6px);
    color: #222222;
}
.section-subtext.b-bold {
    font-family: 'Bariol-Bold';
}
.subscription-features-section .feature-title {
    font-family: 'Bariol-Bold';
    font-size: 22px;
}
.subscription-features-section .feature-list {
    list-style: none;
    padding: 0;
}
.subscription-features-section .feature-list li {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    line-height: 1.7;
}

.subscription-features-section .feature-list li:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0 0;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 16px;
    color: #04cc09;
}

.subscription-features-section .feature-list li.cross:before {
    content: "\f00d";
    color: #c20d0d;
    font-size: 18px;
}

.cart-section, .cart-section td {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    color: #555555;
}
.cart-section .thick-line {
    color: #161616;
}

.terms-section {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    color: #555555;
}

hr {border-top: 1px solid rgba(0,0,0,.3);}

/* RMI - Form control styling */
.payment-section {
    padding-right: 50px;
}
.payment-form .form-control {
    border: 1px solid #222222;
    padding: 8px 14px;
    height: calc(1.9em + .75rem + 2px);
    color: #222222;
}
.payment-form:focus {
    box-shadow: 0 4px 4px 0px rgba(128, 128, 128, 0.42);
}
.payment-form .form-control.error-txt:focus {
    box-shadow: none;
}
.payment-section .img-cards-accepted {
    margin-top: -10px;
}
.btn_place_order {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    border: 2px solid #21bdbb;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.btn_place_order:hover {
    background: #0e9e9d;
    border: 2px solid #0e9e9d;
}
 .btn_place_order:focus {
    box-shadow: none;
}

.btn_place_order i{
opacity: 0;
visibility: hidden;
margin-left: -25px;
font-size: 20px;
transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
-webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.btn_place_order:hover i {  
margin-right: 10px;
opacity: 1;
visibility: visible;  
}
 .btn_place_order i.fa-spin {
    margin-right: 10px;
    opacity: 1;
    visibility: visible;
}

.first-opt {
    color: #a20000 !important;
}

.error-txt {
    border: 2px solid #ef4836!important;
}

.error-checkout {
    color: red;
    font-size: 14px;
    text-align: center;
    font-family: 'Gotham-Book';
}

#error_check_terms {
    margin-top: 12px;
    color: red;
    font-size: 14px;
    font-family: 'Gotham-Book';
}

.rs-enabled {
    display: none;
}

#hideAll-1 {
    display: none;
}

#seeAll-1, #hideAll-1 {
    width: 100%;
    text-transform: none;
    font-size: 20px;
}
#seeAll-1:hover {
    cursor: pointer;
}

.general-footer-actions .step-previous {
    margin-right: 0px !important;
}

.op-sb-terms{
    width: 100% !important;
}

.cls-termspolicy:before {
    min-width: 1.8em !important;
}

/* Media Queries - S */
@media screen and (max-width: 1200px) {
    .package-col-1, .package-col-2 {
        margin: 0!important;
    }
}

@media screen and (max-width: 767px) {
    .feature-list {
        display: none;
    }
    #seeAll-1 {
        display: block;
    }
    .rs-disabled {
        display: none;
    }
    .rs-enabled {
        display: block;
    }
    .supported-card-col {
        display: none;
    }
    .payment-section {
        padding-right: 0px;
    }
    .payment-section .mb-3 {
        margin-bottom: 0px!important;
    }
    .payment-section .secure-payment {
        width: 100%;
    }

    .general-footer-actions .step-previous {
        margin: 0 auto !important;
        text-align: center;
        float: none !important;
    }
    
    .cart-section {
        border-top: 1px solid #dee2e6;
    }
}

@media screen and (max-width: 480px) {
    .infoform-padd{
        padding: 10px 10px 10px 10px;
    }
    .info-form-padd{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    }
}
/* RMI - General Classes */
.heading-section {
    font-family: 'Gotham-Medium';
    font-size: 24px;
    text-transform: uppercase;
    color: #161616;
}
.section-title {
    font-family: 'poppins';
    font-size: 18px;
    font-weight: 400;
}
.section-subtext {
    font-family: 'Bariol-Regular';
    font-size: 18px;
}

.section-subtext.b-bold {
    font-family: 'Bariol-Bold';
}
.subscription-features-section .feature-title {
    font-family: 'Bariol-Bold';
    font-size: 22px;
}
.subscription-features-section .feature-list-billing {
    list-style: none;
    padding: 0;
}
.subscription-features-section .feature-list-billing li {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    line-height: 1.7;
}

/* .subscription-features-section .feature-list li:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0 0;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 16px;
    color: #04cc09;
} */

.subscription-features-section .feature-list-billing li.cross:before {
    content: "\f00d";
    color: #c20d0d;
    font-size: 18px;
}

.cart-section, .cart-section td {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    color: #555555;
}
.cart-section .thick-line {
    color: #161616;
}

.terms-section {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    color: #555555;
}

hr {border-top: 1px solid rgba(0,0,0,.3);}

/* RMI - Form control styling */
.payment-section {
    padding-right: 50px;
}
.payment-form-sub .form-control {
    border: 1px solid #222222;
    padding: 8px 14px !important;
    height: calc(1.9em + .75rem + 2px);
    color: #222222;
}
.payment-form-sub .form-control:focus {
    box-shadow: 0 4px 4px 0px rgba(128, 128, 128, 0.42);
}
.payment-form-sub .form-control.error-txt:focus {
    box-shadow: none;
}
.payment-section .img-cards-accepted {
    margin-top: -10px;
}
.payment-form-sub .btn_place_order {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    border: 2px solid #21bdbb;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.payment-form-sub .btn_place_order:hover {
    background: #0e9e9d;
    border: 2px solid #0e9e9d;
}
.payment-form-sub .btn_place_order:focus {
    box-shadow: none;
}



.card_options {
    background-color: #fff;
	outline: none;
	
	padding: 10px 10px;
    border-radius: 5px;
	border-bottom: 4px solid #fbc504;
	
	color: #333;
		
}

.payment-form-sub .btn_place_order i{
opacity: 0;
visibility: hidden;
margin-left: -25px;
font-size: 20px;
transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
-webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.payment-form-sub .btn_place_order:hover i {  
margin-right: 10px;
opacity: 1;
visibility: visible;  
}
.payment-form-sub .btn_place_order i.fa-spin {
    margin-right: 10px;
    opacity: 1;
    visibility: visible;
}

.first-opt {
    color: #a20000 !important;
}

.error-txt {
    border: 2px solid #ef4836!important;
}

.error-checkout {
    color: red;
    font-size: 14px;
    text-align: center;
    font-family: 'Gotham-Book';
}

#error_check_terms {
    margin-top: 12px;
    color: red;
    font-size: 14px;
    font-family: 'Gotham-Book';
}

.rs-enabled {
    display: none;
}

#hideAll-1 {
    display: none;
}

#seeAll-1, #hideAll-1 {
    width: 100%;
    text-transform: none;
    font-size: 20px;
}
#seeAll-1:hover {
    cursor: pointer;
}

.general-footer-actions .step-previous {
    margin-right: 0px !important;
}

.op-sb-terms{
    width: 100% !important;
}

.cls-termspolicy:before {
    min-width: 1.8em !important;
}

/* Media Queries - S */
@media screen and (max-width: 1200px) {
    .package-col-1, .package-col-2 {
        margin: 0!important;
    }
}

@media screen and (max-width: 767px) {
    .feature-list-billing {
        display: none;
    }
    #seeAll-1 {
        display: block;
    }
    .rs-disabled {
        display: none;
    }
    .rs-enabled {
        display: block;
    }
    .supported-card-col {
        display: none;
    }
    .payment-section {
        padding-right: 0px;
    }
    .payment-section .mb-3 {
        margin-bottom: 0px!important;
    }
    .payment-section .secure-payment {
        width: 100%;
    }

    .general-footer-actions .step-previous {
        margin: 0 auto !important;
        text-align: center;
        float: none !important;
    }
    
    .cart-section {
        border-top: 1px solid #dee2e6;
    }
}

@media screen and (max-width: 480px) {

}
.err{
    margin-top: 0px !important;
}


/* RMI - General Classes */
.package-table {
    border: 2px solid #161616;
}
.package-table:hover {
    box-shadow: 0 2px 20px 2px rgba(128, 128, 128, 0.32);
}
.card-title.pricing-card-title {
    font-size: 80px;
    font-family: 'Bariol-Regular';
    color: #000000;
}
.card-title.pricing-card-title small {
    font-size: 16px;
    font-family: 'Bariol-Regular';
    font-weight: 600;
    color: #000000 !important;
}
.currency-symbol {
    font-size: 30px;
    font-family: 'Bariol-Regular';
    color: #000000;
    vertical-align: text-top;
}
.card-header {
    background: #161616;
    color: #ffffff;
    font-family: 'poppins';
}
.card-header h4 {
    font-size: 22px;
    color: #fff;
    font-family: 'poppins';
    
}
.apple-os .card-header h4 {
    padding-top: 12px;
}
.card-header .ribbon {
    display: none;
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}
.card-header .ribbon.selected {
    display: inline;
}
.card-header .ribbon span {
    font-size: 12px;
    font-family: 'Gotham-Book';
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #21bdbb;
    background: linear-gradient(#21bdbb 0%, #21bdbb 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
}
.apple-os .card-header .ribbon span {
    line-height: 24px;
}
.card-header .ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #21bdbb;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #21bdbb;
}
.card-header .ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #21bdbb;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #21bdbb;
}
.package-info {
    border: 2px solid #222222;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding: 8px 0;
}
.package-info .info-line1 {
    font-family: 'poppins';
    font-size: 14px;
    padding: 8px 0px;
}
.package-info .info-line2 {
    font-family: 'Gotham-Medium';
    font-size: 16px;
    padding: 8px 0px;
}
.btn-package {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 18px;
    border: 2px solid #21bdbb;
}
.btn-package:hover {
    background: #0e9e9d;
    border: 2px solid #0e9e9d;
}
.btn-package.highlighted {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 18px;
    border: 2px solid #21bdbb;
}
.btn-package.highlighted:hover {
    background: #0e9e9d;
    border: 2px solid #0e9e9d;
}
.btn-package.highlighted.blackborder {
    background: transparent;
    border: 2px solid #161616;
    color: #161616;
}
.btn-package i{
    opacity: 1;
    visibility: visible;
    margin-left: 2px;
    font-size: 20px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
/* .btn-package:hover i {  
    margin-right: 10px;
    opacity: 1;
    visibility: visible;  
} */

.btn-package:focus {
    box-shadow: none;
}
.package-feature-list li {
    text-align: left;
    font-family: 'Bariol-Regular';
    font-size: 18px;
    color: #000000;
}
/* .package-feature-list li:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0 0;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 16px;
    color: #04cc09;
} */
.package-feature-list li.premium:before {
    /* content: "\f00d"; */
    color: #c20d0d;
    /* font-size: 18px; */
}
.regular-price {
    display: block;
    font-size: 20px;
    font-family: 'Gotham-Book';
    color: #161616 !important;
    text-decoration: line-through;
}

#seeAll-1, #seeAll-2 {
    display: none;
}

#hideAll-1, #hideAll-2 {
    display: none;
}

.cls-view-features {
    font-family: 'Gotham-Medium';
    cursor: pointer;
}

.cls-view-features i {
    color: #21bdbb;
}
/* Media Queries - S */
@media screen and (max-width: 1200px) {
    .package-col-1, .package-col-2 {
        margin: 0!important;
    }
}

@media screen and (max-width: 767px) {
    .package-feature-list {
        /* display: none; */
    }
    #seeAll-1, #seeAll-2 {
        display: block;
    }
    .btn-bottom {
        display: none;
    }
}

@media screen and (max-width: 480px) {

}
.pricesign{
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    position: relative;
    font-family: 'poppins';
}
.service-pg-button:hover{
    background: transparent !important;
    text-decoration: none!important;
    color: #ffffff!important;
    border-color: #ffffff!important;
}

.category_card{
     margin-top: 10px;
       
}

.service-pg-button{
    background: #FBC504!important;
    color: #141414 !important;
    border: 1px solid #FBC504!important;
    padding: 10px 80px;
    text-decoration: none!important;
    border-radius: 50px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 18px;
    min-width: 175px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    /* margin: 0 5px 0 5px !important; */
    text-shadow: none !important;
}

@media only screen and (max-width:480px) and (min-width:320px){
   
.cat{
    margin-top: 10px !important
    ;   
} 


}
.cat{
    cursor: pointer;
}
/* RMI - General Classes */
.cart-sticky {
    position: fixed;
    left: 62.2%;
    right: 0;
    top: 0px;
    z-index: 2;
    width: 100%;
    width: 400px;
}

.bill-option-padd {
    margin-right: 10px !important;
}

.label-inline {
    display: inline !important;
}
.cart-section {
    padding: 10px 20px 10px 20px;
    background-color: #f7f7f7;
    /* width: 400px; */
}

.heading-section {
    font-family: 'Gotham-Medium';
    font-size: 24px;
    text-transform: uppercase;
    color: #161616;
}

.section-title {
    font-family: 'poppins';
    font-size: 18px;
    font-weight: 400;
}

.section-subtext {
    font-family: 'Bariol-Regular';
    font-size: 18px;
}

.section-subtext.b-bold {
    font-family: 'Bariol-Bold';
}

.subscription-features-section .feature-title {
    font-family: 'Bariol-Bold';
    font-size: 22px;
}

.subscription-features-section .feature-list-billing {
    list-style: none;
    padding: 0;
}

.subscription-features-section .feature-list-billing li {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    line-height: 1.7;
}

/* .subscription-features-section .feature-list li:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0 0;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 16px;
    color: #04cc09;
} */

.subscription-features-section .feature-list-billing li.cross:before {
    content: "\f00d";
    color: #c20d0d;
    font-size: 18px;
}

.card_options {
    background-color: #fff;
	outline: none;
	
	padding: 10px 10px !important;
    border-radius: 8px;
	border-bottom: 4px solid #fbc504;
	
	color: #333;
		
}
.cart-section,
.cart-section td {
    font-family: 'poppins';
    font-size: 17px;
    color: #555555;
}

.cart-section .thick-line {
    color: #161616;
}

.terms-section {
    font-family: 'Bariol-Regular';
    font-size: 18px;
    color: #555555;
}

hr {
    border-top: 1px solid rgba(0, 0, 0, .3);
}

/* RMI - Form control styling */
.payment-section {
    padding-right: 50px;
}

.payment-form-sub .form-control {
    border: 1px solid #222222;
    padding: 8px 14px !important;
    height: calc(1.9em + .75rem + 2px);
    color: #222222;
}

.payment-form-sub .form-control:focus {
    box-shadow: 0 4px 4px 0px rgba(128, 128, 128, 0.42);
}

.payment-form-sub .form-control.error-txt:focus {
    box-shadow: none;
}

.payment-section .img-cards-accepted {
    margin-top: -10px;
}

.payment-form-sub .btn_place_order {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 18px;
    border: 2px solid #21bdbb;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}

.payment-form-sub .btn_place_order:hover {
    background: #0e9e9d;
    border: 2px solid #0e9e9d;
}

.payment-form-sub .btn_place_order:focus {
    box-shadow: none;
}

.payment-form-sub .btn_place_order i {
    opacity: 0;
    visibility: hidden;
    margin-left: -25px;
    font-size: 20px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.payment-form-sub .btn_place_order:hover i {
    margin-right: 10px;
    opacity: 1;
    visibility: visible;
}

.payment-form-sub .btn_place_order i.fa-spin {
    margin-right: 10px;
    opacity: 1;
    visibility: visible;
}

.first-opt {
    color: #a20000 !important;
}

.error-txt {
    border: 2px solid #ef4836 !important;
}

.error-checkout {
    color: red;
    font-size: 14px;
    text-align: center;
    font-family: 'Gotham-Book';
}

#error_check_terms {
    margin-top: 12px;
    color: red;
    font-size: 14px;
    font-family: 'Gotham-Book';
}

.rs-enabled {
    display: none;
}

#hideAll-1 {
    display: none;
}

#seeAll-1,
#hideAll-1 {
    width: 100%;
    text-transform: none;
    font-size: 20px;
}

#seeAll-1:hover {
    cursor: pointer;
}

.general-footer-actions .step-previous {
    margin-right: 0px !important;
}

.op-sb-terms {
    width: 100% !important;
}

.cls-termspolicy:before {
    min-width: 1.8em !important;
}

.subcription-pg-heading {
    font-family: 'poppins';
    font-size: 30px;
    color: #212529;
}

/* Media Queries - S */
@media screen and (max-width: 1200px) {

    .package-col-1,
    .package-col-2 {
        margin: 0 !important;
    }
}

@media screen and (max-width: 767px) {

    .feature-list-billing {
        display: none;
    }

    #seeAll-1 {
        display: block;
    }

    .rs-disabled {
        display: none;
    }

    .rs-enabled {
        display: block;
    }

    .supported-card-col {
        display: none;
    }

    .payment-section {
        padding-right: 0px;
    }

    .payment-section .mb-3 {
        margin-bottom: 0px !important;
    }

    .payment-section .secure-payment {
        width: 100%;
    }

    .general-footer-actions .step-previous {
        margin: 0 auto !important;
        text-align: center;
        float: none !important;
    }

    .cart-section {
        border-top: 1px solid #dee2e6;
    }

    .cart-amount-item {
    position: -webkit-sticky;
    position: sticky;
    }
}

@media screen and (max-width: 480px) {
    .cart-section {
        padding: 20px 0px 20px 0px;
    }

    .subcription-pg-heading {
        font-size: 25px;
    }

    .cart-amount-item {
        position: -webkit-sticky;
        position: sticky;
    }

    .payment-sub-padd-mob {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}

.err {
    margin-top: 0px !important;
}


@media screen and (max-width: 768px) {
    .display {
        display: none;
    }
    .cart-mobile{
        display: block !important;
    }
}

@media screen and (min-width: 768px) {
    .display {
        display: block !important;
    }
    .cart-mobile{
        display: none !important;
    }
}
/* RMI - General Classes */
.package-table {
    border: 2px solid #161616;
}
.package-table:hover {
    box-shadow: 0 2px 20px 2px rgba(128, 128, 128, 0.32);
}
.card-title.pricing-card-title {
    font-size: 80px;
    font-family: 'Bariol-Regular';
    color: #000000;
}
.card-title.pricing-card-title small {
    font-size: 16px;
    font-family: 'Bariol-Regular';
    font-weight: 600;
    color: #000000 !important;
}
.currency-symbol {
    font-size: 30px;
    font-family: 'Bariol-Regular';
    color: #000000;
    vertical-align: text-top;
}
.card-header {
    background: #161616;
    color: #ffffff;
    font-family: 'poppins';
}
.card-header h4 {
    font-size: 22px;
    color: #fff;
    font-family: 'poppins';
    
}
.apple-os .card-header h4 {
    padding-top: 12px;
}
.card-header .ribbon {
    display: none;
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}
.card-header .ribbon.selected {
    display: inline;
}
.card-header .ribbon span {
    font-size: 12px;
    font-family: 'Gotham-Book';
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #21bdbb;
    background: linear-gradient(#21bdbb 0%, #21bdbb 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
}
.apple-os .card-header .ribbon span {
    line-height: 24px;
}
.card-header .ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #21bdbb;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #21bdbb;
}
.card-header .ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #21bdbb;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #21bdbb;
}
.package-info {
    border: 2px solid #e3e3e3;;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    padding: 8px 0;
}
.package-info .info-line1 {
    font-family: 'poppins';
    font-size: 14px;
    padding: 8px 0px;
}
.package-info .info-line2 {
    font-family: 'Gotham-Medium';
    font-size: 16px;
    padding: 8px 0px;
}
.btn-package {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 18px;
    border: 2px solid #21bdbb;
}
.btn-package:hover {
    background: #0e9e9d;
    border: 2px solid #0e9e9d;
}
.btn-package.highlighted {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 18px;
    border: 2px solid #21bdbb;
}
.btn-package.highlighted:hover {
    background: #0e9e9d;
    border: 2px solid #0e9e9d;
}
.btn-package.highlighted.blackborder {
    background: transparent;
    border: 2px solid #161616;
    color: #161616;
}
.btn-package i{
    opacity: 1;
    visibility: visible;
    margin-left: 2px;
    font-size: 20px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
/* .btn-package:hover i {  
    margin-right: 10px;
    opacity: 1;
    visibility: visible;  
} */

.btn-package:focus {
    box-shadow: none;
}
.package-feature-list li {
    text-align: left;
    font-family: 'poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: #000000;
}
/* .package-feature-list li:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0 0;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 16px;
    color: #04cc09;
} */
.package-feature-list li.premium:before {
    /* content: "\f00d"; */
    color: #c20d0d;
    /* font-size: 18px; */
}
.regular-price {
    display: block;
    font-size: 20px;
    font-family: 'Gotham-Book';
    color: #161616 !important;
    text-decoration: line-through;
}

#seeAll-1, #seeAll-2 {
    display: none;
}

#hideAll-1, #hideAll-2 {
    display: none;
}

.cls-view-features {
    font-family: 'Gotham-Medium';
    cursor: pointer;
}

.cls-view-features i {
    color: #21bdbb;
}
.webpackage-price{
    font-size: 60px;
    font-weight: 400;
    font-family: 'Poppins';
    color: #000000;
}
.webpackage-currency{
    font-size: 30px;
    font-family: 'poppins';
    font-weight: 300;
    color: #000000;
    vertical-align: text-top;
}
/* Media Queries - S */
@media screen and (max-width: 1200px) {
    .package-col-1, .package-col-2 {
        margin: 0!important;
    }
}

@media screen and (max-width: 767px) {
    .package-feature-list {
        /* display: none; */
    }
    #seeAll-1, #seeAll-2 {
        display: block;
    }
    .btn-bottom {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .webpackage-price{
        font-size: 50px;
    }
    .package-feature-list li{
        font-size: 14px;
    }
}
.domain-status-bar i {font-size: 22px;}
.domain_status {max-width: 100%;}
.domain_status i {font-size: 18px;}
.domain_new, .domain_email_new {margin-right: -32px;box-shadow: 0 13px 24px 1px rgba(195, 195, 195, 0.32);min-height: 50px;border: 1px solid #2b2e54;font-family: 'Bariol-Regular';font-size: 22px;}
.domain_new:focus, .domain_email_new:focus {outline:0;box-shadow: 0 13px 24px 1px rgba(195, 195, 195, 0.32); border: 1px solid #2b2e54;}
.domain_own {box-shadow: 0 13px 24px 1px rgba(195, 195, 195, 0.32);min-height: 50px;border: 1px solid #2b2e54;font-family: 'Bariol-Regular';font-size: 22px;}
.domain_own:focus {outline:0;box-shadow: 0 13px 24px 1px rgba(195, 195, 195, 0.32); border: 1px solid #2b2e54;}
.opt_domain .domain_own {max-width: 880px;margin: 0 auto;}
.domain-field {width: 100%;display: flex;}


.lead {
  font-family: "Poppins" !important;
}

.col-aligment{
  align-self: center;
}
.card-header{
  font-family: 'Poppins' !important;
  font-size: 20px;
  color: black;
  font-weight: 700;
}

.domain-card-body label{
  font-family: 'Poppins' !important;
  font-size: 16px;
  color: black;
  font-weight: 700;
}

.domain-card-body p{
  font-family: 'Poppins' !important;
  font-size: 16px;
  color: black;
  font-weight: 500;
}

.btn-search.btn_find_new_domain {
  width: 100%;
  color: #f7f7f7;
  background: #161616;
  font-family: 'Bariol-Bold';
  font-size: 22px;
  text-transform: uppercase;
  border: 2px solid #161616;
  max-width: 180px;
}
.btn-search {
transition: all 0.3s ease-in-out !important;
-webkit-transition: all 0.3s ease-in-out !important;
}
.btn-search i{
opacity: 0;
visibility: hidden;
margin-left: -18px;
font-size: 20px;
transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
-webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.btn-search:hover i {  
margin-right: 10px;
opacity: 1;
visibility: visible;  
}
.btn-next-step{
width: 100%;
color: #f7f7f7;
background: #1980dd;
font-family: 'Bariol-Bold';
font-size: 22px;
text-transform: uppercase;
border: 2px solid #1980dd;
max-width: 180px;
}
.btn-next-step:hover{
color: #f7f7f7;
background: #1980dd;
border: 2px solid #1980dd;
box-shadow: none;
}
.btn-next-step:focus {
box-shadow: none!important;
color: #f7f7f7;
background: #1980dd;
border: 2px solid #1980dd;
}

.btn-next-step i{
opacity: 0;
visibility: hidden;
margin-right: -22px;
transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
-webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.btn-next-step:hover i {
margin-left: 12px;
opacity: 1;
visibility: visible;  
}
.btn-search.btn-search-again {
background: transparent;
color: #161616;
}
.btn-search.btn-search-again:focus {
box-shadow: none!important;
}
#next_step_bar {display: none; margin-bottom: 140px;}
#error_step_bar {display: none; margin-bottom: 140px;}
.btn-go-next {
color: #ffffff;
background: #1980dd;
font-family: 'Bariol-Bold';
font-size: 22px;
border: 2px solid #1980dd;
text-transform: uppercase;
width: 180px;
padding: 4px 6px;
}
.btn-go-next:hover {
background: #1980dd;border: 2px solid #1980dd;color:#ffffff;
}
.btn-go-next:focus {
box-shadow: none!important;
}
.err-colour{
  color: #ff0b0b;
    font-size: 14px;
    font-weight: bold;
    margin-top: -24px;
}
#search{
  display: none;
}
.domain-title{
  font-family: 'poppins';
    font-size: 18px;
    font-weight: 400;
    color:#131313;
}
.domain-request-head{
  font-size: 22px;
    color: #131313;
    font-family: 'poppins';
    margin: 5px;
}
.domain-head{
  margin-top: 20px;
    padding: 15px;
    background-color: #fbc504;
    border-radius: 10px;
}
.domain-info-padd{
  padding: 20px 0px 20px 0px !important;
}
@media only screen and (max-width:767px) and (min-width:280px){
  .domain-card-body p{
    font-size: 12px;
    color: black;
    font-weight: 800;
  }
  .domain-card-body label{
    font-size: 12px;
    font-weight: 500;
  }
  .domain-head{
    margin-top: 15px;
    padding: 2px;
  }
  .domain-request-head{
    font-size: 18px;
  }
  .domain-title{
    font-size: 14px;
  }
  .domainname-option{
    margin-bottom: 5px !important;
  }
  .mg-bottom{
    margin-bottom: 40px;
  }
  .btn-next-step{
    font-size: 18px;
  }
  .domain_own{
    font-size: 17px;
  }
  .form-size{
    height: 50px !important;
  }
}
@media only screen and (max-width:1024px) and (min-width:768px){
  .domain-title{
    font-size: 18px;
  }
}
@media only screen and (max-width:1600px) and (min-width:1200px){
  .domain-title{
    font-size: 18px;
  }
}
/* RMI - Category Sidebar - S */
.design-bgclr{
    background-color: #f7f7f7 !important;
}

.design-selection-box {
    display: flex;
}
.filter-category-design{
    border-bottom: 1px solid #bfbfbf2e;
    padding: 10px 0px 15px 0px;
}
.filter-category-design h3{
    font-size: 20px;
    font-family: 'poppins';
    color: #404040;
}
.category-sidebar {
    max-height: 600px;
    overflow-y: scroll;
}

.category-sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.category-sidebar::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.category-sidebar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.3);
    background-color: #dddddd;
}

.category-heading {
    padding: 40px 0px;
    padding-bottom: 5px;
}

.category-heading h3 {
    font-size: 18px;
    font-family: 'Gotham-Book';
    color: #404040;
}
/* RMI - Category Sidebar - E */

.design-category-list {
    padding: 0;
}

.design-category-list .cl-item-web {
    padding: 18px 0px;
    list-style: none;
   
}

.cl-item-web a {
    color: #494949;
    font-family: 'Gotham-Book';
    font-size: 16px;
    text-decoration: none !important;
    transition-duration: .2s;
    transition-property: padding-left text-indent;
    /* font-smoothing: antialiased; */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.cl-item-web:hover .cl-item-name {
    padding-left: 5px;    
}


/* .cl-item:before {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0px 6px 0px 6px;
    content: "\f054";
    font-family: FontAwesome;
    font-size: 16px;
    color: #21bdbb;
} */

.design-items, .design-items > div {
    display: block;
    float: left;
 }

.design-items {
    padding: 5px 30px;
    width: 100%;
    min-height: 1200px;
}

.design-items.minheight {
    min-height: 1200px;
}

.item-details {
    /* padding: 0 30px !important; */
}

.item-details img {
    width: 100%;
}

.design-box {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    border: 1px solid #e0e0e0;
}
.design-box img {
    width: 100%;
    height: 250px !important;
}
.design-box .design-inner {
    background: #ffffff;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    padding: 0;
    transition: opacity .5s;
}
.design-box .design-inner p {
    color: #fff;
    line-height: 150px;
    font-family: 'arial';
    text-align: center;
}
.design-box:hover .design-inner {
    /* opacity: .96;
    transition: opacity .5s; */
}

.design-inner .inner-content {
    float: left;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: relative;
}

.noScroll {
    overflow-y: hidden;
}

.stickySidebar {
    position: fixed;
    width: 290px;
    max-height: 100%;
    height: calc(100vh - 50px);
    bottom: 0px;
    top: auto;
}

.search-by-tag-web-design {
    padding-bottom: 10px;
    border-bottom: 3px solid #21bdbb;
    width: 250px;
}

.search-by-tag-web-design .tag-input {
    outline: 0;
    border: 0;
    box-shadow: none !important;
    width: 210px;
}
.search-by-tag-web-design .tag-input:hover {
    outline: 0;
    border: 0;
    box-shadow: none !important;
}

/* RMI - Screen loader spinner */
.preview-progressbar .screen-overlay {
    display: block;
    z-index: 100;
    background-color: #fff;
    opacity: 0.97;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    margin: 0 0;
    height: auto;
    position: absolute;
}
.preview-progressbar .loader-message {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    height: 100px;
    bottom: 0;
    top: 0;
    margin: auto;
    font-family: 'Gotham-Book';
    font-size: 18px;
}

.preview-progressbar .screen-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;    
    margin: auto;
}

@-webkit-keyframes rotation {
from {-webkit-transform: rotate(0deg);}
to {-webkit-transform: rotate(359deg);}
}
@keyframes rotation {
from {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
to {-webkit-transform: rotate(359deg);transform: rotate(359deg);}
}

/* Design Preview Box - S */
.design-preview {
    background-color: #fff;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 10px;
    z-index: 250;
    transition: all 0.25s ease-in;
    display: none;
}
.design-preview.show {
    opacity: 1;
    top: 0;
    position: fixed;
    display: block;
    z-index: 999;
}

.item-preview-open {
    background: transparent;
    color: #212529;
    font-family: 'Gotham-Medium';
    font-size: 16px !important;
    text-transform: uppercase;
}

.btn-sw-theme {
    background: transparent;
    color: #212529;
    font-family: 'Gotham-Medium';
    font-size: 16px !important;
    text-transform: uppercase;
}

.apple-os .btn-sw-theme, .apple-os .item-preview-open {
    line-height: 30px;    
}

.btn-sw-theme:hover, .item-preview-open:hover {
    background: transparent;
    color: #21bdbb !important;
}

.btn-sw-theme:focus, .item-preview-open:focus {
    box-shadow: none;
}

.design-box .or-seperator {
    border-top: 2px solid #21bdbb;
    position: relative;
    margin: 26px 0 0px;
    text-align: center;
}

.design-box .or-seperator .text-seperator {
    background-color: #fff;
    padding: 0 .5em;
    position: relative;
    color: #000000;
    top: -.9em;
    font-style: italic;
    font-size: 18px;
}

.design-box:hover {
    border: 1px solid #e0e0e0;
}

.box-actions {
    border: 1px solid #e0e0e0;
    background: rgba(241, 241, 241, 0.5);
}

.box-actions a i {
    color: #21bdbb;
}

/* Design Preview Box - E */

/* Design Preview Modal Box - S */
#previewDesignModalBox .modal-content {
    min-height: 300px;
    /* padding: 22px 4px; */
}
#previewDesignModalBox #iframe-view {
    /* max-width: 100%;
    min-height: 600px;
    width: 100%;
    margin: 0 auto !important; */

    max-width: 100%;
    min-height: 600px;
    width: 100%;
    margin: 0 auto !important;
    max-height: 60vh;    
}
#previewDesignModalBox .btn-select-preview {
    color: #ffffff;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 20px;
    border: 2px solid #21bdbb;
    text-transform: uppercase;
    width: 240px;
    padding: 4px 6px;
}
#previewDesignModalBox .btn-select-preview:hover {
    background: #21bdbb;
    border: 2px solid #21bdbb;
    color: #ffffff;
}
#previewDesignModalBox .btn-select-preview i {
    margin-left: 0px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
#previewDesignModalBox .btn-select-preview:hover i { 
    margin-left: 10px;
}
#previewDesignModalBox .btn-select-preview:focus, #previewDesignModalBox .btn-select-preview:active {
    box-shadow: none;
}

#previewDesignModalBox .btn-close-preview {
    border: 0;
    border-bottom: 2px solid #524940;
    width: 180px;
    padding: 4px 6px;
    background: transparent;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    margin-right: 28px !important;
    text-transform: uppercase;
    border-radius: 0 !important;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}

#previewDesignModalBox .btn-close-preview:focus {
    box-shadow: none!important;
}
#previewDesignModalBox .btn-close-preview i { 
    color: #21bdbb;
    margin-right: 0px;
    transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -webkit-transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
#previewDesignModalBox .btn-close-preview:hover i { 
    margin-right: 10px;
}
#previewDesignModalBox #previewDesignModalBoxLongTitle {
    line-height: 1.8;
    font-family: 'Gotham-Medium';
}
#previewDesignModalBox #iframe-view {
    /* -webkit-overflow-scrolling: touch;
    overflow: auto; */
}

/* Design Preview Modal Box - E */

/* RMI - Preview Top Bar - S */
.preview-topbar {
    height: 50px;
    color: rgba(255,255,255,0.6);
    background-color: #18202e;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0px 1px 2px 0px black;
    z-index: 15;
}

.preview-topbar .brand-logo {
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.preview-topbar .brand-logo img {
    height: 30px;
    display: inline-block;
    line-height: 48px;
}

.preview-topbar .brand-logo span {
    line-height: 49px;
    overflow: hidden;
    display: inline-block;
    padding-left: 4px;
    font-family: 'Gotham-Book';
    color: #d0d0d0;
}

.preview-topbar .btn-select-design {
    background: #21bdbb;
    color: #ffffff;
    padding: 6px 50px;
    font-family: 'Gotham-Medium';
    text-transform: uppercase;
}

.apple-os .preview-topbar .btn-select-design {
    line-height: 32px;
    height: 35px;
}

.preview-topbar .btn-select-design:hover {
    background: #0e9e9d;
    color: #ffffff;
}

.preview-topbar .btn-select-design:focus {
    box-shadow: none;
}

.preview-topbar .close-preview {
    background: transparent;
    color: #e4e4e4;
    padding: 5px 18px;
    font-family: 'Bariol-Bold';
    text-transform: uppercase;
    word-spacing: 4px;
    outline:0 !important
}

.apple-os .preview-topbar .close-preview {
    line-height: 32px;
    height: 35px;
}

.preview-topbar .close-preview:hover {
    background: transparent;
    color: #ffffff;
    outline:0 !important
}
/* RMI - Preview Top Bar - E */


/* RMI - Mobile Menu Side Nav - S */

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 760;
    top: 0;
    left: 0;
    background-color:#fff;
    border-right: 1px solid #e5e5e5;
    overflow: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a {
    display: block;
    transition: 0.3s;
    white-space: nowrap;
}

.sidenav a:hover {
/* color: #f1f1f1; */
}

.sidenav .closebtn {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 36px;
    font-family: 'Bariol-Bold';
    color: #ef6742;
    margin-left: 50px;
}

.sidenav .closebtn:hover {
    text-decoration: none !important;
}

.mbl-industry-menu {
    font-size: 20px;
    font-family: 'Gotham-Book';
    font-weight: bolder;
    text-align: center;
    display: none;
    margin-bottom: 18px;
}

.mbl-industry-menu:hover {
    cursor: pointer !important;
}

.mbl-industry-text {
    text-align: center;
    padding: 0px 16px;
    font-size: 18px;
    display: none;
}
/* RMI - Mobile Menu Side Nav - E */


/* RMI - Preview Iframe Box - S */
.preview-iframe {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50px;
    height: calc(100vh - 50px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
 }

.preview-iframe iframe {
    border: 0;
    width: 100%;
    height: 100%;
    display: block;
}
/* RMI - Preview Iframe Box - E */

.heading-section {
    font-family: 'Gotham-Medium';
    font-size: 24px;
    text-transform: uppercase;
    color: #161616;
}

#selected_design {
    display: none;
    border: 1px solid #d6d6d6;
}

#selected_industry {
    display: none;
    font-family: 'Gotham-Book';
    margin: 0 auto;
    padding-bottom: 10px;
    padding-top: 22px;
}

.selected-industry-heading {
    padding: 0px 20px;
    font-size: 20px;
    font-family: 'Gotham-Medium';
    color: #404040;
    /* border-bottom: 2px solid #21bdbb; */
    padding-bottom: 6px;
}

.selected-industry-heading .noresult-subtext {
    font-size: 18px;
    font-family: 'Gotham-Light';
    color: #404040;
    margin-top: 6px;
}

#selected_design_sticky_bar {
    background: #ffffff;
    border: 1px solid #222222;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    text-align: center;
    margin: 0 auto;
    padding: 10px;
    display: none;
}
#selected_design_sticky_bar .design-thumbnail {
    margin-right: 20px;
}
#selected_design_sticky_bar .design-thumbnail img {
    width: 80px;
}
#selected_design_sticky_bar .design-info {
    font-family: 'Gotham-Book';
    font-size: 22px;
    vertical-align: middle;
}
#selected_design_sticky_bar .step-continue {
    margin-left: 15px;
}

#selected_design_sticky_bar .mbl-show {
    display:none;
}

.step-continue {
    color: #f7f7f7;
    background: #21bdbb;
    font-family: 'Bariol-Bold';
    font-size: 22px;
    border: 2px solid #21bdbb;
    width: 180px; 
    padding: 4px 6px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out !important;
    -webkit-transition: all 0.3s ease-in-out !important;
}
.step-continue:hover {
    background: #0e9e9d;border: 2px solid #0e9e9d;
    color: #f7f7f7 !important;
}
.step-continue:focus {
    box-shadow: none;
}

.category-sidebar::-webkit-scrollbar {
    width: 1em;
}
 
.category-sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    
}
 
.category-sidebar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.main-sidebar {
    width: 313px;
    padding: 10px;
    box-sizing: border-box;
}
.main-design-container {
    float: right;
    width: calc(100% - 313px);
    padding: 10px;
}

.op-ie-valign{
    display: flex; 
    justify-content: center; 
    align-items: center;
}

/* RMI - Media Queries - S */
@media screen and (max-width: 1199px) and (min-width: 991px) {
    .btn-sw-theme, .item-preview-open {
        padding: 8px 4px;
    }
    .btn-sw-theme, .item-preview-open {
        font-size: 14px !important;
    }
}
@media screen and (max-width: 992px) {
    /* .main-sidebar {display:none;} */
    .main-design-container {width: 100%;}
    .mbl-industry-menu {display: block!important;
        overflow: hidden;}
    .selected-industry-heading {text-align: center;}
    #previewDesignModalBox {
        padding: 0!important;
    }
    #previewDesignModalBox .modal-dialog {
        max-width: 95%;
    }
}  
@media screen and (max-width: 989px) {
    .responsive #top #wrap_all .av_mobile_menu_tablet .main_menu {
        top: 40 !important;
        left: auto !important;
        right: 0 !important;
        display: block !important;
    }
}
@media screen and (max-width: 767px) {
    #header_main .inner-container {height: auto;}
    .selected-design-heading {
        padding: 40px 0!important;
        border-top: 1px solid #b2b2b2;
    }
    body {
        overflow: auto!important
    }

    .design-preview.show {
        overflow: auto!important;
        /* position: absolute; */
    }

    /* Fixing scroll */
    .designs-container > div {
    margin: 20px auto 0!important;
    }
    
    .designs-container > div > div {
    margin: 0!important;
    padding: 0;
    }
    
    .designs-container > div > .item-details {
    padding: 0!important;
    }

    .mbl-industry-text {display: block;}

    #selected_design_sticky_bar {
        text-align: left;
        min-height: 90px;
    }
    #selected_design_sticky_bar .design-info .design-info-text, #selected_design_sticky_bar .design-thumbnail {
        display: none!important;
    }    
    #selected_design_sticky_bar .step-continue {
        margin-left: 12px;
        margin-top: 12px;
    }
    .general-footer-actions {
        padding-bottom: 130px !important;
    }
    #selected_design_sticky_bar .mbl-show {
        display: inline;
    }
    #selected_design_sticky_bar .step-continue {
        font-size: 20px;
        width: 350px;   
    }
    #previewDesignModalBox .btn-close-preview, #previewDesignModalBox .btn-select-preview {
        width: 45%;
        display: inline-block;
    }
    #previewDesignModalBox .btn-preview-actions {
        display: block !important;
        width: 100%;


        width: 100%;
        height: 100%;
        max-width: 94%;
        max-height: 500px;
        position: absolute;
    }
    #previewDesignModalBox .modal-header {
        height: 70px;
    }
    #previewDesignModalBox .modal-body {
        overflow-y:auto !important; 
        -webkit-overflow-scrolling:touch !important;
        position: relative;
        width: 100%;
        max-height: 600px;
        height: 450px;
        padding: 0px;
    }    

    #previewDesignModalBox #iframe-view {
        min-height: 400px;        
        border: none;
    }
    #previewDesignModalBox #previewDesignModalBoxLongTitle {
        display: none;
    }
}
@media screen and (max-width: 560px) {
    #selected_design_sticky_bar .step-continue {
        font-size: 18px;
        width: 285px;
    }
}
@media screen and (max-width: 480px) {
    .screen-loader {left: 45%;}
    .preview-topbar .brand-logo span {display:none;}

    #selected_design_sticky_bar .mbl-show {
        display: none;
    }
    #selected_design_sticky_bar .step-continue {
        font-size: 20px;    
        width: 240px;
    }


    #selected_design_sticky_bar .design-info .design-info-text {
        display: block!important;
        font-size: 16px;
        margin-left: 12px;
    }    
    #selected_design_sticky_bar {
        min-height: 120px;
    }    
    #selected_design_sticky_bar .step-continue {
        margin-top: 16px;
    }

    #previewDesignModalBox .btn-close-preview, #previewDesignModalBox .btn-select-preview {
        width: 45%;
        display: inline-block;
        font-size: 18px;
        margin: 0 !important;
    }
    #previewDesignModalBox .btn-close-preview {
        margin-right: 10px !important;
    }
}
@media screen and (max-width: 430px) {
    #selected_design_sticky_bar .step-continue {
        font-size: 20px;
        width: 170px;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}
@media screen and (max-width: 420px) {
    #previewDesignModalBox .btn-close-preview i, #previewDesignModalBox .btn-select-preview i {
        display: none;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}
@media screen and (max-width: 400px) {
    .btn-sw-theme, .item-preview-open {
        font-size: 14px !important;
    }
    .preview-progressbar .screen-loader {
        top: 80px;
    }
    .preview-progressbar .loader-message {
        padding: 0 20px;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}
@media screen and (max-width: 360px) {
    #previewDesignModalBox .btn-close-preview, #previewDesignModalBox .btn-select-preview {
        font-size: 15px;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}
@media screen and (max-width: 360px) {
    .btn-sw-theme, .item-preview-open {
        padding: 4px;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}
@media screen and (max-width: 350px) {
    .btn-sw-theme, .item-preview-open {
        padding: 8px 4px;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}
@media screen and (max-width: 330px) {
    .box-actions a i {
        display: none;
    }
    .webdesign-portfolio-category{
        float: none !important;
    }
}

/* RMI - Height Media Queries */
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}     
}
@media screen and (max-height: 330px) {
    .preview-topbar .brand-logo {
        display: none;
    }
}

.modal-content{
    height: 600px !important;
}
.spinner-border{
    margin-left: 50%;
    margin-top: 12%;
}
.selected{
    color: #fbc504 !important;
}


@font-face {
    font-family: Bariol;
    src: url(/static/media/bariol_regular-webfont.e4213a39.eot);
    src: url(/static/media/bariol_regular-webfontd41d.e4213a39.eot?#iefix) format("embedded-opentype"),
     url(/static/media/bariol_regular-webfont.7d0cf79e.svg) format('svg'),
      url(/static/media/bariol_regular-webfont.6c235190.ttf) format('truetype'),
       url(/static/media/bariol_regular-webfont.38c3b9e9.woff) format('woff'),
        url(/static/media/Bariol_Regular.6a64a521.otf) format('otf'),
         url(/static/media/bariol_regular-webfont.6577b355.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: GothamBook;
    src: url(/static/media/Gotham-Book.7fe2d669.eot);
    src: url(/static/media/Gotham-Bookd41d.7fe2d669.eot?#iefix) format("embedded-opentype"),
     url(/static/media/Gotham-Book.65288315.woff) format("woff"), 
     url(/static/media/Gotham-Book.79ab7007.ttf) format('truetype'),
      url(/static/media/Gotham-Book.92032bfd.svg#Gotham-Book) format("svg"), 
      url(/static/media/Gotham-Book.e37cf599.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url(/static/media/GothamMedium.d23ef85d.woff) format('woff'),
    url(/static/media/GothamMedium.de228587.woff2) format('woff2'),
    url(/static/media/GothamMedium.ed646164.eot),
    url(/static/media/GothamMediumd41d.ed646164.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamMedium.c3f574b2.ttf) format('truetype'),
    url(/static/media/GothamMedium.9faf290f.svg#Gotham-Book) format('svg'),
    url(/static/media/GothamMedium.bcd733e6.otf) format('opentype'); 
}
  @font-face {
    font-family: BariolBold;
    src: url(/static/media/bariol_bold-webfont.fa0ddc94.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

    /******************* Timeline Demo - 1 *****************/
    html {
      scroll-behavior: smooth;
    }
    .main-header.header-custom.header-transparency {
      background: #fff !important;
    }
    a{text-decoration:none}
    h4{text-align:center;margin:15px 0;color:#444}
    .main-timeline{position:relative}
    .main-timeline:before{content:"";width:5px;height:100%;border-radius:20px;margin:0 auto;background:#242922;position:absolute;top:0;left:0;right:0}
    .main-timeline .timeline{display:inline-block;margin-bottom:50px;position:relative}
    .main-timeline .timeline:before{content:"";width:20px;height:20px;border-radius:50%;border:4px solid #fff;background:#ec496e;position:absolute;top:50%;left:50%;z-index:1;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}
    .main-timeline .timeline-icon{position:absolute;left:0;}
    .main-timeline .timeline-icon i{display:block;border-radius:50%;background:#ec496e;font-size:64px;color:#fff;line-height:100px;z-index:1;position:relative}
    /*.main-timeline .timeline-icon:after,.main-timeline .timeline-icon:before{content:"";width:100px;height:4px;background:#ec496e;position:absolute;top:50%;right:-100px;transform:translateY(-50%)}*/
    .main-timeline .timeline-icon:after{width:70px;height:50px;background:#fff;top:89px;right:-30px}
    .main-timeline .timeline-content{width:50%;padding:4px 50px;margin:2px 0 0;float:right;position:relative}
    .main-timeline .timeline-content1{width:50%;padding:0 50px 0 0;margin:2px 0 0;position:relative}
    /*.main-timeline .timeline-content:before{content:"";width:70%;height:100%;border:3px solid #ec496e;border-top:none;border-right:none;position:absolute;bottom:-13px;left:35px}*/
    .main-timeline .timeline-content:after{content: "";width: 25px;height: 3px;background: #c2c2c2;position: absolute;top: 17px;left: 19px;}
    .main-timeline .timeline-content1:after{content: "";width: 25px;height: 3px;background: #c2c2c2;position: absolute;top: 17px;right: 19px;}
    .main-timeline .title{font-size:20px;font-weight:600;color:#ec496e;text-transform:uppercase;margin:0 0 5px}
    ul.description li{    display: inline-block;font-family: 'Bariol' !important;margin: 10px 0;font-size: 23px;line-height: 32px;color: #222;}
    ul.description {margin-left: 0;padding-left: 0 !important;}
    .main-timeline .timeline:nth-child(even) .timeline-icon{left:auto;right:0;}
    .main-timeline .timeline:nth-child(even) .timeline-icon:before{right:auto;left:-100px}
    .main-timeline .timeline:nth-child(even) .timeline-icon:after{right:auto;left:-30px}
    .main-timeline .timeline:nth-child(even) .timeline-content{float:left}
    /*.main-timeline .timeline:nth-child(even) .timeline-content:before{left:auto;right:35px;transform:rotateY(180deg)}*/
    .main-timeline .timeline:nth-child(even) .timeline-content:after{left:auto;right:22px;}
    .main-timeline .timeline:nth-child(2n) .timeline-content:after,.main-timeline .timeline:nth-child(2n) .timeline-icon i,.main-timeline .timeline:nth-child(2n) .timeline-icon:before,.main-timeline .timeline:nth-child(2n):before{background:#c2c2c2}
    .main-timeline .timeline:nth-child(2n) .timeline-icon{border-color:#f9850f}
    .main-timeline .timeline:nth-child(2n) .title{color:#f9850f}
    /*.main-timeline .timeline:nth-child(2n) .timeline-content:before{border-left-color:#f9850f;border-bottom-color:#f9850f}*/
    .main-timeline .timeline:nth-child(3n) .timeline-content:after,.main-timeline .timeline:nth-child(3n) .timeline-icon i,.main-timeline .timeline:nth-child(3n) .timeline-icon:before,.main-timeline .timeline:nth-child(3n):before{background:#c2c2c2}
    .main-timeline .timeline:nth-child(3n) .timeline-icon{border-color:#8fb800}
    .main-timeline .timeline:nth-child(3n) .title{color:#8fb800}
    .main-timeline .timeline:nth-child(3n) .timeline-content:before{border-left-color:#8fb800;border-bottom-color:#8fb800}
    .main-timeline .timeline:nth-child(4n) .timeline-content:after,.main-timeline .timeline:nth-child(4n) .timeline-icon i,.main-timeline .timeline:nth-child(4n) .timeline-icon:before,.main-timeline .timeline:nth-child(4n):before{background:#c2c2c2}
    .main-timeline .timeline:nth-child(4n) .timeline-icon{border-color:#2fcea5}
    .main-timeline .timeline:nth-child(4n) .title{color:#2fcea5}
    .main-timeline .timeline:nth-child(4n) .timeline-content:before{border-left-color:#2fcea5;border-bottom-color:#2fcea5}
    @media only screen and (max-width:1200px){.main-timeline .timeline-icon:before{width:50px;right:-50px}
    .main-timeline .timeline:nth-child(even) .timeline-icon:before{right:auto;left:-50px}
    .main-timeline .timeline-content{margin-top:0px}
    }
    @media only screen and (max-width:990px){.main-timeline .timeline{margin:0 0 10px}
    .main-timeline .timeline-icon{left:25%}
    .main-timeline .timeline:nth-child(even) .timeline-icon{right:25%}
    .main-timeline .timeline-content{margin-top:115px}
    }
    @media only screen and (max-width:767px){.main-timeline{padding-top:50px}
    .main-timeline:before{left:80px;right:0;margin:0}
    .main-timeline .timeline{margin-bottom:70px}
    .main-timeline .timeline:before{top:0;left:83px;right:0;margin:0}
    .main-timeline .timeline-icon{width:60px;height:60px;line-height:40px;padding:5px;top:0;left:0}
    .main-timeline .timeline:nth-child(even) .timeline-icon{left:0;right:auto}
    .main-timeline .timeline-icon:before,.main-timeline .timeline:nth-child(even) .timeline-icon:before{width:25px;left:auto;right:-25px}
    .main-timeline .timeline-icon:after,.main-timeline .timeline:nth-child(even) .timeline-icon:after{width:25px;height:30px;top:44px;left:auto;right:-5px}
    .main-timeline .timeline-icon i{font-size:30px;line-height:45px}
    .main-timeline .timeline-content,.main-timeline .timeline:nth-child(even) .timeline-content{width:100%;margin-top:-15px;padding-left:130px;padding-right:5px}
    .main-timeline .timeline:nth-child(even) .timeline-content{float:right}
    .main-timeline .timeline-content:before,.main-timeline .timeline:nth-child(even) .timeline-content:before{width:50%;left:120px}
    .main-timeline .timeline:nth-child(even) .timeline-content:before{right:auto;-webkit-transform:rotateY(0);transform:rotateY(0)}
    .main-timeline .timeline-content:after,.main-timeline .timeline:nth-child(even) .timeline-content:after{left:85px}
    }

    /*css start */
    .list1{
      padding: 20px;
    }
    .process-pad-top{
      margin: 20px 0px 10px 0px;
    }
    .listpoint{
    font-size: 15px;
    font-family: 'Poppins';
    color: #fff;
    font-weight: 300;
    margin-bottom: 10px;
    }
    .listpoint::marker {
      color: #fbc504;
      font-size: 25px;
  }
  .process-hr {
    border: 1px solid #868181;
    margin: 0 auto;
    width: 80%;
}
    /*css start */

    #standard, #ultimate {
        counter-reset: my-sec-counter;
      }
      #process .main-timeline { 
        position: relative; 
        padding-top: 50px;
      }
      #process .main-timeline:before{
        content: '';
        height: 95.5%;
        width: 1px;
        border-left: 2px solid #909090;
        -webkit-transform: translatex(-50%);
                transform: translatex(-50%);
        position: absolute;
        left: 0;
        top: 90px;
      }
      #process .main-timeline .timeline{
        margin-bottom: 80px;
        padding: 20px 0;
        position: relative;
        z-index: 1;
      }
      #process .main-timeline .timeline:before{
        height: 35px;
        width: 35px;
        background-color: #898989;
        border-radius: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
        position: absolute;
        left: 50%;
        top: 11%;
        z-index: -1;
        text-align: center;
        line-height: 35px;
        color: #fff;
      }
      #process .main-timeline .timeline:after{
        width: 35%;
        height: 100px;
        border-radius: 0;
        left: auto;
        right: 0;
        top: 50%;
        -webkit-transform: translateX(0) translateY(-50%);
                transform: translateX(0) translateY(-50%);
        -webkit-clip-path: polygon(10% 0, 0 50%, 10% 100%, 100% 100%, 100% 0);
        clip-path: polygon(10% 0, 0 50%, 10% 100%, 100% 100%, 100% 0);
      }
      #process .main-timeline .timeline-content{
        display: block;
        padding-bottom: 0px;
      }
      #process .main-timeline .timeline-content:hover{text-decoration: none;}
      #process .main-timeline .timeline-content:after{
        content: '';
        display: block;
        clear: both;
      }
      #process .main-timeline .content{
        display: inline-block !important;
        width: 45% !important;
        color: #909090 !important;
        position: relative !important;
        border: none !important;
        padding-top: 0;
      }
      #process .main-timeline p.description{
        font-size: 18px;
        margin: 0;
        line-height: 1.5;
        font-family: "GothamLight" !important;
        color: #2a2a2a !important;
      }
      #process .main-timeline .title{
        margin: 0 0 5px 0;
        text-transform: capitalize;
        margin-bottom: 15px !important;
        position: relative;
        font-family: 'Gotham-Medium'!important;
        font-weight: 700!important;
        font-size: 36px;
        color: #292929;
      }
    
      #process .main-timeline .timeline:nth-child(even):after{
        right: auto;
        left: 0;
        -webkit-transform: translateX(0) translateY(-50%) rotate(180deg);
                transform: translateX(0) translateY(-50%) rotate(180deg);
      }
      #process .main-timeline .timeline:nth-child(even) .content{float: right;}
      #process.main-timeline .timeline:nth-child(even) .timeline-icon{
        right: auto;
        left: 2%;
      }
      #process .main-timeline .timeline:nth-child(4n+1):after{
        background: linear-gradient(to right,#01C0F7,#01C0F7,#01C0F7,#01C0F7,#01C0F7,transparent,#868686);
      }  
      #process .main-timeline .timeline:nth-child(4n+2) .timeline-icon{
        color: #0076B0;
        border-color: #0076B0;
      }
      #process .main-timeline .timeline:nth-child(4n+3) .timeline-icon{
        color: #00B09E;
        border-color: #00B09E;
      }
      #process .main-timeline .timeline:nth-child(4n+4) .timeline-icon{
        color: #22237E;
        border-color: #22237E;
      }
    
      #process .content-heading1 {
        font-size: 38px!important;
        color: #222;
        font-family: 'Gotham-Medium' !important;
        line-height: 56px;
        font-weight: 700;
        text-align: center;
        margin-top: 0;
      }
      #process .content-cont {
        font-family: 'Bariol' !important;
        font-size: 23px;
        line-height: 32px;
        color: #4d545b;
        text-align: center;
      }
      #process .left-auto {
        margin-right: auto !important;
        margin-left: unset !important;
      }
      #process .timeline-icon.right {
        float: right;
        width: 42%;
        text-align: left;
        padding: 0 !important;
      }
      #process .timeline-icon.left {
        float: none;
        width: 42%;
        text-align: right;
        display: inline-block;
      }
      #process .main-timeline .timeline.no-number:before {
        content: '' !important;
        height: 22px;
        width: 22px;
      }
      #process .main-timeline .title.no-margin {
        margin-bottom: 0 !important;
      }
      #process .content p.sub-title {
        margin-top: 2px;
        text-align: left;
        width: 55%;
        margin-left: auto !important;
        margin-right: 0 !important;
      }
      #process .process-btn {
        text-align: center;
        line-height: 2;
        padding-top: 40px;
      }
    
      #process div#ultimate-content,#process div#ultimate {
        display: none;
      }
      #process div#process-selection {
        text-align: center;
        padding-bottom: 40px;
      }
      #process div#process-selection label {
        font-size: 23px !important;
        margin-right: 40px;
        margin-left: 40px;
        cursor: pointer;
        position: relative;
        display: inline-block;
        color: #222222;
        text-align: center;
        font-family: 'Gotham-Medium' !important;
      }
      #process input[type=radio] {
        position: absolute;
        opacity: 0;
      }
      #process .alrdomain-checkmark {
        position: absolute;
        top: 0px;
        left: -20px;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 50%;
        border: 3px solid #636363;
        background: #fcfcfc;
        cursor: pointer;
      }
      #process .alrdomain-label-checkbox {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        font-size: 20px !important;
        -webkit-user-select: none;
        user-select: none;
        font-family: 'GothamLight' !important;
        text-align: left;
        color: #222222;
        text-align: left;
        font-weight: lighter !important;
      }
      #process .alrdomain-label-checkbox input:checked ~ .alrdomain-checkmark:after {
        display: block;
        background: #222222 !important;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        top: 3px;
        left: 3px;
      }
    
      /* When the checkbox is checked, add a blue background */
    #process input:checked ~ .checkmark {
      background-color: #187cd4;
    }
    
    /* Create the checkmark/indicator (hidden when not checked) */
    .alrdomain-checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Show the checkmark when checked */
    #process input:checked ~ .checkmark:after {
      display: block;
    }
      .timeline-content img {
       max-height: 140px;
          margin-top: -20px;
      }
      .timeline.no-number {
          padding: 0 !important;
      }
      h3.title.right-align {
       
          text-align: left !important;
          margin-right: 0 !important;
          margin-left: auto !important;
      }
      div#ultimate-content p.content-cont:nth-child(2) {
          padding-bottom: 15px !important;
      }
      /*Media Query Start*/
      a.sw_process_button{
        color: #ffffff !important;
        background-color: #197cd3 !important;
        border: 1px solid #197cd3 !important;
        padding: 10px 30px;
        text-decoration: none!important;
        border-radius: 5px;
        font-family: Bariol;
        font-weight: bold;
        font-size: 19px;
        max-width: 270px!important;
        text-align: center;
        background: 0 0;
        width: initial;
        transition: all .5s ease-in-out;
        text-transform: uppercase;
        text-shadow: none !important;
        margin-right: 10px !important;
      }
      .sw_process_button:hover {
        background: #ffffff !important;
        text-decoration: none!important;
        border-color: #197cd3 !important;
        color: #197cd3 !important;
    }
    h2.swpr-heading-one {
      font-size: 38px!important;
      color: #222;
      font-family: 'Gotham-Medium' !important;
      line-height: 56px;
      font-weight: 700;
      text-align: center;
      margin-top: 0;
    }
    p.swpr-text-one {
      font-family: 'Bariol' !important;
      font-size: 23px;
      line-height: 32px;
      color: #4d545b;
    }
    .sw-lg-sep {
        width: 100%;
        height: 50px;
    }
    .section_seprator {
      width: 100%;
      height: 80px;
    }
    hr.sw-line-sep {
        border-top: 1px solid #a1a1a1;
        display: block;
        width: 100%;
        margin: 0 !important;
    }
    a.timeline {
        text-decoration: none !important;
    }
    h3.title {
      text-transform: capitalize;
      margin-bottom: 15px !important;
      position: relative;
      font-family: 'Gotham-Medium'!important;
      font-weight: 700!important;
      font-size: 36px;
      color: #292929;
    }
    section#why-to-choose-godiggo {
        background: #197cd3;
    }
    h2.section_five_heading {
        font-family: 'Gotham-Medium' !important;
        color: #fff;
        text-align: center;
        font-size: 38px;
        margin: 0 !important;
    }
    #sw_table tr th {
        border: none;
        width: 30%;
    }
    th.tb_heading {
        color: #fff;
        font-size: 18px;
        font-family: 'Gotham-Medium';
        text-align: center;
        text-transform: capitalize;
    }
    #sw_table tbody tr td {
        border: none !important;
        border-right: 1px solid #fff !important;
        font-family: 'Bariol';
        font-weight: 100 !important;
        font-size: 18px;
        padding: 15px 0 !important;
    }
    #sw_table tbody tr td:last-child {
        border: none !important;
        border-right: none !important;
    }
    td.tb_left_heading {
        color: #fff;
        font-family: 'Bariol';
        font-size: 22px;
        padding: 0 15px;
        vertical-align: middle;
    }
    td.tb_image {
        text-align: center;
    }
    #sw_table tbody tr {
        vertical-align: middle !important;
    }
    td.tb_image img {
        width: 30px;
    }
    th.tb_heading {
        font-family: 'Gotham-Medium' !important;
        font-weight: lighter;
        font-size: 18px;
    }
    .sw-choose-container {
        max-width: 600px;
        margin: 0 auto;
    }
    td:first-child {
        width: 245px !important;
        max-width: 245px !important;
        min-width: 245px;
    }
    .sw_process_sec {
        color: #197cd3 !important;
        border: 1px solid #197cd3 !important;
        padding: 8px 30px;
        text-decoration: none!important;
        border-radius: 5px;
        font-family: Bariol;
        font-weight: bold;
        font-size: 19px;
        max-width: 270px!important;
        display: block;
        text-align: center;
        background: 0 0;
        width: initial;
        transition: all .5s ease-in-out;
        display: inline-block;
        text-transform: uppercase;
    }
    .sw_process_sec:hover {
        background: #197cd3  !important;
        text-decoration: none!important;
        border-color: #197cd3 !important;
        color: #fff!important;
    }
    .sw_btn_div {
        margin-top: 10px;
        text-align: center;
    }
    .sw-sm-sep{
    width: 100%;
    height: 20px;
    }
    .sw-pricing-nav nav {
        width: 100%;
        background: #0f0f0f !important;
        margin-top: 0px;
        padding: 20px;
    }
    .nav-col-div {
        height: 0px;
        background: #fff;
    }
    .sw-pricing-nav ul li a {
      padding: 10px 25px;
      color: #fff !important;
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      transition: all 0.2s ease;
      font-family: 'poppins';
    }
    .nav-item.active .nav-link {
        color: #fbc504 !important;
        /* border-bottom: 4px solid #fff; */
    }
    .nav>li>a:focus, .nav>li>a:hover {
        background-color: transparent !important;
    }
    .sw-pricing-nav ul {
        padding: 0px;
        margin: 0 auto;
        list-style: none;
        text-align: center;
        width: 90%;
        /* justify-content: center; */
        justify-content: space-evenly;
    }
    .sticky.is-sticky {
        position: fixed;
        left: 0;
        right: 0;
        top: 0px;
        z-index: 2;
        width: 100%;
    }
    .sw_sep_15{
      width: 100%;
      height: 15px;
    }
    .radio-box {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      user-select: none;
    }
    .radio-box input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkmark {
      position: absolute;
      top: 4px;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #868181;
      border-radius: 50%;
    }
    .radio-box:hover input ~ .checkmark {
      background-color: #ccc;
    }
    .radio-box input:checked ~ .checkmark {
      background-color: rgb(251, 197, 4);
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    .radio-box input:checked ~ .checkmark:after {
      display: block;
    }
    .radio-box .checkmark:after {
         top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }
    .sw-pricing-main .col-md-12 {
      padding: 0 !important;
      margin: 0 !important;
    }
    @media only screen and (max-width:1600px) and (min-width:1200px) {
      h2.swpr-heading-one,#process .content-heading1,h2.section_five_heading {
        font-size: 28px!important;
      }
      p.swpr-text-one,#process .content-cont,ul.description li {
        font-size: 20px !important;
      }
      #process .main-timeline .title,h3.title{
        font-size: 24px !important;
      }
    }
    @media only screen and (max-width:1024px) and (min-width:768px) {
      .section_seprator {
        width: 100%;
        height: 40px;
    }
    #process .timeline-icon img {
        max-height: 100% !important;
        width: auto !important;
        margin-left: 20px;
    }
    #process .main-timeline .title {
      font-size: 20px !important;
    }
    ul.description li {
      margin: 0px 0 !important;
      font-size: 18px !important;
    }
    h3.title {
      font-size: 24px !important;
    }
    .main-timeline .timeline-content {
        margin-top: 25px !important
    }
    .main-timeline .timeline-content {
        width: 100% !important;
    }
    .main-timeline .timeline-icon {
        left: 5% !important;
    }
    .main-timeline .timeline-icon {
        position: initial !important;
    }
    .main-timeline:before {
        margin: 0 !important;
    }
    #process .main-timeline .timeline:before{
      left: 0 !important;
    }
    .main-timeline .timeline-content1 {
        padding: 0 0px 0 50px !important;
    }
    .sw-pricing-nav ul li a {
        padding: 10px 14px !important;
        font-size: 13px !important;
    }
    /* span.navbar-toggler-icon {
        display: none !important;
    } */
    p.swpr-text-one,#process .content-cont {
        font-size: 18px !important;
        margin: 10px auto !important;
    }
    .main-timeline .timeline-content{
      margin-top: 20px !important;
    }
    .sw-lg-sep {
        width: 100%;
        height: 30px !important;
    }
    .main-timeline .timeline-content1 {
        width: 100% !important;
    }
    #process .content-heading1 {
      margin: 0px !important;
      font-size: 24px!important;
    }
    h2.swpr-heading-one, h2.section_five_heading {
        font-size: 26px !important;
        line-height: 1.3;
    }
    #process .process-btn{
      padding-top: 0 !important;
    }
    #process .main-timeline {
        padding-top: 0px !important;
    }
    .timeline-icon img {
        max-width: 100% !important;
    }
    section#change-request img {
        max-width: 100% !important;
    }
    .main-timeline .timeline-content1:after {
        left: 10px !important;
    }
    #process div#process-selection label {
        font-size: 18px !important;
    }
    }
    @media only screen and (max-width:767px) and (min-width:280px) {
      .sw-schedule-call-formdiv{
        padding: 20px !important;
      }
      .sw_posu_heading{
        font-size: 15px !important;
      }
      a.sw_home_succeed{
        display: inline-block !important;
      }
      .section_seprator {
        width: 100%;
        height: 30px;
    }
    .checkmark {
      top: 0px !important;
    }
    #sw_table tbody tr td {
      font-size: 14px;
    }
    td.tb_image img {
      width: 20px;
    }
    #process div#process-selection {
      padding-top: 20px;
    }
    #process .timeline-icon img {
        max-width: 100% !important;
    }
    .main-timeline .timeline-icon {
        width: 100% !important;
        height: auto !important;
    }
    .main-timeline .timeline {
        display: contents !important;
    }
    #process div#process-selection label {
        font-size: 18px !important;
    }
    #process .main-timeline:before {
        height: 117% !important;
    }
    section#change-request img {
        width: 100% !important;
        max-width: 100% !important; 
    }
    .main-timeline .timeline-icon {
        position: relative !important;
    }
    .main-timeline .timeline-content, .main-timeline .timeline:nth-child(2n) .timeline-content {
        padding-left: 20px !important;
    }
    .main-timeline .timeline-content1 {
        width: 100% !important;
        padding: 0 0px 0 38px !important;
    }
    .sw-lg-sep {
        width: 100%;
        height: 30px !important;
    }
    h2.swpr-heading-one,h2.section_five_heading,#process .content-heading1 {
        font-size: 22px !important;
        line-height: 1.3;
    }
    p.swpr-text-one,#process .content-cont {
        font-size: 18px !important;
    }
    ul.description li{
      display: block !important;
      font-size: 18px !important;
    }
    .main-timeline .timeline-content:after, .main-timeline .timeline:nth-child(2n) .timeline-content:after {
        left: 0 !important;
        top: 30px !important;
    }
    ul.description {
        margin-left: 0;
        padding-left: 0px !important;
    }
    a.sw_process_sec {
        display: block!important;
        margin: 10px auto !important;
    }
    a.sw_process_button {
        margin: 0 auto !important;
        display: block !important;
    }
    td:first-child {
        width: 150px !important;
        max-width: 150px !important;
        min-width: 150px !important;
    }
    #process .main-timeline .title,h3.title {
        font-size: 20px !important;
    }
    .main-timeline .timeline-content, .main-timeline .timeline:nth-child(2n) .timeline-content {
        padding-left: 38px !important;
        padding-top: 19px !important;
    }
    .main-timeline .timeline-content1:after {
        left: 0px !important;
    }
    th.tb_heading {
        font-size: 12px !important; 
    }
    .mb-col-sep{
      width:40px;
      height: 40px;
    }
    nav#menu-center {
      display: none;
    }
    }
    @media (max-width:1024px) and (min-width:768px) and (orientation:landscape) {
    #process .main-timeline .timeline {
        margin-bottom: 25px !important;
    }
    .timeline-icon img {
        max-width: 88% !important;
    }
    .main-timeline .timeline:nth-child(even) .timeline-icon,.main-timeline .timeline:nth-child(odd) .timeline-icon {
        max-width: 50% !important;
    }
    }
    @media (max-width:1366px) and (min-width:1024px) {
    .timeline-icon img {
        max-width: 88% !important;
    }
    .main-timeline .timeline:nth-child(even) .timeline-icon,.main-timeline .timeline:nth-child(odd) .timeline-icon {
        max-width: 50% !important;
    }
    }
    @media (max-width:767) and (min-width:280px) and (orientation:landscape) {
    }
    @media only screen and (max-width:1024px) and (min-width:280px) {
      nav#menu-center {
        display: none;
      }
      .sticky-wrapper {
        height: 0px !important;
    }
      }

/*  review css*/
.revimg-radius {
    border-radius: 8px 8px 0px 0px;
}
.rev-bg{
    background-color: #fff;
    padding: 20px;
    border-radius: 0px 0px 8px 8px;
}
.review-txt{
    font-size: 15px;
    font-family: 'Poppins' !important;
    color: rgb(7, 7, 7);
    font-weight: 300;
    margin: 10px 0px 10px 0px;
}
.client-name{
    font-size: 18px;
    font-family: 'Poppins' !important;
    font-weight: 600;
    margin: 0px !important;
}
.client-pos{
    font-size: 14px;
    font-family: 'Poppins' !important;
    font-weight: 400;
    margin: 0px;
}
.review-height{
    height: 245px !important;
}
/*  review css*/
.template-page-review{
    background-color: #161616;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-x: hidden;
}

.xsf-sep {
    width: 100%;
    height: 150px;
}

/* ======================================================================================================================================================
HR
====================================================================================================================================================== */

.hr{
    clear: both;
    display:block;
    width:100%;
    height:25px;
    line-height:25px;
    position: relative;
    margin:30px 0;
    float:left;
    }
    
    .hr-inner{
    width:100%;
    position: absolute;
    height:1px;
    left:0;
    top:50%;
    width:100%;
    margin-top:-1px;
    border-top-width:1px;
    border-top-style:solid;
    }
    
    #top .hr-invisible, .hr-invisible .hr-inner{margin:0; border:none; }
    
    .hr-invisible {float:none;}
    
    .hr-full, .hr-big{margin:70px 0;}
    .hr-full .hr-inner{width:300%; left:-100%;}
    .hr-full.hr-shadow .hr-inner{ box-shadow:0 1px 2px 0px rgba(0, 0, 0, 0.1);}
    .sidebar_right .hr-full .hr-inner{left:auto; right:-50px;}
    .sidebar_left .hr-full .hr-inner{left:-50px;}
    
    
    
    .hr-short{height:20px; line-height:20px; margin:30px 0; float:none;}
    .hr-short .hr-inner{ width:32%; left:50%; margin-left:-16%;  }
    .hr-short.hr-left .hr-inner{  left:0%; margin-left:0%;}
    .hr-short.hr-right .hr-inner{ left:auto; right:0; margin-left:0%; }
    .hr-short .hr-inner-style{border-radius: 20px; height:9px; width:9px; border-width:2px; border-style:solid; display:block; position: absolute; left:50%; margin-left:-5px; margin-top:-5px; }
    .hr-short.hr-left .hr-inner-style{  left:5px; }
    .hr-short.hr-right .hr-inner-style{  left:auto; right:0; }
    
    body .container_wrap .hr.avia-builder-el-last, body .container_wrap .hr.avia-builder-el-first {
    margin:30px 0;
    }
    
    
    /*custom*/
    .hr-custom{margin:0; display: block; min-height:21px; height:auto; overflow: hidden; white-space: nowrap; width:100%; float: left;}
    .hr-custom .hr-inner{display: inline-block; position: relative; vertical-align: middle;}
    .hr-custom.hr-center{text-align: center;}
    .hr-custom.hr-right {text-align: right;}
    .hr-custom.hr-left  {text-align: left;}
    
    .hr-custom .hr-inner.inner-border-av-border-none{border:none;}
    .hr-custom .hr-inner.inner-border-av-border-fat{border-top-width:2px;}
    .av-seperator-icon { display: inline-block; vertical-align: middle; font-size: 15px; }
    .hr-custom.hr-icon-yes .hr-inner{margin-left:10px; margin-right:10px;}
















.row:before, .row:after {display: none !important;}
/*header Styling */
body {
    font-family: 'Gotham' !important;
}
html{
	   font-family: 'GothamLight' !important;
	
}
.avia-menu-text,
p {
    font-family: 'GothamLight' !important;
}
.avia-menu-text{
    color:#000 !important;
    font-weight: bolder;
    font-size: 1vw;
}
span.logo {
    padding: 20px;
}
.html_header_transparency #top .avia-builder-el-0 .container, .html_header_transparency #top .avia-builder-el-0 .slideshow_caption {
    padding-top: 0px !important;
}

div#header_main {
    border: none !important;
}
.avia-menu-fx {
    height: 5px;
    margin-bottom: 83px;
    background: #e47558 !important;
    display: block !important;
}
#top .av_header_transparency .avia-menu-fx {
    bottom: 0;
    width: 100%;
    left: 0;
}
.header_color .sign-btn a {
    text-decoration: none !important;
}

.header_bg {
    opacity: 1;
}
.main_menu {
    left: 17vw;
}
.search-bar {
    top: 30%;
    bottom: 0;
    left: 56vw;
    position: absolute;
    z-index: 999;
    color: #ffffff;
    width: 24%;
}
#search-box {
    background: transparent !important;
    color: #000 !important;
    border: none !important;
    box-shadow: none !important;
}
.search-query {
    font-family: 'GothamLight' !important;
    font-size: 13px !important;
}
/*.responsive .container {
    max-width: 1920px !important;
}*/
.magnifying-glass {
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0px;
    margin: auto auto;
    left: 100%;
    width: 20px;
    height: 48px;
    transition: opacity 400ms cubic-bezier(0.694, 0, 0.335, 1), visibility 300ms cubic-bezier(0.694, 0, 0.335, 1) 0ms, -webkit-transform 400ms cubic-bezier(0.694, 0, 0.335, 1);
    transition: opacity 400ms cubic-bezier(0.694, 0, 0.335, 1), transform 400ms cubic-bezier(0.694, 0, 0.335, 1), visibility 300ms cubic-bezier(0.694, 0, 0.335, 1) 0ms;
    transition: opacity 400ms cubic-bezier(0.694, 0, 0.335, 1), transform 400ms cubic-bezier(0.694, 0, 0.335, 1), visibility 300ms cubic-bezier(0.694, 0, 0.335, 1) 0ms, -webkit-transform 400ms cubic-bezier(0.694, 0, 0.335, 1);
}
.sign-btn {
    right: 0;
    /* color: #000 !important; */
    font-size: 20px;
    position: absolute;
    font-family: 'GothamLight' !important;
    font-weight: bolder !important;
    text-transform: uppercase !important;
    z-index:99999;
    text-decoration: none !important;
}
#search-bar button.btn.btn-search {
    vertical-align: top !important;
    border-bottom: 1px solid;
    border-radius: 0;
    bottom: 0px;
    outline: none !important;
    box-shadow: none !important;
    z-index: 1;
    /*margin-top: -55px !important;*/
}
#top #search-bar input[type="text"] {
    margin-bottom: 0px !important;
    border-bottom: 1px solid #000 !important;
    color:#000 !important;
    font-weight: bolder;
    font-size: 0.8vw !important;

}

.av-main-nav > li > a {
    font-weight: normal;
    font-size: 1vw;
}
button#search-submit {
    color: #000;
    background: transparent;
    border: none;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    right: 0px;
    margin: auto auto;
    left: 100%;
    width: 20px;
    height: 36px;
    transition: opacity 400ms cubic-bezier(0.694, 0, 0.335, 1), visibility 300ms cubic-bezier(0.694, 0, 0.335, 1) 0ms, -webkit-transform 400ms cubic-bezier(0.694, 0, 0.335, 1);
    transition: opacity 400ms cubic-bezier(0.694, 0, 0.335, 1), transform 400ms cubic-bezier(0.694, 0, 0.335, 1), visibility 300ms cubic-bezier(0.694, 0, 0.335, 1) 0ms;
    transition: opacity 400ms cubic-bezier(0.694, 0, 0.335, 1), transform 400ms cubic-bezier(0.694, 0, 0.335, 1), visibility 300ms cubic-bezier(0.694, 0, 0.335, 1) 0ms, -webkit-transform 400ms cubic-bezier(0.694, 0, 0.335, 1);
}
button.btn.btn-search {
    background: transparent;
    color: #000;
}

/*Home Page Css */
.slider-heading {
    color: #222222 !important;
    line-height: 1.3;
    text-align: center;
    font-size: 2.3vw;
    font-family: 'GothamBook' !important;
}
.slider-text {
    color: #575758 !important;
    line-height: 1.068;
    text-align: center;
    font-size: 1.6vw;
    margin-bottom: 50px;
    font-family: 'GothamLight' !important;
}
.slider-btn {
    border: 1px solid #474747 !important;
    padding: 8px 50px;
    border-radius: 30px/30px;
    color: #474747 !important;
    text-decoration: none !important;
    font-size: 1.7vw;
    font-family: 'GothamBook' !important;
}
.content-heading {
    color: #606060;
    text-transform: capitalize !important;
    font-size: 1.7vw;
    font-family: 'GothamBook' !important;
}
#first_section {
    padding: 0 0px 0 80px;
}
#first_section div .av_three_fifth {
    margin-left: 6%;
    width: 52.6% !important;
}
.text-content {
    color: #606060;
    font-size: 1vw;
    line-height: 1.3;
    font-family: 'GothamLight' !important;
}
.start-now {
    color: #80c3c2 !important;
    text-decoration: none !important;
    font-size: 1.3vw;
    font-family: 'GothamBook' !important;
}
h3.slide-entry-title.entry-title {
    display: none;
}
/*Dot Css on home page Custom/Content Slider*/
#top .avia-smallarrow-slider .avia-slideshow-dots a {
   border-style: solid;
    border-width: 1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-bottom: -1vw !important;
    /* margin-left: 0; */
}
.avia-slideshow-dots{
    z-index :99999 !important;
}

#top .avia-content-slider-element-container .avia-slideshow-dots{

        /* left: 0; */
    text-align: left !important;
    margin-left: 55px !important;
    width: 100% !important;
}
#top .avia-smallarrow-slider  .avia-slideshow-dots{

        position: absolute !important;
    width: 100% !important;
    left: 0 !important;
    bottom: 0 !important;
    margin-top: 0px !important;
}
#top .avia-smallarrow-slider-heading> div {
    display: block !important;
}
.avia-slideshow-dots{
    height: auto !important;
}

.avia-content-slider .slide-entry-excerpt{
    padding: 0px !important;
}
a.goto-slide {
    border: 1px solid #82cccb;
}
a.goto-slide.active,
a.goto-slide:hover {
    background: #82cccb !important;
    border: 1px solid #82cccb;
}
/*Dot Css on home page Custom/Content Slider*/
#second_section {
    padding-right: 0px;
}
.right {
    width: 45%;
    float: right;
    padding-top: 200px;
}
.left {
    width: 45%;
    float: left;
}
.content-heading1 {
    color: #606060;
    text-transform: capitalize !important;
    font-size: 3.0vw;
    text-align: center;
    font-weight: 400;
    margin-bottom: 50px !important;
    font-family: 'GothamBook' !important;
}
.get-started-btn {
    border: 1px solid #e7866d !important;
    background: #e7866d;
    padding: 8px 70px;
    border-radius: 21px /20px;
    color: #fff !important;
    text-decoration: none !important;
    font-size: 1.7vw;
    font-family: 'GothamBook' !important;
}
h3.widgettitle {
    font-size: 23px;
    font-family: 'GothamBook' !important;
}
.footer-com-desc{
margin-top: 1.6vw !important;
font-size:16px !important;
line-height: 2;

}


#footer .flex_column:nth-child(1) {
    width: 15% !important;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(2) {
    width: 15% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(3) {
    width: 15% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(4) {
    width: 15% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(5) {
    width: 30% !important;
    margin-left: 2% !important;
}
#footer {
    padding: 50px 40px 40px 50px;
    font-size: 19px;
    border-top: 2px solid #000000;
    background: #ffffff !important;
}
#footer .textwidget {
    line-height: 1.8;
}
.footer-links {
    font-family: 'GothamLight' !important;
    text-decoration: none;
    font-size: 16px !important;
}
.footer-links:hover,.footer-links:focus {
    color: #e47558 !important;
    text-decoration: none;
}
.footer-column {
    margin-top: 60px;
}
.slider-btn1 {
    font-size: 31px;
    cursor: pointer;
    background: 0 0;
    width: initial;
    border: 1px solid #000 !important;
    transition: all .5s ease-in-out;
    display: inline-block;
    margin: 0;
    border: 1px solid #474747 !important;
    padding: 18px 50px;
    border-radius: 30px/30px;
    color: #474747 !important;
    text-decoration: none !important;
    font-size: 1.6vw;
    font-family: 'GothamBook' !important;
}
.slider-btn1:hover {
    text-decoration: none !important;
    background: #e47558 !important;
    border-color: #e47558 !important;
    color: #fff !important;
}
div#header_main:hover {
    background: #ffffff !important;
    transition: all .8s ease-in-out !important;
    webkit-animation: .8s ease-in-out !important;
    -webkit-animation: .8s linear ease-in-out !important;
            animation: .8s linear ease-in-out !important;
}

/* About Us Page CSS*/
.slideshow_caption {
    padding: 0 30px !important;
}
html,body {
    overflow-x: hidden;
}
/*.avia-builder-el-0.avia-fullscreen-slider .avia-slideshow {
    height: 100% !important;
}*/
.about-sub-heading {
    line-height: 1.2 !important;
}

.page-id-259 .avia-slideshow-dots {
    display: none;
}
.inner-page-heading {
    font-size: 3.4vw !important;
    color: #080808;
    font-family: 'GothamLight' !important;
    text-transform: capitalize;
    font-weight: normal;
}
.page-id-259 #fullscreen_slider_0 .slideshow_align_caption {
    display: table-cell;
}
.about-text-content {
    color: #606060;
    font-size: 1.1vw;
    line-height: 1.5;
    font-family: 'GothamLight' !important;
}
.about-content-heading {
    color: #080808 !important;
    text-transform: capitalize !important;
    font-size: 2.5vw;
    font-family: 'GothamBook' !important;
}
.about-heading {
    font-size: 2.5vw;
    font-family: 'GothamMedium' !important;
    font-weight: normal;
    text-transform: capitalize;
}
.about-sub-heading {
    font-size: 2.1vw;
    font-family: 'GothamBook' !important;
}
.about-sub-text {
    font-size: 1.2vw;
    line-height: 1.4;
    font-family: 'GothamLight' !important;
    font-weight: lighter;
}
.web-separator {
    width: 50px;
    height: 50px;
}
.web-small-separator {
    width: 10px;
    height: 10px;
}
.page-id-259 .slideshow_caption {
    z-index: 100;
    width: 65%;
    position: absolute;
    bottom: 0;
    top: auto;
    height: 100%;
    display: block;
    text-decoration: none;
    padding: 50px;
}
/*.page-id-259 .slideshow_align_caption {
    display: block;
}*/
.about-get-started {
    cursor: pointer;
    background: 0 0;
    width: initial;
    border: 1px solid #e47558 !important;
    transition: all .5s ease-in-out;
    display: inline-block;
    margin: 0;
    padding: 18px 50px;
    border-radius: 30px/30px;
    color: #e47558 !important;
    text-decoration: none !important;
    font-size: 1.5vw;
    font-family: 'GothamBook' !important;
}
.about-get-started:hover {
    text-decoration: none !important;
    background: #e47558 !important;
    border-color: #e47558 !important;
    color: #fff !important;
}
.need-more-info,
.need-more-info:hover {
    font-family: 'GothamBook' !important;
    text-decoration: none;
    font-size: 25px;
}
/* Contact Us Page CSS*/

.contact-page-banner-heading {
    font-size: 3.5vw;
    color: #FFF;
    text-shadow: 6px 6px 16px #080808;
    font-family: 'GothamLight' !important;
    text-transform: capitalize;
    font-weight: normal;
    line-height: 1.0;
}
h1.cls-contact-us-heading {
    color: #fff;
    font-family: 'GothamMedium';
    font-weight: normal;
    font-size: 2.0vw;
    text-transform: capitalize !important;
}
.cls-contact-us-text {
    color: #fff;
    font-size: 1.4vw;
    font-family: 'GothamLight' !important;
    font-weight: normal;
    line-height: 1.2;
}
h1.contact-content-heading {
    color: #080808 !important;
    font-family: 'GothamLight';
    font-weight: bolder;
    text-transform: none !important;
    font-size: 1.8vw;
}

#top #contactus .av-flex-placeholder {
    display: table-cell;
    width: 3px;
}
#contactus .container {
    padding: 0px 50px!important;
}

.cls-contact-btn:hover {
    background: #fff;
    color: #545454 !important;
    text-decoration: none !important;
}
.cls-contact-btn {
   border: 2px solid #dce9e2;
    color: #fff !important;
    text-decoration: none !important;
    padding: 7px 70px;
    border-radius: 15px;
    font-size: 1.5vw;
    font-family: 'GothamLight';
    text-decoration: none;
    cursor: pointer;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
}
#top #contactus .flex_column_table {
    display: table;
    table-layout: fixed;
    width: 100%;
    /*height: 450px;*/
    height: 4vw !important;
}

/* web-page-package Page Css*/

.page-id-758 .main_color.container_wrap_first.container_wrap.fullsize {
    display: none;
}

.cls-web-page-package-heading {
    text-align: center;
    font-size: 1.7vw;
line-height:1.0;
}
h3.cls-web-page-package-text {
    text-align: center;
    font-weight: 500;
    font-family: 'GothamMedium';
    font-size: 2.0vw;
    color: #000 !important;
}

.web-page-package-column {
    float: left;
    width: 460px;
  background:#fff;
    border: 0px solid #CC6666;
    border-radius: 32px/28px;
    display: inline-block;
    height: 600px;
}
.web-page-package-column-right {
    float: right;
    width: 460px;
    border: 0px solid #80c3c2;
    border-radius: 32px/28px;
    display: inline-block;
height:600px;
background:#fff;
}
.web-page-package-heading-right {
    font-size: 1.7vw;
    text-align: center;
    color: #fff;
    font-family: 'GothamLight' !important;
}
.web-page-package-header-right {
    border-radius: 25px 25px 0px 0px;
    text-align: center;
    background: #80c3c2;
    padding: 20px 0;
}
.web-page-package-heading {
    font-size: 1.7vw;
    text-align: center;
    color: #fff;
    font-family: 'GothamLight' !important;
}
.web-page-package-header {
    border-radius: 25px 25px 0px 0px;
    text-align: center;
    background: #e47558;
    padding: 20px 0;
}
.web-page-package-body {
    padding: 20px;
background:#fff;
}
.seprator-big {
    width: 30px;
    height: 30px;
}
.seprator-small {
    width: 20px;
    height: 20px;
}
.web-page-package-body-row {
    text-align: center;
    font-size: 15px;
    line-height: 1.3;
    color: #838484;
    font-family: 'GothamLight' !important;
}
.web-page-package-price {
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    color: #e47558;
    font-family: 'Gotham' !important;
}
.web-page-package-price-right {
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    color: #80c3c2;
    font-family: 'Gotham' !important;
}
.web-page-package-price-subtext {
    font-size: 18px;
    text-align: center;
    color: #e47558;
    font-family: 'GothamLight' !important;
}
.web-page-package-price-subtext-right {
    font-size: 18px;
    text-align: center;
    color: #80c3c2;
    font-family: 'GothamLight' !important;
}
.web-page-package-description {
    text-align: left;
}
.web-page-package-description-list {
    font-size: 18px;
    line-height: 1.4;
    color: #838484;
    font-family: 'GothamLight' !important;
}
.web-page-package-footer {
    text-align: center;
}
.web-page-package-footer-text {
    color: #4c4b4b;
    font-weight: bold;
    line-height: 0.876;
    text-align: center;
    font-size: 20px;
    font-family: 'GothamBold' !important;
}
.web-page-packages-fee-heading {
    font-size: 2.4vw;
    color: #fff;
    font-family: 'GothamMedium' !important;
}
.web-page-packages-fee-text {
    font-size: 1.4vw;
    line-height: 1.5;
    color: #fff;
    font-family: 'GothamLight' !important;
}
.web-page-packages-fee-sub-text {
    font-family: 'GothamLight' !important;
    color: #fff;
    font-size: 20px;
}
.web-page-packages-no-extra-fee {
    font-family: 'GothamMedium' !important;
    color: #fff;
    font-size: 1.9vw;
line-height:1.0;
}

.standard-web-pack-page-btn {
  font-family: 'GothamLight' !important;
    color: #ffffff !important;
    font-size: 1.7vw;
    background: #fff;
    padding: 10px 40px;
    border-radius: 20px;
    text-decoration: none;
    cursor: pointer;
    background: 0 0;
    width: initial;
    border: 1px solid #fff !important;
    transition: all .5s ease-in-out;
}
.standard-web-pack-page-btn:hover {
    text-decoration: none;
background:#fff;
color:#e47558 !important;

}
.ultimate-pack-page-btn {
    font-family: 'GothamLight' !important;
    color: #fff !important;
    font-size: 1.7vw;
    background: #fff;
    padding: 10px 40px;
    border-radius: 20px;
text-decoration:none;
 cursor: pointer;
    background: 0 0;
    width: initial;
    border: 1px solid #fff !important;
    transition: all .5s ease-in-out;
}
.ultimate-pack-page-btn:hover {
    text-decoration: none;
color:#80c3c2 !important;
background:#fff;
}

.div-pack-btn{

    text-align: left;
    width: 100%;
    margin-top: 80px;
}
span.web-page-packages-fee-subheading {
    font-size: 1.2vw;
    font-weight: lighter;
    font-family: 'GothamLight';
}

.web-page-pack-column {
    float: left;
    width: 50%;
    padding: 10px;
  float: none;
    display: table-cell;
vertical-align: middle !important;
}

/* Clear floats after the columns */
.pack-row-container:after {
    content: "";
    display: table;
    clear: both;
}
.pack-row-container{
display: table;
    table-layout: fixed;
    width: 100%;

}
    

/* Process Page Css */

h1.process-step-heading {
    text-align: center;
    color: #e47558;
    font-family: 'GothamBook';
    font-size: 2.2vw !important;
text-transform: capitalize !important;
}
.step_details {
    font-size: 1.4vw !important;
    font-family: 'GothamLight';
   line-height:1.1;
}
.process-get-started, .process-get-started:hover {
    color: #80c3c2 !important;
    text-decoration: none !important;
}
.process-step-done {
    font-size: 3.2vw !important;
    font-family: 'GothamLight';
    font-weight: normal;
    text-transform: none !important;
    color: #2a2a2a !important;
}
a.cls-done-get-started {
    border: 2px solid #e47558;
    padding: 14px 50px;
    font-size: 24px;
    border-radius: 14px;
    font-family: 'GothamMedium';
    color: #e47558;
    text-decoration: none !important;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
}
a.cls-done-get-started:hover{
color:#fff;
 background: #e47558;
}

/*Review Page*/
.review-column {
    height: auto !important;
}
.review-clients {
    padding: 0px !important
}
.reviews-heading {
    font-size: 38px!important;
    color: #222;
    font-family: GothamMedium !important;
    line-height: 56px;
    font-weight: 700;
    text-align: center;
}
.reviews-subtext {
    text-align: center;
    font-size: 1.8vw;
    font-family: 'GothamLight' !important;
    color: #222 !important;
}
.review-column {
    float: left;
    width: 33.33%;
    padding: 10px;
    height: 100%; 
}

/* Clear floats after the columns */
.container-row:after {
    content: "";
    display: table;
    clear: both;
}
.vertical-seperator {
  float: right;
    background: #e47558;
    height: 400px;
    width: 2px;
    margin-top: -375px;
}
.review-content {
    padding: 30px 20px;
}
.review-clients {
    font-size: 18px;
    color: #222 !important;
    padding: 20 0 0 20px;
    line-height: 1.2;
}
.review-company-logo{
text-align:center;
}

/* .vertical-divider{
    clear: both;
    position: relative;
} */

/* .vertical-divider:after {
    clear: both;
    content: " ";
    display: block;
    height: 0;
    visibility: hidden;
} */

/* .vertical-divider .review-column:not(:first-child):after, .vertical-divider .review-columns:not(:first-child):after{
    background: #002536;
    bottom: 0;
    content: " ";
    margin-left: -10px;
    position: absolute;
    top: 0;
    width: 0.2ch;
} */
.client-details {
    color: #3f3f3f !important;
    font-family: 'GothamBook' !important;
    font-weight: bolder;
    font-size: 18px;
    margin: 20px 0 0 20px !important;
}
.client-details > span {
    color: #222222 !important;
    font-family: 'GothamLight' !important;
font-size:16px;
}
a.reviews-btn{
    color: #e47558 !important;
    border: 1px solid #e47558;
    padding: 7px 60px;
    text-decoration: none;
    background: #ffffff;
    border-radius: 26px/26px;
    font-size: 25px;
    font-family: 'GothamLight' !important;
    transition: all .5s ease-in-out;
    cursor: pointer;
}

.reviews-btn:hover {
    background: #e47558;
    color: #fff !important;
}

/* Design Page */

.design-heading
{
text-align: center;
    font-family: 'GothamBook';
    color: #222222 !important;
    text-transform: capitalize !important;
    font-size: 2.8vw !important;
}
p.design-subtext
{
text-align: center;
    font-size: 1.6vw !important;
    font-family: 'GothamLight' !important;
    color: #222 !important;
    line-height: 1.0;
    margin: 0;
}
.design-separator{
width:40px;
height:40px;
}
.design-image{
box-shadow: 3px 3px 50px #b1b1b1;
}
h2.design-theme-name {
    font-family: 'GothamBook';
    font-size: 1.5vw !important;
    color: #080808;
}
p.design-theme-description {
    font-family: 'GothamLight';
    font-size: 1.0vw !important;
    color: #080808;
    line-height: 1.4;
    width: 95%;
    height: 7vw;
}
p.design-sub-theme-description b{
     font-family: 'GothamLight';
    font-size: 1.0vw !important;
    color: #080808;
    line-height: 1.4;
    width: 95%;
}

/* Design Page Addition*/
h2.web-theme-name {
    color: #e47558;
    font-family: 'GothamLight';
    font-size: 30px;
    margin-bottom: 20px;
}
a.web-theme-btn.next {
    padding: 12px 48px;
    text-align: center;
    color: white;
    z-index: 1;
    text-decoration: none;
    background: #e4775b;
    border-radius: 25px/26px;
    font-family: 'GothamLight';
    margin: 0 2px;
}
a.webpage-view-btn {
    color: #080808;
    text-decoration: none;
    font-family: 'GothamLight';
    font-size: 23px;
    /* border-bottom: 2px solid #000; */
    transition: background-color 0.45s, border-bottom 0.45s;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.webpage-view-btn:hover:after {
    -webkit-transform: scaleX(0.5);
            transform: scaleX(0.5);
}
.webpage-view-btn:after {
    display: block;
    content: ' ';
    border-bottom: solid 2px #080808;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    transition: -webkit-transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
}
.web-page-design{
padding-left: 1.8vw;
}

 div#webpage-design-theme {
    background: #ffffff;
}
 .page-id-912 div#av_section_1{
    background: #ffffff; 
}
.page-id-912 div#av_section_3{
      background: #ffffff; 
}

.page-id-912 .main_color.container_wrap_first.container_wrap.fullsize{
display: none;
}
/* Design preview form webpage*/
.webpage-theme-preview {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 1;
    background: #ffffff;
    padding: 0px 4%;
    box-sizing: border-box;
    -webkit-transition: opacity .3s ;
    z-index: 999;
 }

.webpage-theme-preview header {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}




.webpage-theme-preview .webpage-exit {
    position: absolute;
    top: 5px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
}

.webpage-theme-preview  .webpage-theme-preview-content {
    width: 100%;
    height: calc(100vh - 110px - 40px);
     background: #fff;
}
iframe#webpage_theme_preview_iframe {
    box-shadow: 2px 2px 40px #cecbcb;
}
#webpage_theme_preview_iframe{
    outline: none !important;
}

a.webpage-choose-design {
    background: #64bef8;
    color: #fff !important;
    padding: 15px 28px;
    font-family: 'GothamLight';
    font-size: 18px;
    border-radius: 40px;
    text-decoration: none;
    cursor: pointer;
}







/*Feature Page*/
#feature_section .container{
padding: 0 100px;
}
.feature-page-heading {
    font-size: 3.0vw  !important;
    line-height: 1.1;
    color: #080808;
    font-family: 'GothamLight' !important;
    text-transform: capitalize;
    font-weight: normal;
}
h2.features-heading {
    font-family: 'GothamBook';
    color: #e47558;
    text-transform: capitalize !important;
    font-size: 30px;
    font-weight: lighter;
}
p.features-text {
    font-family: 'GothamLight' !important;
    font-size: 1.0vw;
    line-height: 1.2;
}
p.features-end-text {
    font-family: 'GothamBook';
    font-weight: bold;
    color: #323232;
    font-size: 30px;
    text-align: center;
    line-height: 1.2;
    padding: 0 50px;
}
.features-column{
    z-index: 1;
    float: left;
    position: relative;
    min-height: 1px;
}
.features-half{
    margin-left: 6%;
    width: 47%;
}
.features-first{
margin-left:0px ;
    clear: left;
}
.features-display{
    float: none;
    display: table-cell;
}

.feature-seprator {
    width: 40px;
    height: 40px;
}

/*Porfolio Static Page */

.porfolio-column{
    z-index: 1;
    float: left;
    position: relative;
    min-height: 1px;
}
.porfolio-half{
    margin-left: 6%;
    width: 47%;
}
.porfolio-first{
margin-left:0px ;
    clear: left;
}
.porfolio-column-display{
float:none;
display:table-cell;
}
.porfolio-align{
vertical-align:middle;
}
.vertical-placeholder{
    display: table-cell;
    width: 6%;
}
.portfolio-theme-description {
    font-size: 1.5vw !important;
    line-height: 1.1;
    margin: 0;
    width: 450px;
    color: #222;
    font-family: 'GothamLight' !important;
}
.portfolio-btn{
border: 1px solid #474747 !important;
    padding: 8px 50px;
    color: #474747 !important;
    text-decoration: none !important;
    font-size: 1.4vw !important;
    font-family: 'GothamBook' !important;
    text-decoration:none;
   cursor: pointer;
    background: 0 0;
    width: initial;
    border: 1px solid #000 !important;
    border-radius: 21px /20px;
    transition: all .5s ease-in-out;
}
.portfolio-btn:hover{
text-decoration: none !important;
    background: #e47558 !important;
    border-color: #e47558 !important;
    color: #fff !important;
}

.portfolio-heading {
    text-align: center;
    font-family: 'GothamBook' !important;
    color: #222222;
    font-size: 2.2vw;
    text-transform: capitalize !important;
}
/* Portfolio Static Page End */

/* Privacy Policy Page*/

h1.privacy-policy-heading {
    text-align: center;
    color: #222;
    text-transform: capitalize !important;
    font-family: 'GothamBook';
    font-weight: 800;
    font-size: 55px;
}
h1.privacy-sub-headings {
    font-size: 25px;
    color: #222;
    font-family: 'GothamBook';
    font-weight: bolder;
    line-height: 1.0;
}
p.privacy-sub-text {
    font-size: 20px;
    line-height: 1.5;
    font-family: 'GothamLight';
    color: #080808;
}
ul.privacy-list {
    font-size: 20px;
    font-family: 'GothamLight';
    color: #080808;
}
.privacy-container {
    padding: 0 80px;
}

/* Terms And Condition Page*/

.terms-heading{
    text-align: center;
    color: #222 !important;
    text-transform: capitalize !important;
    font-family: 'GothamBook';
    font-weight: 800;
    font-size: 55px;
}
h1.terms-sub-headings {
    font-size: 25px;
    color: #222;
    font-family: 'GothamBook';
    font-weight: bolder;
    line-height: 1.0;
}
ul.terms-list {
    list-style-type: decimal;
    font-size: 20px;
    line-height: 1.5;
    font-family: 'GothamLight';
    color: #080808 !important;
margin-left:1em;
}
.terms-container{
padding: 0 80px;
}




/* Mobile Responsivness */
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .template-page-review{
        padding: 20px !important;
    }
.avia-menu-text{
    font-size: 16px !important;
}

/* slider */
  .slider-heading{
    font-size: 22px !important;
  }
  .slider-text {
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.slider-btn1 {
    padding: 11px 30px;
    font-size: 18px;
}
.avia-caption-content {
    margin-top: -100px;
}


/* section1  */

#first_section div .av_three_fifth{
    width: 100% !important;
}

#main-puzzle{
    height: 100vw !important;
}

#first_section{
    padding: 0 0px 0 58px;
}
 
.hr.hr-invisible.avia-builder-el-2.el_before_av_two_fifth.avia-builder-el-first , .hr.hr-invisible.avia-builder-el-5.el_after_av_codeblock.el_before_av_codeblock {
    height: 40px !important;
}
.content-heading
{
  font-size: 18px !important;
}
.text-content{
  font-size: 15px !important;
}
.start-now
{
  font-size: 18px !important;
}
.content-heading1{
  font-size: 25px !important;
}
.get-started-btn{
      padding: 8px 30px;
      font-size: 23px !important;
}

/*Section 1 end*/

/*Section 2 start*/
.responsive #top .avia-slideshow-controls a {
    display: inline-block !important;
}
/*Section 2 end*/

#footer .flex_column:nth-child(1) {
    width: 100% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(2) {
    width: 100% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(3) {
    width: 100% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(4) {
    width: 100% !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(5) {
    width: 100% !important;
    margin-left: 2% !important;
}
#footer {
    padding: 10px;
}





    /* Footer */
    #footer {
    padding: 20px 20px !important;
    }
    .footer-column{
    margin-top: 25px;
    }
    #footer .widget{
        margin: 0 !important;
    }
    .search-bar {
        display: none;
    }
    .sign-btn {
        display: none;
    }

    /* web-page-packages Page */

    h3.cls-web-page-package-text{
        font-size: 26px !important;
    }

    .column {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 20px;
    }

/* Features Page */

.feature-page-heading{

    font-size: 30px !important;
}
    
.responsive #top .features_column_table_cell {
   display: block;
}
p.features-text {

font-size: 16px !important;
}
.features-column{
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
}
.features-half{
    width: 100%;
}
.hr.hr-invisible.avia-builder-el-3.el_after_av_codeblock.el_before_av_codeblock {
    height: 10px;
}
p.features-end-text{
    padding: 0;
    font-size: 20px;
    text-align: left;
}
/* Review Page */
    .review-column{
        width: 100%;
    }
     .review-content {
    
    padding: 0 !important;
    }
    .column {
    width: 100% !important;
    padding: 0 !important;
    }
  /* .vertical-divider:after {
    display: none;
    } */
    .reviews-heading{
        font-size: 45px !important;
    } 
    .reviews-subtext{
         font-size: 20px !important;
    }
    .review-clients{
        padding: 0;
    }
    /* Design Page */

    h2.web-theme-name{
        font-size: 18px !important;
        margin-bottom: 0 !important;
    }
    a.web-theme-btn.next{
        font-size: 13px !important;
        padding: 7px 32px !important;
    }
    a.webpage-view-btn{
        font-size: 15px !important; 
    }

    .design-heading{

        font-size:30px !important;
        margin-top: 50px !important;
    }
    .design-subtext{
        font-size:20px !important;
    }
    p.design-subtext{
        font-size: 16px !important; 
    }
    p.design-theme-description{
        font-size: 16px !important;
        height: 100% !important;
    }
    p.design-sub-theme-description b{
        font-size: 16px !important;
    }
    .web-page-design {
    padding-left: 5px;
    }
    h2.design-theme-name{
        font-size: 22px !important;
    }
    .webpage-theme-preview  .webpage-theme-preview-content{
   width: 100%;
   height: 100%;
   -webkit-overflow-scrolling: touch !important;
     overflow-y: scroll !important;
    }

    /*Process Page*/
    .inner-page-heading {
    font-size: 30px !important;
    line-height: 1.0;
    }
    h1.process-step-heading{
        font-size: 30px !important;
    }
    .step_details {
    font-size: 16px !important;
    }
    .process-step-done{
        font-size: 30px !important;
    }
    /* Portfolio Page*/
    .portfolio-heading {
        font-size: 22px !important;
        padding: 25px 0 !important;
    }
    .responsive #top .porfolio_column_table_cell {
       display: block;
    }
    .porfolio-column{
        margin: 0;
        margin-bottom: 20px;
        width: 100%;
    }
    .portfolio-theme-description {
    font-size: 23px !important;
    line-height: 1.1;
    width: 100%;
    }
    .cls-web-page-package-heading{
        font-size: 25px !important;
    }
    .web-page-package-heading, .web-page-package-heading-right {
    font-size: 18px;
    }
    /*Package page*/
    .web-page-pack-column{
        display: table !important;
        width: 100% !important;
        margin-top: 25px;
    }
    .web-page-package-column,.web-page-package-column-right{
        width: 100% !important;
    }
    .web-page-package-heading,.web-page-package-heading-right {
    font-size: 20px !important;
    }
    .web-page-package-description-list {
    font-size: 16px !important; 
    }
    .web-page-packages-fee-heading {
    font-size: 35px !important;
    }
    span.web-page-packages-fee-subheading{
        font-size: 16px !important;
     }
    .web-page-packages-fee-text {
    font-size: 22px !important;
    }
    .web-page-packages-no-extra-fee {
    font-size: 16px !important;
     }
     .standard-web-pack-page-btn, .ultimate-pack-page-btn{
        padding: 10px 20px;
        font-size: 25px !important;
     }
      #ultimate_package .container {
    height: 1200px !important;
     }

.web-page-package-column-right{

    height: 650px !important;
}
    .div-pack-btn{

    width: 100%;
}
/* About Us*/
.about-content-heading{
    font-size: 36px !important;
}
.about-text-content{

    font-size: 16px !important;
}
.about-heading {
    font-size: 30px !important;
    line-height: 1.0;
    }
    .about-sub-heading {
    font-size: 30px !important;
    line-height: 1.0;
}
.about-sub-text {
    font-size: 16px !important;
}
.about-get-started{

        padding: 10px 35px !important;
        font-size: 20px !important;
}
  /*Terms of services*/

.terms-container{
    padding: 0;
}
.terms-heading{
    font-size: 30px;
}
h1.terms-sub-headings{
    font-size: 22px;
}
ul.terms-list{
    font-size: 16px;
}
/*Privacy Policy Page*/
p.privacy-sub-text{
    font-size: 16px;
}
h1.privacy-policy-heading{
        font-size: 22px;
}
.privacy-container{
    padding: 0;
}
/* Contact Us Page*/
.responsive #top .flex_column_table {
    display: block !important;
}

h1.cls-contact-us-heading{
    font-size: 23px !important;
}
.cls-contact-btn{
    font-size: 23px !important;
}

#contactus .container {
    padding: 0px 0px!important;
}
#top #contactus .flex_column_table{
    height: 100%;
}
.cls-contact-us-text{
    font-size: 18px !important;
}
.contact-page-banner-heading {
    font-size: 30px !important;
}
h1.contact-content-heading{
    font-size: 26px !important;
}

.portfolio-btn{
    font-size: 20px !important;
}
.client-details{
    margin: 0 !important;
}
.review-clients{
    padding: 0 !important;
}
}

/*Ipad pro*/
@media only screen and (min-width: 1024px) and (max-width: 1260px) {

#footer {
    padding: 0;
}
#footer .flex_column:nth-child(1) {
    width: 135px !important;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(2) {
    width: 135px !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(3) {
    width: 135px !important;
    margin-left: 2% !important;
}
#footer .flex_column:nth-child(4) {
    width: 166px !important;
    margin-left: 20px !important;
}
#footer .flex_column:nth-child(5) {
    width: 240px !important;
    margin-left: 2% !important;
}

}

/*Ipad pro end*/


   
/* Tablet Responsivness */
@media only screen and (min-width: 768px) and (max-width: 996px) {

/*#search-bar button.btn.btn-search{
    bottom: 1px !important;
}*/
#top #search-bar input[type="text"]{
   font-size: 16px !important; 
}
/*Section 1*/
#first_section div .av_three_fifth{
    width: 100% !important;
}

.avia-menu-text{

    font-size:16px !important;
}

#main-puzzle{
    height: 80vw !important;
}
#first_section div .av_three_fifth {
    width: 100%;
    margin-left: 0 !important;
}

    .search-bar {
        left: 40%;
        width: 44%;
    }
    .sign-btn {
        display: none;
    }
    .web-page-package-column,.web-page-package-column-right{
        width: 96% !important;
    }

    .slider-heading{
        font-size: 25px !important;
    }
    .slider-text{
        font-size: 20px !important;
    }
    .slider-btn1{
        font-size: 23px !important;
    }
    
    #first_section div .av_two_fifth{
        width: 100%;
    }
    #second_section div .av_one_half{
        width: 100% !important;
        margin-left: 0 !important; 
    }
    #top #second_section .flex_column_table,#top #second_section .flex_column_table_cell,#top #first_section .flex_column_table,#top #first_section .flex_column_table_cell  {
    display: block;
    }

    .hr.hr-invisible.avia-builder-el-5.el_after_av_codeblock.el_before_av_codeblock {
    height: 100px !important;
}


.content-heading{
    font-size: 26px !important;
}
.text-content{
    font-size: 18px !important;
}
.start-now{
    font-size: 22px !important;
}
.content-heading1{
    font-size: 30px !important;
}

.get-started-btn{
    font-size: 25px !important;
}
/*
#top .avia-smallarrow-slider .avia-slideshow-dots a{
        margin-bottom: -33% !important;
    left: -91.4% !important;
}
*/

    /* Features Page*/

    #feature_section .container{
        padding: 0 50px;
    }
    h2.features-heading{
        font-size: 18px;
    }
    p.features-text{
        font-size: 16px !important;
    }
    .feature-page-heading{
        font-size: 35px !important;
    }
    p.features-end-text{
        font-size: 22px;
        padding: 0;
    }
    .page-id-517 .hr.hr-invisible.avia-builder-el-3.el_after_av_codeblock.el_before_av_codeblock{
        height: 20px !important;
    }


    /* Review Page */
  .reviews-heading{
    font-size: 30px !important;
  }
    .review-content{
        padding: 0 !important;
    }
     .review-column{
        width: 100%;
    }
     .review-content {
    
    padding: 0 !important;
    }
    .column {
    width: 100% !important;
    padding: 0 !important;
    }
  /* .vertical-divider:after {
    display: none;
    } */
    .design-heading{

        font-size:35px !important;
    }
    .design-subtext{
        font-size:20px !important;
    }
    .web-page-design {
    padding-left: 5px;
    }
    h2.design-theme-name{
        font-size: 22px !important;
    }
    .reviews-subtext{
        font-size: 20px !important;
        line-height: 1.0;
    }
    .review-clients{
        padding: 0;
    }

    /* Design Page*/
    p.design-subtext{
        font-size: 16px !important;
    }
    p.design-theme-description
    {
        font-size: 16px !important;
        height: 100% !important;
    }
    p.design-sub-theme-description b
    {
        font-size: 16px !important;
    }
    #top #webpage-design-theme .flex_column_table,#top #webpage-design-theme .flex_column_table_cell  {
    display: block;
    width: 100%;
    }
    
    /* Process Page*/
    .inner-page-heading {
    font-size: 35px !important;
    line-height: 1.0;
    }
    .process-step-done{
        font-size: 48px !important;
    }
    h1.process-step-heading{
        font-size: 30px !important;
    }
    .step_details{
        font-size: 16px !important;
    }
    /*Portfolio page*/
    .portfolio-heading{
        font-size: 22px !important;
    }
    .portfolio-theme-description {
    font-size: 16px !important;
    line-height: 1.1;
    width: 100%;
    }
    .portfolio-theme-description{
        font-size: 20px;
    }
    .portfolio-btn{
        font-size: 20px !important;
    }
    /* Package Page*/
    h3.cls-web-page-package-text,.cls-web-page-package-heading {
        font-size: 26px !important;
    }
    .web-page-package-heading,.web-page-package-heading-right {
    font-size: 20px !important;
    }
    .web-page-package-description-list {
    font-size: 16px !important;
    }
    .web-page-packages-fee-heading {
    font-size: 35px !important;
    }
    .web-page-packages-fee-text {
    font-size: 22px !important;
    }
    .web-page-packages-no-extra-fee {
    font-size: 16px !important;
     }
     .standard-web-pack-page-btn, .ultimate-pack-page-btn{
        padding: 10px 20px;
        font-size: 22px !important;
     }
     span.web-page-packages-fee-subheading{
        font-size: 16px !important;
     }
     /*About Page*/
     .about-heading {
    font-size: 30px !important;
    line-height: 1.0;
    }
    .about-sub-heading {
    font-size: 30px !important;
    line-height: 1.0;
}
.about-get-started{

        padding: 10px 35px !important;
        font-size: 20px !important;
}
.about-text-content{
    font-size: 16px !important;
}
.about-sub-text {
    font-size: 16px !important;
}
.about-content-heading{
    font-size: 36px !important;
}
/*Contact Page*/
.contact-page-banner-heading {
    font-size: 30px !important;
}
h1.cls-contact-us-heading{
    font-size: 23px !important;
}
.cls-contact-us-text{
    font-size: 18px !important;
}
.cls-contact-btn{
    font-size: 23px !important;
}
h1.contact-content-heading
{
    font-size: 26px !important;
}

/*Terms Page*/
 .terms-container{
    padding: 0;
}
.terms-heading{
    font-size: 30px;
}
h1.terms-sub-headings{
    font-size: 22px;
}
ul.terms-list{
    font-size: 16px;
}   
p.privacy-sub-text{
    font-size: 16px;
}
/*Privacy Policy Page*/
p.privacy-sub-text{
    font-size: 16px;
}
h1.privacy-policy-heading{
        font-size: 30px;
}
.privacy-container{
    padding: 0;
}
h1.privacy-sub-headings{
    font-size: 22px;
}

/*Footer Styling*/
#footer .container {
    padding: 0 10px !important;
}
#footer .flex_column:nth-child(1) {
    width: 125px !important;
    padding-right: 5px !important;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(2) {
   width: 135px !important;
    padding-right: 5px;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(3) {
           width: 135px !important;
    padding-right: 5px;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(4) {
            width: 135px !important;
    padding-right: 5px !important;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(5) {
        width: 200px !important;
    margin-left: 0!important;
}
h3.widgettitle {
    font-size: 17px !important;
}
#footer{
    padding: 0 !important;
}
a.footer-links{
    font-size: 14px !important;
}
.footer-com-desc{
margin-top: 22px !important;
font-size:13px !important;

}
img.footer-image {
    width: 80%;
}
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  


.main_menu {
    left: 20vw !important;
}
.sign-btn {
    right: -38px !important;
}

#footer {
    padding: 0 0 0 50px !important;
}
#footer .flex_column:nth-child(1) {
    width: 150px !important;
    padding-right: 5px !important;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(2) {
    width: 150px !important;
    padding-right: 5px;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(3) {
    width: 150px !important;
    padding-right: 5px;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(4) {
    width: 150px !important;
    padding-right: 5px;
    margin-left: 0!important;
}
#footer .flex_column:nth-child(5) {
    width: 230px !important;
    padding-right: 5px;
    margin-left: 0!important;
}
h3.widgettitle {
    font-size: 20px !important;
}


  
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
   /* .search-bar {
        left: 63%;
        width: 24%;
    }
    input#search-box {
        font-size: 10px !important;
    }
    .main_menu {
        left: 22%;
    }*/
    .main_menu {
    left: 20vw !important;
    }
    .search-bar {
        left: 54vw !important;
    }



    /*first section spacer*/
    .hr.hr-invisible.avia-builder-el-5.el_after_av_codeblock.el_before_av_codeblock{
        height: 100px !important;
    }
    /*first section spacer*/

}
@media screen and (min-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .right {
        padding-top: 70px !important;
    }
    /*
    #top .avia-smallarrow-slider .avia-slideshow-dots a {
        margin-bottom: -45% !important;
        /*left: -44% !important;
    }
    */
    .hr.hr-invisible.avia-builder-el-14.el_before_av_codeblock.avia-builder-el-first
    {

            margin-top: -250px !important;
    }
    /*first section spacer*/
    .hr.hr-invisible.avia-builder-el-5.el_after_av_codeblock.el_before_av_codeblock{
        height: 100px !important;
    }
    /*first section spacer*/
    

}

@media only screen and (min-width: 997px) and (max-width: 1024px) {
.hr.hr-invisible.avia-builder-el-5.el_after_av_codeblock.el_before_av_codeblock{

    height: 100px !important;
}

}

/*TZ NEW STYLE FOR BLOG, CATEGORY, TAG PAGE STARTS*/

/* img{
      image-rendering: auto !important;
} */
span.logo{
    padding: 0px !important;
    margin: 0px 0;
}
li#menu-item-1229 {
    display: none;
}
.responsive .container.template-blog {
    max-width: 1430px !important;
    padding: 0 !important;
}
.stretch_full.container_wrap.alternate_color.light_bg_color.title_container {
    display: none;
}
.tag .post-title.tag-page-post-type-title {
    font-family: 'GothamMedium' !important;
    color: #222222 !important;
    text-transform: capitalize !important;
    font-size: 42px;
    font-weight: bold;
text-align:center;
margin-top: 95px;
}
.tag .avia-content-slider .slide-entry-excerpt {
    padding: 20px 0 !important;
}
.tag .slide-entry-excerpt.entry-content {
    padding: 0 !important;
    font-family: 'Bariol';
    font-size: 18px;
}
.slide-meta {
    display: none;
}
.tag .slide-entry-title.entry-title {
    display: block !important;
    font-family: 'GothamLight';
    font-size: 20px;
    color: #000;
}
.tag .slide-content {
    width: 94%;
    border: 1px solid #ccc !important;
    border-radius: 10px !important;
    padding: 23px 22px 23px 22px;
    margin: 0 auto!important;
    position: relative;
    top: -40px;
    min-height: 200px;
    background: #fff;
    box-shadow: 1px 1px 13px 0px rgba(67, 93, 133, 0.16);
}
.avia_transform a:hover .image-overlay {
    opacity: 0 !important;
}
.tag .container_wrap.container_wrap_first.main_color.fullsize {
    background: #fcfcfc !important;
}
.tag .av_one_third {
    margin-left: 2% !important;
    width: 30.333333% !important;
}
.tag .slide-entry-wrap {
    margin-top: 50px !important;
}
#top .fullsize .template-blog .tag-page-post-type-title {
    font-size: 54px !important;
}
.category-blog {
    max-width: 1430px;
    margin: 0 auto;
}
.category .entry {
    margin-left: 2% !important;
    width: 30.333333%;
    /*display: inline-block;*/
        display: inline-table;
}
.category .slide-content {
    width: 96%;
    border: 1px solid #ccc !important;
    border-radius: 10px !important;
    padding: 23px 22px 23px 22px;
    margin: 0 auto!important;
    position: relative;
    top: -40px;
    min-height: 200px;
    background: #fff;
    box-shadow: 1px 1px 13px 0px rgba(67, 93, 133, 0.16);
}
.category  .slide-content h3 {
    display: block !important;
    font-family: 'GothamLight';
    font-size: 20px;
    color: #000;
}
section.category-section {
    padding: 100px 0;
}
h1.archive-title {
    text-align: center;
    font-family: 'GothamMedium' !important;
    color: #222222 !important;
    text-transform: capitalize !important;
    font-size: 42px;
    font-weight: normal;
    margin-bottom: 50px;
}
.slide-entry-excerpt{
    padding: 0 !important;
    font-family: 'Bariol' !important;
    font-size: 18px !important;
    color: #222 !important;
}
a.sw_cattitle {
    color: #606060;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
.category .entry{
    width: 100% !important;
    display: block;
    /*padding: 0 30px;*/
}
.tag .slide-entry-wrap {
    padding: 0 30px;
}
.tag .av_one_third {
    width: 100% !important;
    display: block;
}
h1.archive-title,#top .fullsize .template-blog .tag-page-post-type-title {
    font-size: 30px !important;
    margin-bottom: 20px !important;
}
section.category-section {
    padding: 10px 0;
}
.archive .category-term-description {
    margin-bottom: 0;
}
p.excerpt_text strong {
    color: #000 !important;
    font-family: 'GothamLight';
    font-size: 15px;
}
p.excerpt_text {
    font-size: 15px;
}
h1.archive-title, #top .fullsize .template-blog .tag-page-post-type-title {
    margin: 0 !important;
    padding: 0 !important;
}
.tag .content{
    padding-top: 0 !important;
}
h3.post-title.tag-page-post-type-title {
    font-size: 30px !important;
    margin-top: 150px !important;
}
.slide-entry-excerpt{
    font-size: 14px !important;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
.tag .post-title.tag-page-post-type-title{
font-size: 26px!important;
    line-height: 1.3!important;
}
p.excerpt_text strong {
    font-size: 11px !important;
}
.slide-content h3{
    font-size: 14px !important;
    line-height: 1.4em;
}
.excerpt_text + p {
    font-size: 14px !important;
    font-family: 'Bariol' !important;
}
.slide-content {
    padding: 8px !important;
    min-height: 240px !important;
}
h3.slide-entry-title.entry-title, #swb_section .avia-content-slider .slide-entry-excerpt {
    font-size: 14px !important;
}
.tag .slide-entry-excerpt.entry-content {
    padding: 0 !important;
    font-family: 'Bariol';
    font-size: 14px;
}
h3.slide-entry-title.entry-title {
    font-size: 14px !important;
    line-height: 1.4em;
}
h3.slide-entry-title.entry-title {
    font-size: 14px !important;
    line-height: 1.4em;
    padding-bottom: 0px !important;
}
.tag h3.slide-entry-title.entry-title {
    font-size: 14px !important;
    line-height: 1.4em;
    padding-bottom: 0px !important;
}
.archive-title.xs-cat-title {
    font-weight: bold !important;
    font-size: 26px;
}
.slide-entry-excerpt{
    font-size: 16px !important;
}
section.category-section {
    padding: 10px 0 !important;
}
}
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
section.category-section {
    padding: 100px 0 !important;
}
}

/*TZ NEW STYLE FOR BLOG, CATEGORY, TAG PAGE END*/

/*TZ NEW STYLE FOR PAGES START*/

div .logo{
    position: relative;
}
#swb_section .avia-content-slider .slide-entry-excerpt {
    padding: 0 !important;
    font-family: 'Bariol' !important;
    font-size: 18px !important;
    color: #222 !important;
}
h3.slide-entry-title.entry-title {
    display: block !important;
    font-family: 'GothamBook' !important;
    font-size: 18px !important;
    color: #000 !important;
    padding-bottom: 15px !important;
}
.sw_slider_heading {
    color: #292929!important;
    font-family: GothamMedium !important;
    font-size: 42px !important;
    text-transform: capitalize!important;
    font-weight: bolder!important;
    text-shadow: none!important;
    line-height: 1.3 !important;
    margin: 0 0 20px 0 !important;
    text-transform: inherit !important;
}
.reviews_heading {
    font-size: 38px!important;
    color: #222 !important;
    font-family: 'Gotham-Medium' !important;
    line-height: 1.2 !important;
    font-weight: 700 !important;
    text-align: center !important;
}
.sw_subheading_heading {
    font-family: GothamMedium!important;
    font-weight: 700!important;
    font-size: 36px !important;
    color: #292929 !important;
    width: 100% !important;
}
.sw_fea_subheading_heading{
    font-family: GothamMedium!important;
    font-weight: 700!important;
    font-size: 25px !important;
    color: #292929 !important;
    width: 100% !important;
}
.sw_slider_subtext {
    color: #000 !important;
    font-family: Bariol !important;
    font-size: 23px !important;
    line-height: 1.5 !important;
    text-shadow: none!important;
    margin: 0 0 20px 0 !important;
}
.sw_heading_sub_text {
    font-size: 23px !important;
    font-family: Bariol !important;
    text-align: center !important;
    color: #4d545b !important;
    line-height: 1.3 !important;
    margin: 0 auto !important;
}
.sw_content_text {
    font-size: 23px !important;
    line-height: 1.3 !important;
    font-family: Bariol !important;
    color: #222 !important;
    margin: 20px 0 !important;
}
.sw_fill_btn {
    background: #002536!important;
    color: #fff !important;
    border: 1px solid #002536!important;
    padding: 10px 30px !important;
    text-decoration: none!important;
    border-radius: 5px !important;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px !important;
    min-width: 240px!important;
    display: block !important;
    text-align: center !important;
    background: 0 0 !important;
    width: initial !important;
    transition: all .5s ease-in-out !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    margin: 0 15px 0 0 !important;
    text-shadow: none !important;
}
.sw_fill_btn:hover {
    background: transparent !important;
    text-decoration: none!important;
    color: #002536!important;
    border-color: #002536!important;
}
.sw_unfill_btn {
    color: #002536!important;
    border: 1px solid #002536!important;
    padding: 10px 30px !important;
    text-decoration: none!important;
    border-radius: 5px !important;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px !important;
    min-width: 240px!important;
    display: block !important;
    text-align: center !important;
    background: 0 0 !important;
    width: initial !important;
    transition: all .5s ease-in-out !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    text-shadow: none !important;
}
.sw_unfill_btn:hover {
    background: #002536!important;
    text-decoration: none!important;
    border-color: #002536!important;
    color: #fff!important;
}
.sw_unfill_conbtn {
    color: #ffffff!important;
    border: 1px solid #ffffff!important;
    padding: 10px 30px !important;
    text-decoration: none!important;
    border-radius: 5px !important;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px !important;
    min-width: 240px!important;
    display: block !important;
    text-align: center !important;
    background: 0 0 !important;
    width: initial !important;
    transition: all .5s ease-in-out !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    text-shadow: none !important;
}
.sw_unfill_conbtn:hover {
    background: #002536!important;
    text-decoration: none!important;
    border-color: #002536!important;
    color: #fff!important;
}
.section_sp_ft {
    width: 100%;
    height: 80px;
}
.white{
    color:#fff !important;
}
.txt-left{
text-align: left !important;
}
.border-clr{
    border: 1px solid #fff !important;
}
.cat-sep{
    width: 100%;
    height: 50px;
}
.archive-title.xs-cat-title{
    font-weight: bold !important;
}
@media only screen and (max-width: 1600px) and (min-width: 1200px){
.sw_slider_heading {
    font-size: 30px !important;
}
.reviews_heading {
    font-size: 28px!important;
}
.sw_subheading_heading {
    font-size: 24px!important;
}
.sw_fea_subheading_heading{
    font-size: 21px!important;
}
.sw_slider_subtext {
    font-size: 20px !important;
}
.sw_content_text,.sw_heading_sub_text{
    font-size: 20px !important;
}
}
@media only screen and (max-width: 1024px) and (min-width: 768px){
.sw_slider_heading {
    font-size: 26px!important;
    line-height: 1.3!important;
    margin: 0 0 20px 0 !important;
}
.reviews_heading {
    font-size: 24px!important;
}
.sw_subheading_heading {
    font-size: 20px !important;
}
.sw_fea_subheading_heading{
    font-size: 21px!important;
}
.sw_slider_subtext {
    font-size: 18px !important;
}
.sw_content_text,.sw_heading_sub_text{
    font-size: 18px!important;
}
.section_sp_ft {
    width: 100%;
    height: 30px;
}
.hmbg-overlay .closebtn {
    position: absolute;
    top: 32px;
    right: 40px;
}
.cat-sep{
    width: 100%;
    height: 150px;
}
}
@media only screen and (max-width: 767px) and (min-width: 280px){
.sw_slider_heading {
    font-size: 24px!important;
    margin: 6px 0;
}
.reviews_heading {
    font-size: 22px!important;
    line-height: 35px!important;
}
.sw_subheading_heading {
    font-size: 22px!important;
    margin: 0 !important;
}
.sw_fea_subheading_heading{
    font-size: 21px!important;
}
.sw_slider_subtext {
    font-size: 18px!important;
    line-height: 1.2!important;
}
.sw_content_text,.sw_heading_sub_text{
    font-size: 18px !important;
}
a.sw_fill_btn,.sw_unfill_btn,.sw_unfill_conbtn {
    margin: 10px 0!important;
    display: block !important;
    font-size: 16px !important;
}
.section_sp_ft {
    width: 100%;
    height: 30px;
}
.archive-title.xs-cat-title{
    margin-bottom: 50px !important;
}
.cat-sep{
    width: 100%;
    height: 120px;
}
/*.hmbg-overlay .closebtn {
    position: absolute;
    top: 32px;
    right: 40px;
}*/
}
/*TZ NEW STYLE FOR PAGES END*/
@font-face {
    font-family: Bariol;
    src: url(/static/media/bariol_regular-webfont.e4213a39.eot);
    src: url(/static/media/bariol_regular-webfontd41d.e4213a39.eot?#iefix) format("embedded-opentype"),
     url(/static/media/bariol_regular-webfont.7d0cf79e.svg) format('svg'),
      url(/static/media/bariol_regular-webfont.6c235190.ttf) format('truetype'),
       url(/static/media/bariol_regular-webfont.38c3b9e9.woff) format('woff'),
        url(/static/media/Bariol_Regular.6a64a521.otf) format('otf'),
         url(/static/media/bariol_regular-webfont.6577b355.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Book';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Book'), 
    url(/static/media/Gotham-Book.65288315.woff) format('woff'),
    url(/static/media/Gotham-Book.7fe2d669.eot),
    url(/static/media/Gotham-Bookd41d.7fe2d669.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Gotham-Book.79ab7007.ttf) format('truetype'),
    url(/static/media/Gotham-Book.92032bfd.svg#Gotham-Book) format('svg'),
    url(/static/media/Gotham-Book.e37cf599.otf) format('opentype');   
}

@font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url(/static/media/GothamMedium.d23ef85d.woff) format('woff'),
    url(/static/media/GothamMedium.de228587.woff2) format('woff2'),
    url(/static/media/GothamMedium.ed646164.eot),
    url(/static/media/GothamMediumd41d.ed646164.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamMedium.c3f574b2.ttf) format('truetype'),
    url(/static/media/GothamMedium.9faf290f.svg#Gotham-Book) format('svg'),
    url(/static/media/GothamMedium.bcd733e6.otf) format('opentype'); 
}
  @font-face {
    font-family: 'Bariol-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Bold'), 
    url(/static/media/Bariol-Bold.bc734d95.woff) format('woff'),
    url(/static/media/Bariol-Bold.d458cb75.eot),
    url(/static/media/Bariol-Boldd41d.d458cb75.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Bariol-Bold.fa0ddc94.ttf) format('truetype'),
    url(/static/media/Bariol-Bold.0ed2ea7a.svg#Bariol-Regular) format('svg');
}

/*TZ Starts*/

.cal-clr{
    color: #fbc504;
}
.cal-clr:hover{
    color: #000000;
    text-decoration: none;
}
.placeholder-pos{
   padding-top: 18px;
}
.centr-btn{
    text-align: center !important;
}
/* css */
.sldate {
    color: #ff0000 !important;
    font-weight: 500;
    font-size: 13px;
    font-family: 'GothamBook';
}
#sw-schedulecall-message{
    margin-top: 15px !important;
}
/*TZ End*/
.container {
    width: 1270px!important;
    max-width: 1249px !important;
}
.section_seprator {
    width: 80px;
    height: 80px;
}
.sw_schedule_call {
    background: #131313;
    padding: 0px 0px;
}
.sw-schedule-call-intro{
    text-align: center;
}
.sw-schedule-head{
    color: #292929!important;
    font-family: 'Gotham-Medium' !important;
    font-size: 42px !important;
    text-transform: capitalize!important;
    font-weight: bolder!important;
    text-shadow: none!important;
    line-height: 1.3 !important;
    margin: 0 0 20px 0 !important;
    text-transform: inherit !important;
}
.sw-schedule-text {
    font-size: 23px !important;
    line-height: 1.3 !important;
    font-family: Bariol !important;
    color: #222 !important;
    margin: 20px 0 !important;
}
.sw-schedule-text a{
    color: #e47458;
    text-decoration: none;
}
.faq-btn {
    color: #002536!important;
    border: 1px solid #002536!important;
    padding: 10px 30px !important;
    text-decoration: none!important;
    border-radius: 5px !important;
    font-family: 'Bariol-Bold' !important;
    font-size: 19px !important;
    min-width: 240px!important;
    display: block !important;
    text-align: center !important;
    background: 0 0 !important;
    width: initial !important;
    transition: all .5s ease-in-out !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    text-shadow: none !important;
}

.faq-btn:hover{
    background-color:#002536 !important;
    transition:1s;
    color: #fff !important;
}
.faq-btn:focus{
    box-shadow: none;
}
.live-btn {
    color: #002536!important;
    border: 1px solid #002536!important;
    padding: 10px 30px !important;
    text-decoration: none!important;
    border-radius: 5px !important;
    font-family: 'Bariol-Bold' !important;
    font-size: 19px !important;
    min-width: 240px!important;
    display: block !important;
    text-align: center !important;
    background: 0 0 !important;
    width: initial !important;
    transition: all .5s ease-in-out !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    text-shadow: none !important;
    margin-left: 16px !important;
}
.live-btn:hover{
    background-color:#002536 !important;
    transition:1s;
    color: #fff !important;
}
.live-btn:focus{
    box-shadow: none;
}
.sw-schedule-call-formdiv{
    margin: 0 auto;
    border-radius: 0px;
    padding: 4em;
    background: #131313;
}
.sw-schedule-para {
    color: #ffffff!important;
    font-family: "Poppins" !important;
    font-size: 23px;
    font-weight: 500!important;
    line-height: 1.3;
    text-transform: inherit !important;
}
.sw-schedule-call-formdiv label {
    font-weight: 500;
}
.sw-schedule-call-formdiv ::-webkit-input-placeholder {
    font-size: 14px !important;
    font-family: 'poppins';
    color: #bcbcbc !important;
}
.sw-schedule-call-formdiv ::placeholder {
    font-size: 14px !important;
    font-family: 'poppins';
    color: #bcbcbc !important;
}
.sw-schedule-call-formdiv .form-control{
    font-size: 14px !important;
    font-family: 'poppins';
    background-color: rgba(255, 255, 255, 0);
}
.form-col1{
    padding-left: 0px;
}
.form-col2{
    padding-right: 0px;
    padding-left: 0px;
}
.form-col3{
    padding-right: 0px;
    padding-left: 0px;
}
.schedule-btn{
    background: #002536!important;
    color: #fff !important;
    border: 1px solid #002536!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: 'Bariol-Bold';
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: block;
    text-transform: uppercase;
    margin: 0 auto !important;
    text-shadow: none !important;
}
.schedule-btn:hover{
    background: transparent !important;
    text-decoration: none!important;
    color: #002536!important;
    border-color: #002536!important;
}
#callRequestForm .error {
    color: #ff0000;
    font-weight: 500;
    font-size: 13px;
    font-family: 'GothamBook';
}
.red-border{
    border: 2px solid #ef4836!important;
}
[placeholder]:focus::-webkit-input-placeholder {
    color: transparent !important ;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.form-control:focus {
    border-color: #fbc504;
    box-shadow: none;
}
.sw-schedule-call-formdiv textarea{
    height: 100px;
}
.sw-schedule-call-formdiv .alert-success{
    text-align: center;
    margin-top: 10px;
}
.section_seprator {
    width: 100% !important;
    height: 80px;
}
p.sw_slider_subtext.text-center a {
    color: #ffffff;
    text-decoration: none;
}
@media screen and (max-width: 1440px) {
.sw-schedule-text {
    font-size: 20px;
}
}
@media screen and (max-width: 1200px) {
.sw-schedule-head {
    font-size: 29px;
}
.faq-btn {
    padding: 10px 21px 10px;
    font-size: 13px;
}
.live-btn{
    padding: 10px 21px 10px;
    font-size: 13px;
}
.schedule-btn{
    padding: 10px 21px 10px;
    font-size: 13px;
}
}
@media screen and (max-width: 990px) {
.sw-schedule-text {
    font-size: 18px;
    line-height: 1.5;
}
.sw-schedule-call-formdiv ::-webkit-input-placeholder {
    font-size: 10px !important;
}
.sw-schedule-call-formdiv ::placeholder {
    font-size: 10px !important;
}
.schedule-btn{
    padding: 10px 21px 10px;
    font-size: 13px;
    max-width: 40%;
}
#callRequestForm .error {
    font-size: 11px;
}
.sw-schedule-call-formdiv .form-control{
    font-size: 10px !important;
}
}
@media screen and (max-width: 767px) {
.sw-schedule-head {
        font-size: 22px;
}
.sw-schedule-text {
    font-size: 15px;
    line-height: 1.5;
}
.faq-btn {
    padding: 10px 21px 10px;
    font-size: 13px;
    margin-bottom: 10px;
    width: 100%;
}
.live-btn{
    width: 100%;
    margin-left: 0px;
}
.sw-schedule-call-formdiv {
    box-shadow: 0px 0px 46px 0px rgba(0,0,0,0.15);
    padding: 30px 5px 30px 5px !important;
    margin: 0;
}
.sw-schedule-para {
    font-family: 'GothamBook';
    color: #000;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
}
.form-col1 {
    padding-right: 0px;
}
.sw-schedule-call-formdiv label {
    font-size: 13px;
}
.schedule-btn {
    max-width: 100%;
}
}
@media only screen and (max-width: 1366px) and (min-width: 280px){
    .container {
        width: 100%!important;
        max-width: 100%!important;
        padding: 0 30px !important;
    }
    }
    .sw_slider_heading {
        color: #292929!important;
        font-family: 'Gotham-Medium' !important;
        font-size: 42px !important;
        text-transform: capitalize!important;
        font-weight: bolder!important;
        text-shadow: none!important;
        line-height: 1.3 !important;
        margin: 0 0 20px 0 !important;
        text-transform: inherit !important;
    }
    .sw_content_heading {
        font-size: 38px!important;
        color: #ffffff  !important;
        font-family: 'Gotham-Medium' !important;
        line-height: 1.2 !important;
        font-weight: 700 !important;
        text-align: center !important;
    }
    .sw_subheading_heading {
        font-family: 'Gotham-Medium'!important;
        font-weight: 700!important;
        font-size: 36px !important;
        color: #292929 !important;
        width: 100% !important;
    }
    .sw_fea_subheading_heading{
        font-family: 'Gotham-Medium'!important;
        font-weight: 700!important;
        font-size: 25px !important;
        color: #292929 !important;
        width: 100% !important;
    }
    .sw_slider_subtext {
        color: #ffffff !important;
        font-family: Bariol !important;
        font-size: 23px !important;
        line-height: 1.5 !important;
        text-shadow: none!important;
        margin: 0 0 20px 0 !important;
    }
    .sw_heading_sub_text {
        font-size: 23px !important;
        font-family: Bariol !important;
        text-align: center !important;
        color: #4d545b !important;
        line-height: 1.3 !important;
        margin: 0 auto !important;
    }
    .sw_content_text {
        font-size: 23px !important;
        line-height: 1.3 !important;
        font-family: Bariol !important;
        color: #222 !important;
        margin: 20px 0 !important;
    }
    .sw_fill_btn {
        background: #002536!important;
        color: #fff !important;
        border: 1px solid #002536!important;
        padding: 10px 30px !important;
        text-decoration: none!important;
        border-radius: 5px !important;
        font-family: 'Bariol-Bold' !important;
        font-size: 19px !important;
        min-width: 240px!important;
        display: block !important;
        text-align: center !important;
        background: 0 0 !important;
        width: initial !important;
        transition: all .5s ease-in-out !important;
        display: inline-block !important;
        text-transform: uppercase !important;
        margin: 0 15px 0 0 !important;
        text-shadow: none !important;
    }
    .sw_fill_btn:hover {
        background: transparent !important;
        text-decoration: none!important;
        color: #002536!important;
        border-color: #002536!important;
    }
    .sw_unfill_btn {
        color: #ffffff !important;
        border: 1px solid #ffffff !important;
        padding: 10px 30px !important;
        text-decoration: none!important;
        border-radius: 5px !important;
        font-family: 'Bariol-Bold' !important;
        font-size: 19px !important;
        min-width: 240px!important;
        display: block !important;
        text-align: center !important;
        background: #187fdb !important;
        width: initial !important;
        transition: all .5s ease-in-out !important;
        display: inline-block !important;
        text-transform: uppercase !important;
        text-shadow: none !important;
    }
    .sw_unfill_btn:hover {
        background: #ffffff!important;
        text-decoration: none!important;
        border-color: #187fdb!important;
        color: #187fdb !important;
    }
    .sw_unfill_conbtn {
        color: #ffffff!important;
        border: 1px solid #ffffff!important;
        padding: 10px 30px !important;
        text-decoration: none!important;
        border-radius: 5px !important;
        font-family: BariolBold !important;
        font-size: 19px !important;
        min-width: 240px!important;
        display: block !important;
        text-align: center !important;
        background: 0 0 !important;
        width: initial !important;
        transition: all .5s ease-in-out !important;
        display: inline-block !important;
        text-transform: uppercase !important;
        text-shadow: none !important;
    }
    .sw_unfill_conbtn:hover {
        background: #002536!important;
        text-decoration: none!important;
        border-color: #002536!important;
        color: #fff!important;
    }
    .section_sp_ft {
        width: 100%;
        height: 80px;
    }
    .white{
        color:#fff !important;
    }
    .txt-left{
    text-align: left !important;
    }
    .border-clr{
        border: 1px solid #fff !important;
    }
    @media only screen and (max-width: 1600px) and (min-width: 1200px){
    .sw_slider_heading {
        font-size: 30px !important;
    }
    .sw_content_heading {
        font-size: 28px!important;
    }
    .sw_subheading_heading {
        font-size: 24px!important;
    }
    .sw_fea_subheading_heading{
        font-size: 21px!important;
    }
    .sw_slider_subtext {
        font-size: 20px !important;
    }
    .sw_content_text,.sw_heading_sub_text{
        font-size: 20px !important;
    }
    }
    @media only screen and (max-width: 1024px) and (min-width: 768px){
    .sw_slider_heading {
        font-size: 26px!important;
        line-height: 1.3!important;
        margin: 0 0 20px 0 !important;
    }
    .sw_content_heading {
        font-size: 24px!important;
    }
    .sw_subheading_heading {
        font-size: 20px !important;
    }
    .sw_fea_subheading_heading{
        font-size: 21px!important;
    }
    .sw_slider_subtext {
        font-size: 18px !important;
    }
    .sw_content_text,.sw_heading_sub_text{
        font-size: 18px!important;
    }
    .section_sp_ft {
        width: 100%;
        height: 30px;
    }
    .hmbg-overlay .closebtn {
        position: absolute;
        top: 32px;
        right: 40px;
    }
    .section_seprator {
        width: 100% !important;
        height: 40px;
    }
    }
    @media only screen and (max-width: 767px) and (min-width: 280px){
    .section_seprator {
        width: 100%!important;
        height: 30px!important;
    }
    .sw_slider_heading {
        font-size: 24px!important;
        margin: 6px 0;
    }
    .sw_content_heading {
        font-size: 22px!important;
        line-height: 35px!important;
    }
    .sw_subheading_heading {
        font-size: 20px!important;
        margin: 0 !important;
    }
    .sw_fea_subheading_heading{
        font-size: 21px!important;
    }
    .sw_slider_subtext {
        font-size: 18px!important;
        line-height: 1.2!important;
    }
    .sw_content_text,.sw_heading_sub_text{
        font-size: 18px !important;
    }
    a.sw_fill_btn,.sw_unfill_btn,.sw_unfill_conbtn {
        margin: 10px 0!important;
        display: block !important;
        font-size: 16px !important;
    }
    .section_sp_ft {
        width: 100%;
        height: 30px;
    }
    /*.hmbg-overlay .closebtn {
        position: absolute;
        top: 32px;
        right: 40px;
    }*/
    }
    /*TZ NEW STYLE FOR PAGES END*/
    @supports (-moz-appearance:none) {
  select
  {
  -moz-appearance:none !important;
  background: transparent url('data:image/gif;base64,R0lGODlhBgAGAKEDAFVVVX9/f9TU1CgmNyH5BAEKAAMALAAAAAAGAAYAAAIODA4hCDKWxlhNvmCnGwUAOw==') right center no-repeat !important;
  background-position: calc(100% - 5px) center !important;
  }

  .error-style{
      color: red !important;
  }
}
    

input.PhoneInputInput::-webkit-input-placeholder{
    color: #bcbcbc !important;
    font-size: 16px;
}
input.PhoneInputInput::placeholder{
    color: #bcbcbc !important;
    font-size: 16px;
}
.feilds{
    border-radius: 16px !important;
    border-color: '#818A98';
}
button#btn_sqignup {
    padding-top:14px ;
    padding-bottom: 14px;
    margin: 10px 0px 10px 0px;
    background-color: #fbc504 !important;
    border-radius: 50px;
    color: #000000;
    border: none;
    font-size: 20px !important;
    font-family: 'poppins';
    font-weight: 500;
    padding: 10px !important;
}
.form-group{
    padding: 5px 20px 5px 20px;
}

header {
    background: #ffffff;
    display: none !important;
    visibility: hidden !important;
}

body {
    background: #f7f7f7;
    margin: 0;
}
.login100-more {
    background-color: #161616;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: calc(100% - 600px);
    position: relative;
    z-index: -1;
    background-image: url(/static/media/SignRight.2c9e6665.svg);
}

.error-terms {
    font-family: 'Poppins';
    color: red;
    font-size: 12px;
}
.login100-more::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 520px;
    background: linear-gradient(bottom,#e8519e,#c77ff2);
    opacity: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: calc(100% - 520px);
    z-index: 1;
}
.wrap-login100 {
    /* width: 730px; */
   width: 600px !important;
    min-height: 100vh;
    background: #fff;
    border-radius: 2px;
    position: relative;
}
.container-login100 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-wrap: wrap;
}
.card{
    border:none !important;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
}

.card-signin .card-title {
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
    padding: 0;
    width: 458px;
    margin: 0 auto;
}
.form-control{
/*    background: #f1f1f1 !important;*/
       margin-bottom: 0px !important;  
      height: 45px !important;
      font-family: 'Poppins' !important;
}
.form-label-group{
    padding-bottom: 0px !important;
    /* height: 85px !important; */
}
.form-control:focus{
    color: #495057;
    background-color: #fff;
    border-color: #ced4da;
    outline: 0;
    box-shadow: none !important;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus{
      border-color: transparent !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgb(84, 84, 84,0.15) !important;
}
.sw-logo{
    margin: 0 auto;
    height: 73.41px;
    max-width:201px ;
    text-align: center;
}
.sw-logo img {
    width: 220px;
}
.form-col {
  min-height: 100% !important;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh !important; /* These two lines are counted as one :-)       */
  display: flex !important;
  align-items: center !important;
  width: 90%;
    margin: 0 auto;
    display: flex !important;
}
.myflex-5{
  flex: 1 1;
}
.btn-login{
    color: #fff;
    background-color: #1b1b1b !important;
    border-color: #1b1b1b !important;
}
a.forgot-password-link,.query-link,.create-account-link {
    color: #38adab !important;
    font-weight: 500;
    font-family: 'Poppins';
    line-height: 2;
}
.query-text {
    margin-bottom: 0 !important;
    font-family: 'Poppins';
}
p.sw-login {
    font-family: 'Poppins';
    font-size: 14px;
    margin-bottom: 55px;
}
.error-login {
    color: #C54D51;
    font-family: 'Poppins';
    font-size: 14px;
    margin-bottom: 0;
}
div#error_login a {
    color: #c54d51;
}
#btn_login {
    margin-bottom: 10px;
}
.op-lg-sep {
    width: 100%;
    height: 40px !important;
}
.dsl-inline{
    width: 50%;
    float: left;
}
.dsr-inline{
    /* float: right; */
  display: inline-block;

}
div#error_login {
    border: 1px solid #C54D51 !important;
    margin-bottom: 30px;
}

input {
    font-family: 'Poppins';
}
/* Stylish Check and Radion Button Css - START */
input[type=radio].with-font,
input[type=checkbox].with-font {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
    
input[type=radio].with-font ~ label:before,
input[type=checkbox].with-font ~ label:before {
    font-family: 'Poppins';
    display: inline-block;
    content: "\f1db";
    letter-spacing: 15px;
    font-size: 1.2em;
    color: #535353;
    width: 1.2em;
}

input[type=radio].with-font:checked ~ label:before,
input[type=checkbox].with-font:checked ~ label:before  {
    content: "\f192";
    font-size: 1.2em;
    color: #333333;
    letter-spacing: 15px;
}
input[type=checkbox].with-font ~ label:before {        
    content: "\f096";
}
input[type=checkbox].with-font:checked ~ label:before {
    content: "\f046";        
    color: #333333;
}
input[type=radio].with-font:focus ~ label:before,
input[type=checkbox].with-font:focus ~ label:before,
input[type=radio].with-font:focus ~ label,
input[type=checkbox].with-font:focus ~ label
{                
    color: #333333;
}
/* Stylish Check and Radion Button Css - END */
.pl-0 {
    padding-top: 10px;
}
.tac, .pp{
color: #000 !important;
font-weight: 500;
font-family: 'Poppins';
}
.border-red {
    border: 1px solid #ff0000;
}
input::-webkit-input-placeholder {
  font-family: 'Poppins';
}
input::placeholder {
  font-family: 'Poppins';
}
button#btn_signup {
    font-family: 'Poppins';
    font-weight: 600;
    padding: 10px !important;
}
.error-field{
    border: 1px solid #ff0000 !important;
}
/*Slider Text Starts*/
.thumbnail {
    display: block !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
    border: none !important;
}
.item {
    background: #fff;
    text-align: center;
    height: 150px;
}






 .item {
  opacity: 0;
  left: 0;
  transition: opacity 0.1s ease-out !important;
}
/*i.fa {
    margin-top: 50px;
}*/
/*Slider Text End*/
label.form-check-label.cls-theme-label {
    font-size: 14px;
    color: #161616;
    font-weight: normal;
    font-family: 'Poppins' !important;
}
.query-link{
    text-shadow: none !important;
}
.grecaptcha-badge {
    z-index: 1;
}
@media only screen and (max-width:767px) and (min-width:280px) {
.hand img{
    height: 29px !important;
    width: 29px !important;
}
    .grecaptcha-badge {
    display: none;
}   
.form-col,.login100-more,.wrap-login100{
  width: 100% !important;
}
.wrap-login100 {
    min-width: 100%;
}
.form-col{
    display: block !important;
    width: 100% !important; 
}
button#btn_signup{
  font-size: 16px;
  padding: 13px !important;
}
.op-lg-sep {
    width: 100%;
    height: 10px !important;
}

p.sw-login {
    margin-bottom: 25px !important;
}
.card-signin .card-title {
  margin-bottom: 0.5rem !important;
}
}
@media only screen and (max-width:360px) and (min-width:280px) {
.card-signin .card-body {
    margin: 0 auto;
    width: 100%;
    padding: 10px 20px 20px 20px; 
}
.my-5 {
    margin-top: 0 !important;
}
}
@media only screen and (max-width:767px) and (min-width:361px) {
.card-signin .card-body {
    margin: 0 auto;
    width: 100%;
    padding: 20px; 
}
}

@media only screen and (max-width:996px) and (min-width:768px) {
.form-col,.login100-more,.wrap-login100{
  width: 100% !important;
}
.wrap-login100 {
    min-width: 100%;
}
.form-col{
    display: block !important;
    width: 100% !important; 
}
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}
.PhoneInputInput{
    height: 30px !important;
    border: none !important; 
    outline: none;
}
.error-email{
    margin-top: -24px !important;
}

@font-face {
    font-family: Bariol;
    src: url(/static/media/bariol_regular-webfont.e4213a39.eot);
    src: url(/static/media/bariol_regular-webfontd41d.e4213a39.eot?#iefix) format("embedded-opentype"),
     url(/static/media/bariol_regular-webfont.7d0cf79e.svg) format('svg'),
      url(/static/media/bariol_regular-webfont.6c235190.ttf) format('truetype'),
       url(/static/media/bariol_regular-webfont.38c3b9e9.woff) format('woff'),
        url(/static/media/Bariol_Regular.6a64a521.otf) format('otf'),
         url(/static/media/bariol_regular-webfont.6577b355.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Book';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Book'), 
    url(/static/media/Gotham-Book.65288315.woff) format('woff'),
    url(/static/media/Gotham-Book.7fe2d669.eot),
    url(/static/media/Gotham-Bookd41d.7fe2d669.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Gotham-Book.79ab7007.ttf) format('truetype'),
    url(/static/media/Gotham-Book.92032bfd.svg#Gotham-Book) format('svg'),
    url(/static/media/Gotham-Book.e37cf599.otf) format('opentype');   
}
@font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url(/static/media/GothamMedium.d23ef85d.woff) format('woff'),
    url(/static/media/GothamMedium.de228587.woff2) format('woff2'),
    url(/static/media/GothamMedium.ed646164.eot),
    url(/static/media/GothamMediumd41d.ed646164.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamMedium.c3f574b2.ttf) format('truetype'),
    url(/static/media/GothamMedium.9faf290f.svg#Gotham-Book) format('svg'),
    url(/static/media/GothamMedium.bcd733e6.otf) format('opentype'); 
}
@font-face {
    font-family: 'Bariol-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Bariol-Bold'), 
    url(/static/media/Bariol-Bold.bc734d95.woff) format('woff'),
    url(/static/media/Bariol-Bold.d458cb75.eot),
    url(/static/media/Bariol-Boldd41d.d458cb75.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Bariol-Bold.fa0ddc94.ttf) format('truetype'),
    url(/static/media/Bariol-Bold.0ed2ea7a.svg#Bariol-Regular) format('svg');
}








  .template-page{
    overflow-x: hidden;
}
.main-header.header-custom.header-transparency {
    background: #fff !important;
}
  .section_seprator {
    width: 100%;
    height: 80px;
}
.sw_heading {
    font-size: 38px!important;
    color: #222;
    font-family: 'Gotham-Medium' !important;
    line-height: 56px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}
.sw_tcs_heading {
    font-family: 'Gotham-Medium'!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
}
ul.sw_tcs_list li {
    color: #4d545b;
    font-family: 'Bariol' !important;
    font-size: 23px;
    padding: 10px 0px;
}
ul.sw_tcs_list {
    list-style: disc;
}
.horizontal_seprator {
    border: 1px solid #a1a1a1;
    width: 100%;
}
p.sw_tcs_text {
    font-size: 23px;
    line-height: 32px;
    font-family: Bariol !important;
    color: #4d545b;
    margin: 10px 0;
}
@media only screen and (max-width: 1600px) and (min-width: 1200px){
    .sw_tcs_heading {
        font-size: 24px !important;
    }
    .sw_heading {
        font-size: 28px!important;
    }
    ul.sw_tcs_list li, p.sw_tcs_text {
        font-size: 20px !important;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 768px){
.sw_tcs_heading {
    font-size: 20px !important;
}
.sw_heading {
    font-size: 24px!important;
}
ul.sw_tcs_list li, p.sw_tcs_text {
    font-size: 18px !important;
}
.section_seprator {
    width: 80px;
    height: 40px;
}
ul.sw_tcs_list {
    padding: 0;
}
}
@media only screen and (max-width: 767px) and (min-width: 280px){
    .sw_tcs_heading {
        font-size: 20px !important;
    }
    .sw_heading {
        font-size: 22px!important;
    }
    ul.sw_tcs_list li, p.sw_tcs_text {
        font-size: 18px !important;
    }
    .section_seprator {
        width: 80px;
        height: 30px;
    }
    ul.sw_tcs_list {
        padding: 0;
    }
    }
@media only screen and (max-width: 1366px) and (min-width: 280px){
}
/* .paddtopbottom{
    padding-top: 40px !important;
    padding-bottom: 40px !important;
} */

.padding {
    padding: 3rem !important
}
.info-box{
    background-color: #fff;
   
    padding: 20px;
    height: 434px;


}

.row>* {
    padding-left: 0;
}
.dashboard-list a{
    color: #808080 ;
   font-weight: 400;
    font-family: 'poppins' !important;
    font-size: 20px !important;
    text-decoration: none !important;
    transition-duration: .2s;
}
.black{
    color: #000000 !important;
    font-weight: bold;
}
.btnhover{
    margin: 0 !important ;

}
.btnhover:hover{
    background-color: #fbc504;
   
}

.design-category-list .dashboard-list{
   }
.user-profile-head{
    font-size: 25px;
    font-family: 'poppins';
    font-weight: 400;
    padding: 6px 0px 4px 15px;
    /* margin: 0px; */
    border-left: 4px solid #fbc504;
}
.icon-padd{
    padding: 15px 0px 15px 0px;
}
.user-dash-head{
    font-size: 43px;
    font-family: 'poppins';
    font-weight: 500;
    margin-bottom: 20px;
}
.card-background{
    background-color: #fbc504;
    padding: 10px;
}
.cont-centr{
    align-self: center;
}
.card-head{
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 400;
    margin-bottom: 0px;
    color: black !important;
}
.user-card-full {
    overflow: hidden
}

.card {
    border-radius: 5px;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
}

.m-r-0 {
    margin-right: 0px
}

.m-l-0 {
    margin-left: 0px
}

.user-card-full .user-profile {
    border-radius: 5px 0 0 5px
}

.bg-c-lite-green {
    background: linear-gradient(to right, #000000, #40352f);
}

.user-profile {
    padding: 20px 0
}

.card-block {
    padding: 1.25rem
}

.m-b-25 {
    margin-bottom: 25px
}

.img-radius {
    border-radius: 5px
}

h6 {
    font-size: 14px
}

.card .card-block p {
    line-height: 25px
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px
    }
}

.card-block {
    padding: 1.25rem
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.m-b-20 {
    margin-bottom: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.card .card-block p {
    line-height: 25px
}

.m-b-10 {
    margin-bottom: 10px
}

.text-muted {
    color: #919aa3 !important
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.f-w-600 {
    font-weight: 600
}

.m-b-20 {
    margin-bottom: 20px
}

.m-t-40 {
    margin-top: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.m-b-10 {
    margin-bottom: 10px
}

.m-t-40 {
    margin-top: 20px
}

.user-card-full .social-link li {
    display: inline-block
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    transition: all 0.3s ease-in-out
}


/* orderCard CSS */





.order-card {
    background-color: #fff;
    width: 300px;
    border: none;
    border-radius: 16px
}

.info {
    line-height: 19px
}

.name {
    color: #4c40e0;
    font-size: 18px;
    font-weight: 600
}

.order {
    font-size: 14px;
    font-weight: 400;
    color: #b7b5b5
}

.detail {
    line-height: 19px
}

.summery {
    color: #d2cfcf;
    font-weight: 400;
    font-size: 13px
}


.text {
    line-height: 15px
}

.new {
    color: #000;
    font-size: 14px;
    font-weight: 600
}

.money {
    font-size: 14px;
    font-weight: 500
}

.address {
    color: #d2cfcf;
    font-weight: 500;
    font-size: 14px
}

.last {
    font-size: 10px;
    font-weight: 500
}

.address-line {
    color: #4C40E0;
    font-size: 11px;
    font-weight: 700
}

.log-div{
    margin: 25px auto;
}
.padding-cards{
    padding: 0px 5px 0px 5px !important;
}
@media only screen and (max-width: 500px) {
     .media-div{
       display: block !important;
    }
}



@media (min-width: 768px) and (max-width: 1324px)  {
    /* CSS */
    
  }
  
  @media (min-width: 481px) and (max-width: 768px) {
 


    /* CSS */
    .step-description {
    display: none !important;
    }
  
  
  }
  

  @media (min-width: 481px) and (max-width: 1200px) {
    .main{

        padding-top: 0;;
   
    }

    
    /* CSS */
    .step-description {
    display: none !important;
    }
  
  
  }
  @media screen and  (min-width:1025px) {
    .bottombar{
        display: none;
    }
    }
  @media (min-width: 481px) and (max-width: 1024px) {
    .main{

        padding-top: 0 ;
        margin: 0 10px !important;
    }
    .main{
      padding: 0 !important;
    }
    .iconss{
   
   font-size: 20px !important;
    }
    .cl-item-web span{
        display: block !important;
       
        }
    .main{

        padding-top: 0;;
   
    }
    .listr{
        padding-left: 0 !important;
        text-align: center !important;
    }
    ul {
        padding-left: 0 !important;
}
    

    .sidebar{
        display: none !important;
    }
    .cl-item-name{
        
    }
    .tabletnavicons{
        display: block !important;
        
    }
    
    .bottombar{
        
        
    }
    
    /* CSS */
    .step-description {
    display: none !important;
    }
  
  
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
  
    .category_card{
        display: flex;
        justify-content: center;
    }
    .main{
    padding: 0 !important;
    }
    

   
   

    .Search{
        display: none;
    }
    .left{
        display: none;
    }
    .main{

        padding-top: 0;
   
    }
    .mainwrapper{
        padding: 0 !important;
    }

    .main{

        padding-top: 0;;
   
    }
    .sidebar{
        display: none !important;
    }
    .cl-item-web{
        padding: 0 !important;
    }
    .cl-item-web span{
    display: block !important;
    font-size: 14px !important;

    }
    .cl-item-web span{
    }
    .cl-item-web{
        margin: 0 !important;
       
    padding: 0 !important;
    }
    .bottomul{
        padding: 0 !important;
        padding-bottom: 0 !important;
    }
    .listr{
        padding-left: 0 !important;
        text-align: center !important;
    }
    ul {
        padding-left: 0 !important;
}
.iconss{
   
    font-size: 20px !important;
     }


  }
  


@font-face {
    font-family: Bariol;
    src: url(/static/media/bariol_regular-webfont.e4213a39.eot);
    src: url(/static/media/bariol_regular-webfontd41d.e4213a39.eot?#iefix) format("embedded-opentype"),
     url(/static/media/bariol_regular-webfont.7d0cf79e.svg) format('svg'),
      url(/static/media/bariol_regular-webfont.6c235190.ttf) format('truetype'),
       url(/static/media/bariol_regular-webfont.38c3b9e9.woff) format('woff'),
        url(/static/media/Bariol_Regular.6a64a521.otf) format('otf'),
         url(/static/media/bariol_regular-webfont.6577b355.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: GothamBook;
    src: url(/static/media/Gotham-Book.7fe2d669.eot);
    src: url(/static/media/Gotham-Bookd41d.7fe2d669.eot?#iefix) format("embedded-opentype"),
     url(/static/media/Gotham-Book.65288315.woff) format("woff"), 
     url(/static/media/Gotham-Book.79ab7007.ttf) format('truetype'),
      url(/static/media/Gotham-Book.92032bfd.svg#Gotham-Book) format("svg"), 
      url(/static/media/Gotham-Book.e37cf599.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: BariolBold;
    src: url(/static/media/bariol_bold-webfont.fa0ddc94.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gotham-Medium'), 
    url(/static/media/GothamMedium.d23ef85d.woff) format('woff'),
    url(/static/media/GothamMedium.de228587.woff2) format('woff2'),
    url(/static/media/GothamMedium.ed646164.eot),
    url(/static/media/GothamMediumd41d.ed646164.eot?#iefix) format('embedded-opentype'),
    url(/static/media/GothamMedium.c3f574b2.ttf) format('truetype'),
    url(/static/media/GothamMedium.9faf290f.svg#Gotham-Book) format('svg'),
    url(/static/media/GothamMedium.bcd733e6.otf) format('opentype'); 
}
/*  css start */
.designprocess-bg {
    padding: 30px;
    border-radius: 8px;
    height: 290px;
}
.websiteredesign-bg{
    padding: 20px;
}
.featureslist{
    padding: 0px 20px 10px 20px;
}
.featurespoints{
    font-size: 15px;
    font-family: 'Poppins';
    color: #fff;
    font-weight: 300;
}
/*  css start */


.template-page{
    overflow-x: hidden;
}
section#sw_rdw_one_caption {
    background: #00E90B;
}
.sw-tour-slider{
  position: relative;
  text-align: center;
  color: white;
}
p.icon_sub_text {
    font-size: 18px;
    font-family: 'Bariol';
    text-align: center;
}
ul.redesign_list li {
    font-size: 23px;
    font-family: Bariol;
    color: #4d545b;
    line-height: 1.8;
    list-style: disc;
}
ul.redesign_list {
    padding-left: 18px;
}
section#sw_tour_one_image .col-sm {
    padding: 0 !important;
    margin-top: -1px !important;
}
.container-fluid.sw-tour-slider {
    padding: 0 !important;
}
img.sw_lg{
width: 100% !important;
}
p.sw_slider_sup {
    font-size: 20px;
    color: #000;
    font-family: Bariol!important;
    text-transform: uppercase;
    text-shadow: none!important;
}
.sw_slider_heading_redesign {
    color: #ffffff!important;
    font-family: 'Gotham-Medium' !important;
    font-size: 42px;
    text-transform: capitalize!important;
    font-weight: bolder!important;
    text-shadow: none!important;
    line-height: 1.3;
    margin: 0 0 20px 0 !important;
    text-transform: inherit !important;
    text-align: center !important;
}
p.sw_slider_subtext_redesign {
    color: #ffffff;
    font-family: Bariol;
    font-size: 23px;
    line-height: 1.5;
    text-shadow: none!important;
    margin: 0 0 30px 0 !important;
    text-align: center !important;
}
section#sw_home .col-sm {
    padding: 0;
}
.sw_features_div {
    padding: 16px 0 0 98px;
}
.vertical_seprator {
    background: #e5e5e5;
    bottom: 0;
    content: " ";
    margin-left: -15px;
    position: absolute;
    top: 0;
    width: .3ch;
}
h3.sw_features_heading {
    margin: 0;
    font-size: 16px;
    font-family: GothamBook;
    color: #292929!important;
    font-weight: 700;
}
ul.sw_features_list {
    margin-left: 0!important;
    padding-left: 0;
    font-size: 14px!important;
    font-family: GothamBook!important;
    color: #292929;
}
.sw_features_seprator {
    width: 100%;
    height: 30px;
}
.row.sw_section_one {
    padding: 50px 40px;
    border-radius: 9px;
    box-shadow: 0 19px 30px 0 rgba(84, 84, 84, .3);
}
.section_seprator {
    width: 80px;
    height: 80px;
}
.sw_online_heading {
    font-size: 38px!important;
    color: #222;
    font-family: 'Gotham-Medium' !important;
    line-height: 56px;
    font-weight: 700;
    text-align: center;
}
.sw_ol_sub_text {
    font-size: 23px;
    font-family: Bariol;
    text-align: center;
    color: #4d545b;
    line-height: 33px;
    width: 80%;
    margin: 0 auto;
}
.sw_fm_heading {
    font-size: 21px;
    line-height: 26px;
    color: #010101;
    text-align: center;
    font-family: GothamBook!important;
    font-weight: bolder!important;
    margin: 0!important;
}
.sw_fm {
    margin: 0 auto;
    text-align: center;
}
section#sw_rdw_threee{
background: #00E90B;
background:#00E90B;
}
.sw_div_center{
text-align: center !important;
}
.white{
    color: #ffffff !important;
}
.sw_sep_30 {
    width: 100%;
    height: 30px;
}
.sw_sep_15 {
    width: 100%;
    height: 15px;
}
.sw_s4_sup {
    color: #80c3c2!important;
    font-size: 21px;
    font-family: 'Gotham-Medium'!important;
}
.sw_s4_heading {
    font-family: 'Gotham-Medium'!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
}
.sw_s4_heading_center{
   font-family: 'Gotham-Medium'!important;
    font-weight: 700!important;
    font-size: 36px;
    color: #292929;
    width: 100%;
    text-align: center;
}
.sw_s4_text {
    font-size: 23px;
    line-height: 32px;
    font-family: Bariol;
    color: #4d545b;
    margin: 20px 0;
}
.sw_redesign_button{
    color: #ffffff!important;
    border: 1px solid #ffffff!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 10px;
}
.sw_redesign_button:hover {
    background: #ffffff!important;
    text-decoration: none!important;
    border-color: #ffffff!important;
    color: #d9b717!important;
}
.sw_redesign_button_sc {
    color: #00E90B!important;
    border: 1px solid #00E90B!important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 10px;
}
.sw_redesign_button_sc:hover {
    background: #00E90B !important;
    text-decoration: none!important;
    border-color: #00E90B!important;
    color: #fff!important;
}

.sw_redesign_button_color{
      color: white !important;
    border: 1px solid #ffffff !important;
    padding: 10px 30px;
    text-decoration: none!important;
    border-radius: 5px;
    font-family: Bariol;
    font-weight: bold;
    font-size: 19px;
    min-width: 240px!important;
    display: block;
    text-align: center;
    background: 0 0;
    width: initial;
    transition: all .5s ease-in-out;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 20px !important; 
}
.sw_redesign_button_color:hover {
    background: #ffffff!important;
    text-decoration: none!important;
    border-color: #ffffff!important;
    color: #00E90B !important;
}
h2.sw_con_heading {
    color: #fff;
    text-align: center!important;
    font-size: 42px;
    font-family: GothamBook;
    font-weight: bolder;
    margin: 0 auto;
    line-height: 46px;
}
h3.sw_con_su_heading {
    font-size: 22px;
    color: #fff;
    font-family: GothamBook;
    text-align: center!important;
    margin: 0 auto;
    line-height: 29px;
}
.sw_btn_div {
    text-align: center;
    margin: 0 auto;
}
.sw_posu_text {
    font-size: 23px;
    line-height: 33px;
    color: #292929;
    font-family: Bariol;
    text-align: center;
    width: 100%;
}

.po_icon {
    text-align: center;
    padding-bottom: 15px;
}
h3.sw_suheading {
    text-align: center;
    font-size: 22px;
    font-family: 'Gotham-Medium';
    line-height: 30px;
}
.col-center {
    margin: 0 auto;
    float: none!important;
}
#custom-footer {
    display: block!important;
}
.horizontal_seprator {
    border: 1px solid #a1a1a1;
}
.sw_rimg{
width: 100%;
float: right;
}
.sw_limg{
    margin-top: -60px;
    width: 100%;
    height: 100%;
}
@media only screen and (max-width:1600px) and (min-width:1200px) {
    .sw_slider_heading_redesign {
        font-size: 30px!important;
    }
    .sw_online_heading,.sw_s4_heading_center {
        font-size: 28px!important;
    }
    .sw_s4_heading{
        font-size: 24px !important; 
    }
    p.sw_slider_subtext_redesign,.sw_s4_text,ul.redesign_list li{
        font-size:20px;
    }
}
@media only screen and (max-width:1366px) and (min-width:1024px) {
.row.sw_section_one {
    padding: 50px 11px;
}
}
@media only screen and (max-width:1024px) and (min-width:768px) {
section#sw_home {
    margin-top: 0!important;
}
.sw_slider_heading_redesign {
    font-size: 26px!important;
    line-height: 1.3!important;
}
p.sw_slider_subtext_redesign {
    font-size: 18px!important;
    line-height: 1.2!important;
    margin: 20px 0 !important;
}
.sw_s4_text{
    font-size: 18px !important;
}
ul.redesign_list li {
    font-size: 18px;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px;
}
.sw_s4_heading,.sw_s4_heading_center {
    font-size: 20px;
}
.sw_online_heading {
    font-size: 24px!important;
}
.sw_online_heading br{
display: none !important;
}
.vertical_seprator {
    display: none!important;
}
h3.sw_suheading {
    font-size: 18px !important;
    line-height: 25px !important;
}
.sw_redesign_button,.sw_redesign_button_color {
    display: block !important;
    margin: 10px auto 10px auto !important;
    padding: 10px !important;
    font-size: 16px;
    width: 230px;
}
.sw_redesign_button_sc{
    /*display: block !important;*/
    margin: 10px 0 10px 0 !important;
    padding: 10px !important;
    font-size: 16px;
    width: 230px;
}
}
@media only screen and (max-width:1023px) and (min-width:768px) {
.header-custom {
    background-color: #ffffff !important;
}
.section_seprator {
    width: 80px;
    height: 40px;
}
.sw_slider_heading_redesign {
    font-size: 26px!important;
    line-height: 1.3!important;
    margin-bottom: 10px !important;
}
}

@media only screen and (max-width:767px) and (min-width:280px) {
a.sw-brand-logo img {
    width: 155px!important;
    padding: 10px 0 12px 24px!important;
}
.header-custom {
    padding: 4px!important;
}
section#sw_home {
    margin-top: 0!important;
}
.sw_online_heading {
    font-size: 22px!important;
    line-height: 1.3;
}
.sw_wk_rating_icon img {
    width: 90px!important;
    height: 81px!important;
}
.sw_s4_heading,.sw_s4_heading_center {
    font-size: 20px!important;
}
.sw_s4_text {
    font-size: 18px!important;
    margin: 0!important;
}
.sw_posu_heading, h2.sw_con_heading {
    font-size: 28px!important;
}
.sw_redesign_button {
    display: block!important;
    margin: 10px 0!important;
}
.sw_slider_heading_redesign {
    font-size: 24px!important;
    margin: 6px 0 !important;
}
.sw_slider_heading_redesign br,.sw_slider_subtext_redesign br{
    display: none !important;
}
p.sw_slider_subtext_redesign {
    font-size: 18px!important;
    line-height: 1.2!important;
}
p.sw_slider_sup {
    font-size: 18px!important;
    margin: 0!important;
}
.row.sw_section_one {
    padding: 50px 10px!important;
}
.sw_ol_sub_text {
    width: 100%}
.sw_wk_heading {
    font-size: 26px!important;
    line-height: 34px!important;
}
.section_seprator {
    width: 80px!important;
    height: 30px!important;
}
.po_icon img {
    width: 100px !important;
}
.sw_redesign_button,.sw_redesign_button_color, .sw_redesign_button_sc {
    display: block !important;
    margin: 10px 0 !important;
    font-size: 16px;
}
 .sw_redesign_button_color{
    margin: 10px 0 20px 0 !important;
 }
h3.sw_suheading {
    font-size: 18px !important;
}
a.sw_tour_sac,a.sw_tour_succeed{
    margin: 0 !important;
    display: block !important;
}
.header-custom {
    background-color: #ffffff !important;
}
ul.redesign_list li {
    font-size: 19px !important;
}
.mb-col-sep{
width: 100%;
height: 40px;
}
.flex-switch{
    display: flex !important;
    flex-direction: column-reverse;
}
.section_seprator.fl-spxs{
height: 0px !important;
}
}
@media only screen and (max-width: 1366px) and (min-width: 280px){
  /* img{
    width: 100% !important;
} */
.po_icon img {
    width: 100px !important;
}
}
body {
  margin: 0;
  font-family: 'Product Sans !important' ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y:hidden;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* // header css */

.tawk-custom-color{
  bottom: 61px !important;
}



































